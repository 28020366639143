import { Box, ListItem, ListItemIcon, useTheme } from "@mui/material";
import { AccountInfo } from "@/models/accountInfo";
import InitialsAvatar from "@/components/Avatar";
import CrownIcon from "@/components/Icons/Crown";
import { ReactNode } from "react";
import * as Icons from "@mui/icons-material";

export default function MemberDrawerListItem({
	account,
	myAccount,
	isCurrentFeedAdmin,
	isDm,
	children,
}: {
	account: AccountInfo;
	myAccount: AccountInfo;
	isCurrentFeedAdmin: boolean;
	isDm?: boolean;
	children: ReactNode;
}) {
	const theme = useTheme();
	const initials = account?.name?.[0] || "?";
	const isMe = account.id === myAccount?.id;

	return (
		<ListItem
			className="menu-options-list"
			sx={{
				display: "flex",
				width: "100%",
				position: "relative",
				p: 1,
				alignItems: "center",
				borderRadius: "0.5rem",
				boxShadow: isMe ? `0 0 0 1px ${theme.palette.brand.primary.main}` : "",
				"&:hover": {
					background:
						!isDm && isCurrentFeedAdmin && theme.palette.secondary.main,
				},
			}}
		>
			<Box sx={{ width: "40px", mr: 1 }}>
				<ListItemIcon>
					<InitialsAvatar
						initials={initials}
						color={account.avatarColor}
						sxProps={{
							fontSize: "16px",
							color: theme.palette.primary.main,
							width: 40,
							height: 40,
						}}
					/>
				</ListItemIcon>
			</Box>
			<Box>
				<Box>{account.name || account.id}</Box>
				<Box sx={{ display: "flex", alignItems: "center", color: "#ccc" }}>
					{account.isAdmin && (
						<Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
							<CrownIcon
								name="Feed Admin"
								fill="#ccc"
								height={16}
								width={16}
								aria-label="Admin"
							/>
						</Box>
					)}
					{account.isMuted && (
						<Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
							<Icons.VolumeOff fontSize="small" role="img" aria-label="Muted" />
						</Box>
					)}
				</Box>
			</Box>

			{isCurrentFeedAdmin && (
				<Box
					className="menu-options-button"
					sx={{
						position: "absolute",
						height: "calc(100% - 1rem)",
						width: "70px",
						right: "0.5rem",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "end",
							height: "100%",
						}}
					>
						{children}
					</Box>
				</Box>
			)}
		</ListItem>
	);
}
