import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

interface Props {
	setStatus: (value: boolean) => void;
	label?: string;
	text?: string;
	deleteText?: string;
}
export default function ConfirmDiscardChanges({
	setStatus,
	label,
	text,
	deleteText,
}: Props) {
	const theme = useTheme();
	const [disabled, setDisabled] = useState<boolean>(false);
	return (
		<Box
			sx={{
				position: "absolute",
				background: "rgba(0,0,0, 0.5)",
				backdropFilter: "blur(4px)",
				width: "100%",
				height: "100%",
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
				zIndex: 10,
			}}
		>
			<Box
				sx={{
					width: "480px",
					p: 4,
					borderRadius: "1rem",
					background: theme.palette.secondary.dark,
					textAlign: "center",
				}}
			>
				{label && (
					<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
						{label}
					</Typography>
				)}
				{text && (
					<Typography sx={{ fontWeight: 600, mb: 2 }}>{text}</Typography>
				)}
				<Stack
					sx={{
						flexDirection: { xs: "column", sm: "row" },
						width: "100%",
						gap: 2,
					}}
				>
					<Button
						disabled={disabled}
						variant="outlined"
						sx={{ order: { xs: 1, sm: 0 } }}
						onClick={() => setStatus(false)}
					>
						Cancel
					</Button>
					<LoadingButton
						loading={disabled}
						variant="contained"
						color="error"
						sx={{ order: { xs: 0, sm: 1 } }}
						onClick={() => {
							setStatus(true);
							setDisabled(() => true);
						}}
					>
						{deleteText ? deleteText : "Discard"}
					</LoadingButton>
				</Stack>
			</Box>
		</Box>
	);
}
