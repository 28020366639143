import { AudioAppContext } from "@/models/AudioAppContextProvider";
import type { SliderProps, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import type { IconButtonProps } from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import React, { useContext, useState } from "react";
import PlayPauseButton from "../PlayPauseButton";
import PlaybackTimeline from "../PlaybackTimeline";
import { TimeStamp } from "../TimeStamp";
import { AudioControls, connectControlsToElement } from "./timeline";

export type AudioSourceAndType = {
	src: string;
	type?: string;
};

export interface AudioPlayerProps {
	id?: string;
	inline?: boolean;
	paperize?: boolean;
	showTimestamps?: boolean;
	playPauseIconButtonProps?: IconButtonProps;
	containerSx?: SxProps<Theme>;
	containerHeight?: string | number;
	containerWidth?: string | number;

	onPlay?: (time: number) => void;
	onPause?: (time: number) => void;
	onFinished?: () => void;
	onSeeked?: (time: number) => void;
	onSeeking?: (time: number) => void;

	duration?: number;
	inlineSliderProps?: SliderProps;
	size?: "small" | "medium" | "large";
	disabledPlayButton: boolean;
}

export default function AudioPlayer(props: AudioPlayerProps) {
	const {
		id,
		inline = false,
		paperize = true,
		showTimestamps = true,
		playPauseIconButtonProps,
		containerSx,
		containerHeight = "auto",
		containerWidth = 250,
		onPlay,
		onPause,
		onFinished,
		onSeeked,
		onSeeking,
		size,
		duration,
		disabledPlayButton = false,
	} = props;

	const [loading, setLoading] = useState(false);
	// const [progress, setProgress] = useState(0);
	const [playing, setPlaying] = useState(false);
	const [position, setPosition] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [endTime, setEndTime] = useState(duration || 0);
	const [controls, setControls] = useState<AudioControls>();

	const { audioElement, activeQueueItemId, queuePlaying } =
		useContext(AudioAppContext);

	React.useEffect(() => {
		setEndTime(duration || 0);
	}, [duration]);

	React.useEffect(() => {
		const audioControls: AudioControls = {
			id,
			setLoading,
			setCurrentTime,
			setEndTime,
			setPosition,
			setPlaying,
			onPlay,
			onPause,
			onFinished,
			onSeeked,
			onSeeking,
		};

		console.log("Setting local audio controls");
		setControls(audioControls);
	}, [id, onPlay, onPause, onFinished, onSeeked, onSeeking]);

	React.useEffect(() => {
		if (activeQueueItemId !== id || !controls) return;
		// reset playing state if activeItem is re-rendered
		if (activeQueueItemId === id) {
			if (queuePlaying && !playing) {
				setPlaying(queuePlaying);
			}
			setCurrentTime(audioElement?.currentTime);
			const { currentTime, duration } = audioElement;
			if (currentTime && duration) {
				setPosition((audioElement?.currentTime / audioElement?.duration) * 100);
			}
		}
		return connectControlsToElement(controls, audioElement);
	}, [activeQueueItemId, controls]);

	if (!audioElement || !controls) return null;

	const mergedContainerStyle = {
		height: containerHeight,
		width: containerWidth,

		...(containerSx || {}),
	};

	return (
		<Stack
			sx={mergedContainerStyle}
			direction={inline ? "row" : "column"}
			component={paperize ? Paper : "div"}
			alignItems="center"
		>
			<PlayPauseButton
				disabled={disabledPlayButton}
				playing={playing}
				playPauseIconButtonProps={{
					size: size,
					...playPauseIconButtonProps,
				}}
				id={id}
			/>
			<Stack
				component={Box}
				direction="row"
				flexGrow={loading ? 0 : 1}
				height="100%"
				width="100%"
				alignItems="center"
				spacing={1}
			>
				<TimeStamp
					position="left"
					time={currentTime}
					loading={loading}
					show={showTimestamps}
				/>
				<Box flexGrow={1} height="100%" width="100%" alignItems="center">
					{!loading && (
						<Box mx={"4px"} display="flex" alignItems="center" height="100%">
							<PlaybackTimeline
								duration={endTime}
								feedItemId={id}
								position={position}
								size={size}
							/>
						</Box>
					)}
				</Box>
				<TimeStamp
					position="right"
					time={endTime}
					loading={loading}
					show={showTimestamps}
				/>
			</Stack>
			{!inline ? (
				<Box display="flex" justifyContent="center" alignItems="center">
					<PlayPauseButton
						playing={playing}
						playPauseIconButtonProps={playPauseIconButtonProps}
						id={id}
					/>
				</Box>
			) : null}
		</Stack>
	);
}
