import InitialsAvatar from "@/components/Avatar";
import { FullInput } from "@/components/Utils";
import {
	MappedWorkspaceMembership,
	mapWorkspaceMemberships,
	WorkspaceMember,
	workspaceMemberships,
} from "@/data/liveQueries/workspaceMemberships";
import { WorkspaceMembership } from "@/generated/client";
import * as Icons from "@mui/icons-material";
import {
	Box,
	Checkbox,
	Link,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Radio,
	Typography,
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { VList } from "virtua";
import { useLiveQuery } from "electric-sql/react";
import { DataContext } from "@/models/DataProvider";
import { useElectric } from "@/electric/ElectricWrapper";
import Fuse from "fuse.js";

export function SelectWorkspaceMemberships({
	saving = false,
	selectedUsers,
	selectedUsersHandler,
	handleWorkspaceInvite,
	isWorkspaceAdmin,
	workspaceId,
	theme,
	alreadyCheckedAccountIds = [],
	accountIdsToExclude = [],
	selectType = "checkbox",
}: {
	saving: boolean;
	workspaceId: string;
	selectedUsers: string[];
	selectedUsersHandler: (selectedUsers: string[]) => void;
	handleWorkspaceInvite: () => void;
	isWorkspaceAdmin: WorkspaceMembership;
	theme: any;
	alreadyCheckedAccountIds?: string[];
	accountIdsToExclude?: string[];
	selectType?: string;
}) {
	const { db } = useElectric();

	const [searchField, setSearchField] = useState<string>("");

	const { currentFeedPendingInvites } = useContext(DataContext);

	const pendingInviteIds = currentFeedPendingInvites.map(
		(invite) => invite.workspaceMembershipId,
	);

	const { workspaceMembers } = workspaceMemberships(useLiveQuery, db, {
		workspaceId,
	});

	const mappedMembers = mapWorkspaceMemberships(
		workspaceMembers,
		alreadyCheckedAccountIds,
		pendingInviteIds,
	)?.filter((member) => !accountIdsToExclude.includes(member.accountId));

	// fuse search engine
	const fuse = new Fuse(mappedMembers, {
		// https://www.fusejs.io/examples.html#extended-search rules
		useExtendedSearch: true,
		keys: [
			"name",
			"accountEmail",
			"accountPhoneNumber",
			"inviteEmail",
			"invitePhoneNumber",
		],
	});

	const searchedMembers = useMemo(() => {
		if (searchField) {
			// `'` denotes includes this search text
			return fuse.search(`'${searchField}`)?.map((result) => result.item);
		}
		return mappedMembers;
	}, [searchField, mappedMembers]);

	const handleToggle = (id: string) => () => {
		if (selectType === "checkbox") {
			const currentIndex = selectedUsers.indexOf(id);
			const newChecked = [...selectedUsers];

			if (currentIndex === -1) {
				newChecked.push(id);
			} else {
				newChecked.splice(currentIndex, 1);
			}
			selectedUsersHandler(newChecked);
		} else {
			selectedUsersHandler([id]);
		}
	};

	const secondaryAction = (member: MappedWorkspaceMembership) => {
		if (selectType === "checkbox") {
			return member?.isCurrentFeedMember || member?.isPendingFeedMember ? (
				<Icons.CheckBox color="warning" sx={{ mr: -0.25 }} />
			) : (
				<Checkbox
					edge="end"
					onChange={handleToggle(member.membershipId)}
					checked={selectedUsers.indexOf(member.membershipId) !== -1}
					inputProps={{ "aria-labelledby": member.membershipId }}
					color="primary"
				/>
			);
		}
		return (
			<Radio
				edge="end"
				onChange={handleToggle(member.membershipId)}
				checked={selectedUsers.indexOf(member.membershipId) !== -1}
				inputProps={{ "aria-labelledby": member.membershipId }}
				color="primary"
			/>
		);
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<FullInput
					id="workspace-name"
					placeholder="Search workspace members"
					disabled={saving}
					value={searchField}
					callback={(e) => setSearchField(e.target.value)}
				/>
				{isWorkspaceAdmin && (
					<Typography sx={{ mt: 1, fontSize: 14, fontWeight: 500 }}>
						Don’t see who you are looking for?{" "}
						<Link
							component="button"
							style={{ color: theme.palette.primary.main }}
							onClick={handleWorkspaceInvite}
						>
							Invite users to workspace
						</Link>
					</Typography>
				)}
			</Box>
			<VList
				style={{
					width: "100%",
					height: 260,
					borderRadius: 8,
					border: `1.5px solid ${theme.palette.secondary.light}`,
					boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
					position: "relative",
					background: theme.palette.secondary.dark,
					overflow: searchedMembers?.length > 0 ? "auto" : "hidden",
					padding: "8px 10px",
				}}
			>
				{searchedMembers?.length > 0 ? (
					searchedMembers?.map((member, index) => (
						<ListItem
							key={member.membershipId}
							secondaryAction={secondaryAction(member)}
							disablePadding
						>
							<ListItemButton
								onClick={
									!member.isCurrentFeedMember
										? handleToggle(member.membershipId)
										: null
								}
								disableRipple
							>
								<ListItemAvatar>
									<InitialsAvatar
										initials={member?.name?.[0]}
										color={member?.avatarColor}
										sxProps={{
											fontSize: "16px",
											color: theme.palette.primary.main,
											width: 40,
											height: 40,
										}}
									/>
								</ListItemAvatar>
								<ListItemText
									id={member?.membershipId}
									primary={member?.displayName}
								/>
							</ListItemButton>
						</ListItem>
					))
				) : (
					<ListItem
						key={"no-results"}
						disablePadding
						sx={{ textAlign: "center" }}
					>
						<ListItemText
							primary={
								<Typography sx={{ fontWeight: 700 }}>No users found</Typography>
							}
						/>
					</ListItem>
				)}
			</VList>
		</>
	);
}
