import AudioEncodingsTable from "@/components/debug/AudioEncodingsTable";
import ItemCallRecordTable from "@/components/debug/ItemCallRecordTable";
import ItemFilesTable from "@/components/debug/ItemFilesTable";
import ItemInfoTable from "@/components/debug/ItemInfoTable";
import ItemLinksTable from "@/components/debug/ItemLinksTable";
import TranscriptionsTable from "@/components/debug/TranscriptionsTable";
import { useElectric } from "@/electric/ElectricWrapper";
import { AppContext } from "@/models/AppStateProvider";
import { Stack, Typography } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import React from "react";
import { useParams } from "react-router-dom";
import "regenerator-runtime/runtime";

export default function DebugItemId() {

	const params = useParams();

	const workspaceId = params.workspaceId as string;
	const feedId = params.feedId as string;
	const feedItemId = params.itemId as string;

	const { flags } = React.useContext(AppContext);

	const { db } = useElectric();

	const { results: item } = useLiveQuery(
		db.item.liveFirst({
			where: {
				id: feedItemId,
			},
		}),
	);

	const { results: currentFeed } = useLiveQuery(
		db.feed.liveFirst({
			where: {
				id: feedId,
			},
		}),
	);

	if (!currentFeed) {
		return (
			<Stack
				sx={{
					width: "100%",
					height: "100%",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				Feed Not Found
			</Stack>
		);
	}

	if (!item) {
		return (
			<Stack
				sx={{
					width: "100%",
					height: "100%",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				Item Not Found
			</Stack>
		);
	}

	if (!flags.debugMode) {
		return (
			<Stack
				sx={{
					width: "100%",
					height: "100%",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				Debug Mode Disabled
			</Stack>
		);
	}

	return (
		<Stack spacing={2}>
			<Typography variant="h4">Item Info</Typography>
			<ItemInfoTable item={item} />
			<Typography variant="h4">Call Records</Typography>
			<ItemCallRecordTable item={item} />
			<Typography variant="h4">Files</Typography>
			<ItemFilesTable item={item} />
			<Typography variant="h4">Links</Typography>
			<ItemLinksTable item={item} />
			<Typography variant="h4">Audio</Typography>
			<AudioEncodingsTable item={item} />
			<Typography variant="h4"> Transcriptions </Typography>
			<TranscriptionsTable item={item} />
		</Stack>
	);
}
