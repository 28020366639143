import { Box } from '@mui/material';
import './playback-indicator.css';

export default function PlaybackIndicator({
  playing,
  variant = 'primary'
}: {
  playing: boolean;
  variant?: string;
}) {
  const classes = `playback-indicator ${variant}`;

  return (
    <Box
      className={classes}
      data-playing={playing}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '22px',
        width: '40px'
      }}
    >
      <div className="playing playing-bar1"></div>
      <div className="playing playing-bar2"></div>
      <div className="playing playing-bar3"></div>
    </Box>
  );
}
