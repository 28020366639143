import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { useParams } from "react-router-dom";
import { AppContext } from "@/models/AppStateProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { useCallback, useContext } from "react";
import {
	Box,
	Button,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	useTheme,
} from "@mui/material";
import { format } from "date-fns";

export default function WorkspaceInvitesTable() {
	const { db } = useElectric();
	const { client } = useContext(AppContext);
	const { ampli } = useContext(TrackingContext);
	const params = useParams();
	const workspaceId = params.workspaceId;
	const theme = useTheme();

	const { results: workspaceInvites } = useLiveQuery(() => {
		if (!workspaceId) return;
		return db.liveRaw({
			sql: `
			SELECT 
				direct_ws_invitation.email, 
				direct_ws_invitation.phoneNumber,
				direct_ws_invitation.createdAt as date, 
				direct_ws_invitation.workspaceMembershipId as membershipId, 
				workspace_membership.status as membershipStatus
			FROM 
				direct_ws_invitation
			JOIN 
				workspace_membership 
			ON 
				direct_ws_invitation.workspaceMembershipId = workspace_membership.id
			WHERE 
				workspace_membership.workspaceId = ?
			AND 
				workspace_membership.status = 'active'
			AND 
				direct_ws_invitation.claimedBy is null
			ORDER BY 
				direct_ws_invitation.createdAt DESC`,
			args: [workspaceId],
		});
	}, [workspaceId]) as {
		results: {
			email: string;
			phoneNumber: string;
			date: string;
			membershipId: string;
			membershipStatus: string;
		}[];
	};

	const formatDate = (date: string) => {
		return format(new Date(date), "MMM dd, yyyy");
	};

	const revokeInvite = useCallback(
		async (workspaceMembershipId: string) => {
			try {
				if (workspaceId) {
					ampli.revokePendingInvite();
					// happy path for workspaces mvp, set the invite membership status to 'inactive', which follows the same functionality of updating a member
					await client.removeWorkspaceMember(workspaceId, [
						workspaceMembershipId,
					]);
				}
			} catch (e) {
				console.log(e);
			}
		},
		[ampli, workspaceId, client],
	);

	return (
		<Box
			sx={{
				width: "100%",
				maxWidth: 1300,
				background: theme.palette.secondary.dark,
				borderRadius: 2,
				py: 5,
				px: 3,
			}}
		>
			<TableContainer>
				<Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								".MuiTableCell-head": {
									fontWeight: 600,
									borderBottom: `1px solid ${theme.palette.primary.main}`,
								},
								".MuiTableCell-stickyHeader": {
									backgroundColor: theme.palette.secondary.dark,
								},
							}}
						>
							<TableCell>Contact</TableCell>
							<TableCell>Invitation Date</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{workspaceInvites?.map((row, index) => (
							<TableRow
								key={`workspace-invite-${index}`}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									<Typography fontWeight={700}>
										{row.email || row.phoneNumber}
									</Typography>
								</TableCell>
								<TableCell component="th" scope="row">
									<Typography>{formatDate(row.date)}</Typography>
								</TableCell>
								<TableCell
									component="th"
									scope="row"
									sx={{
										width: 200,
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
										}}
									>
										<Button onClick={() => revokeInvite(row.membershipId)}>
											Revoke
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
