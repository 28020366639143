import { UxContext } from "@/models/UxStateProvider";
import { Box, Drawer, DrawerProps, Paper, useTheme } from "@mui/material";
import { useContext } from "react";
import WorkspaceList from "./WorkspaceList";
import WorkspaceDrawerHeader from "./WorkspaceDrawerHeader";
import WorkspaceDrawerFooter from "./WorkspaceDrawerFooter";

export interface WorkspaceDrawerProps extends DrawerProps {
	handleClose?: () => void;
	url: string | null;
}

export default function WorkspaceDrawer({
	className,
	children,
	sx,
	url,
	handleClose,
}: WorkspaceDrawerProps) {
	const {
		isSmUp,
		isMdUp,
		leftNavOpen,
		toggleLeftNav,
		setLeftNavOpen,
		rightNavOpen,
		toggleRightNav,
	} = useContext(UxContext);

	const theme = useTheme();

	const _handleClose = () => {
		handleClose?.();
		toggleLeftNav();
	};

	return (
		<Drawer
			variant={isSmUp ? "persistent" : "temporary"}
			sx={sx}
			anchor="left"
			open={leftNavOpen}
			onClose={_handleClose}
		>
			<Paper
				sx={{
					background: theme.palette.primary.dark,
					p: 2,
					overflow: "hidden",
					height: "100svh",
					position: "relative",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<WorkspaceDrawerHeader />
					<WorkspaceList />
				</Box>
			</Paper>
		</Drawer>
	);
}
