import { createTheme } from "@mui/material/styles";
import Loading from "@/components/Loading/Index";
// used for type definitions of lab components
import type {} from "@mui/lab/themeAugmentation";
import { MODAL_DEFAULT_Z_INDEX } from "@/elements/ModalForm";
// declare custom theme variables
// define the new variable to add it to the theme variable
declare module "@mui/material/styles" {
	interface Palette {
		brand: {
			primary: Palette["primary"];
			secondary: Palette["secondary"];
			info: Palette["info"];
			other: {
				teal: Palette["primary"];
				black: Palette["primary"];
			};
			mute: {
				activeHover: string;
				active: string;
			};
		};
		gradient: {
			main: string;
		};
	}

	interface PaletteOptions {
		brand?: {
			primary?: PaletteOptions["primary"];
			secondary?: PaletteOptions["secondary"];
			other?: {
				teal?: PaletteOptions["primary"];
				black?: PaletteOptions["primary"];
			};
			info?: PaletteOptions["info"];
			mute?: {
				activeHover: string;
				active: string;
			};
		};
		gradient?: {
			main: string;
		};
	}

	interface Palette {
		neutral: Palette["primary"];
	}

	interface PaletteOptions {
		neutral: PaletteOptions["primary"];
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsVariantOverrides {
		link: true;
	}
}

const themeBase = createTheme({
	palette: {
		mode: "dark",
		primary: {
			light: "#fafafa",
			main: "#ffffff",
			dark: "#040404",
		},
		secondary: {
			light: "#737579",
			main: "#404449",
			dark: "#26292C",
		},
		neutral: {
			main: "#C0C0C2",
			dark: "#1B1D1F",
		},
		info: {
			light: "#D79BFF",
			main: "#8803FF",
			dark: "#8803FF",
		},
		success: {
			light: "#2C9B4D",
			main: "#07892D",
		},
		error: {
			light: "#E63B3B",
			main: "#E21818",
			dark: "#CA1D1D",
			contrastText: "#FC2424",
		},
		gradient: {
			main: "linear-gradient(180deg, #1B1D1F 0%, #040404 64.58%)",
		},
		brand: {
			primary: {
				light: "#A929FE",
				main: "#9F11FE",
				dark: "#591595",
			},
			secondary: {
				light: "#FF723E",
				main: "#FF6229",
				dark: "#F54100",
			},
			other: {
				teal: { light: "#007A7A", main: "#004949", dark: "#003131" },
				black: { main: "#202225" },
			},
			info: {
				main: "#07689F",
			},
			mute: {
				activeHover: "#FFCBB9",
				active: "#FFE0D4",
			},
		},
	},
});

const themeComponents = createTheme({
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				"html, body": {
					backgroundColor: themeBase.palette.primary.dark,
				},
				body: {
					background: themeBase.palette.gradient.main,
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "12px",
				},
				message: {
					padding: "4px 0",
					fontWeight: 700,
				},
				filledSuccess: {
					background: themeBase.palette.success.main,
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableFocusRipple: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					minWidth: 48,
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					color: themeBase.palette.primary.main,
					fontSize: "1rem",
					fontWeight: 700,
					border: "2px solid transparent",
					"&:disabled": {
						cursor: "not-allowed",
						color: themeBase.palette.neutral.main,
						background: themeBase.palette.secondary.light,
					},
				},
				containedPrimary: {
					padding: "12px 20px",
					height: "44px",
					borderRadius: "60px",
					background: themeBase.palette.brand.primary.main,
					"&:hover:not([disabled]), &:focus:not([disabled])": {
						background: themeBase.palette.brand.primary.light,
						boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
					},
					"&:focus:not([disabled])": {
						border: `2px solid ${themeBase.palette.info.light}`,
					},
				},
				containedSecondary: {
					padding: "12px 20px",
					height: "44px",
					borderRadius: "60px",
					background: themeBase.palette.secondary.main,
					"&:disabled": {
						boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
					},
					"&:hover:not([disabled]), &:focus:not([disabled])": {
						background: themeBase.palette.secondary.light,
						boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
					},
					"&:focus:not([disabled])": {
						border: `2px solid ${themeBase.palette.neutral.main}`,
					},
				},
				containedError: {
					padding: "12px 20px",
					height: "44px",
					borderRadius: "60px",
					background: themeBase.palette.error.main,
					"&:disabled": {
						boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
					},
					"&:hover:not([disabled]), &:focus:not([disabled])": {
						background: themeBase.palette.error.light,
						boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
					},
					"&:focus:not([disabled])": {
						border: `2px solid ${themeBase.palette.error.dark}`,
					},
				},
				outlinedPrimary: {
					padding: "12px 20px",
					height: "44px",
					borderRadius: "60px",
					background: themeBase.palette.secondary.dark,
					border: `2px solid ${themeBase.palette.neutral.main}`,
					"&:disabled": {
						boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
					},
					"&:hover:not([disabled])": {
						borderWidth: "2px",
					},
				},
			},
			variants: [
				{
					props: { variant: "link" },
					style: {
						color: themeBase.palette.primary.main,
						fontWeight: 500,
						lineHeight: 1,
						padding: 0,
						border: "none",
						textTransform: "none",
						textDecoration: "underline",
						"&:hover:not([disabled]), &:focus:not([disabled])": {
							textDecoration: "none",
							backgroundColor: "transparent",
						},
					},
				},
			],
		},
		MuiCheckbox: {
			defaultProps: {
				disableFocusRipple: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					"&.Mui-checked": {
						color: themeBase.palette.brand.primary.main,
					},
				},
			},
		},
		// @ts-ignore
		MuiDateCalendar: {
			styleOverrides: {
				root: {
					width: "100%",
					maxHeight: 380,
					p: 4,
					border: `1px solid ${themeBase.palette.neutral.main}`,
					borderRadius: "8px",
				},
			},
		},
		MuiDayCalendar: {
			styleOverrides: {
				weekDayLabel: {
					margin: "0 auto",
					fontSize: "1rem",
					color: themeBase.palette.primary.main,
				},
			},
		},
		MuiDigitalClock: {
			styleOverrides: {
				item: {
					"&.Mui-selected": {
						backgroundColor: themeBase.palette.brand.primary.main,
						color: themeBase.palette.primary.main,
						"&:hover": {
							backgroundColor: themeBase.palette.brand.primary.light,
							boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
						},
					},
				},
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableFocusRipple: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					borderRadius: "8px",
					background: themeBase.palette.secondary.dark,
					color: themeBase.palette.neutral.light,
					border: "1.5px solid transparent",
					"&:hover:not([disabled]), &:focus:not([disabled])": {
						color: themeBase.palette.primary.main,
						background: themeBase.palette.secondary.light,
					},
					"&:focus:not([disabled])": {
						border: `1.5px solid ${themeBase.palette.secondary.light}`,
					},
					"&:disabled": {
						background: themeBase.palette.secondary.light,
						color: themeBase.palette.neutral,
					},
				},
				colorPrimary: {
					color: themeBase.palette.primary.main,
					background: "transparent",
					"&:hover, &:focus": {
						color: themeBase.palette.primary.main,
						background: "transparent",
						border: `1.5px solid ${themeBase.palette.primary.main}`,
					},
				},
				colorSecondary: {
					color: themeBase.palette.neutral.main,
					background: "transparent",
					"&:hover, &:focus": {
						background: themeBase.palette.secondary.dark,
						borderColor: themeBase.palette.secondary.light,
					},
				},
				colorSuccess: {
					color: themeBase.palette.primary.main,
					background: themeBase.palette.success.main,
					"&:hover:not([disabled]), &:focus:not([disabled])": {
						background: themeBase.palette.success.light,
					},
					"&:focus:not([disabled])": {
						background: themeBase.palette.secondary.light,
						border: `1.5px solid ${themeBase.palette.secondary.light}`,
					},
				},
				colorError: {
					color: themeBase.palette.primary.main,
					background: themeBase.palette.error.main,
					"&:hover:not([disabled])": {
						background: themeBase.palette.error.light,
					},
					"&:focus:not([disabled])": {
						background: themeBase.palette.secondary.light,
						border: `1.5px solid ${themeBase.palette.primary.main}`,
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontWeight: 700,
					color: themeBase.palette.primary.main,
					transform: "none",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textUnderlineOffset: "4px",
				},
			},
		},
		MuiLoadingButton: {
			defaultProps: {
				loadingIndicator: <Loading variant="disabled" size="tiny" />,
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					background: themeBase.palette.secondary.dark,
					border: `1px solid ${themeBase.palette.secondary.light}`,
					borderRadius: "8px",
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					fontSize: "1rem",
					margin: "0 auto",
					"&.Mui-selected": {
						color: themeBase.palette.primary.main,
						background: themeBase.palette.brand.primary.light,
						border: `1px solid ${themeBase.palette.brand.primary.light}`,
						fontWeight: 400,
						"&:hover:not([disabled]), &:focus:not([disabled])": {
							background: themeBase.palette.brand.primary.light,
							boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
						},
						"&:focus:not([disabled])": {
							border: `2px solid ${themeBase.palette.info.light}`,
						},
					},
				},
				today: {
					background: "transparent",
					border: "none",
					"&:not(.Mui-selected)": {
						border: "none",
						background: "transparent",
						"&:hover": {
							backgroundColor: "rgba(255, 255, 255, 0.08)",
						},
					},
				},
			},
		},
		MuiPickersPopper: {
			styleOverrides: {
				root: {
					zIndex: 2300,
					bottom: "100% !important",
					marginTop: "8px !important",
				},
				paper: {
					border: `1.5px solid ${themeBase.palette.secondary.light}`,
					boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
					background: themeBase.palette.secondary.dark,
					borderRadius: "0.5rem",
				},
			},
		},
		MuiMultiSectionDigitalClockSection: {
			styleOverrides: {
				root: {
					"&::after": {
						content: "none",
					},
				},
				item: {
					border: "2px solid transparent",
					"&.Mui-selected": {
						backgroundColor: themeBase.palette.brand.primary.main,
						color: themeBase.palette.primary.main,
						"&:hover:not([disabled]), &:focus:not([disabled])": {
							background: themeBase.palette.brand.primary.light,
							boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
						},
						"&:focus:not([disabled])": {
							border: `2px solid ${themeBase.palette.info.light}`,
						},
					},
				},
			},
		},
		MuiTab: {
			defaultProps: {
				disableFocusRipple: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					color: themeBase.palette.primary.main,
					padding: "12px 8px",
					fontWeight: 700,
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: themeBase.palette.brand.primary.light,
					bottom: "8px",
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					borderRadius: "1000px",
					background: themeBase.palette.brand.other.black.main,
					height: 64,
					maxWidth: 1300,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				popper: {
					zIndex: MODAL_DEFAULT_Z_INDEX,
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					"label + &": {
						marginTop: themeBase.spacing(3.5),
					},
					"> .MuiOutlinedInput-notchedOutline": {
						border: "none",
						borderRadius: 0,
						borderColor: "transparent",
						"&:hover": {
							borderColor: "transparent",
						},
					},
					"& .MuiInputBase-input": {
						borderRadius: 8,
						border: `1.5px solid ${themeBase.palette.secondary.light}`,
						boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
						position: "relative",
						background: themeBase.palette.secondary.dark,
						fontSize: 14,
						width: "100%",
						padding: 8,
						transition: themeBase.transitions.create([
							"border-color",
							"background-color",
							"box-shadow",
						]),
						"&:focus": {
							borderColor: themeBase.palette.primary.main,
						},
						boxSizing: "border-box",
						minHeight: 44,
					},
					"&.Mui-error, &.Mui-error > .MuiInputBase-input,  &.Mui-error + p": {
						color: themeBase.palette.error.main,
						borderColor: themeBase.palette.error.main,
					},
					"+ p": {
						fontSize: 12,
						fontWeight: 500,
						color: themeBase.palette.primary.main,
					},
					"& .MuiOutlinedInput-input:focus": {
						borderRadius: 8,
					},
				},
			},
		},
		MuiSwitch: {
			defaultProps: {
				disableFocusRipple: true,
				disableRipple: true,
				focusVisibleClassName: ".Mui-focusVisible",
			},
			styleOverrides: {
				root: {
					width: 52,
					height: 32,
					padding: 0,
				},
				switchBase: {
					padding: 0,
					margin: 4,
					transitionDuration: "300ms",
					"&.Mui-checked": {
						transform: "translateX(20px)",
						color: themeBase.palette.primary.main,
						"& + .MuiSwitch-track": {
							backgroundColor: themeBase.palette.brand.primary.main,
							opacity: 1,
							border: 0,
						},
						"&.Mui-disabled + .MuiSwitch-track": {
							opacity: 0.5,
						},
					},
					"&.Mui-focusVisible .MuiSwitch-thumb": {
						outline: `solid 2px ${themeBase.palette.primary.light}`,
						outlineOffset: "2px",
						boxShadow: `0 0 0 4px ${themeBase.palette.info.main}`,
					},
				},
				thumb: {
					boxSizing: "border-box",
					width: 24,
					height: 24,
				},
				track: {
					borderRadius: 32 / 2,
					backgroundColor: themeBase.palette.secondary.main,
					opacity: 1,
					transition: themeBase.transitions.create(["background-color"], {
						duration: 500,
					}),
				},
				sizeSmall: {
					width: 36,
					height: 18,
					".MuiSwitch-switchBase": {
						margin: "-1px 0",
					},
					".MuiSwitch-thumb": {
						width: 12,
						height: 12,
					},
				},
			},
		},
	},
});

const theme = createTheme({
	...themeBase,
	components: themeComponents.components,
});

export default theme;
