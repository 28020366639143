import { useElectric } from "@/electric/ElectricWrapper";
import { AppContext } from "@/models/AppStateProvider";
import { useConnectivityState, useLiveQuery } from "electric-sql/react";
import { WorkspaceRole } from "web-client/api/data-contracts";
import { useCallback, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Button,
	IconButton,
	Menu,
	MenuItem,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	useTheme,
	Typography,
	Chip,
	Stack,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown, Check } from "@mui/icons-material";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function WorkspaceMembersTable() {
	const navigate = useNavigate();
	const { removeMember, updateMemberRole, createWorkspaceDm } =
		useContext(ActionContext);
	const { myAccount } = useContext(DataContext);
	const { ampli } = useContext(TrackingContext);
	const { db } = useElectric();
	const params = useParams();
	const workspaceId = params.workspaceId;
	const theme = useTheme();
	const { directMessage } = useFlags();

	const [roleMenuEl, setRoleMenuEl] = useState<null | HTMLElement>(null);
	const [roleMenuOpen, setRoleMenuOpen] = useState<string>();
	const [disabled, setDisabled] = useState<string>("");

	const closeRoleMenu = () => {
		setRoleMenuEl(null);
		setRoleMenuOpen("");
	};

	const createDmAction = async (workspaceMembershipId: string) => {
		setDisabled(() => workspaceMembershipId);
		const newWorkspaceDm = await createWorkspaceDm(workspaceId, [
			workspaceMembershipId,
		]);
		if (newWorkspaceDm) {
			navigate(`/workspaces/${workspaceId}/feeds/${newWorkspaceDm.id}`);
		}
	};

	const { results: workspaceMembers } = useLiveQuery(
		db.liveRaw({
			sql: `
			SELECT 
				workspace_membership.accountId as id, 
				workspace_membership.role, 
				workspace_membership.id as membershipId, 
				account.name, 
				account.email,
				account.phoneNumber
			FROM 
				workspace_membership
			JOIN 
				account 
			ON 
				workspace_membership.accountId = account.id
      		WHERE 
				workspace_membership.workspaceId = ?
			AND 
				workspace_membership.status = 'active'
			ORDER BY 
				account.name ASC`,
			args: [workspaceId],
		}),
	) as {
		results: {
			id: string;
			membershipId: string;
			name: string;
			role: string;
			email?: string;
			phoneNumber?: string;
			mine?: boolean;
		}[];
	};

	workspaceMembers?.forEach((member) => {
		if (member.id === myAccount?.id) {
			member.mine = true;
		}
	});

	const removeWorkspaceMember = useCallback(
		(workspaceId, membershipId) => {
			ampli.removeWorkspaceAccess();
			removeMember(workspaceId, [membershipId]);
		},
		[ampli, removeMember],
	);

	const updateWorkspaceMemberRole = useCallback(
		(workspaceId, membershipId, currentRole, newRole) => {
			if (currentRole === "admin" && newRole === "member") {
				ampli.downgradePermissionMember();
			}
			if (currentRole === "member" && newRole === "admin") {
				ampli.upgradePermissionAdmin();
			}
			updateMemberRole(workspaceId, [membershipId], newRole);
		},
		[ampli, updateMemberRole],
	);

	return workspaceMembers?.length > 0 ? (
		<Box
			sx={{
				width: "100%",
				maxWidth: 1300,
				background: theme.palette.secondary.dark,
				borderRadius: 2,
				py: 5,
				px: 3,
			}}
		>
			<TableContainer className="dark-scrollbar">
				<Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								".MuiTableCell-head": {
									fontWeight: 600,
									borderBottom: `1px solid ${theme.palette.primary.main}`,
								},
								".MuiTableCell-stickyHeader": {
									backgroundColor: theme.palette.secondary.dark,
								},
							}}
						>
							<TableCell>Full Name</TableCell>
							<TableCell>Contact</TableCell>
							<TableCell align="left" sx={{ pr: 8 }}>
								Type
							</TableCell>
							{directMessage && <TableCell>Direct Message</TableCell>}
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{workspaceMembers?.map((row) => (
							<TableRow
								key={row.id}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell>
									<Stack
										direction="row"
										sx={{
											alignItems: "center",
										}}
									>
										<Typography fontWeight={700}>{row.name}</Typography>
										<Box sx={{ pl: 2 }}>
											{row.mine && <Chip label="Me" color="info" />}
										</Box>
									</Stack>
								</TableCell>
								<TableCell>
									<Typography>{row.email || row.phoneNumber}</Typography>
								</TableCell>
								<TableCell size="small" align="left">
									{row.mine && (
										<Typography
											sx={{ textTransform: "capitalize", pr: 4.2, pl: 1 }}
										>
											{row.role}
										</Typography>
									)}
									{!row.mine && (
										<>
											<IconButton
												onClick={(e) => {
													setRoleMenuEl(e.currentTarget);
													setRoleMenuOpen(row.membershipId);
												}}
												sx={{ textTransform: "capitalize" }}
											>
												<Typography>{row.role}</Typography>
												{row.membershipId === roleMenuOpen ? (
													<KeyboardArrowUp />
												) : (
													<KeyboardArrowDown />
												)}
											</IconButton>
											{roleMenuEl && row.membershipId === roleMenuOpen && (
												<Menu
													anchorEl={roleMenuEl}
													open={row.membershipId === roleMenuOpen}
													onClose={closeRoleMenu}
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "right",
													}}
													transformOrigin={{
														vertical: "top",
														horizontal: "right",
													}}
													sx={{
														mt: 1,
													}}
													slotProps={{
														paper: {
															sx: {
																width: 130,
															},
														},
													}}
												>
													<MenuItem
														sx={{ gap: 1 }}
														key={"role-option-admin"}
														onClick={() =>
															updateWorkspaceMemberRole(
																workspaceId,
																row.membershipId,
																row.role,
																"admin",
															)
														}
													>
														Admin {row.role === "admin" && <Check />}
													</MenuItem>
													<MenuItem
														sx={{ gap: 1 }}
														key={"role-option-member"}
														onClick={() =>
															updateWorkspaceMemberRole(
																workspaceId,
																row.membershipId,
																row.role,
																"member",
															)
														}
													>
														Member {row.role === "member" && <Check />}
													</MenuItem>
												</Menu>
											)}
										</>
									)}
								</TableCell>

								{directMessage && (
									<TableCell>
										<Button
											disabled={
												disabled && disabled === row.membershipId ? true : false
											}
											onClick={() => createDmAction(row.membershipId)}
										>
											Send Message
										</Button>
									</TableCell>
								)}
								<TableCell size="small" align="right">
									{!row.mine && (
										<Button
											onClick={() =>
												removeWorkspaceMember(workspaceId, row.membershipId)
											}
										>
											REMOVE
										</Button>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	) : (
		<Box> Loading Users </Box>
	);
}
