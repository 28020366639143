import useOnScreen from "@/hooks/useOnScreen";
import { AppContext } from "@/models/AppStateProvider";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { DataContext } from "@/models/DataProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { defaultFocusStyles, menuItemFocusSX, randomString } from "@/utils";
import * as Icons from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	Collapse,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";

import { FullItem } from "@/components/FeedMap";
import FileFragment from "@/components/FileFragments";
import LinkFragment from "@/components/LinkFragment";
import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import { Feed } from "@/generated/client";
import { ActionContext } from "@/models/ActionsProvider";
import { AccountInfo } from "@/models/accountInfo";
import {
	targetCodecForBrowser,
	selectAudio,
	selectTranscription,
} from "@/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { format } from "date-fns";
import { useLiveQuery } from "electric-sql/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLongPress } from "react-aria";
import { useNavigate } from "react-router-dom";
import { throttle } from "throttle-typescript";
import AudioPlayer from "./AudioPlayer";
import { ContentOverlay, LongPressOverlay } from "./AudioPlayer/styles";
import { InitialsAvatar } from "./Avatar";
import FeedItemStatus, { Status } from "./FeedItemStatus";
import Loading from "./Loading/Index";
import PlaybackIndicator from "./PlaybackIndicator/Index";
import { AllPreferredLanguage } from "@/models/languages";

type Props = {
	accountInfo: Map<string, AccountInfo>;
	item: FullItem;
	feed: Feed;
	unread: boolean;
};

export default function FeedItem({ accountInfo, item, feed, unread }: Props) {
	const navigate = useNavigate();
	const { flags } = React.useContext(AppContext);
	const { preferredLanguage } = React.useContext(DataContext);
	const { accountEvent, deleteItem } = React.useContext(ActionContext);
	const { myAccount, isCurrentFeedAdmin } = useContext(DataContext);
	const { queuePlaying } = React.useContext(AudioAppContext);
	const [copiedTranscript, setCopiedTranscript] = React.useState(false);
	const { isSmUp } = React.useContext(UxContext);
	const { ampli } = React.useContext(TrackingContext);
	const feedStatusesRef = useRef<any>();
	const theme = useTheme();
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const { client } = useContext(AppContext);

	const { db } = useElectric();
	const contentId = item.contentId;
	const mine = item.accountId === myAccount?.id;

	const { results: audioQueue } = useLiveQuery(
		db.audio_queue_item.liveMany({
			orderBy: {
				createdAt: "asc",
			},
		}),
	);

	const { results: audioContents } = useLiveQuery(() => {
		if (!contentId) return;
		return db.audio_encoding.liveMany({
			where: {
				contentId,
			},
		});
	}, [contentId]);

	const { results: transcriptions } = useLiveQuery(() => {
		if (!contentId) return;
		return db.transcription.liveMany({
			where: {
				contentId,
				transcriptionType: "full",
			},
		});
	}, [contentId]);

	const { results: rawFiles } = useLiveQuery(() => {
		if (!contentId) return;
		return db.file.liveMany({
			where: {
				contentId,
			},
		});
	}, [contentId]);

	const { results: rawLinks } = useLiveQuery(() => {
		if (!contentId) return;
		return db.link.liveMany({
			where: {
				contentId,
			},
			orderBy: { linkOrder: "asc" },
		});
	}, [contentId]);

	const { results: displayArtifact } = useLiveQuery(() => {
		if (!contentId) return;
		return db.display_artifact.liveFirst({
			where: {
				contentId,
			},
		});
	}, [contentId]);

	const canDelete = feed?.isDm ? item?.mine : isCurrentFeedAdmin || item?.mine;

	const startedEvent = "Started Listening to Feed Item";
	const completedEvent = "Finished Listening to Feed Item";

	const { results: completedEvents } = useLiveQuery(() => {
		if (!item.feedId || !item.id) return;
		return db.account_event.liveMany({
			where: {
				feedId: item.feedId,
				itemId: item.id,
				name: "Finished Listening to Feed Item",
			},
			orderBy: {
				createdAt: "desc",
			},
		});
	}, [item]);

	const { results: startedEvents } = useLiveQuery(() => {
		if (!item.feedId || !item.id || !completedEvents) return;
		return db.account_event.liveMany({
			where: {
				feedId: item.feedId,
				itemId: item.id,
				name: "Started Listening to Feed Item",
				accountId: { notIn: completedEvents?.map((event) => event.accountId) },
			},
			orderBy: {
				createdAt: "desc",
			},
		});
	}, [item, completedEvents]);

	const allEvents = startedEvents?.concat(completedEvents);

	const { results: accounts } = useLiveQuery(() => {
		if (!completedEvents || !startedEvents) return;
		return db.account.liveMany({
			where: {
				OR: [
					{ id: { in: completedEvents?.map((listen) => listen.accountId) } },
					{ id: { in: startedEvents?.map((listen) => listen.accountId) } },
				],
			},
		});
	}, [completedEvents, startedEvents]);

	const statuses = React.useMemo(() => {
		const itemAccountStatus = new Map<string, Status>();
		if (allEvents) {
			for (const listen of allEvents) {
				const listener = accounts?.find(
					(account) => account?.id === listen.accountId,
				);

				if (!listener) continue;

				const status = {
					account: listener,
					started: listen.name === startedEvent ? listen : null,
					completed: listen.name === completedEvent ? listen : null,
				} as Status;
				itemAccountStatus.set(listener?.id, status);
			}
			return Array.from(itemAccountStatus.values());
		}
		return [];
	}, [allEvents, accounts]);

	const files =
		rawFiles?.length > 0
			? rawFiles.map((file) => ({
					...file,
					fragment: FileFragment(file),
			  }))
			: [];

	const links =
		rawLinks?.length > 0
			? rawLinks.map((link) => ({
					...link,
					fragment: LinkFragment(link),
			  }))
			: [];

	const messageSender = React.useMemo(
		() => accountInfo.get(item.accountId) || null,
		[item, accountInfo],
	);

	const messageGridStyles = () => ({
		gridTemplateAreas: {
			xs: `"avatar info info info info"
"avatar message message message message"
"avatar details details details details"`,
			sm:
				mine || isCurrentFeedAdmin
					? `"avatar info info info ."
"avatar message message message message"
"avatar details details details ."`
					: `"avatar info info info ."
"avatar message message message ."
"avatar details details details ."`,
		},
		gridTemplateColumns: "40px 1fr 1fr 1fr 34px",
	});

	const { simpleHtml, richTranscript, textTranscript } = selectTranscription(
		preferredLanguage,
		transcriptions,
	);
	const transcriptionContent = simpleHtml || richTranscript || textTranscript;
	const transcriptIsHtml = simpleHtml || richTranscript ? true : false;
	const inputLanguage = transcriptions?.filter((t) => !t.translatedFrom)[0]
		?.language as AllPreferredLanguage;

	const targetCodec = targetCodecForBrowser();

	const suitableAudioContent = selectAudio(
		preferredLanguage,
		audioContents?.filter((a) => a.codec === targetCodec),
		inputLanguage,
	);

	const audioContent = suitableAudioContent[0];

	let duration: number = audioContent?.duration || 0;
	if (!duration) {
		// console.log("Calculating duration from other codec");
		duration = audioContents?.filter(
			(a) =>
				a.codec !== targetCodec &&
				a.duration &&
				a.language === audioContent?.language,
		)[0]?.duration;
	}

	const hasAudio = audioContents?.length >= 2;
	const hasPlayableAudio = suitableAudioContent.length > 0;
	const hasTranscription = !!transcriptionContent;
	const hasFiles = rawFiles?.length > 0;
	const hasLinks = rawLinks?.length > 0;
	const filesOrLinks = hasFiles || hasLinks;

	useEffect(() => {
		if (filesOrLinks || hasPlayableAudio || !!transcriptionContent) {
			setIsProcessing(false);
		} else {
			setIsProcessing(true);
		}
	}, [
		preferredLanguage,
		rawFiles?.length,
		rawLinks?.length,
		audioContents?.length,
		transcriptions?.length,
	]);

	const statusMessage = React.useMemo(() => {
		// if we have 0 audio contents or transcription then likely a newly uploaded message
		if (isProcessing && !hasAudio && !hasTranscription) return "Sending...";

		// check for files and links first
		if (filesOrLinks) return "Sent";

		if (!hasPlayableAudio && (!hasTranscription || hasTranscription)) {
			return "Processing...";
		}
		if (hasPlayableAudio && !hasTranscription) {
			return "Transcribing...";
		}

		return "Sent";
	}, [
		isProcessing,
		audioContents?.length,
		transcriptions?.length,
		rawFiles?.length,
		rawLinks?.length,
	]);

	const [showTranscription, setShowTranscription] = React.useState(false);
	const [showTranscriptionToggle, setShowTranscriptionToggle] =
		React.useState(true);

	useEffect(() => {
		if (showTranscription) {
			ampli.openTranscript({ itemId: item.id, feedId: feed.id });
		}
	}, [showTranscription, ampli, item, feed]);

	const [transcriptionHeight, setTranscriptionHeight] = React.useState(0);
	const showTranscriptionLabel = `${
		showTranscription ? "Hide" : "Show"
	} full transcription`;

	const transcriptionStyles = () => ({
		WebkitMaskImage:
			!showTranscription && showTranscriptionToggle
				? `linear-gradient(180deg, ${theme.palette.primary.dark} 5%, transparent)`
				: "",
		maxWidth: "100%",
	});

	const transcriptionRef = React.useCallback(
		(node) => {
			if (node !== null) {
				// if content height is less than or equal to the max container height of 54px
				const overMaxHeight = node?.clientHeight > 54;
				const height = !overMaxHeight ? node?.clientHeight : 54;
				setTranscriptionHeight(height);
				setShowTranscriptionToggle(overMaxHeight);
			}
		},
		[transcriptionContent],
	);

	const paperColor = () => {
		if (displayArtifact) {
			return theme.palette.brand.other.teal.main;
		} else {
			return mine
				? theme.palette.brand.primary.dark
				: theme.palette.neutral.dark;
		}
	};
	const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
	/**
	 * @NOTE keyboard v. mouse "open" states
	 *  - when a menu opens via keyboard, its first item is focused
	 *  - however, its control element should also appear to be focused but is not
	 *  - we're currently "hacking" this into place, which requires knowing which gesture was taken
	 *
	 * @TODO consolidate duplicate logic across components into one that handles this internally
	 */
	const [anchorElOpen, setAnchorElOpen] = useState(false);
	const open = Boolean(anchorEl);

	const { longPressProps } = useLongPress({
		isDisabled: isSmUp || false,
		accessibilityDescription: "Long press to edit message",
		onLongPress: (event) => {
			setAnchorEl(event.target);
		},
	});

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		ampli.viewMessageContextMenu();
	};

	const _handleKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		const keyCode = event.key.toLowerCase();

		/**
		 * @NOTE filter key presses
		 *  - when initially focused, this event will fire off of `TAB`
		 *  -> in order to keep the illusion that the parent element is opened,
		 *     we need to throttle + filter for <space> or <enter>
		 */
		if (keyCode === " " || keyCode === "enter") {
			setAnchorElOpen(!anchorElOpen);
		}
	};

	const handleKeyUp = throttle(_handleKeyUp, 100);

	const handleMessageMenuClose = () => {
		setAnchorEl(null);
		setAnchorElOpen(false);
	};

	const makeID = (len = 5) =>
		`feedItem-${feed?.id}-${item?.id}-${randomString(len)}`;

	const [feedItemId] = useState(makeID());

	const handleDelete = async () => {
		ampli.deleteRecord({ itemId: item.id });
		setAnchorEl(null);
		deleteItem(item.id);
		setConfirmDeleteDialog(false);
	};

	const [confirmDeleteDialog, setConfirmDeleteDialog] =
		useState<boolean>(false);

	const ref = React.useRef<HTMLDivElement>(null);
	const isVisible = useOnScreen(ref);

	React.useEffect(() => {
		if (isVisible && accountEvent) {
			accountEvent("Saw Feed Item", { feedId: feed.id, feedItemId: item.id });
		}
	}, [isVisible]);

	const accountName = !mine ? accountInfo.get(item.accountId)?.name : "Me";
	const avatarColor = accountInfo.get(item.accountId)?.avatarColor;
	const initial = accountName?.toString()[0];
	const time = format(new Date(item.createdAt), "h:mm aa");

	const itemStyles = {
		boxSizing: "border-box",
		background: paperColor,
		borderRadius: "16px",
		border: () => {
			let color = mine
				? theme.palette.brand.primary.light
				: theme.palette.secondary.light;
			if (displayArtifact) {
				color = theme.palette.brand.other.teal.dark;
			}
			return `1px solid ${color}`;
		},
	};

	const handlePlay = React.useCallback(
		async (time: number) => {
			await accountEvent("Started Listening to Feed Item", {
				feedId: feed.id,
				feedItemId: item.id,
				data: { time },
			});
		},
		[feed.id, item.id],
	);

	const handleFinished = React.useCallback(async () => {
		await accountEvent("Finished Listening to Feed Item", {
			feedId: feed.id,
			feedItemId: item.id,
		});
	}, [feed.id, item.id]);

	const handlePause = React.useCallback(
		async (time: number) => {
			await accountEvent("Paused Feed Item", {
				feedId: feed.id,
				feedItemId: item.id,
				data: { time },
			});
		},
		[feed.id, item.id],
	);

	const handleCopy = React.useCallback(() => {
		setCopiedTranscript(true);
		navigator.clipboard.writeText(textTranscript.transcriptionContent);
		setTimeout(() => setCopiedTranscript(false), 3000);
	}, [textTranscript]);

	const handleStatusOpen = React.useCallback(() => {
		if (feedStatusesRef.current) {
			feedStatusesRef.current?.open();
			handleMessageMenuClose();
		}
	}, [feedStatusesRef]);

	const refreshPipeline = async () => {
		setIsProcessing(true);
		try {
			await client.refreshContent(item.contentId);
			setTimeout(() => setIsProcessing(false), 3000);
		} catch (error) {
			setIsProcessing(false);
			console.error("Error refreshing content:", error);
		}
	};

	if (
		!window ||
		(!audioContent &&
			!transcriptionContent &&
			!files &&
			!links &&
			item.status !== "Processing")
	)
		return null;

	const messageWrapperLabel = `${accountName} at ${time}, click to play`;

	return (
		<Stack
			id={item.id}
			ref={ref}
			direction={"row"}
			sx={{
				mt: "28px",
				alignItems: "center",
				justifyContent: mine ? "flex-end" : "flex-start",
				width: "100%",
			}}
			useFlexGap={true}
			spacing={2}
			aria-label={messageWrapperLabel}
		>
			<ModalForm
				open={confirmDeleteDialog}
				onClose={() => setConfirmDeleteDialog(false)}
			>
				<Box>
					<Typography sx={{ fontSize: 24 }}>Delete Message</Typography>
					<Typography sx={{ fontSize: 16, mb: 2 }}>
						Are you sure you want to delete this message from the feed?
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", sm: "row" },
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
							gap: 2.5,
						}}
					>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setConfirmDeleteDialog(false)}
						>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="error"
							sx={{ borderRadius: 6 }}
							onClick={handleDelete}
							aria-label="Confirm Delete Message"
						>
							Delete
						</LoadingButton>
					</Box>
				</Box>
			</ModalForm>

			<Box
				sx={{
					display: "grid",
					...messageGridStyles(),
					alignItems: "center",
					gap: 1,
					width: "100%",
					maxWidth: "495px",
					padding: 1,
					alignSelf: "flex-end",
				}}
				role="presentation"
			>
				<Box sx={{ alignSelf: "flex-start", gridArea: "avatar", py: 3 }}>
					{!mine && audioQueue?.[0]?.itemId !== item.id && (
						<>
							{displayArtifact ? (
								<Avatar
									sx={{
										fontSize: "20px",
										width: 40,
										height: 40,
										fontWeight: 700,
										color: theme.palette.primary.main,
										background: theme.palette.brand.other.teal.light,
										border: `1.5px solid ${theme.palette.brand.other.teal.dark}`,
									}}
								>
									<Icons.CampaignOutlined />
								</Avatar>
							) : (
								<InitialsAvatar
									aria-label={accountName}
									initials={initial}
									color={avatarColor}
									sxProps={{
										fontSize: "20px",
										fontWeight: 700,
										width: 40,
										height: 40,
									}}
								/>
							)}
						</>
					)}
					{audioQueue?.[0]?.itemId === item.id && (
						<PlaybackIndicator playing={queuePlaying} />
					)}
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: mine ? "flex-end" : "flex-start",
						gridArea: "info",
					}}
					color={theme.palette.secondary.light}
					role="presentation"
				>
					<Typography
						id={`${feedItemId}-user`}
						aria-label={`${accountName} at ${time}`}
						sx={{ fontSize: "12px", fontWeight: "medium" }}
					>
						{accountName} • {time}
					</Typography>
				</Box>
				<Stack
					sx={{
						width: "100%",
						alignItems: "center",
						justifyContent: mine ? "flex-end" : "flex-start",
						gridArea: "message",
					}}
					direction={"row"}
					role="presentation"
				>
					<Paper
						variant="outlined"
						id={`${feedItemId}-wrapper`}
						sx={{
							...itemStyles,
							width: "100%",
							maxWidth: isProcessing ? "120px" : "100%",
							position: "relative",
						}}
					>
						<Box p={"14px"} sx={{ width: "100%" }}>
							{isProcessing ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<Loading variant="white" size="large" baseSize={6} />
								</Box>
							) : (
								<Stack direction={"column"} spacing={1} width="100%">
									<LongPressOverlay>
										{hasPlayableAudio && (
											<AudioPlayer
												duration={duration}
												id={item.id}
												containerWidth={"100%"}
												onPlay={handlePlay}
												onFinished={handleFinished}
												onPause={handlePause}
												inline
												paperize={false}
												disabledPlayButton={!hasPlayableAudio}
											/>
										)}
									</LongPressOverlay>
									<ContentOverlay
										{...(!isSmUp ? longPressProps : null)}
										$hasAudio={!!hasPlayableAudio}
									>
										{displayArtifact && (
											<>
												<Typography
													component="h3"
													sx={{
														fontWeight: 700,
														fontSize: "18px",
														wordBreak: "break-word",
													}}
												>
													{displayArtifact?.title}
												</Typography>
												<div
													id={`${feedItemId}-description`}
													role="textarea"
													style={{
														wordBreak: "break-word",
														whiteSpace: "pre-wrap",
													}}
													dangerouslySetInnerHTML={{
														__html: displayArtifact?.description,
													}}
												/>
												<ModalForm
													open={showTranscription}
													onClose={() => setShowTranscription(false)}
													maxWidth={"80%"}
													sx={{
														overflow: "hidden",
													}}
													keepMounted
												>
													<Stack sx={{ height: "100%" }}>
														<Box
															sx={{
																py: 5,
																pt: { xs: 5, sm: 0 },
															}}
														>
															{hasPlayableAudio && (
																<AudioPlayer
																	duration={duration}
																	id={item.id}
																	containerWidth={"100%"}
																	onPlay={handlePlay}
																	onFinished={handleFinished}
																	onPause={handlePause}
																	inline
																	paperize={false}
																	disabledPlayButton={!hasPlayableAudio}
																/>
															)}
														</Box>
														<Box sx={{ px: "5%", overflow: "auto" }}>
															{transcriptIsHtml ? (
																<div
																	ref={transcriptionRef}
																	id={`${feedItemId}-content`}
																	role="textbox"
																	data-test="transcription-content"
																	aria-label={
																		textTranscript?.transcriptionContent
																	}
																	dangerouslySetInnerHTML={{
																		__html:
																			transcriptionContent?.transcriptionContent,
																	}}
																/>
															) : (
																<div
																	ref={transcriptionRef}
																	id={`${feedItemId}-content`}
																	role="textbox"
																	data-test="transcription-content"
																	aria-label={
																		textTranscript?.transcriptionContent
																	}
																	style={{
																		whiteSpace: "pre-line",
																		fontFamily: "inherit",
																		margin: 0,
																		wordBreak: "break-word",
																	}}
																>
																	{transcriptionContent?.transcriptionContent}
																</div>
															)}
														</Box>
													</Stack>
												</ModalForm>
											</>
										)}
										{transcriptionContent && !displayArtifact && (
											<Collapse
												orientation="vertical"
												in={showTranscription}
												collapsedSize={`${transcriptionHeight}px`}
												sx={{ margin: 0, ...transcriptionStyles() }}
												timeout={300}
											>
												{transcriptIsHtml ? (
													<div
														ref={transcriptionRef}
														id={`${feedItemId}-content`}
														role="textbox"
														data-test="transcription-content"
														aria-label={textTranscript?.transcriptionContent}
														dangerouslySetInnerHTML={{
															__html:
																transcriptionContent?.transcriptionContent,
														}}
													/>
												) : (
													<div
														ref={transcriptionRef}
														id={`${feedItemId}-content`}
														role="textbox"
														data-test="transcription-content"
														aria-label={textTranscript?.transcriptionContent}
														style={{
															whiteSpace: "pre-line",
															fontFamily: "inherit",
															margin: 0,
															wordBreak: "break-word",
														}}
													>
														{transcriptionContent?.transcriptionContent}
													</div>
												)}
												{flags.debugMode && (
													<>
														<Divider sx={{ pt: 2, pb: 2 }} />
														<Stack sx={{ wordBreak: "break-word" }}>
															<div>
																<strong>Language: </strong>
																{transcriptionContent?.language}
															</div>
															<div>
																<strong>Translated From: </strong>
																{transcriptionContent?.translatedFrom}
															</div>
															<div>
																<strong>Priority: </strong>
																{transcriptionContent?.priority}
															</div>
															<div>
																<strong>model: </strong>
																{transcriptionContent?.backendModel}
															</div>
															<div>
																<strong>Type: </strong>
																{transcriptionContent?.transcriptionType}
															</div>
															<div>
																<strong>Format: </strong>
																{transcriptionContent?.format}
															</div>
															{files?.map((file) => (
																<div key={file.url}>
																	<strong>File: </strong>
																	{file.url}
																</div>
															))}
															{links?.map((link) => (
																<div key={link?.url}>
																	<strong>Link: </strong>
																	{link?.url}
																</div>
															))}
														</Stack>
													</>
												)}
											</Collapse>
										)}

										{links?.length > 0 && (
											<List>
												{links.map((link, index) => (
													<ListItem
														key={`links-${link?.id}`}
														divider={index !== links?.length - 1}
													>
														{link?.fragment}
													</ListItem>
												))}
											</List>
										)}

										{files && files?.length > 0 && (
											<List>
												{files.map((file) => (
													<ListItem
														onClick={() => ampli.openFile()}
														key={`files-${file?.id}`}
													>
														{file?.fragment}
													</ListItem>
												))}
											</List>
										)}
									</ContentOverlay>
								</Stack>
							)}
						</Box>
					</Paper>
					{(mine || isCurrentFeedAdmin) && (
						<>
							{isSmUp && (
								<Box sx={{ p: "8px" }}>
									<IconButton
										color="secondary"
										aria-label="Message Actions"
										onClick={handleClick}
										onKeyUp={handleKeyUp}
										sx={{
											...(anchorElOpen ? defaultFocusStyles : {}),
											height: "100%",
											px: 0,
											py: 1,
											color: theme.palette.primary.main,
										}}
									>
										<Icons.MoreVert role="img" />
									</IconButton>
								</Box>
							)}

							<Menu
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								sx={{
									mt: 1,
									...menuItemFocusSX,
								}}
								open={open}
								onClose={handleMessageMenuClose}
							>
								{statuses?.length > 0 && (
									<MenuItem onClick={handleStatusOpen}>
										<ListItemIcon>
											<Icons.InfoOutlined role="img" />
										</ListItemIcon>
										<ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
											Message Details
										</ListItemText>
									</MenuItem>
								)}
								{textTranscript && (
									<MenuItem onClick={handleCopy}>
										<ListItemIcon>
											{copiedTranscript ? (
												<Icons.Done role="presentation" />
											) : (
												<Icons.ContentCopy role="presentation" />
											)}
										</ListItemIcon>
										<ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
											{copiedTranscript ? "Copied!" : "Copy transcript"}
										</ListItemText>
									</MenuItem>
								)}
								{canDelete && (
									<MenuItem
										onClick={() => {
											setConfirmDeleteDialog(true);
											handleMessageMenuClose();
										}}
									>
										<ListItemIcon>
											<Icons.Delete role="img" />
										</ListItemIcon>
										<ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
											Delete Message
										</ListItemText>
									</MenuItem>
								)}
							</Menu>
						</>
					)}
					{!mine && unread && (
						<Icons.CircleSharp
							role="img"
							sx={{
								color: theme.palette.brand.secondary.main,
								fontSize: "12px",
								pl: 2,
							}}
						/>
					)}
				</Stack>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						gridArea: "details",
					}}
				>
					<Box
						sx={{
							order: mine ? 0 : 1,
						}}
					>
						<FeedItemStatus
							ref={feedStatusesRef}
							sender={messageSender}
							item={item}
							statuses={statuses}
							statusMessage={statusMessage}
						/>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						{!isProcessing &&
							showTranscriptionToggle &&
							transcriptions?.length > 0 && (
								<Button
									onClick={() => setShowTranscription(!showTranscription)}
									size="small"
									disabled={!transcriptionContent}
								>
									{showTranscription && !displayArtifact ? (
										<Icons.KeyboardArrowUp role="img" />
									) : (
										<Icons.TextFields
											role="img"
											style={{ transform: "scaleX(-1)" }}
										/>
									)}
									<Typography
										sx={{
											fontSize: "12px",
											fontWeight: "medium",
											textTransform: "none",
										}}
										ml={0.5}
									>
										{showTranscriptionLabel}
									</Typography>
								</Button>
							)}
						{flags.debugMode && (
							<>
								<IconButton
									color="secondary"
									size="small"
									aria-label="Click to re-run pipeline for this FeedItem"
									disabled={isProcessing ? true : false}
									onClick={refreshPipeline}
								>
									<Icons.RefreshOutlined role="img" />
								</IconButton>
								<IconButton
									color="secondary"
									size="small"
									aria-label="Click for debug info"
									onClick={() => {
										navigate(
											`/workspaces/${feed?.workspaceId}/feeds/${feed?.id}/items/${item.id}/debug`,
										);
									}}
								>
									<Icons.InfoOutlined role="img" />
								</IconButton>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</Stack>
	);
}
