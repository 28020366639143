import {
	Box,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Typography,
	Button,
	useTheme,
	Switch,
	FormLabel,
} from "@mui/material";
import { InitialsAvatar } from "@/components/Avatar";
import React from "react";
import { DataContext } from "@/models/DataProvider";
import { AppContext } from "@/models/AppStateProvider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { UxContext } from "@/models/UxStateProvider";

export default function WorkspaceUserProfile() {
	const { client, flags, setFlags } = React.useContext(AppContext);
	const { myAccount } = React.useContext(DataContext);
	const theme = useTheme();
	const params = useParams();
	const workspaceId = params?.workspaceId;
	const { showDebugMenu } = useFlags();

	const { db } = useElectric();

	const handleFlag = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFlags({ ...flags, [event.target.name]: event.target.checked });
	};

	const handleLogout = () => {
		client.logout();
	};

	const submitFeedback = () => {
		const anchor = document.createElement("a");
		anchor.href =
			"https://docs.google.com/forms/d/e/1FAIpQLScSTHMXz5aP_a_e3THPtve2aTxQVXSZ6vU0KWGwlOsqIF90CQ/viewform";
		anchor.target = "_blank";
		anchor.click();
	};

	const readableFlags = Object.keys(flags).map((flag) => ({
		key: flag,
		text: flag.replace(/([a-z])([A-Z])/g, "$1 $2"),
	}));

	const profileActions = [
		{
			text: "Submit Feedback",
			key: "submit-feedback",
			action: submitFeedback,
			icon: <KeyboardArrowRightIcon />,
		},
		{
			text: "Sign Out",
			key: "sign-out",
			action: handleLogout,
			icon: <ExitToAppIcon />,
		},
	];

	return (
		<Stack sx={{ p: 2, position: "relative", height: "100%" }}>
			<Box sx={{ mb: 2, p: 2 }}>
				<Stack
					sx={{
						flexDirection: "row",
						alignItems: "center",
						gap: 1.5,
					}}
				>
					<InitialsAvatar
						initials={myAccount?.name?.[0] || ""}
						color={myAccount?.avatarColor}
						sxProps={{
							fontSize: "13px",
							color: theme.palette.primary.main,
							width: 30,
							height: 30,
						}}
					/>
					<Typography sx={{ fontWeight: 500, fontSize: "1.25rem" }}>
						{myAccount?.name}
					</Typography>
				</Stack>
			</Box>

			<List sx={{ p: 0 }}>
				{profileActions.map((item) => {
					if (item) {
						return (
							<ListItemButton
								key={item.key}
								aria-label={`Navigation to ${item.text}`}
								onClick={item.action}
								disableRipple={true}
								sx={{
									width: "100%",
									borderRadius: "8px",
									justifyContent: "space-between",
									wordBreak: "break-word",
									p: 2,
									fontSize: "1.125rem",
									fontWeight: 500,
								}}
							>
								<Box
									sx={{
										flexGrow: 1,
										textAlign: "left",
										justifyContent: "start",
									}}
								>
									{item.text}
								</Box>
								<Box
									sx={{
										flex: "0 1 auto",
										display: "flex",
										alignItems: "center",
									}}
								>
									{item.icon}
								</Box>
							</ListItemButton>
						);
					}
				})}
			</List>

			{showDebugMenu && (
				<Box sx={{ p: 1, borderRadius: 1 }}>
					<Typography variant="h5" component="h2" fontWeight={700}>
						Debug Menu
					</Typography>
					<Box sx={{ p: 2 }}>
						{readableFlags.map((flag) => (
							<FormLabel
								sx={{
									display: "flex",
									cursor: "pointer",
									alignItems: "center",
									mb: 1,
								}}
								key={flag.key}
							>
								<Switch
									checked={flags[flag.key]}
									onChange={handleFlag}
									name={flag.key}
									sx={{ mr: 2 }}
								/>
								<Box sx={{ textTransform: "capitalize" }}>{flag.text}</Box>
							</FormLabel>
						))}
					</Box>
				</Box>
			)}
		</Stack>
	);
}
