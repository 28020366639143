/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BootstrapWorkspacesResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Bootstrap<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name WorkspacesList
   * @summary bootstrapWorkspaces
   * @request GET:/bootstrap/workspaces
   */
  workspacesList = (params: RequestParams = {}) =>
    this.request<BootstrapWorkspacesResponse, any>({
      path: `/bootstrap/workspaces`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
