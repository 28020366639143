import { UxContext, UxState } from "@/models/UxStateProvider";
import { MAIN_DRAWER_WIDTH } from "@/utils";
import { Theme, styled } from "@mui/material";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import React from "react";

export type SBAppBarProps = AppBarProps;

export interface OpenDrawerStylesProps
	extends Pick<
		UxState,
		"leftNavOpen" | "rightNavOpen" | "bothNavOpen" | "isSmUp"
	> {
	theme: Theme;
}

export const drawerOpenStyles = ({
	isSmUp,
	leftNavOpen,
	rightNavOpen,
	theme,
}: OpenDrawerStylesProps) => {
	const bothDrawersOpen = leftNavOpen && rightNavOpen;

	return {
		...(leftNavOpen && {
			width: isSmUp ? `calc(100% - ${MAIN_DRAWER_WIDTH}px)` : "100%",
			marginLeft: isSmUp
				? `${bothDrawersOpen ? MAIN_DRAWER_WIDTH * 2 : MAIN_DRAWER_WIDTH}px`
				: 0,
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
		...(rightNavOpen && {
			width: isSmUp
				? `calc(100% - ${
						bothDrawersOpen ? MAIN_DRAWER_WIDTH * 2 : MAIN_DRAWER_WIDTH
				  }px)`
				: "100%",
			marginRight: isSmUp ? `${MAIN_DRAWER_WIDTH}px` : 0,
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	};
};

export const SBAppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "leftOpen" && prop !== "rightOpen",

	/**
	 * @NOTE -- move `leftOpen`, `rightOpen` into context
	 *  - `DateHeader` uses context to apply width styling above
	 */
})<SBAppBarProps>(({ theme }) => {
	const { isSmUp, leftNavOpen, rightNavOpen } = React.useContext(UxContext);

	return {
		height: 120,
		padding: "0 16px",
		background: "transparent",
		boxShadow: "none",
		justifyContent: "center",
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		zIndex: 1200,
		...drawerOpenStyles({
			isSmUp,
			leftNavOpen,
			rightNavOpen,
			theme,
		}),
	};
});

export default SBAppBar;
