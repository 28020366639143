import { SVGProps } from "react";
export default function TemplateIcon({ ...props }: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V2C20 0.9 19.1 0 18 0ZM11 5.5C11 3.6 12.6 2 14.5 2C16.4 2 18 3.6 18 5.5C18 7.4 16.4 9 14.5 9C12.6 9 11 7.4 11 5.5ZM2 2H9V9H2V2ZM2 18V11H9V18H2ZM18 18H11V11H18V18Z"
				fill="white"
			/>
		</svg>
	);
}
