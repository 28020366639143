import cuid from "cuid";
import { AccountEvent as AccountEventClient } from "./api/AccountEvent";
import { Bootstrap as BootstrapClient } from "./api/Bootstrap";
import { Content as ContentClient } from "./api/Content";
import { Feeds as FeedsClient } from "./api/Feeds";
import { Phone as PhoneClient } from "./api/Phone";
import { Workspaces as WorkspacesClient } from "./api/Workspaces";
import {
	AccountEventRequest,
	ContentEventStep,
	ContentEventStepStatus,
	ContentReceiptType,
	WorkspaceRole,
} from "./api/data-contracts";
import { HttpResponse } from "./api/http-client";
import { Accounts as AccountsClient } from "./api/Accounts";
import { Login } from "./api/Login";
import { Userinfo as UserInfoClient } from "./api/Userinfo";
import { buildVersion } from "@/utils";

export default class Client {
	public apiBaseUrl: string;
	public authBaseUrl: string;

	public feedsClient: FeedsClient;
	public userInfoClient: UserInfoClient;
	public contentClient: ContentClient;
	public accountEventClient: AccountEventClient;
	public loginClient: Login;
	public accountClients: AccountsClient;
	public phoneClient: PhoneClient;
	public workspacesClient: WorkspacesClient;
	public bootstrapClient: BootstrapClient;

	public baseDomain: string;
	public login?: () => void;
	public logout?: () => void;
	public headers?: any;

	constructor({
		baseDomain,
		headers,
		login,
		logout,
	}: {
		baseDomain?: string;
		headers?: any;
		login?: () => void;
		logout?: () => void;
	}) {
		this.headers = headers;
		this.baseDomain = baseDomain || "https://dev.app.storyboardtesting.com";

		this.login = login;
		this.logout = logout;

		this.apiBaseUrl = `${this.baseDomain}/v1`;
		this.authBaseUrl = `${this.baseDomain}/auth`;

		this.feedsClient = new FeedsClient({
			baseUrl: this.apiBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
		this.userInfoClient = new UserInfoClient({
			baseUrl: this.authBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
		this.contentClient = new ContentClient({
			baseUrl: this.apiBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
		this.accountEventClient = new AccountEventClient({
			baseUrl: this.apiBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
		this.loginClient = new Login({
			baseUrl: this.authBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
		this.accountClients = new AccountsClient({
			baseUrl: this.authBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
		this.phoneClient = new PhoneClient({
			baseUrl: this.apiBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
		this.workspacesClient = new WorkspacesClient({
			baseUrl: this.apiBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
		this.bootstrapClient = new BootstrapClient({
			baseUrl: this.apiBaseUrl,
			baseApiParams: { credentials: "include", headers },
		});
	}

	public async createScheduledBroadcast({
		workspaceId,
		feedIds,
		workspaceMembershipIds,
		workflowItemId,
		date,
		cron,
		timezone,
	}: {
		workspaceId: string;
		feedIds?: string[];
		workspaceMembershipIds?: string[];
		workflowItemId: string;
		date?: string;
		cron?: string;
		timezone?: string;
	}) {
		return this.processRequest(
			this.workspacesClient.triggerScheduleCreate(workspaceId, {
				feedIds,
				workspaceMembershipIds,
				workflowItemId,
				workspaceId,
				date,
				cron,
				timezone,
			}),
		);
	}

	public async updateScheduledBroadcast({
		workspaceId,
		scheduleId,
		feedIds,
		workspaceMembershipIds,
		date,
		cron,
		timezone,
	}: {
		workspaceId: string;
		scheduleId: string;
		feedIds?: string[];
		workspaceMembershipIds?: string[];
		date?: string;
		cron?: string;
		timezone?: string;
	}) {
		return this.processRequest(
			this.workspacesClient.triggerScheduleUpdate(workspaceId, scheduleId, {
				feedIds,
				workspaceMembershipIds,
				date,
				cron,
				timezone,
			}),
		);
	}

	public async getScheduledBroadcasts(workspaceId: string) {
		return this.processRequest(
			this.workspacesClient.scheduledBroadcastsDetail(workspaceId),
		);
	}

	public async publishBroadcast({
		workspaceId,
		contentId,
		workspaceMembershipIds,
		feedIds,
		inputText,
	}: {
		workspaceId: string;
		contentId: string;
		workspaceMembershipIds?: string[];
		feedIds?: string[];
		inputText?: string;
	}) {
		return this.processRequest(
			this.workspacesClient.publishBroadcastCreate(workspaceId, {
				contentId,
				workspaceMembershipIds,
				feedIds,
				workspaceId,
				inputText,
			}),
		);
	}

	public async createWorkspaceWorkflowItem({
		workspaceId,
		contentId,
		displayName,
		workflowItemId,
		inputText,
	}: {
		workspaceId: string;
		contentId?: string;
		displayName?: string;
		workflowItemId?: string;
		inputText?: string;
	}) {
		contentId = contentId || cuid();
		workflowItemId = workflowItemId || cuid();
		return this.processRequest(
			this.workspacesClient.workflowItemsCreate(workspaceId, {
				workspaceId,
				contentId,
				displayName,
				workflowItemId,
				inputText,
			}),
		);
	}

	public async getWorkspaceWorkflowItems(workspaceId: string) {
		return this.processRequest(
			this.workspacesClient.workflowItemsDetail(workspaceId),
		);
	}

	public async deleteWorkspaceWorkflowItem(
		workspaceId: string,
		workflowItemId: string,
	) {
		return this.processRequest(
			this.workspacesClient.workflowItemsDelete(workspaceId, workflowItemId),
		);
	}

	public async publishWorkspaceWorkflowItem({
		workspaceId,
		workflowItemId,
		feedIds = [],
		workspaceMembershipIds = [],
	}: {
		workspaceId: string;
		workflowItemId: string;
		feedIds?: string[];
		workspaceMembershipIds?: string[];
	}) {
		return this.processRequest(
			this.workspacesClient.workflowItemsPublishCreate(
				workspaceId,
				workflowItemId,
				{
					feedIds,
					workspaceMembershipIds,
				},
			),
		);
	}

	public async createWorkspaceInvitations(
		workspaceId: string,
		emails?: string[],
		phoneNumbers?: string[],
		sendEmail?: boolean,
	) {
		return this.processRequest(
			this.workspacesClient.directInvitationsCreate(workspaceId, {
				emails,
				phoneNumbers,
				sendEmail,
			}),
		);
	}

	public async getWorkspaceInvitations(workspaceId: string) {
		return this.processRequest(
			this.workspacesClient.invitationsDetail(workspaceId),
		);
	}

	// public async revokeWorkspaceInvitation(workspaceId: string) {
	// 	return this.processRequest(
	// 		this.workspacesClient.removeWorkspaceInvitation()
	// 	);
	// }

	public async deleteWorkspaceFeedItem(
		workspaceId: string,
		feedId: string,
		feedItemId: string,
	) {
		return this.processRequest(
			this.workspacesClient.itemsDelete(workspaceId, feedId, feedItemId),
		);
	}

	public async publishToWorkspaceFeed({
		workspaceId,
		feedId,
		contentId,
		itemId,
		groupId,
		url,
		text,
	}: {
		workspaceId: string;
		feedId: string;
		contentId: string;
		itemId?: string;
		groupId?: string;
		url?: string;
		text?: string;
	}) {
		itemId = itemId || cuid();

		return this.processRequest(
			this.workspacesClient.itemsCreate(workspaceId, feedId, {
				contentId,
				itemId,
				url,
				groupId,
				text,
			}),
		);
	}

	public async getWorkspaceFeedEvents(
		workspaceId: string,
		feedId: string,
		page = 0,
		pageSize = 1000,
	) {
		return this.processRequest(
			this.workspacesClient.feedsEventsDetail(workspaceId, feedId, {
				page,
				pageSize,
			}),
		);
	}

	public async getWorkspaceFeedPermissions(
		workspaceId: string,
		feedId: string,
		page = 0,
		pageSize = 1000,
	) {
		return this.processRequest(
			this.workspacesClient.feedsPermissionsDetail(workspaceId, feedId, {
				page,
				pageSize,
			}),
		);
	}

	public async createWorkspace(name: string) {
		return this.processRequest(
			this.workspacesClient.workspacesCreate({ name }),
		);
	}

	public async updateWorkspace(workspaceId: string, data: { name: string }) {
		return this.processRequest(
			this.workspacesClient.workspacesPartialUpdate(workspaceId, data),
		);
	}

	public async createWorkspaceFeed({
		workspaceId,
		title,
		isPrivate,
		readOnly,
	}: {
		workspaceId: string;
		title: string;
		isPrivate: boolean;
		readOnly: boolean;
	}) {
		return this.processRequest(
			this.workspacesClient.feedsCreate(workspaceId, {
				title,
				isPrivate,
				readOnly,
			}),
		);
	}

	public async updateWorkspaceFeed({
		workspaceId,
		feedId,
		title,
	}: { workspaceId: string; feedId: string; title: string }) {
		return this.processRequest(
			this.workspacesClient.feedsPartialUpdate(workspaceId, feedId, {
				title,
			}),
		);
	}

	public async createWorkspaceDm({
		workspaceId,
		workspaceMembershipIds,
	}: {
		workspaceId: string;
		workspaceMembershipIds: string[];
	}) {
		return this.processRequest(
			this.workspacesClient.directMessagesCreate(workspaceId, {
				membershipIds: workspaceMembershipIds,
			}),
		);
	}

	public async getWorkspaceFeedItems(
		workspaceId: string,
		feedId: string,
		page = 0,
		pageSize = 10,
	) {
		return this.processRequest(
			this.workspacesClient.feedsItemsDetail(workspaceId, feedId, {
				page,
				pageSize,
			}),
		);
	}

	public async addWorkspaceMembersToFeed(
		workspaceId: string,
		feedId: string,
		workspaceMembershipIds: string[],
	) {
		return this.processRequest(
			this.workspacesClient.feedsWorkspaceMembershipsCreate(
				workspaceId,
				feedId,
				{ workspaceMembershipIds },
			),
		);
	}

	public async updateWorkspaceMember(
		workspaceId: string,
		workspaceMembershipIds: string[],
		role: WorkspaceRole,
	) {
		return this.processRequest(
			this.workspacesClient.workspaceMembershipsUpdatePartialUpdate(
				workspaceId,
				{
					workspaceMembershipIds,
					role,
				},
			),
		);
	}

	public async removeWorkspaceMember(
		workspaceId: string,
		workspaceMembershipIds: string[],
	) {
		return this.processRequest(
			this.workspacesClient.workspaceMembershipsRemoveDelete(workspaceId, {
				workspaceMembershipIds,
				status: "inactive",
			}),
		);
	}

	public async muteUnmuteWorkspaceFeedMember(
		workspaceId: string,
		feedId: string,
		workspaceMemberId: string,
		type: string,
	) {
		return await this.processRequest(
			this.workspacesClient.feedsMuteUnmuteCreate(workspaceId, feedId, {
				workspaceMemberId,
				type,
			}),
		);
	}
	public async promoteDemoteMember(
		workspaceId: string,
		feedId: string,
		workspaceMemberId: string,
		type: string,
	) {
		return await this.processRequest(
			this.workspacesClient.feedsPromoteDemoteCreate(workspaceId, feedId, {
				workspaceMemberId,
				type,
			}),
		);
	}

	public async removeMemberFromFeed(
		workspaceId: string,
		feedId: string,
		workspaceMemberId: string,
	) {
		return await this.processRequest(
			this.workspacesClient.feedsRemoveMemberCreate(workspaceId, feedId, {
				workspaceMemberId,
			}),
		);
	}

	public async bootstrapWorkspaces() {
		return this.processRequest(this.bootstrapClient.workspacesList());
	}

	public async tokenLogin(token: string) {
		return this.loginClient.tokenList({ token });
	}

	public async getUserInfo() {
		return this.processRequest(this.userInfoClient.userinfoList());
	}

	public async inviteViaEmail(feedId: string, emails: string[]) {
		return this.processRequest(
			this.feedsClient.inviteViaEmailCreate(feedId, {
				emails,
				feedId,
			}),
		);
	}

	public async createContentReceipt({
		contentId,
		error,
		artifactId,
		artifactType,
	}: {
		contentId: string;
		error?: Error;
		artifactId?: string;
		artifactType?: ContentReceiptType;
	}) {
		return this.processRequest(
			this.contentClient.receiptsCreate(contentId, {
				contentId,
				artifactId,
				artifactType,
				timestamp: Date.now().toString(),
				deviceContext: {
					surface: "web",
					surfaceContext: window?.navigator?.userAgent,
					surfaceBuild: buildVersion(),
				},
				// TODO get app context
				error,
			}),
		);
	}

	public async createContentEvent({
		contentId,
		step,
		status,
		context,
		feedId,
		error,
	}: {
		contentId: string;
		step: ContentEventStep;
		status: ContentEventStepStatus;
		context?: string;
		feedId?: string;
		error?: Error;
	}) {
		return this.processRequest(
			this.contentClient.eventsCreate(contentId, {
				contentId,
				step,
				status,
				feedId,
				eventContext: context,
				timestamp: Date.now().toString(),
				deviceContext: {
					surface: "web",
					surfaceContext: window?.navigator?.userAgent,
					surfaceBuild: buildVersion(),
				},
				// TODO get app context
				error,
			}),
		);
	}

	public async refreshContent(uploadId?: string) {
		uploadId = uploadId || cuid();
		this.processRequest(this.contentClient.refreshCreate({ uploadId }));
		return uploadId;
	}

	public async publishContent(
		feedId: string,
		uploadId: string,
		feedItemId?: string,
		groupId?: string,
		url?: string,
	) {
		feedItemId = feedItemId || cuid();

		this.processRequest(
			this.feedsClient.itemsCreate(feedId, {
				uploadId,
				feedItemId,
				url,
				groupId,
			}),
		);

		return feedItemId;
	}

	public async unSubscribeFromFeed(feedId: string) {
		return this.processRequest(this.feedsClient.unSubscribeCreate(feedId));
	}

	public async unSubscribeFromWorkspaceFeed(
		workspaceId: string,
		feedId: string,
	) {
		return this.processRequest(
			this.workspacesClient.unsubscribeFromFeedCreate(workspaceId, feedId),
		);
	}

	public async createAccountEvent(body: AccountEventRequest) {
		body.id = cuid();
		// console.log('account event', body);
		this.processRequest(this.accountEventClient.accountEventCreate(body));
		return body;
	}

	public async updateAccount(
		accountId: string,
		{ fullName, email }: { fullName: string; email?: string },
	) {
		return this.processRequest(
			this.accountClients.accountsPartialUpdate(accountId, {
				name: fullName,
				email,
			}),
		);
	}

	public async deleteAccount(accountId: string) {
		await this.processRequest(this.accountClients.accountsDelete(accountId));
		return this.logout();
	}

	public async fetchPhoneToken(identity) {
		return await this.processRequest(
			this.phoneClient.tokenCreate({ identity }),
		);
	}

	public async deleteAppPhoneNumber(appPhoneNumberId: string) {
		return await this.processRequest(
			this.phoneClient.numbersDelete(appPhoneNumberId),
		);
	}

	public async processRequest<I, T>(request: Promise<HttpResponse<I, T>>) {
		try {
			const response = await request;
			if (response.status === 401) {
				console.log("401 - redirecting to login", response);
				if (this.login) this.login();
			}
			return response.data;
		} catch (e) {
			console.error("ERROR", e);
			switch (e.status) {
				case 401:
					console.log("401 from error - redirecting to login");
					if (this.login) this.login();
			}
		}
	}

	public async createPhoneNumber(forwardingPhoneNumber?: string) {
		return this.processRequest(
			this.phoneClient.numbersCreate({
				forwardingPhoneNumber,
			}),
		);
	}

	public async getWorkspaceTemplates({ workspaceId }: { workspaceId: string }) {
		return this.processRequest(
			this.workspacesClient.templatesDetail(workspaceId),
		);
	}

	public async createWorkspaceTemplate({
		workspaceId,
		name,
		template,
	}: {
		workspaceId: string;
		name: string;
		template: string;
	}) {
		return this.processRequest(
			this.workspacesClient.templatesCreate(workspaceId, { name, template }),
		);
	}

	public async updateWorkspaceTemplate({
		workspaceId,
		templateId,
		name,
		template,
	}: {
		workspaceId: string;
		templateId: string;
		name: string;
		template: string;
	}) {
		return this.processRequest(
			this.workspacesClient.templatesPartialUpdate(workspaceId, templateId, {
				name,
				template,
			}),
		);
	}

	public async deleteWorkspaceTemplate({
		workspaceId,
		templateId,
	}: {
		workspaceId: string;
		templateId: string;
	}) {
		return this.processRequest(
			this.workspacesClient.templatesDelete(workspaceId, templateId),
		);
	}
}
