import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { menuItemFocusSX } from "@/utils";
import CrownIcon from "@/components/Icons/Crown";
import * as Icons from "@mui/icons-material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { AccountInfo } from "@/models/accountInfo";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { useContext } from "react";
import { ActionContext } from "@/models/ActionsProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function MemberDrawerSubOptions({
	account,
	activeMemberMenuAccount,
	individualMemberAnchorEl,
	setActiveMemberMenuAccount,
	setConfirmDialogOpen,
	disabledMemberMenu,
	resetIndividualMemberAnchorEl,
}: {
	account: AccountInfo;
	activeMemberMenuAccount: AccountInfo;
	individualMemberAnchorEl: any;
	setActiveMemberMenuAccount: (value: null) => void;
	resetIndividualMemberAnchorEl: (value: null) => void;
	setConfirmDialogOpen: (value: string) => void;
	disabledMemberMenu: boolean;
}) {
	const { createWorkspaceDm } = useContext(ActionContext);
	const params = useParams();
	const workspaceId = params.workspaceId;
	const navigate = useNavigate();
	const { directMessage } = useFlags();
	const mine = account.mine === 1

	const createDmAction = async (workspaceMembershipId: string) => {
		const newWorkspaceDm = await createWorkspaceDm(workspaceId, [
			workspaceMembershipId,
		]);
		if (newWorkspaceDm) {
			navigate(`/workspaces/${workspaceId}/feeds/${newWorkspaceDm.id}`);
		}
	};

	return (
		<Box
			className={`menu-options-button ${
				activeMemberMenuAccount?.id === account.id ? "active" : "not-active"
			}`}
			sx={{ ml: 1, position: "absolute", right: "0.5rem" }}
		>
			<Menu
				anchorEl={individualMemberAnchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{
					mt: 1,
					...menuItemFocusSX,
				}}
				open={activeMemberMenuAccount?.id === account?.id}
				onClose={(data) => {
					setActiveMemberMenuAccount(null);
				}}
			>
				{account.isAdmin ? (
					<MenuItem
						disabled={disabledMemberMenu}
						onClick={() => {
							setConfirmDialogOpen("change-member-status");
						}}
					>
						<ListItemIcon>
							<CrownIcon
								aria-label="Demote to Member"
								fill="#fff"
								name="Feed Admin"
							/>
						</ListItemIcon>
						<ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
							Demote to member
						</ListItemText>
					</MenuItem>
				) : (
					<MenuItem
						disabled={disabledMemberMenu}
						onClick={() => {
							setConfirmDialogOpen("change-member-status");
						}}
					>
						<ListItemIcon>
							<CrownIcon
								aria-label="Promote to Owner"
								fill="#fff"
								name="Feed Admin"
							/>
						</ListItemIcon>
						<ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
							Promote to Owner
						</ListItemText>
					</MenuItem>
				)}

				{directMessage && !mine && (
					<MenuItem
						disabled={disabledMemberMenu}
						onClick={() => {
							createDmAction(account.workspaceMembership.id);
							resetIndividualMemberAnchorEl(null);
						}}
					>
						<ListItemIcon>
							<AddCommentOutlinedIcon sx={{ transform: "scaleX(-1)" }} />
						</ListItemIcon>
						<ListItemText>Send Direct Message</ListItemText>
					</MenuItem>
				)}


				{account?.isMuted ? (
					<MenuItem
						disabled={disabledMemberMenu}
						onClick={() => {
							setConfirmDialogOpen("change-mute-status");
						}}
					>
						<ListItemIcon>
							<Icons.VolumeUp role="img" />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								fontWeight: 500,
							}}
						>
							Un-Mute this Member
						</ListItemText>
					</MenuItem>
				) : (
					<MenuItem
						disabled={disabledMemberMenu}
						onClick={() => {
							setConfirmDialogOpen("change-mute-status");
						}}
					>
						<ListItemIcon>
							<Icons.VolumeOff role="img" />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								fontWeight: 500,
							}}
						>
							Mute this Member
						</ListItemText>
					</MenuItem>
				)}
				<MenuItem
					disabled={disabledMemberMenu}
					onClick={() => {
						setConfirmDialogOpen("remove-from-channel");
					}}
				>
					<ListItemIcon>
						<ArrowOutwardIcon role="img" />
					</ListItemIcon>
					<ListItemText>Remove from Channel</ListItemText>
				</MenuItem>
			</Menu>
		</Box>
	);
}
