import { AudioEncoding, Transcription } from "@/generated/client";
import { PreferredLanguage, AllPreferredLanguage } from "@/models/languages";

function sortByLanguageThenPriorityThenDate(
	preferredLanguage: PreferredLanguage,
	shortPreferredLanguage: string,
) {
	return function (
		a: AudioEncoding | Transcription,
		b: AudioEncoding | Transcription,
	) {
		if (preferredLanguage !== "none") {
			if (a.language === preferredLanguage) return -1;
			if (b.language === preferredLanguage) return 1;
			if (a.language?.split("-")[0] === shortPreferredLanguage) return -1;
			if (b.language?.split("-")[0] === shortPreferredLanguage) return 1;
		}

		if (a.priority > b.priority) return -1;
		if (a.priority < b.priority) return 1;
		if (a.createdAt > b.createdAt) return -1;
		if (a.createdAt < b.createdAt) return 1;
		return 0;
	};
}


function sortByLanguageThenPriority(
	preferredLanguage: PreferredLanguage,
	shortPreferredLanguage: string,
) {
	return function (
		a: AudioEncoding | Transcription,
		b: AudioEncoding | Transcription,
	) {
		if (preferredLanguage !== "none") {
			if (a.language === preferredLanguage) return -1;
			if (b.language === preferredLanguage) return 1;
			if (a.language?.split("-")[0] === shortPreferredLanguage) return -1;
			if (b.language?.split("-")[0] === shortPreferredLanguage) return 1;
		}

		if (a.priority > b.priority) return -1;
		if (a.priority < b.priority) return 1;
		return 0;
	};
}

function getAudioForLanguage(audioContents, language) {
	return audioContents?.filter(audio => audio.language === language);
}

function isOriginal(encoding: AudioEncoding) {
	if (!encoding.translatedFrom || encoding.translatedFrom === "") return true
	return false
}

export function selectAudio(
	preferredLanguage: PreferredLanguage,
	audioEncodings: AudioEncoding[],
	inputLanguage?: AllPreferredLanguage,
) {
	if (!audioEncodings || audioEncodings.length === 0) return []	

	let filteredAudioEncodings = preferredLanguage === "none"
			? audioEncodings?.filter(
					(c) => isOriginal(c),
			  )
			: audioEncodings?.filter((c) => {
					if (c.language) {
						return c.language === preferredLanguage;
					}
					// `language` will be null for the original encoding, so use inputLanguage instead
					return inputLanguage === preferredLanguage;
			  });

	if (filteredAudioEncodings.length === 0) {
		// default to original audioEncoding when the filter returns an empty list
		// this happens when there is no encoding for the preferred language, and
		// the original is also not in the preferred language
		filteredAudioEncodings = audioEncodings?.filter(a => isOriginal(a))
	}

	// sort encodings preferring Original -> ElevenLabs -> Polly
	const sortedEncodings = filteredAudioEncodings?.sort((a, b) => {
		if (isOriginal(a) && isOriginal(b)) return 0 // both encodings are original
		if (isOriginal(a) && !isOriginal(b)) return 1 // a is original, b is not
		if (isOriginal(b) && !isOriginal(a)) return -1 // b is original, a is not
			
		// if neither are original, use the priority to determine the sorting
		// polly will be lower priority than elevenLabs
		return b.priority - a.priority
	})

	if (!sortedEncodings || sortedEncodings.length === 0) {
		return [];
	}

	const mp3 = sortedEncodings
		.filter((a) => a.codec === "mp3")
	const opus = sortedEncodings
		.filter((a) => a.codec === "opus")
	const caf = sortedEncodings
		.filter((a) => a.codec === "caf")


	return [mp3[0], opus[0], caf[0]].filter((a) => a);
}

export function selectTranscription(
	preferredLanguage: PreferredLanguage,
	transcriptions: Transcription[],
) {
	const shortPreferredLanguage = preferredLanguage?.split("-")[0];
	const fullTranscripts =
		preferredLanguage === "none"
			? transcriptions?.filter(
					(c) =>
						c.transcriptionContent !== "" &&
						(!c.translatedFrom || c.translatedFrom === ""),
			  )
			: transcriptions?.filter(
					(c) =>
						c.transcriptionContent !== "" &&
						c.language.split("-")[0] === shortPreferredLanguage,
			  );

	const sortTranscription = sortByLanguageThenPriorityThenDate(
		preferredLanguage,
		shortPreferredLanguage,
	);

	const richTranscript = fullTranscripts
		?.filter((c) => c.format === "Html")
		.sort(sortTranscription)[0];

	const simpleHtml = fullTranscripts
		?.filter((c) => c.format === "SimpleHtml")
		.sort(sortTranscription)[0];

	const textTranscript = fullTranscripts
		?.filter((c) => c.format === "Text")
		.sort(sortTranscription)[0];

	return { simpleHtml, richTranscript, textTranscript };
}
