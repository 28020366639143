import ModalForm, { MODAL_DEFAULT_Z_INDEX } from "@/elements/ModalForm";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	Link,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
	Stack,
	Select,
	MenuItem,
	useTheme,
} from "@mui/material";
import {
	useState,
	useEffect,
	useMemo,
	useContext,
	useCallback,
	useRef,
} from "react";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import AddedFile from "../UploadFiles/AddedFile";
import LoadingButton from "@mui/lab/LoadingButton";
import TimePicker from "../TimePicker";
import DatePicker, { DatePickerVariant } from "../DatePicker";
import { ActionContext } from "@/models/ActionsProvider";
import ScheduleMessageRecurringOptions from "./ScheduleMessageRecurringOptions";
import { useParams } from "react-router-dom";
import {
	WsScheduleTrigger,
	WsWorkflowItem,
} from "web-client/api/data-contracts";
import {
	isPast,
	isFuture,
	startOfDay,
	getDate,
	getDay,
	getMinutes,
	getHours,
	isValid,
} from "date-fns";
import Fuse from "fuse.js";
import { FullInput } from "../Utils";
import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { getDmChannels } from "@/data/liveQueries/dmChannels";
import { parseCronScheduledDate } from "@/utils";
import { UxContext } from "@/models/UxStateProvider";

// export type EditScheduledMessageProps = BoxProps & {
//     workflowItem: WsWorkflowItem
// }

export type SelectedChannel = {
	id: string;
	title: string;
};

export default function EditScheduledMessage({
	workflowItem,
	broadcastId,
}: { workflowItem: WsWorkflowItem; broadcastId: string }) {
	const [editMessageModalOpen, setEditMessageModalOpen] =
		useState<boolean>(false);
	const { timezone } = useContext(SchedulingContext);
	const { updateScheduledWorkflowItem } = useContext(ActionContext);
	const { isSmUp } = useContext(UxContext);
	const [newDate, setNewDate] = useState<Date>(null);
	const [minDatetime, setMinDatetime] = useState<Date>(new Date(Date.now()));
	const [doesRepeat, setDoesRepeat] = useState<boolean>(false);
	const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<Array<number>>(
		[],
	);
	const [newSelectedChannels, setNewSelectedChannels] = useState<
		Array<SelectedChannel>
	>([]);
	const [searchField, setSearchField] = useState<string>("");
	const [error, setError] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [saved, setSaved] = useState<boolean>(false);
	const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
	const [confirmUnsavedChanges, setConfirmUnsavedChanges] =
		useState<boolean>(false);
	const params = useParams();
	const workspaceId = params?.workspaceId;
	const theme = useTheme();
	const editModalId = `edit-${workflowItem?.id}`;

	const initialState = useRef({
		newDate: null,
		doesRepeat: false,
		selectedDaysOfWeek: [],
		newSelectedChannels: [],
	});

	const { db } = useElectric();
	// const { results: item } = useLiveQuery(
	// 	db.item.liveFirst({
	// 		where: {
	// 			contentId: workflowItem?.contentId,
	// 		},
	// 	}),
	// );

	const { results: broadcastRecipients } = useLiveQuery(() => {
		if (!broadcastId) return;
		return db.broadcast_recipient.liveMany({
			where: {
				broadcastId,
			},
		});
	}, [broadcastId]);

	const { results: channels } = useLiveQuery(() => {
		if (!workspaceId) return;
		return db.feed.liveMany({
			where: {
				workspaceId,
			},
			orderBy: {
				title: "asc",
			},
		});
	}, [workspaceId]);

	const { dmChannels } = getDmChannels(useLiveQuery, db, { channels });

	const mappedChannels = useMemo(
		() =>
			channels
				?.map((channel) => {
					if (channel.isDm === 1 && !channel?.title) {
						const dm = dmChannels?.find((d) => d.feedId === channel.id);
						return {
							...channel,
							title: dm?.name || dm?.email,
						};
					}
					return channel;
				})
				?.sort((a, b) => a?.title?.localeCompare(b?.title)),
		[dmChannels, channels],
	);

	// fuse search engine
	const fuse = new Fuse(mappedChannels, {
		// https://www.fusejs.io/examples.html#extended-search rules
		useExtendedSearch: true,
		keys: ["title", "id"],
	});

	const searchedChannels = useMemo(() => {
		if (searchField) {
			// `'` denotes includes this search text
			return fuse
				.search(`'${searchField}`)
				?.map((result) => result.item)
				?.sort((a, b) => a?.title?.localeCompare(b?.title));
		}
		return mappedChannels;
	}, [searchField, fuse, mappedChannels]);

	const { results: broadcastAction } = useLiveQuery(() => {
		if (!workflowItem?.id) return;
		return db.broadcast_action.liveFirst({
			where: {
				workflowItemId: workflowItem?.id,
			},
		});
	}, [workflowItem]);

	const { results: scheduledTrigger } = useLiveQuery(() => {
		if (!broadcastAction?.id) return;

		return db.schedule_trigger.liveFirst({
			where: {
				broadcastActionId: broadcastAction?.id,
			},
		});
	}, [broadcastAction]);

	const handleDateSelection = useCallback(
		(date: Date, unsavedChanges?: boolean) => {
			if (isValid(date)) {
				if (isPast(date)) {
					// if selected date is today, it cannot be earlier than the current datetime
					setError(true);
				} else {
					setError(false);
				}

				setNewDate(date);

				if (isFuture(date)) {
					setMinDatetime(startOfDay(date));
				} else {
					setMinDatetime(date);
				}
			} else {
				setError(true);
			}
			setUnsavedChanges(unsavedChanges);
		},
		[],
	);

	const handleSelectedChannels = useCallback(
		(channels: Array<SelectedChannel>, unsavedChanges?: boolean) => {
			setNewSelectedChannels(channels);
			setUnsavedChanges(unsavedChanges);
		},
		[],
	);

	const handleDoesRepeat = useCallback(
		(doesRepeat: boolean, unsavedChanges?: boolean) => {
			setDoesRepeat(doesRepeat);
			setUnsavedChanges(unsavedChanges);
		},
		[],
	);

	const handleSelectedDaysOfWeek = useCallback(
		(daysOfWeek: Array<number>, unsavedChanges?: boolean) => {
			setSelectedDaysOfWeek(daysOfWeek);
			setUnsavedChanges(unsavedChanges);
		},
		[],
	);

	const saveWorkflowItem = async () => {
		try {
			setSaving(true);

			let scheduledWorkflow: any = {
				workspaceId,
				scheduleId: scheduledTrigger?.id,
				feedIds: newSelectedChannels?.map((newChannel) => newChannel?.id),
			};

			if (doesRepeat) {
				const mins = getMinutes(newDate);
				const hours = getHours(newDate);
				// const month = getMonth(selectedDate);
				const month = "*";
				let daysOfWeek = "?";
				let dayOfMonth = "?";

				if (selectedDaysOfWeek?.length > 0) {
					// figure out when we have the ability for multi day selection
					// daysOfWeek = selectedDaysOfWeek.join(",");
					daysOfWeek = selectedDaysOfWeek[0].toString();
					dayOfMonth = "?";
				} else {
					dayOfMonth = getDate(newDate).toString();
				}

				const scheduledCron = `${mins} ${hours} ${dayOfMonth} ${month} ${daysOfWeek} *`;
				scheduledWorkflow.scheduledCron = scheduledCron;
				scheduledWorkflow.timezone = timezone;
			} else {
				scheduledWorkflow.scheduledDate = newDate?.toISOString();
			}
			await updateScheduledWorkflowItem(scheduledWorkflow);
			setSaved(true);
			//set the new initial state of the item to the saved values
			if (initialState.current) {
				initialState.current.newDate = newDate;
				initialState.current.doesRepeat = doesRepeat;
				initialState.current.selectedDaysOfWeek = selectedDaysOfWeek;
				initialState.current.newSelectedChannels = newSelectedChannels;
			}
		} catch (e) {
			console.log("ERROR", e);
		} finally {
			setSaving(false);
			setUnsavedChanges(false);
			setTimeout(() => {
				setSaved(false);
				setEditMessageModalOpen(false);
			}, 2000);
		}
	};

	const handleEditClick = () => {
		setEditMessageModalOpen(true);
	};

	const handleToggle = (id: string, title: string) => () => {
		const currentIndex = newSelectedChannels?.findIndex(
			(channel) => channel?.id === id,
		);
		const newChecked = [...newSelectedChannels];

		if (currentIndex === -1) {
			newChecked.push({ id, title });
		} else {
			newChecked.splice(currentIndex, 1);
		}
		handleSelectedChannels(newChecked);
		setUnsavedChanges(true);
	};

	const secondaryAction = (channel) => {
		return (
			<Checkbox
				edge="end"
				onChange={handleToggle(channel?.id, channel?.title)}
				checked={
					newSelectedChannels?.findIndex(
						(newChannel) => newChannel?.id === channel?.id,
					) !== -1
				}
				inputProps={{ "aria-labelledby": channel?.id }}
				color="primary"
			/>
		);
	};

	const confirmDiscardClose = useCallback(() => {
		setConfirmUnsavedChanges(false);
		setUnsavedChanges(false);
		setEditMessageModalOpen(false);
		// reset the workflow items previous info
		if (initialState.current) {
			setNewDate(initialState.current.newDate);
			setDoesRepeat(initialState.current.doesRepeat);
			setNewSelectedChannels(initialState.current.newSelectedChannels);
			setSelectedDaysOfWeek(initialState.current.selectedDaysOfWeek);
		}
	}, []);

	const denyClose = () => {
		setConfirmUnsavedChanges(false);
	};

	const handleClose = useCallback(() => {
		if (unsavedChanges) {
			setConfirmUnsavedChanges(true);
		} else {
			confirmDiscardClose();
		}
	}, [unsavedChanges, confirmDiscardClose]);

	useEffect(() => {
		if (scheduledTrigger?.oneTimeSchedule) {
			const currentDate = new Date(scheduledTrigger?.oneTimeSchedule);
			handleDateSelection(currentDate);
			if (initialState.current && !initialState.current.newDate) {
				initialState.current.newDate = currentDate;
			}
		}

		if (scheduledTrigger?.cronSchedule) {
			handleDoesRepeat(true);
			const parsedDate = parseCronScheduledDate(
				scheduledTrigger?.cronSchedule,
				scheduledTrigger?.timezone,
			);
			if (parsedDate) {
				handleDateSelection(parsedDate);
				// date-fns uses 0-6, while aws cron uses 1-7, so prevent an off by one error
				const dayOfWeek = getDay(parsedDate) + 1;
				handleSelectedDaysOfWeek([dayOfWeek]);
				if (
					initialState.current &&
					(!initialState.current.doesRepeat ||
						!initialState.current.newDate ||
						initialState.current.selectedDaysOfWeek.length === 0)
				) {
					initialState.current.doesRepeat = true;
					initialState.current.newDate = parsedDate;
					initialState.current.selectedDaysOfWeek = [dayOfWeek];
				}
			}
		}
	}, [
		scheduledTrigger,
		handleDoesRepeat,
		handleDateSelection,
		handleSelectedDaysOfWeek,
	]);

	useEffect(() => {
		if (broadcastRecipients?.length > 0) {
			const broadcastChannels = broadcastRecipients.map((br) => br.feedId);
			const currentSelectedChannels = mappedChannels
				?.filter((c) => broadcastChannels.includes(c?.id))
				?.map((channel) => {
					return { id: channel?.id, title: channel?.title };
				});
			handleSelectedChannels(currentSelectedChannels);
			if (
				initialState.current &&
				initialState.current.newSelectedChannels.length === 0
			) {
				initialState.current.newSelectedChannels = currentSelectedChannels;
			}
		}
	}, [broadcastRecipients, mappedChannels, handleSelectedChannels]);

	return (
		<>
			<ModalForm
				id={editModalId}
				open={editMessageModalOpen}
				onClose={handleClose}
				sx={{ flexGrow: 1, padding: { xs: 2, sm: "64px 40px" } }}
			>
				<Stack sx={{ width: "100%", gap: 3.5 }}>
					{/* <Stack sx={{ gap: 1 }}>
						<AddedFile file={item} />
						<Link
							component="button"
							sx={{ fontWeight: 600, alignSelf: "flex-end" }}
						>
							File Upload Details
						</Link>
					</Stack> */}
					<Typography variant="h6" component="h3" sx={{ alignSelf: "center" }}>
						{workflowItem?.displayName}
					</Typography>
					<Stack sx={{ gap: "5px" }}>
						<Stack
							sx={{
								flexDirection: "row",
								alignItems: "center",
								gap: 3,
							}}
						>
							<Box sx={{ width: "100%", maxWidth: "50%" }}>
								<DatePicker
									label="Date and Time"
									variant={DatePickerVariant.Dropdown}
									selectedDate={newDate}
									dateSelectionHandler={(date) =>
										handleDateSelection(date, true)
									}
									disabled={doesRepeat || saving}
								/>
							</Box>
							<Box sx={{ width: "100%", maxWidth: "50%" }}>
								<TimePicker
									label=""
									includeTimeZone={false}
									selectedTime={newDate}
									minDate={minDatetime}
									timeSelectionHandler={(date) =>
										handleDateSelection(date, true)
									}
									disabled={saving}
								/>
							</Box>
						</Stack>
						<Stack sx={{ position: "relative" }}>
							<ScheduleMessageRecurringOptions
								doesRepeat={doesRepeat}
								doesRepeatHandler={handleDoesRepeat}
								disabled={saving}
								selectedDate={newDate}
								selectedDaysOfWeek={selectedDaysOfWeek}
								selectedDaysOfWeekHandler={(days) =>
									handleSelectedDaysOfWeek(days, true)
								}
							/>
						</Stack>
					</Stack>
					<FormControl>
						<InputLabel id="channels-label">Channels</InputLabel>
						<Select
							labelId="channels-label"
							sx={{ width: "100%" }}
							value={newSelectedChannels}
							MenuProps={{
								slotProps: {
									paper: {
										className: "dark-scrollbar",
										sx: {
											width: "100%",
											maxWidth: { xs: "calc(100% - 32px)", sm: "520px" },
										},
									},
								},
								sx: {
									zIndex: MODAL_DEFAULT_Z_INDEX,
									mt: 1,
									maxHeight: 350,
								},
								MenuListProps: {
									sx: {
										pt: 0,
									},
								},
							}}
							multiple
							renderValue={(selected) => (
								<Box
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: 0.5,
									}}
								>
									{selected.map((s) => (
										<Chip
											sx={{
												background: theme.palette.secondary.main,
												color: theme.palette.primary.main,
												borderRadius: "4px",
											}}
											key={s?.id}
											label={s?.title}
										/>
									))}
								</Box>
							)}
							disabled={saving}
						>
							<Box
								sx={{
									px: 1,
									py: 1,
									position: "sticky",
									top: 0,
									background: theme.palette.secondary.dark,
									zIndex: MODAL_DEFAULT_Z_INDEX,
								}}
							>
								<FullInput
									fullWidth
									callback={(e) => setSearchField(e.target.value)}
									value={searchField}
								/>
							</Box>
							{searchedChannels?.length > 0 ? (
								searchedChannels?.map((channel, index) => (
									<ListItem
										key={`${channel?.id}-${index}`}
										secondaryAction={secondaryAction(channel)}
										disablePadding
									>
										<ListItemButton
											onClick={handleToggle(channel?.id, channel?.title)}
											disableRipple
										>
											<ListItemText id={channel?.id} primary={channel?.title} />
										</ListItemButton>
									</ListItem>
								))
							) : (
								<ListItem
									key={"no-results"}
									disablePadding
									sx={{ textAlign: "center" }}
								>
									<ListItemText
										primary={
											<Typography sx={{ fontWeight: 700 }}>
												No channels found
											</Typography>
										}
									/>
								</ListItem>
							)}
						</Select>
					</FormControl>
					<Stack
						sx={{
							flexDirection: { xs: "column", sm: "row" },
							width: "100%",
							gap: 2,
						}}
					>
						<Button
							variant="outlined"
							sx={{ order: { xs: 1, sm: 0 } }}
							onClick={handleClose}
							disabled={saving}
						>
							Cancel
						</Button>
						<LoadingButton
							loading={saving}
							disabled={error}
							variant="contained"
							color="primary"
							sx={{ order: { xs: 0, sm: 1 } }}
							onClick={saveWorkflowItem}
						>
							Save
						</LoadingButton>
					</Stack>
					{saved ? (
						<Alert
							variant="filled"
							severity="success"
							sx={{
								position: "fixed",
								width: "100%",
								left: 0,
								top: isSmUp ? `calc(100% + 24px)` : 0,
								borderRadius: isSmUp ? "12px" : 0,
								".MuiAlert-icon": {
									padding: 0,
								},
							}}
						>
							File details updated
						</Alert>
					) : null}
				</Stack>
			</ModalForm>
			<ModalForm open={confirmUnsavedChanges} onClose={denyClose}>
				<Stack
					sx={{
						width: "100%",
						height: "100%",
						alignItems: "center",
						textAlign: "center",
						gap: 2,
					}}
				>
					<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
						Unsaved Changes
					</Typography>
					<Typography sx={{ fontWeight: 600 }}>
						Are you sure you want to discard these changes?
					</Typography>
					<Stack
						sx={{
							flexDirection: { xs: "column", sm: "row" },
							width: "100%",
							gap: 2,
						}}
					>
						<Button
							variant="outlined"
							sx={{ order: { xs: 1, sm: 0 } }}
							onClick={denyClose}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="error"
							sx={{ order: { xs: 0, sm: 1 } }}
							onClick={confirmDiscardClose}
						>
							Discard
						</Button>
					</Stack>
				</Stack>
			</ModalForm>
			<Link
				component="button"
				sx={{ fontWeight: 600 }}
				onClick={handleEditClick}
			>
				Edit
			</Link>
		</>
	);
}
