import { Box, useTheme } from "@mui/material";

interface Props {
	textInput: string;
	limit: number;
}
export default function TextInputHelper({ textInput, limit }: Props) {
	const theme = useTheme();
	const messageTooLong = textInput?.length > limit;
	const message = () => {
		let msg = `Enter up to ${limit.toLocaleString()} characters to generate audio.`;
		if (messageTooLong) {
			msg = `Error: You cannot exceed ${limit.toLocaleString()} characters.`;
		}
		return msg;
	};
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				gap: 1,
				width: "100%",
				color: messageTooLong && theme.palette.error.main,
			}}
		>
			<Box>{message()}</Box>
			<Box>
				{textInput.length}/{limit}
			</Box>
		</Box>
	);
}
