/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateAppPhoneNumberRequest,
  CreateAppPhoneNumberResponse,
  FetchTwilioTokenRequest,
  FetchTwilioTokenResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Phone<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name TokenCreate
   * @summary token
   * @request POST:/phone/token
   */
  tokenCreate = (body: FetchTwilioTokenRequest, params: RequestParams = {}) =>
    this.request<FetchTwilioTokenResponse, any>({
      path: `/phone/token`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetRecordingConsentCreate
   * @summary getRecordingConsent
   * @request POST:/phone/get-recording-consent
   */
  getRecordingConsentCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/phone/get-recording-consent`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name NumbersCreate
   * @summary createAppPhoneNumber
   * @request POST:/phone/numbers
   */
  numbersCreate = (body: CreateAppPhoneNumberRequest, params: RequestParams = {}) =>
    this.request<CreateAppPhoneNumberResponse, any>({
      path: `/phone/numbers`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name NumbersDelete
   * @summary deleteAppPhoneNumber
   * @request DELETE:/phone/numbers/{appPhoneNumberId}
   */
  numbersDelete = (appPhoneNumberId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/phone/numbers/${appPhoneNumberId}`,
      method: "DELETE",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name CallsStartOutboundCreate
   * @summary phoneStartOutbound
   * @request POST:/phone/calls/start-outbound
   */
  callsStartOutboundCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/phone/calls/start-outbound`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name NumbersStartInboundCreate
   * @summary phoneStartInbound
   * @request POST:/phone/numbers/{appPhoneNumberId}/start-inbound
   */
  numbersStartInboundCreate = (appPhoneNumberId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/phone/numbers/${appPhoneNumberId}/start-inbound`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ConfirmRecordingConsentCreate
   * @summary phoneRecordingConsent
   * @request POST:/phone/confirm-recording-consent
   */
  confirmRecordingConsentCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/phone/confirm-recording-consent`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name CallsRecordingCreate
   * @summary recordingCallback
   * @request POST:/phone/calls/{callRecordId}/recording
   */
  callsRecordingCreate = (callRecordId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/phone/calls/${callRecordId}/recording`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
}
