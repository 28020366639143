import { useElectric } from "@/electric/ElectricWrapper";
import { DataContext } from "@/models/DataProvider";
import { Box, ListItem, ListItemButton, Stack, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import React, { useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountEventType } from "web-client/api/data-contracts";
import LockIcon from "@mui/icons-material/Lock";
import { AppContext } from "@/models/AppStateProvider";
import { upsertWsPermission } from "@/data/workspace";

export default function WorkspaceChannelListItem({
	feedId,
	latestActivity,
}: { feedId: string; latestActivity: string }) {
	const { client } = useContext(AppContext);
	const { myAccount, currentFeedId, currentFeed } = useContext(DataContext);
	const params = useParams();
	const workspaceId = params.workspaceId;

	const linkRef = React.useRef(null);
	const navigate = useNavigate();
	const theme = useTheme();

	const isCurrent = currentFeedId === feedId;

	const { db } = useElectric();
	const { results: feed } = useLiveQuery(() => {
		if (!feedId) return;
		return db.feed.liveUnique({ where: { id: feedId } });
	}, [feedId]);

	const { results: fetchFeedDMUsers } = useLiveQuery(() => {
		if (!feedId) return;
		return db.liveRaw({
			sql: `	SELECT
						permission.workspace_membershipId,
						workspace_membership.accountId,
						account.name,
						account.email
					FROM
						permission
					INNER JOIN workspace_membership ON workspace_membership.id = permission.workspace_membershipId
					INNER JOIN account ON account.id = workspace_membership.accountId
					WHERE
						permission.enabled = 1
							AND
						permission.feedId = ?
							AND
						permission.workspace_membershipId IS NOT NULL
							AND
						account.mine = 0
					GROUP BY permission.workspace_membershipId`,
			args: [feedId],
		});
	}, [feedId]);

	const opened: AccountEventType = "Opened Feed";
	const { results: myLatestDb } = useLiveQuery(() => {
		if (!myAccount?.id || !feedId) return;
		return db.account_event.liveFirst({
			where: {
				feedId: feedId,
				accountId: myAccount?.id,
				name: opened,
			},
			orderBy: { createdAt: "desc" },
		});
	}, [myAccount?.id, feedId]);

	const myLatest = myLatestDb?.createdAt || currentFeed?.lastOpened;
	const hasUnread = latestActivity ? myLatest < latestActivity : false;
	const dmName =
		fetchFeedDMUsers?.length > 0
			? fetchFeedDMUsers[0]?.name || fetchFeedDMUsers[0]?.email
			: feedId;

	const handleLinkClick = (e) => {
		if (e.target === linkRef.current) {
			navigate(`/workspaces/${workspaceId}/feeds/${feedId}`);
		}
	};

	return (
		<ListItem
			key={`${workspaceId}-${feedId}`}
			sx={{
				fontSize: "1.125rem",
				fontWeight: 500,
				px: 0,
			}}
		>
			<ListItemButton
				ref={linkRef}
				onClick={handleLinkClick}
				selected={isCurrent}
				disableRipple={true}
				sx={{
					width: "100%",
					borderRadius: "8px",
					color: theme.palette.neutral.main,
					"&.Mui-selected": {
						color: theme.palette.primary.main,
						background: theme.palette.secondary.main,
					},
					justifyContent: "space-between",
					wordBreak: "break-word",
					p: 2,
				}}
			>
				<Stack sx={{ gap: 1.5, flexDirection: "row", alignItems: "center" }}>
					{feed?.isPrivate === 1 && <LockIcon sx={{ fontSize: "1.125rem" }} />}
					{feed?.isDm ? dmName : feed?.title || feed?.id}
				</Stack>
				{hasUnread && (
					<Box
						sx={{
							background: theme.palette.brand.secondary.light,
							borderRadius: "50%",
							p: 0.5,
						}}
					/>
				)}
			</ListItemButton>
		</ListItem>
	);
}
