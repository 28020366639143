import {
	Box,
	Divider,
	FormControl,
	FormControlProps,
	FormHelperText,
	InputBase,
	InputLabel,
	InputProps,
	ListItem,
	ListItemProps,
	TextareaAutosize,
	Tooltip,
	TooltipProps,
	styled,
	tooltipClasses,
} from "@mui/material";
import { MODAL_DEFAULT_Z_INDEX } from "@/elements/ModalForm";
import { ReactMultiEmail } from "react-multi-email";
import { MuiChipsInput } from "mui-chips-input";
import React from "react";
import { Link, NavLink } from "react-router-dom";

export const BigDivider = styled(Divider)(() => ({
	marginTop: "10px",
	marginBottom: "10px",
}));

export const BaseNavLink = styled(NavLink)(() => ({
	textDecoration: "inherit",
	color: "inherit",
	// cursor: "inherit",
}));

export const BaseLink = styled(Link)(() => ({
	textDecoration: "inherit",
	color: "inherit",
	// cursor: "inherit",
}));

interface ItemLinkProps extends ListItemProps {
	to: string;
	label?: string;
	children?: React.ReactNode | React.ReactNode[];
}

export function ItemLink({ to, label, children, ...props }: ItemLinkProps) {
	return (
		<BaseNavLink to={to}>
			{({ isActive }) => (
				<ListItem {...props} selected={isActive}>
					{children || label}
				</ListItem>
			)}
		</BaseNavLink>
	);
}

export const FullBox = styled(Box)(() => ({
	height: "100%",
	width: "100%",
}));

export const FullTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		background: theme.palette.secondary.dark,
		border: `1.5px solid ${theme.palette.info.light}`,
		borderRadius: "8px",
		color: theme.palette.primary.main,
		boxShadow: theme.shadows[1],
		fontSize: theme.typography.pxToRem(14),
		padding: theme.spacing(1),
		fontWeight: 700,
	},
}));

export const StyledMultiEmail = styled(ReactMultiEmail)(
	({ theme }) => `
  // 'label + &': {
  //   marginTop: theme.spacing(3)
  // },
  // '& .MuiInputBase-input': {

  border: 1.5px solid ${theme.palette.secondary.light};
  border-radius: 8px;

  &, & > input {
    // border: 1.5px solid ${theme.palette.secondary.light};
    box-shadow: 0px 24px 40px 0px rgba(26, 26, 26, 0.16);
    position: relative;
    background: ${theme.palette.secondary.dark};
    font: inherit;
    // font-size: 14px;
    color: inherit;
    width: 100%;
    padding: 8px;
    transition: ${theme.transitions.create([
			"border-color",
			"background-color",
			"box  -shadow",
		])};
  }

  & > input {
    background-color: inherit !important;
  }

  &.focused,
  &:focus {
    border-color: ${theme.palette.brand.primary.main};
    background: none;
  }

  &.empty > span[data-placeholder] {
    line-height: 1.75;
    z-index: 1;
  }

  & [data-tag] {
    text-decoration: none;

    & [data-tag-handle] {
      color: inherit;
      font-size: 1.5em;
      line-height: 1;
      margin-left: 0.5em;
      padding: 0 0.25em;
      border: 1px solid #767676;
      border-radius: 3px;

      &:hover {
        // border: 1px solid #767676;
        border-radius: 2px;
        background: #fafafa;
      }
    }
  }
  // },
  // '&.Mui-error, &.Mui-error > .MuiInputBase-input,  &.Mui-error + p': {
  //   color: theme.palette.error.main,
  //   borderColor: theme.palette.error.main
  // },
  // '+ p': {
  //   fontSize: 12,
  //   fontWeight: 500,
  //   color: theme.palette.primary.main
  // }`,
);

export const StyledChipsInput = styled(MuiChipsInput)(
	({ theme }) => `
  &.MuiChipsInput-TextField, & .MuiInputBase-root {
    width: 100%;
  }

  & .MuiOutlinedInput-root {
	& fieldset {
		border: 1.5px solid ${theme.palette.secondary.light};
		border-radius: 8px;
	}
	&.Mui-focused fieldset, 
	&:hover fieldset {
		border-color: ${theme.palette.brand.primary.main};
	}
	&.Mui-focused.Mui-error fieldset, 
	&:hover.Mui-error fieldset {
		border-color: ${theme.palette.error.main};
	}
  }

  & .MuiChipsInput-Chip {
	background: ${theme.palette.secondary.main};
	color: ${theme.palette.primary.main};
	border-radius: 4px;
  }

  & .MuiChip-root {
	  &.MuiChipsInput-Chip-Editing {
		color: ${theme.palette.primary.main};
		background-color: ${theme.palette.brand.primary.main};
		border-radius: 4px;
	  }
	  & .MuiChip-deleteIcon {
		color: ${theme.palette.primary.main};
	}
  }

  
  & input {
    box-shadow: 0px 24px 40px 0px rgba(26, 26, 26, 0.16);
    position: relative;
    background: ${theme.palette.secondary.dark};
    font: inherit;
    color: inherit;
    width: 100%;
    padding: 8px;
    transition: ${theme.transitions.create([
			"border-color",
			"background-color",
			"box  -shadow",
		])};
  }
  
  & .MuiFormHelperText-root {
	margin-left: 0;
	margin-right: 0;
	font-size: 16px;
  }
  `,
);

export const StyledTextarea = styled(TextareaAutosize)(
	({ theme }) => `
  // 'label + &': {
  //   marginTop: theme.spacing(3)
  // },
  // '& .MuiInputBase-input': {
  border-radius: 8px;
  border: 1.5px solid ${theme.palette.secondary.light};
  box-shadow: 0px 24px 40px 0px rgba(26, 26, 26, 0.16);
  position: relative;
  background: ${theme.palette.secondary.dark};
  font: inherit;
  // font-size: 14px;
  color: inherit;
  width: 100%;
  padding: 8px;
  transition: ${theme.transitions.create([
		"border-color",
		"background-color",
		"box  -shadow",
	])};

  &:focus {
    border-color: ${theme.palette.brand.primary.main};
  }
  // },
  // '&.Mui-error, &.Mui-error > .MuiInputBase-input,  &.Mui-error + p': {
  //   color: theme.palette.error.main,
  //   borderColor: theme.palette.error.main
  // },
  // '+ p': {
  //   fontSize: 12,
  //   fontWeight: 500,
  //   color: theme.palette.primary.main
  // }`,
);

export const StyledInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3.5),
	},
	"& .MuiInputBase-input": {
		borderRadius: 8,
		border: `1.5px solid ${theme.palette.secondary.light}`,
		boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
		position: "relative",
		background: theme.palette.secondary.dark,
		fontSize: 14,
		width: "100%",
		padding: 8,
		transition: theme.transitions.create([
			"border-color",
			"background-color",
			"box-shadow",
		]),
		"&:focus": {
			borderColor: theme.palette.primary.main,
		},
		boxSizing: "border-box",
		minHeight: 44,
	},
	"&.Mui-error, &.Mui-error > .MuiInputBase-input,  &.Mui-error + p": {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
	"+ p": {
		fontSize: 12,
		fontWeight: 500,
		color: theme.palette.primary.main,
	},
	"&.input-v2 input": {
		border: "transparent",
		padding: "0rem 0.5rem",
		fontSize: "24px",
	},
	"&.text-input-v2 textarea": {
		border: "transparent",
		padding: "0rem 0.5rem",
		fontSize: "16px",
	},
}));

export interface FullInputProps extends InputProps {
	formControlProps?: FormControlProps;
	callback?: Function;
	helperText?: string | JSX.Element;
	label?: string | JSX.Element;
	value?: string;
}

export function FullInput({
	id,
	label = "",
	helperText = "",
	callback,
	required,
	formControlProps,
	inputProps,
	...props
}: FullInputProps) {
	return (
		<FormControl
			variant="standard"
			fullWidth
			error={props.error}
			{...formControlProps}
		>
			{label && (
				<InputLabel shrink htmlFor={id}>
					{label}
				</InputLabel>
			)}
			{/**
			 * @NOTE - `required` requires additional `aria-required`
			 *  -> both need to be passed to `inputProps`
			 *    rather than directly as props to `StyledInput`
			 */}
			<StyledInput
				id={id}
				inputProps={{
					required,
					"aria-required": required,
					"aria-label": label.toString(),
					...inputProps,
				}}
				onChange={(e) => callback(e)}
				{...props}
			/>
			{helperText ? (
				<FormHelperText
					sx={{ mb: 2, alignSelf: "flex-start", width: "100%" }}
					component="div"
				>
					{helperText}
				</FormHelperText>
			) : null}
		</FormControl>
	);
}
