/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddWorkspaceMembersToFeedRequest,
  AddWorkspaceMembersToFeedResponse,
  CreateWorkspaceDirectInvitationRequest,
  CreateWorkspaceDirectInvitationResponse,
  CreateWorkspaceDmRequest,
  CreateWorkspaceFeedRequest,
  CreateWorkspaceFeedResponse,
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  CreateWorkspaceScheduledBroadcastRequest,
  CreateWorkspaceScheduledBroadcastResponse,
  CreateWorkspaceTemplateRequest,
  CreateWorkspaceTemplateResponse,
  CreateWorkspaceWorkflowItemRequest,
  DeleteWorksapceFeedItemResponse,
  GetScheduledBroadcastsResponse,
  GetWorkspaceFeedEventsResponse,
  GetWorkspaceFeedItemsResponse,
  GetWorkspaceFeedPermissionsResponse,
  GetWorkspaceInvitationsResponse,
  GetWorkspaceTemplatesResponse,
  GetWorkspaceWorkflowItemsResponse,
  MuteWorkspaceFeedMemberRequest,
  MuteWorkspaceFeedMemberResponse,
  PromoteDemoteWorkspaceFeedMemberRequest,
  PromoteDemoteWorkspaceFeedMemberResponse,
  PublishBroadcastRequest,
  PublishBroadcastResponse,
  PublishToWorkspaceFeedRequest,
  PublishWorkspaceWorkflowItemRequest,
  RemoveWorkspaceMemberFromFeedRequest,
  RemoveWorkspaceMemberFromFeedResponse,
  UpdateWorkspaceFeedRequest,
  UpdateWorkspaceFeedResponse,
  UpdateWorkspaceMembershipRequest,
  UpdateWorkspaceMembershipResponse,
  UpdateWorkspaceRequest,
  UpdateWorkspaceResponse,
  UpdateWorkspaceScheduledBroadcastRequest,
  UpdateWorkspaceScheduledBroadcastResponse,
  UpdateWorkspaceTemplateRequest,
  UpdateWorkspaceTemplateResponse,
  WsItem,
  WsPublishedWorkflowItem,
  WsWorkflowItem,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Workspaces<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name WorkspacesCreate
   * @summary createWorkspace
   * @request POST:/workspaces
   */
  workspacesCreate = (body: CreateWorkspaceRequest, params: RequestParams = {}) =>
    this.request<CreateWorkspaceResponse, any>({
      path: `/workspaces`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesPartialUpdate
   * @summary updateWorkspace
   * @request PATCH:/workspaces/{workspaceId}
   */
  workspacesPartialUpdate = (workspaceId: string, body: UpdateWorkspaceRequest, params: RequestParams = {}) =>
    this.request<UpdateWorkspaceResponse, any>({
      path: `/workspaces/${workspaceId}`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsCreate
   * @summary createWorkspaceFeed
   * @request POST:/workspaces/{workspaceId}/feeds
   */
  feedsCreate = (workspaceId: string, body: CreateWorkspaceFeedRequest, params: RequestParams = {}) =>
    this.request<CreateWorkspaceFeedResponse, any>({
      path: `/workspaces/${workspaceId}/feeds`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name DirectMessagesCreate
   * @summary createWorkspaceDm
   * @request POST:/workspaces/{workspaceId}/direct-messages
   */
  directMessagesCreate = (workspaceId: string, body: CreateWorkspaceDmRequest, params: RequestParams = {}) =>
    this.request<CreateWorkspaceFeedResponse, any>({
      path: `/workspaces/${workspaceId}/direct-messages`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsPartialUpdate
   * @summary updateWorkspaceFeed
   * @request PATCH:/workspaces/{workspaceId}/feeds/{feedId}
   */
  feedsPartialUpdate = (
    workspaceId: string,
    feedId: string,
    body: UpdateWorkspaceFeedRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceFeedResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsItemsDetail
   * @summary getWorkspaceFeedItems
   * @request GET:/workspaces/{workspaceId}/feeds/{feedId}/items
   */
  feedsItemsDetail = (
    workspaceId: string,
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedItemsResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/items`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name ItemsCreate
   * @summary publishToWorkspaceFeed
   * @request POST:/workspaces/{workspaceId}/{feedId}/items
   */
  itemsCreate = (
    workspaceId: string,
    feedId: string,
    body: PublishToWorkspaceFeedRequest,
    params: RequestParams = {},
  ) =>
    this.request<WsItem, any>({
      path: `/workspaces/${workspaceId}/${feedId}/items`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name UnsubscribeFromFeedCreate
   * @summary unsubscribeFromFeed
   * @request POST:/workspaces/{workspaceId}/{feedId}/unsubscribeFromFeed
   */
  unsubscribeFromFeedCreate = (workspaceId: string, feedId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/workspaces/${workspaceId}/${feedId}/unsubscribeFromFeed`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsPermissionsDetail
   * @summary getWorkspaceFeedPermissions
   * @request GET:/workspaces/{workspaceId}/feeds/{feedId}/permissions
   */
  feedsPermissionsDetail = (
    workspaceId: string,
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedPermissionsResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/permissions`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsEventsDetail
   * @summary getWorkspaceFeedEvents
   * @request GET:/workspaces/{workspaceId}/feeds/{feedId}/events
   */
  feedsEventsDetail = (
    workspaceId: string,
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedEventsResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/events`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name ItemsDelete
   * @summary deleteWorkspaceFeedItem
   * @request DELETE:/workspaces/{workspaceId}/{feedId}/items/{itemId}
   */
  itemsDelete = (workspaceId: string, feedId: string, itemId: string, params: RequestParams = {}) =>
    this.request<DeleteWorksapceFeedItemResponse, any>({
      path: `/workspaces/${workspaceId}/${feedId}/items/${itemId}`,
      method: "DELETE",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name InvitationsDetail
   * @summary getWorkspaceInvitations
   * @request GET:/workspaces/{workspaceId}/invitations
   */
  invitationsDetail = (workspaceId: string, params: RequestParams = {}) =>
    this.request<GetWorkspaceInvitationsResponse, any>({
      path: `/workspaces/${workspaceId}/invitations`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name DirectInvitationsCreate
   * @summary createDirectworkspaceInvitation
   * @request POST:/workspaces/{workspaceId}/directInvitations
   */
  directInvitationsCreate = (
    workspaceId: string,
    body: CreateWorkspaceDirectInvitationRequest,
    params: RequestParams = {},
  ) =>
    this.request<CreateWorkspaceDirectInvitationResponse, any>({
      path: `/workspaces/${workspaceId}/directInvitations`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsWorkspaceMembershipsCreate
   * @summary addWorkspaceMembersToFeed
   * @request POST:/workspaces/{workspaceId}/feeds/{feedId}/workspaceMemberships
   */
  feedsWorkspaceMembershipsCreate = (
    workspaceId: string,
    feedId: string,
    body: AddWorkspaceMembersToFeedRequest,
    params: RequestParams = {},
  ) =>
    this.request<AddWorkspaceMembersToFeedResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/workspaceMemberships`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspaceMembershipsUpdatePartialUpdate
   * @summary updateWorkspaceMember
   * @request PATCH:/workspaces/{workspaceId}/workspaceMemberships/update
   */
  workspaceMembershipsUpdatePartialUpdate = (
    workspaceId: string,
    body: UpdateWorkspaceMembershipRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceMembershipResponse, any>({
      path: `/workspaces/${workspaceId}/workspaceMemberships/update`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspaceMembershipsRemoveDelete
   * @summary removeWorkspaceMember
   * @request DELETE:/workspaces/{workspaceId}/workspaceMemberships/remove
   */
  workspaceMembershipsRemoveDelete = (
    workspaceId: string,
    body: UpdateWorkspaceMembershipRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceMembershipResponse, any>({
      path: `/workspaces/${workspaceId}/workspaceMemberships/remove`,
      method: "DELETE",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsMuteUnmuteCreate
   * @summary muteUnmuteWorkspaceFeedMember
   * @request POST:/workspaces/{workspaceId}/feeds/{feedId}/mute-unmute
   */
  feedsMuteUnmuteCreate = (
    workspaceId: string,
    feedId: string,
    body: MuteWorkspaceFeedMemberRequest,
    params: RequestParams = {},
  ) =>
    this.request<MuteWorkspaceFeedMemberResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/mute-unmute`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsPromoteDemoteCreate
   * @summary promoteDemoteWorkspaceFeedMember
   * @request POST:/workspaces/{workspaceId}/feeds/{feedId}/promote-demote
   */
  feedsPromoteDemoteCreate = (
    workspaceId: string,
    feedId: string,
    body: PromoteDemoteWorkspaceFeedMemberRequest,
    params: RequestParams = {},
  ) =>
    this.request<PromoteDemoteWorkspaceFeedMemberResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/promote-demote`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsRemoveMemberCreate
   * @summary removeMemberFromFeed
   * @request POST:/workspaces/{workspaceId}/feeds/{feedId}/remove-member
   */
  feedsRemoveMemberCreate = (
    workspaceId: string,
    feedId: string,
    body: RemoveWorkspaceMemberFromFeedRequest,
    params: RequestParams = {},
  ) =>
    this.request<RemoveWorkspaceMemberFromFeedResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/remove-member`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkflowItemsCreate
   * @summary createWorkflowItem
   * @request POST:/workspaces/{workspaceId}/workflowItems
   */
  workflowItemsCreate = (workspaceId: string, body: CreateWorkspaceWorkflowItemRequest, params: RequestParams = {}) =>
    this.request<WsWorkflowItem, any>({
      path: `/workspaces/${workspaceId}/workflowItems`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkflowItemsDetail
   * @summary getWorkflowItems
   * @request GET:/workspaces/{workspaceId}/workflowItems
   */
  workflowItemsDetail = (workspaceId: string, params: RequestParams = {}) =>
    this.request<GetWorkspaceWorkflowItemsResponse, any>({
      path: `/workspaces/${workspaceId}/workflowItems`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkflowItemsDelete
   * @summary deleteWorkflowItem
   * @request DELETE:/workspaces/{workspaceId}/workflowItems/{workflowItemId}
   */
  workflowItemsDelete = (workspaceId: string, workflowItemId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/workspaces/${workspaceId}/workflowItems/${workflowItemId}`,
      method: "DELETE",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name WorkflowItemsPublishCreate
   * @summary publishWorkflowItem
   * @request POST:/workspaces/{workspaceId}/workflowItems/{workflowItemId}/publish
   */
  workflowItemsPublishCreate = (
    workspaceId: string,
    workflowItemId: string,
    body: PublishWorkspaceWorkflowItemRequest,
    params: RequestParams = {},
  ) =>
    this.request<WsPublishedWorkflowItem, any>({
      path: `/workspaces/${workspaceId}/workflowItems/${workflowItemId}/publish`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PublishBroadcastCreate
   * @summary publishBroadcast
   * @request POST:/workspaces/{workspaceId}/publishBroadcast
   */
  publishBroadcastCreate = (workspaceId: string, body: PublishBroadcastRequest, params: RequestParams = {}) =>
    this.request<PublishBroadcastResponse, any>({
      path: `/workspaces/${workspaceId}/publishBroadcast`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TriggerScheduleCreate
   * @summary createScheduledBroadcast
   * @request POST:/workspaces/{workspaceId}/trigger/schedule
   */
  triggerScheduleCreate = (
    workspaceId: string,
    body: CreateWorkspaceScheduledBroadcastRequest,
    params: RequestParams = {},
  ) =>
    this.request<CreateWorkspaceScheduledBroadcastResponse, any>({
      path: `/workspaces/${workspaceId}/trigger/schedule`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TriggerScheduleUpdate
   * @summary updateScheduledBroadcast
   * @request PUT:/workspaces/{workspaceId}/trigger/schedule/{scheduleId}
   */
  triggerScheduleUpdate = (
    workspaceId: string,
    scheduleId: string,
    body: UpdateWorkspaceScheduledBroadcastRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceScheduledBroadcastResponse, any>({
      path: `/workspaces/${workspaceId}/trigger/schedule/${scheduleId}`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name ScheduledBroadcastsDetail
   * @summary getScheduledBroadcasts
   * @request GET:/workspaces/{workspaceId}/scheduledBroadcasts
   */
  scheduledBroadcastsDetail = (workspaceId: string, params: RequestParams = {}) =>
    this.request<GetScheduledBroadcastsResponse, any>({
      path: `/workspaces/${workspaceId}/scheduledBroadcasts`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TemplatesCreate
   * @summary createWorkspaceTemplate
   * @request POST:/workspaces/{workspaceId}/templates
   */
  templatesCreate = (workspaceId: string, body: CreateWorkspaceTemplateRequest, params: RequestParams = {}) =>
    this.request<CreateWorkspaceTemplateResponse, any>({
      path: `/workspaces/${workspaceId}/templates`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TemplatesDetail
   * @summary getWorkspaceTemplates
   * @request GET:/workspaces/{workspaceId}/templates
   */
  templatesDetail = (workspaceId: string, params: RequestParams = {}) =>
    this.request<GetWorkspaceTemplatesResponse, any>({
      path: `/workspaces/${workspaceId}/templates`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TemplatesPartialUpdate
   * @summary updateWorkspaceTemplate
   * @request PATCH:/workspaces/{workspaceId}/templates/{templateId}
   */
  templatesPartialUpdate = (
    workspaceId: string,
    templateId: string,
    body: UpdateWorkspaceTemplateRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceTemplateResponse, any>({
      path: `/workspaces/${workspaceId}/templates/${templateId}`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TemplatesDelete
   * @summary deleteWorkspaceTemplate
   * @request DELETE:/workspaces/{workspaceId}/templates/{templateId}
   */
  templatesDelete = (workspaceId: string, templateId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/workspaces/${workspaceId}/templates/${templateId}`,
      method: "DELETE",
      type: ContentType.Json,
      ...params,
    });
}
