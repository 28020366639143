import { SVGProps } from "react";
export default function ChatBubbleOutlined({
	...props
}: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="33"
			height="32"
			viewBox="0 0 33 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M25.1998 3.72637H6.7998C5.5348 3.72637 4.4998 4.83101 4.4998 6.18113V28.274L9.0998 23.3645H25.1998C26.4648 23.3645 27.4998 22.2598 27.4998 20.9097V6.18113C27.4998 4.83101 26.4648 3.72637 25.1998 3.72637ZM25.1998 20.9097H9.0998L6.7998 23.3645V6.18113H25.1998V20.9097Z"
				fill="#C0C0C2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.0498 29.4125V6.18113C4.0498 4.61054 5.25912 3.27637 6.7998 3.27637H25.1998C26.7405 3.27637 27.9498 4.61054 27.9498 6.18113V20.9097C27.9498 22.4803 26.7405 23.8145 25.1998 23.8145H9.29484L4.0498 29.4125ZM9.0998 23.3645H25.1998C26.4648 23.3645 27.4998 22.2598 27.4998 20.9097V6.18113C27.4998 4.83101 26.4648 3.72637 25.1998 3.72637H6.7998C5.5348 3.72637 4.4998 4.83101 4.4998 6.18113V28.274L9.0998 23.3645ZM9.0998 20.9097H25.1998V6.18113H6.7998V23.3645L9.0998 20.9097ZM7.2498 22.2261L8.90477 20.4597H24.7498V6.63113H7.2498V22.2261Z"
				fill="#C0C0C2"
			/>
		</svg>
	);
}
