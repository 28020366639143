import { Electric } from "@/generated/client";
import {
	DirectWorkspaceInvitation,
	Workspace,
	WorkspaceMembership,
	WsAccount,
	WsAudioEncoding,
	WsAudioQueueItem,
	WsCallRecord,
	WsDisplayArtifact,
	WsEvent,
	WsFeed,
	WsFile,
	WsItem,
	WsLink,
	WsPermission,
	WsTranscription,
	WsBroadcastRecipient,
	WsWorkflowItem,
	WsPublishedWorkflowItem,
	WsScheduleTrigger,
	WsBroadcastAction, WsTemplate
} from 'web-client/api/data-contracts';
import { updateWorkspaceConfig } from "./workspaceConfig";

export async function upsertWorkspace(
	db: Electric["db"],
	workspace: Workspace,
) {
	const newWorkspace = db.workspace.upsert({
		where: {
			id: workspace.id,
		},
		create: {
			id: workspace.id,
			name: workspace.name,
		},
		update: {
			name: workspace.name,
		},
	});

	updateWorkspaceConfig(workspace);

	return newWorkspace;
}

export async function upsertWsFeed(db: Electric["db"], feed: WsFeed) {
	const newFeed = db.feed.upsert({
		where: {
			id: feed.id,
		},
		create: {
			id: feed.id,
			createdAt: feed.createdAt,
			updatedAt: feed.updatedAt,
			workspaceId: feed.workspaceId,
			readOnly: feed.readOnly ? 1 : 0,
			lastOpened: feed.lastOpened,
			latestActivity: feed.latestActivity,

			loadedFirstPage: 0,
			loadedLastPage: 0,
			loadedEvents: 0,
			loadedPermissions: 0,

			feedType: feed.feedType,
			title: feed.title,
			isPrivate: feed.isPrivate ? 1 : 0,

			isDm: feed.isDm ? 1 : 0,
		},
		update: {
			createdAt: feed.createdAt,
			updatedAt: feed.updatedAt,
			workspaceId: feed.workspaceId,
			readOnly: feed.readOnly ? 1 : 0,
			lastOpened: feed.lastOpened,
			latestActivity: feed.latestActivity,

			feedType: feed.feedType,
			title: feed.title,
			isPrivate: feed.isPrivate ? 1 : 0,

			isDm: feed.isDm ? 1 : 0,
		},
	});

	return newFeed;
}

export async function upsertWorkspaceMembership(
	db: Electric["db"],
	workspaceMembership: WorkspaceMembership,
) {
	return db.workspace_membership.upsert({
		where: {
			id: workspaceMembership.id,
		},
		create: {
			id: workspaceMembership.id,
			workspaceId: workspaceMembership.workspaceId,
			accountId: workspaceMembership.accountId,
			status: workspaceMembership.status,
			role: workspaceMembership.role,
		},
		update: {
			workspaceId: workspaceMembership.workspaceId,
			accountId: workspaceMembership.accountId,
			status: workspaceMembership.status,
			role: workspaceMembership.role,
		},
	});
}

export async function upsertWsPermission(db: Electric["db"], p: WsPermission) {
	const newWorkspaceMembership = db.permission.upsert({
		where: {
			id: p.id,
		},
		create: {
			id: p.id,
			workspace_membershipId: p.workspaceMembershipId,

			feedId: p.feedId,
			workflowItemId: p.workflowItemId,

			name: p.name,
			enabled: p.enabled ? 1 : 0,
		},
		update: {
			workspace_membershipId: p.workspaceMembershipId,

			feedId: p.feedId,
			workflowItemId: p.workflowItemId,

			name: p.name,
			enabled: p.enabled ? 1 : 0,
		},
	});

	return newWorkspaceMembership;
}

export async function upsertWsAccount(db: Electric["db"], account: WsAccount) {
	const newAccount = db.account.upsert({
		where: {
			id: account.id,
		},
		create: {
			id: account.id,
			name: account.name,
			accountType: account.accountType,
			avatarColor: account.avatarColor,
			firstName: account.firstName,
			lastName: account.lastName,
			email: account.email,
			emailVerified: account.emailVerified ? 1 : 0,
			phoneNumber: account.phoneNumber,
			phoneNumberVerified: account.phoneNumberVerified ? 1 : 0,
		},
		update: {
			name: account.name,
			accountType: account.accountType,
			avatarColor: account.avatarColor,
			firstName: account.firstName,
			lastName: account.lastName,
			email: account.email,
			emailVerified: account.emailVerified ? 1 : 0,
			phoneNumber: account.phoneNumber,
			phoneNumberVerified: account.phoneNumberVerified ? 1 : 0,
		},
	});

	return newAccount;
}

export async function upsertWsItem(db: Electric["db"], item: WsItem) {
	if (!item?.contentId) return;
	return db.item.upsert({
		where: {
			id: item.id,
		},
		create: {
			id: item.id,
			createdAt: item.createdAt,
			feedId: item.feedId,
			accountId: item.accountId,
			contentId: item.contentId,
			deletedAt: item.deletedAt,
			groupId: item.groupId,
		},
		update: {
			createdAt: item.createdAt,
			feedId: item.feedId,
			accountId: item.accountId,
			deletedAt: item.deletedAt,
		},
	});
}

export async function upsertWsDisplayArtifact(
	db: Electric["db"],
	artifact: WsDisplayArtifact,
) {
	if (!artifact.contentId) return;
	return db.display_artifact.upsert({
		where: {
			id: artifact.id,
		},
		create: {
			id: artifact.id,
			title: artifact.title,
			description: artifact?.description,
			createdAt: artifact.createdAt,
			contentId: artifact.contentId,
			deletedAt: artifact.deletedAt,
		},
		update: {
			title: artifact.title,
			description: artifact?.description,
			createdAt: artifact.createdAt,
			contentId: artifact.contentId,
			deletedAt: artifact.deletedAt,
		},
	});
}

export async function upsertWsTranscription(
	db: Electric["db"],
	transcription: WsTranscription,
) {
	return db.transcription.upsert({
		where: {
			id: transcription.id,
		},
		create: {
			id: transcription.id,
			createdAt: transcription.createdAt,
			contentId: transcription.contentId,
			transcriptionContent: transcription.transcription,
			transcriptionType: transcription.transcriptionType,
			backendModel: transcription.model,
			confidence: transcription.confidence,
			language: transcription.language,
			priority: transcription.priority,
			executionTime: transcription.executionTime,
			url: transcription.url,
			translatedFrom: transcription.translatedFrom,
			format: transcription.format,
		},
		update: {
			createdAt: transcription.createdAt,
			contentId: transcription.contentId,
			transcriptionContent: transcription.transcription,
			transcriptionType: transcription.transcriptionType,
			backendModel: transcription.model,
			confidence: transcription.confidence,
			language: transcription.language,
			priority: transcription.priority,
			executionTime: transcription.executionTime,
			url: transcription.url,
			translatedFrom: transcription.translatedFrom,
			format: transcription.format,
		},
	});
}

export async function upsertWsAudioEncoding(
	db: Electric["db"],
	e: WsAudioEncoding,
) {
	return db.audio_encoding.upsert({
		where: {
			id: e.id,
		},
		create: {
			id: e.id,
			contentId: e.contentId,
			codec: e.codec,
			duration: e.duration,
			url: e.url,
			mimeType: e.mimeType,
			transcriptionId: e.transcriptionId,
			transcriptionType: e.transcriptionType,
			language: e.language,
			translatedFrom: e.translatedFrom,
			priority: e.priority,
			generatedService: e.generatedService,
			generatedVoice: e.generatedVoice,
		},
		update: {
			contentId: e.contentId,
			codec: e.codec,
			duration: e.duration,
			url: e.url,
			mimeType: e.mimeType,
			transcriptionId: e.transcriptionId,
			transcriptionType: e.transcriptionType,
			language: e.language,
			translatedFrom: e.translatedFrom,
			priority: e.priority,
			generatedService: e.generatedService,
			generatedVoice: e.generatedVoice,
		},
	});
}

export async function upsertWsAudioQueueItem(
	db: Electric["db"],
	aqi: WsAudioQueueItem,
) {
	return db.audio_queue_item.upsert({
		where: { id: aqi.id },
		create: {
			id: aqi.id,
			itemId: aqi.itemId,
			createdAt: aqi.createdAt,
		},
		update: {
			id: aqi.id,
			itemId: aqi.itemId,
			createdAt: aqi.createdAt,
		},
	});
}

export async function deleteWsAudioQueueItem(
	db: Electric["db"],
	aqi: WsAudioQueueItem,
) {
	return db.audio_queue_item.delete({
		where: { id: aqi.id },
	});
}

export async function deleteAllWsAudioQueueItems(db: Electric["db"]) {
	return db.audio_queue_item.deleteMany();
}

export async function upsertWsLink(db: Electric["db"], l: WsLink) {
	return db.link.upsert({
		where: {
			id: l.id,
		},
		create: {
			id: l.id,
			contentId: l.contentId,
			url: l.url,
			description: l.description,
			title: l.title,
			image: l.image,
			linkOrder: l.linkOrder,
		},
		update: {
			contentId: l.contentId,
			url: l.url,
			description: l.description,
			title: l.title,
			image: l.image,
			linkOrder: l.linkOrder,
		},
	});
}

export async function upsertWsFile(db: Electric["db"], f: WsFile) {
	return db.file.upsert({
		where: {
			id: f.id,
		},
		create: {
			id: f.id,
			contentId: f.contentId,
			url: f.url,
			name: f.name,
			mimeType: f.mimeType,
		},
		update: {
			contentId: f.contentId,
			url: f.url,
			name: f.name,
			mimeType: f.mimeType,
		},
	});
}

export async function upsertWsCallRecord(db: Electric["db"], c: WsCallRecord) {
	return db.call_record.upsert({
		where: {
			id: c.id,
		},
		create: {
			id: c.id,
			contentId: c.contentId,
			appPhoneNumberId: c.appPhoneNumberId,
			direction: c.direction,
			fromNumber: c.from,
			toNumber: c.to,
		},
		update: {
			contentId: c.contentId,
			appPhoneNumberId: c.appPhoneNumberId,
			direction: c.direction,
			fromNumber: c.from,
			toNumber: c.to,
		},
	});
}

export async function upsertWsEvent(db: Electric["db"], c: WsEvent) {
	return db.account_event.upsert({
		where: {
			id: c.id,
		},
		create: {
			id: c.id,
			accountId: c.accountId,
			createdAt: c.createdAt,
			name: c.name,
			contentId: c.contentId,
			feedId: c.feedId,
			itemId: c.itemId,
		},
		update: {
			accountId: c.accountId,
			createdAt: c.createdAt,
			name: c.name,
			contentId: c.contentId,
			feedId: c.feedId,
			itemId: c.itemId,
		},
	});
}

export async function upsertDirectWsInvitation(
	db: Electric["db"],
	c: DirectWorkspaceInvitation,
) {
	return db.direct_ws_invitation.upsert({
		where: {
			id: c.id,
		},
		create: {
			id: c.id,
			email: c.email,
			phoneNumber: c.phoneNumber,
			workspaceMembershipId: c.workspaceMembershipId,
			claimedAt: c.claimedAt,
			claimedBy: c.claimedBy,
			createdAt: c.createdAt,
			updatedAt: c.updatedAt,
		},
		update: {
			email: c.email,
			phoneNumber: c.phoneNumber,
			workspaceMembershipId: c.workspaceMembershipId,
			claimedAt: c.claimedAt,
			claimedBy: c.claimedBy,
			createdAt: c.createdAt,
			updatedAt: c.updatedAt,
		},
	});
}

export async function upsertWsDraft(db: Electric["db"], c: WsWorkflowItem) {
	return db.workflow_item.upsert({
		where: {
			id: c.id,
		},
		create: {
			id: c.id,
			contentId: c.contentId,
			createdAt: c.createdAt,
			deletedAt: c.deletedAt,
			workspaceId: c.workspaceId,
			displayName: c.displayName
		},
		update: {
			contentId: c.contentId,
			createdAt: c.createdAt,
			deletedAt: c.deletedAt,
			workspaceId: c.workspaceId,
			displayName: c.displayName
		},
	});
}

export async function deleteWsDraft(
  db: Electric['db'],
  workflowItemId: string
) {
  return db.workflow_item.delete({
    where: { id: workflowItemId }
  });
}


export async function upsertWsScheduleTrigger(db: Electric["db"], c: WsScheduleTrigger) {
	return db.schedule_trigger.upsert({
		where: {
			id: c.id
		},
		create: {
			id: c.id,
			oneTimeSchedule: c.oneTimeSchedule || null,
			cronSchedule: c.cronSchedule || null,
			timezone: c.timezone || null,
			workspaceId: c.workspaceId,
			broadcastActionId: c.broadcastActionId,
			createdAt: c.createdAt,
			updatedAt: c.updatedAt,
			enabled: c.enabled ? 1 : 0
		},
		update: {
			id: c.id,
			oneTimeSchedule: c.oneTimeSchedule || null,
			cronSchedule: c.cronSchedule || null,
			timezone: c.timezone || null,
			workspaceId: c.workspaceId,
			broadcastActionId: c.broadcastActionId,
			createdAt: c.createdAt,
			updatedAt: c.updatedAt,
			enabled: c.enabled ? 1 : 0
		}
	});
}

export async function upsertWsBroadcastAction(
  db: Electric['db'],
  c: WsBroadcastAction
) {
	return db.broadcast_action.upsert({
		where: {
			id: c.id
		},
		create: {
			id: c.id,
			workspaceId: c.workspaceId,
			workflowItemId: c.workflowItemId,
			broadcastId: c.broadcastId,
			createdAt: c.createdAt,
			updatedAt: c.updatedAt
		},
		update: {
			id: c.id,
			workspaceId: c.workspaceId,
			workflowItemId: c.workflowItemId,
			broadcastId: c.broadcastId,
			createdAt: c.createdAt,
			updatedAt: c.updatedAt
		}
	});
}


export async function upsertWsPublishedDraft(
	db: Electric["db"],
	c: WsPublishedWorkflowItem,
) {
	return db.published_workflow_item.upsert({
		where: {
			id: c.id,
		},
		create: {
			id: c.id,
			contentId: c.contentId,
			createdAt: c.createdAt,
			workspaceId: c.workspaceId,
			workflowItemId: c.workflowItemId,
			broadcastId: c.broadcastId,
			accountId: c.accountId,
		},
		update: {
			contentId: c.contentId,
			createdAt: c.createdAt,
			workspaceId: c.workspaceId,
			workflowItemId: c.workflowItemId,
			broadcastId: c.broadcastId,
			accountId: c.accountId,
		},
	});
}

export async function upsertWsBroadcastRecipient(
	db: Electric["db"],
	c: WsBroadcastRecipient,
) {
	return db.broadcast_recipient.upsert({
		where: {
			id: c.id,
		},
		create: {
			id: c.id,
			broadcastId: c.broadcastId,
			workspaceId: c.workspaceId,
			feedId: c.feedId,
			workspaceMembershipId: c.workspaceMembershipId,
			createdAt: c.createdAt,
		},
		update: {
			broadcastId: c.broadcastId,
			workspaceId: c.workspaceId,
			feedId: c.feedId,
			workspaceMembershipId: c.workspaceMembershipId,
			createdAt: c.createdAt,
		},
	});
}

export async function upsertWsTemplate(db: Electric["db"], c: WsTemplate) {
	return db.template.upsert({
		where: {
			id: c.id,
		},
		create: {
			id: c.id,
			name: c.name,
			template: c.template,
			workspaceId: c.workspaceId,
			authorId: c.authorId,
			createdAt: c.createdAt,
			updatedAt: c.updatedAt,
			deletedAt: c.deletedAt,
		},
		update: {
			name: c.name,
			template: c.template,
			updatedAt: c.updatedAt,
			deletedAt: c.deletedAt,
		},
	});
}
