import "@/assets/index.css";
import { router } from "@/routes/index";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

const clientSideID = import.meta.env.VITE_LD_CLIENT_SIDE_ID ?? false;
const LDProvider = clientSideID
	? await asyncWithLDProvider({ clientSideID })
	: false;

if (!LDProvider && import.meta.env.DEV === true) {
	console.error(
		"%cNo launch darkly client side id provided: https://app.launchdarkly.com/settings/projects",
		"padding:5px; color:yellow;",
	);
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

Sentry.init({
	enabled: import.meta.env.PROD,
	dsn: "https://f8871862445f42c0934637c048b69182@o1146727.ingest.sentry.io/4505133231702016",
	environment: import.meta.env.MODE,
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: import.meta.env.PROD ? 0.75 : 1.0,

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

// https://vite-pwa-org.netlify.app/guide/service-worker-without-pwa-capabilities.html#registering-of-the-service-worker-in-your-app
// service worker features install
if ("serviceWorker" in navigator) {
	navigator.serviceWorker.register(
		import.meta.env.MODE === "production"
			? "/prompt-sw.js"
			: "/dev-sw.js?dev-sw",
		{ type: import.meta.env.MODE === "production" ? "classic" : "module" },
	);
}

function gtag(w, d, s, l, i) {
	w[l] = w[l] || [];
	w[l]?.push({
		"gtm.start": new Date().getTime(),
		event: "gtm.js",
	});
	var f = d?.getElementsByTagName(s)[0],
		j = d?.createElement(s),
		dl = l !== "dataLayer" ? "&l=" + l : "";
	j.async = true;
	j.src = "https://www.googletagmanager.com/gtm.js?id=" + i || "" + dl;
	f?.parentNode.insertBefore(j, f);
}

const ga_key = import.meta.env.VITE_GA_KEY;

if (ga_key) {
	gtag(window, document, "script", "dataLayer", ga_key);
}

if (LDProvider) {
	root.render(
		<LDProvider>
			<RouterProvider router={router} />
		</LDProvider>,
	);
} else {
	root.render(<RouterProvider router={router} />);
}
