/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateContentEventRequest,
  CreateContentRecieptRequest,
  RefreshContentRequest,
  RefreshContentResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Content<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name EventsCreate
   * @summary createContentEvent
   * @request POST:/content/{contentId}/events
   */
  eventsCreate = (contentId: string, body: CreateContentEventRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/content/${contentId}/events`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ReceiptsCreate
   * @summary createContentReciept
   * @request POST:/content/{contentId}/receipts
   */
  receiptsCreate = (contentId: string, body: CreateContentRecieptRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/content/${contentId}/receipts`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name RefreshCreate
   * @summary refreshPipeline
   * @request POST:/content/refresh
   */
  refreshCreate = (body: RefreshContentRequest, params: RequestParams = {}) =>
    this.request<RefreshContentResponse, any>({
      path: `/content/refresh`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
