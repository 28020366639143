import { hexToHsl } from "@/utils/color";
import Person from "@mui/icons-material/Person";
import { Avatar, useTheme } from "@mui/material";
import { HTMLAttributes } from "react";

export type AvatarProps = HTMLAttributes<HTMLElement> & {
	initials?: string;
	color?: string;
	sxProps?: object;
	size?: number;
};

export const InitialsAvatar = ({
	initials,
	color,
	sxProps = {},
	size = 20,
	...rest
}: AvatarProps) => {
	const theme = useTheme();
	return (
		<Avatar
			sx={{
				fontSize: "14px",
				width: 26,
				height: 26,
				fontWeight: 700,
				color: theme.palette.primary.main,
				background: color,
				borderWidth: "2px",
				borderStyle: "solid",
				borderColor: hexToHsl(color, 0, 0, 12),
				...sxProps,
			}}
			role="img"
			{...rest}
		>
			{initials || (
				<Person
					sx={{
						width: size,
						height: size,
						color: theme.palette.primary.main,
					}}
				/>
			)}
		</Avatar>
	);
};

export default InitialsAvatar;
