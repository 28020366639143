import { DataContext } from "@/models/DataProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Button, ButtonProps } from "@mui/material";
import { useContext } from "react";

export interface InviteWorkspaceUsersLinkProps extends ButtonProps {
	setCopied?: () => void;
}

export default function InviteWorkspaceUsersLink({
	variant = "contained",
	setCopied,
	sx,
}: InviteWorkspaceUsersLinkProps) {
	const { currentFeed } = useContext(DataContext);
	const { ampli } = useContext(TrackingContext);

	const { inviteModalOpen, toggleInviteModal } = useContext(UxContext);

	const handleClick = () => {
		console.warn("** invite-user/click", {
			inviteModalOpen,
		});
		ampli.addWorkspaceUser();
		toggleInviteModal();
	};

	return (
		<Button onClick={handleClick} variant={variant} disableFocusRipple sx={sx}>
			Add users
		</Button>
	);
}
