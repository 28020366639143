import React, { useContext, useEffect, useState } from "react";
import "regenerator-runtime/runtime";
import SpeechRecognition, {
	useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import { Box, Button } from "@mui/material";
import { VoiceAssistantContext } from "@/models/VoiceAssistantContextProvider";

export default function ReactSpeechRecognition() {
	const { setCommand, setTranscript } = useContext(VoiceAssistantContext);

	const commands = [
		{
			command: ["start recording", "record", "record audio"],
			callback: (data) => {
				data.resetTranscript();
				setCommand("start-recording");
			},
		},
		{
			command: ["stop", "stop recording"],
			callback: (data) => {
				data.resetTranscript();
				setCommand("stop-recording");
			},
		},
		{
			command: ["cancel", "cancel recording"],
			callback: (data) => {
				data.resetTranscript();
				setCommand("cancel-recording");
			},
		},
		{
			command: ["send tts", "send message", "send text to speech"],
			callback: (data) => {
				setCommand("open-tts-modal");
				data.resetTranscript();
			},
		},
		{
			command: ["clear"],
			callback: (data) => {
				data.resetTranscript();
				setTranscript(null);
			},
		},
	];

	const { transcript, listening, browserSupportsSpeechRecognition } =
		useSpeechRecognition({ commands });

	const [continuous, setContinuous] = useState<boolean>(false);

	const toggleContinuousState = () => {
		setContinuous(() => !continuous);
		if (continuous) {
			SpeechRecognition.stopListening();
		} else {
			SpeechRecognition.startListening({ continuous: true });
		}
	};

	const start = () => {
		SpeechRecognition.startListening({ continuous: true });
	};

	useEffect(() => {
		SpeechRecognition.stopListening();
		// auto-activate speech recognition
		// start()
	}, []);

	useEffect(() => {
		setTranscript(transcript);
	}, [transcript, setTranscript]);

	if (!browserSupportsSpeechRecognition) {
		return <span>Browser doesn't support speech recognition.</span>;
	}

	return (
		<Box>
			<Box
				sx={{
					fontSize: "14px",
					background: listening && "red",
					display: "flex",
					padding: "0.25rem",
					alignItems: "center",
				}}
			>
				<Box sx={{ marginRight: "0.5rem" }}>
					<Button onClick={() => toggleContinuousState()}>
						{continuous ? <MicIcon /> : <MicOffIcon />}
					</Button>
				</Box>
				<Box>HOT MIC {listening ? "ACTIVE" : "IN-ACTIVE"}</Box>
			</Box>
			{transcript && transcript?.length > 0 && (
				<Box
					sx={{
						padding: "0.5rem",
						fontSize: "12px",
						overflowY: "auto",
						maxHeight: "600px",
					}}
					className="dark-scrollbar"
				>
					{transcript}
				</Box>
			)}
		</Box>
	);
}
