import {
  millisecondsToMinutes
} from 'date-fns';
import {
  getTimezoneOffset,
} from 'date-fns-tz';
import awsCronParser from 'aws-cron-parser';
import * as chrono from 'chrono-node';

export const getFeedDateId = (date: string) => `feed-date-${date}`;

export const parseCronScheduledDate = (cronStr: string, timezone: string) => {
    const cron = awsCronParser.parse(cronStr);
    if (cron) {
        const offset = millisecondsToMinutes(
            getTimezoneOffset(timezone),
        );
        const parsedDate = chrono.parseDate(
            awsCronParser.getScheduleDescription(cron),
            {
                instant: new Date(),
                timezone: offset,
            },
            { forwardDate: true },
        );
        return parsedDate
    }
    return null;
}
