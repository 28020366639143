import { FlagData } from "@/routes";
import React, { createContext, useState } from "react";
import PubSub from "@/PubSub";
import Client from "web-client/client";

export type Config = {
	baseDomain: string;
	graphqlApiKey: string;
	graphqlUrl: string;
	sessionRecordingLength: number;
	tusUrl: string;
};

export type AppState = {
	audioCtx?: AudioContext;
	client: Client;
	config?: Config;
	flags: FlagData;
	inviteViaEmail: (feedId: string, emails: string[]) => Promise<void>;
	pubSub?: PubSub;
	redirectPath?: string;
	setFlags: (flags: FlagData) => void;
	setRedirectPath: (path: string) => void;
	tracking?: any;
};

const defaultClient = new Client({});

//create a context, with createContext api
export const AppContext = createContext<AppState>({
	client: defaultClient,
	inviteViaEmail: (a, b) => Promise.resolve(),
	setRedirectPath: () => {},
	flags: {},
	setFlags: (_) => {},
});

type Props = {
	client: Client;
	baseDomain?: string;
	children: React.ReactNode | React.ReactNode[];
	graphqlApiKey: string;
	graphqlUrl: string;
	sessionRecordingLength: number;
	tusUrl: string;
	initialFlags: FlagData;
};

const AppStateProvider = ({
	client,
	baseDomain,
	tusUrl,
	graphqlUrl,
	graphqlApiKey,
	children,
	sessionRecordingLength,
	initialFlags,
}: Props) => {
	const [flags, setFlags] = useState<FlagData>(initialFlags);

	const [redirectPath, setRedirectPath] = useState<string>("");
	const inviteViaEmail = React.useCallback(
		async (feedId: string, emails: string[]) => {
			/**
			 * @NOTE forwarding errors directly to UI
			 * rather than using an ErrorBoundary to display
			 * graceful message rather than a modal
			 */
			await client.inviteViaEmail(feedId, emails);
		},
		[client],
	);

	const pubSub = React.useMemo(
		() => new PubSub(graphqlUrl, graphqlApiKey),
		[graphqlUrl, graphqlApiKey],
	);

	const appState: AppState = {
		client,
		inviteViaEmail,
		redirectPath,
		setRedirectPath,
		flags,
		setFlags,
		pubSub,
		config: {
			baseDomain,
			graphqlApiKey,
			graphqlUrl,
			sessionRecordingLength,
			tusUrl,
		},
	};

	return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};
export default AppStateProvider;
