import {
	upsertWsAudioEncoding,
	upsertWsCallRecord,
	upsertWsEvent,
	upsertWsFile,
	upsertWsItem,
	upsertWsLink,
	upsertWsPermission,
	upsertWsTranscription,
	upsertWsDisplayArtifact,
} from "@/data/workspace";
import { Electric } from "@/generated/client";
import {
	GetWorkspaceFeedEventsResponse,
	GetWorkspaceFeedItemsResponse,
	GetWorkspaceFeedPermissionsResponse,
} from "web-client/api/data-contracts";
import Client from "web-client/client";

const ITEMS_PER_PAGE = 10;
const PERMISSIONS_PAGE_PAGE = 1000;
const EVENTS_PER_PAGE = 1000;

export async function initialFeedLoad(
	client: Client,
	db: Electric["db"],
	feedId: string,
): Promise<boolean> {
	const feed = await db.feed.findUnique({ where: { id: feedId } });
	if (!feed) {
		return Promise.reject(`Feed not found: ${feedId}`);
	}
	const workspaceId = feed?.workspaceId;
	if (
		feed.loadedFirstPage === 1 &&
		feed.loadedEvents === 1 &&
		feed.loadedPermissions === 1
	) {
		console.log("ALREADY LOADED", feedId);
		return;
	}

	console.log("Initial load", feedId);

	// if (feed.loadedPermissions === 0) {
	// 	downloadPaginatedPermissions(client, db, workspaceId, feedId);
	// }

	if (feed.loadedEvents === 0) {
		downloadPaginatedEvents(client, db, workspaceId, feedId);
	}

	if (feed.loadedFirstPage === 0) {
		downloadPaginatedFeedItems(client, db, workspaceId, feedId);
	}
	await db.feed.update({
		where: {
			id: feedId,
		},
		data: {
			loadedFirstPage: 1,
			loadedEvents: 1,
			loadedPermissions: 1,
		},
	});
	return true;
}

export async function getNextFeedItemPage(
	client: Client,
	db: Electric["db"],
	feedId: string,
) {
	const feed = await db.feed.findUnique({ where: { id: feedId } });
	if (!feed) throw new Error("Feed not found");
	const workspaceId = feed?.workspaceId;

	if (feed.loadedLastPage === 0) {
		const itemCounts = await db.raw({
			sql: `select count(*) as itemCount from item where "feedId" = ?`,
			args: [feedId],
		});

		const itemCount = Number(itemCounts[0]?.itemCount || 0);
		const nextPage = Math.ceil(itemCount / ITEMS_PER_PAGE);
		return downloadPaginatedFeedItems(
			client,
			db,
			workspaceId,
			feedId,
			1,
			nextPage,
		);
	}
}

async function downloadPaginatedFeedItems(
	client: Client,
	db: Electric["db"],
	workspaceId: string,
	feedId: string,
	pagesToFetch = 1,
	startingPage = 0,
) {
	let page = startingPage;
	const parallelRequests = 1;
	let count = 0;

	while (page < startingPage + pagesToFetch) {
		const promises: Promise<GetWorkspaceFeedItemsResponse>[] = [];
		for (let index = 0; index < parallelRequests; index++) {
			const currentPage = page;
			promises.push(
				client
					.getWorkspaceFeedItems(
						workspaceId,
						feedId,
						currentPage,
						ITEMS_PER_PAGE,
					)
					.then(async (r) => {
						if (!r) return;
						for (const i of r.items) {
							upsertWsItem(db, i);
						}
						for (const i of r.audioEncodings) {
							upsertWsAudioEncoding(db, i);
						}
						for (const i of r.callRecords) {
							upsertWsCallRecord(db, i);
						}
						for (const i of r.files) {
							upsertWsFile(db, i);
						}
						for (const i of r.links) {
							upsertWsLink(db, i);
						}
						for (const i of r.transcriptions) {
							upsertWsTranscription(db, i);
						}
						for (const i of r.displayArtifacts) {
							upsertWsDisplayArtifact(db, i);
						}
						for (const i of r.events) {
							upsertWsEvent(db, i);
						}

						if (currentPage === 0) {
							db.feed.update({
								where: {
									id: feedId,
								},
								data: {
									loadedFirstPage: 1,
								},
							});
						}
						return r;
					}),
			);
			page += 1;
		}
		const responses = await Promise.all(promises);
		const currentCount = responses.reduce((acc, r) => acc + r?.items.length, 0);
		count += currentCount;

		if (currentCount % ITEMS_PER_PAGE !== 0 || currentCount === 0) {
			db.feed.update({
				where: {
					id: feedId,
				},
				data: {
					loadedLastPage: 1,
				},
			});
			return count;
		}

		if (page > 1000) {
			throw new Error("Too many pages");
		}
	}
	return count;
}

async function downloadPaginatedPermissions(
	client: Client,
	db: Electric["db"],
	workspaceId: string,
	feedId: string,
) {
	let page = 0;
	let count = 0;
	const parallelRequests = 1;

	while (true) {
		const promises: Promise<GetWorkspaceFeedPermissionsResponse>[] = [];

		for (let index = 0; index < parallelRequests; index++) {
			const currentPage = page;
			promises.push(
				client
					.getWorkspaceFeedPermissions(
						workspaceId,
						feedId,
						currentPage,
						PERMISSIONS_PAGE_PAGE,
					)
					.then(async (r) => {
						if (!r) return;
						for (const e of r.permissions) {
							upsertWsPermission(db, e);
						}
						return r;
					}),
			);
			page += 1;
		}

		const responses = await Promise.all(promises);
		const currentCount = responses.reduce(
			(acc, r) => acc + r?.permissions.length,
			0,
		);
		count += currentCount;
		if (count % PERMISSIONS_PAGE_PAGE !== 0 || currentCount === 0) {
			return count;
		}

		if (page > 1000) {
			throw new Error("Too many pages");
		}
	}
}

async function downloadPaginatedEvents(
	client: Client,
	db: Electric["db"],
	workspaceId: string,
	feedId: string,
) {
	let page = 0;
	let count = 0;
	const parallelRequests = 1;

	while (true) {
		const promises: Promise<GetWorkspaceFeedEventsResponse>[] = [];

		for (let index = 0; index < parallelRequests; index++) {
			const currentPage = page;
			promises.push(
				client
					.getWorkspaceFeedEvents(
						workspaceId,
						feedId,
						currentPage,
						EVENTS_PER_PAGE,
					)
					.then(async (r) => {
						if (!r) return;
						for (const e of r.events) {
							upsertWsEvent(db, e);
						}
						return r;
					}),
			);
			page += 1;
		}

		const responses = await Promise.all(promises);
		const currentCount = responses.reduce(
			(acc, r) => acc + r?.events.length,
			0,
		);
		count += currentCount;
		if (currentCount % EVENTS_PER_PAGE !== 0 || currentCount === 0) {
			return count;
		}

		if (page > 1000) {
			throw new Error("Too many pages");
		}
	}
}
