import { Ampli } from "@/ampli";
import { upsertMyAccount } from "@/data/oldWorld";
import {
	upsertDirectWsInvitation,
	upsertWorkspace,
	upsertWorkspaceMembership,
	upsertWsAccount,
	upsertWsAudioEncoding,
	upsertWsBroadcastRecipient,
	upsertWsCallRecord,
	upsertWsDisplayArtifact,
	upsertWsEvent,
	upsertWsFeed,
	upsertWsFile,
	upsertWsItem,
	upsertWsLink,
	upsertWsDraft,
	upsertWsScheduleTrigger,
	upsertWsBroadcastAction,
	upsertWsPublishedDraft,
	upsertWsPermission,
	upsertWsTranscription,
	upsertWsTemplate,
} from "@/data/workspace";
import { useElectric } from "@/electric/ElectricWrapper";
import {
	Account,
	DirectWsInvitation,
	Electric,
	Feed,
	Permission,
	WorkspaceMembership,
} from "@/generated/client";
import { useLiveQuery } from "electric-sql/react";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	WsAppSyncEvent,
	WsAudioEncoding,
	WsPermission,
} from "web-client/api/data-contracts";
import Client from "web-client/client";
import { AppContext } from "./AppStateProvider";
import { AudioAppContext } from "./AudioAppContextProvider";
import { TrackingContext } from "./TrackingStateProvider";
import accountInfo, { AccountInfo } from "./accountInfo";
import {
	getNextFeedItemPage,
	initialFeedLoad,
} from "./actions/initialFeedLoad";
import { LANGUAGE_LIST, PreferredLanguage } from "./languages";
declare const window: Window & { dataLayer: Record<string, unknown>[] };

export const ITEMS_PER_PAGE = 10;
let subscription: any;

const LANGUAGE_SESSION_KEY = "preferredLanguage";
const LANGUAGE_DEFAULT_VALUE = LANGUAGE_LIST[0] ?? "none";

function appSyncSubscriptionUpdate(
	db: Electric["db"],
	event: WsAppSyncEvent,
	addToAudioQueue: (audioEncodings: WsAudioEncoding[]) => Promise<void>,
	client: Client,
) {
	for (const i of event?.accounts || []) {
		upsertWsAccount(db, i);
	}
	for (const i of event?.workspaces || []) {
		upsertWorkspace(db, i);
	}
	for (const i of event?.workspaceMemberships || []) {
		upsertWorkspaceMembership(db, i);
	}
	for (const i of event?.directWorkspaceInvitations || []) {
		upsertDirectWsInvitation(db, i);
	}
	for (const i of event?.feeds || []) {
		upsertWsFeed(db, i);
	}
	for (const i of event?.permissions || []) {
		upsertWsPermission(db, i);
	}
	for (const i of event?.items || []) {
		upsertWsItem(db, i);
		client.createContentReceipt({
			contentId: i.contentId,
			artifactId: i.id,
			artifactType: "item",
		});
	}
	for (const i of event?.audioEncodings || []) {
		upsertWsAudioEncoding(db, i);
		client.createContentReceipt({
			contentId: i.contentId,
			artifactId: i.id,
			artifactType: "audioEncoding",
		});
	}
	for (const i of event?.displayArtifacts || []) {
		upsertWsDisplayArtifact(db, i);
		client.createContentReceipt({
			contentId: i.contentId,
			artifactId: i.id,
			artifactType: "displayArtifact",
		});
	}
	for (const i of event?.files || []) {
		upsertWsFile(db, i);
		client.createContentReceipt({
			contentId: i.contentId,
			artifactId: i.id,
			artifactType: "file",
		});
	}
	for (const i of event?.links || []) {
		upsertWsLink(db, i);
		client.createContentReceipt({
			contentId: i.contentId,
			artifactId: i.id,
			artifactType: "link",
		});
	}
	for (const i of event?.transcriptions || []) {
		upsertWsTranscription(db, i);
		client.createContentReceipt({
			contentId: i.contentId,
			artifactId: i.id,
			artifactType: "transcription",
		});
	}
	for (const i of event?.callRecords || []) {
		upsertWsCallRecord(db, i);
		client.createContentReceipt({
			contentId: i.contentId,
			artifactId: i.id,
			artifactType: "callRecord",
		});
	}
	for (const i of event?.events || []) {
		upsertWsEvent(db, i);
		if (i.contentId) {
			// client.createContentReceipt({
			// 	contentId: i.contentId,
			// 	artifactId: i.id,
			// 	artifactType: "accountEvent",
			// });
		}
	}

	for (const i of event?.workflowItems || []) {
		upsertWsDraft(db, i);
	}

	for (const i of event?.scheduleTriggers || []) {
		upsertWsScheduleTrigger(db, i);
	}

	for (const i of event?.broadcastActions || []) {
		upsertWsBroadcastAction(db, i);
	}

	for (const i of event?.publishedWorkflowItems || []) {
		upsertWsPublishedDraft(db, i);
	}

	for (const i of event?.broadcastRecipients || []) {
		upsertWsBroadcastRecipient(db, i);
	}

	addToAudioQueue(event.audioEncodings);
}

export type DataState = {
	myAccount?: Account;
	myAccountId?: string;
	currentFeedId?: string;
	currentWorkspaceId?: string;
	currentFeed?: Feed;
	currentFeedAccounts?: Map<string, AccountInfo>;
	currentFeedPendingInvites?: DirectWsInvitation[];
	isCurrentFeedAdmin?: boolean;
	workspaceMemberships?: WorkspaceMembership[];
	bootstrapComplete?: boolean;
	loadFeed?: (feedId: string) => Promise<boolean>;
	loadNextFeedItemPage?: (feedId: string) => Promise<number>;
	loadWorkspaceDetails?: (workspaceId: string) => void;
	loadWorkspaceWorkflowItems?: (workspaceId: string) => void;
	preferredLanguage: PreferredLanguage;
	setPreferredLanguage?: (language: PreferredLanguage) => void;
	fetchWorkspaceMembership?: (
		workspaceMembershipId: string,
		feedId: string,
	) => Promise<WorkspaceMembership>;
	listFeedPermissions?: (
		workspaceMembershipId: string,
		feedId: string,
	) => Promise<Array<Permission>>;
	checkFeedPermissions?: (
		workspaceMembershipId: string,
		feedId: string,
	) => Promise<boolean>;
};

//create a context, with createContext api
export const DataContext = createContext<DataState>({
	preferredLanguage: "none",
});

type Props = {
	children: React.ReactNode | React.ReactNode[];
	client: Client;
};

const DataProvider = ({ children, client }: Props) => {
	const { pubSub, flags } = React.useContext(AppContext);
	const { addToAudioQueue } = React.useContext(AudioAppContext);

	const setStorageItem = (key, value) =>
		window?.sessionStorage?.setItem(key, value);

	// not sure if this is the best idea? gonna try it though.
	const params = useParams();
	const { feedId, workspaceId, itemId } = params;
	const currentFeedId = feedId;
	const currentWorkspaceId = workspaceId;

	const [bootstrapComplete, setBootstrapComplete] = useState<boolean>(false);
	const [currentFeedPendingInvites, setCurrentFeedPendingInvites] = useState<
		DirectWsInvitation[]
	>([]);
	const [currentFeedAccounts, setCurrentFeedAccounts] = useState<
		Map<string, Account>
	>(new Map());

	const { ampli }: { ampli: Ampli } = React.useContext(TrackingContext);

	const { db } = useElectric();
	const { results: myAccount } = useLiveQuery(
		db.account.liveFirst({
			where: {
				mine: 1,
			},
		}),
	);

	const preferredLanguage = (myAccount?.preferredLanguage ||
		"none") as PreferredLanguage;

	const setPreferredLanguage = useCallback(
		(language: PreferredLanguage) => {
			if (!myAccount?.id) return;
			db.account
				.update({
					where: {
						id: myAccount.id,
					},
					data: {
						preferredLanguage: language,
					},
				})
				.then(() => {
					setStorageItem(LANGUAGE_SESSION_KEY, language);
				});
		},
		[client, myAccount?.id],
	);

	const { results: workspaceMemberships } = useLiveQuery(
		db.workspace_membership.liveMany({
			where: {
				accountId: myAccount?.id,
				status: "active",
			},
		}),
	);

	const subscribeToAppSync = React.useCallback(
		(accountIds: string[]) => {
			if (!pubSub || !client) return;

			const channels: string[] = [];
			for (const accountId of accountIds) {
				channels.push(`wsaccount#${accountId}`);
			}
			console.log("Subscribing to ws account feed", channels);
			const newSub = pubSub.subscribeFilter(
				channels,
				(data?: any) => {
					if (data?.data) {
						const eventData = JSON.parse(data.data) as WsAppSyncEvent;
						appSyncSubscriptionUpdate(db, eventData, addToAudioQueue, client);
					} else {
						console.log(
							"Account Feed Subscription Event called with no data",
							data,
						);
					}
				},
				(e: any) => {
					console.error("Account Feed Subscription Error", e);
				},
			);
			subscription?.unsubscribe();
			subscription = newSub;
		},
		[pubSub, db, addToAudioQueue, subscription, client],
	);

	// BOOTSTRAP
	React.useEffect(() => {
		const f = async () => {
			if (!client || !db || !pubSub) return;

			const userInfoResponse = client.getUserInfo().then(async (userInfo) => {
				const sessionStorageValue =
					window?.sessionStorage?.getItem(LANGUAGE_SESSION_KEY);
				if (sessionStorageValue) {
					console.log("FOUND SESSION STORAGE VALUE", sessionStorageValue);
				} else {
					console.log("NO SESSION STORAGE VALUE");
				}
				const account = await upsertMyAccount(db, userInfo);
				if (!account.preferredLanguage) {
					await db.account.update({
						where: {
							id: account.id,
						},
						data: {
							preferredLanguage: sessionStorageValue || "none",
						},
					});
				}
				subscribeToAppSync([account.id]);

				ampli.client.setUserId(account.id);
				ampli.authSuccess({ "Auth0 App": "SMS" });
				if (window.dataLayer) {
					window.dataLayer.push({
						event: {
							name: "authSuccess",
							value: "Auth0 App: SMS",
						},
					});
				}
			});
			const bootstrapResponse = client
				.bootstrapWorkspaces()
				.then(async (data) => {
					if (data?.workspaces) {
						for (const workspace of data.workspaces) {
							upsertWorkspace(db, workspace);
						}
					}

					if (data?.feeds) {
						for (const feed of data.feeds) {
							upsertWsFeed(db, feed);
							if (currentFeedId === feed.id) {
								console.log("Initial feed load");
								initialFeedLoad(client, db, feed.id);
							}
						}
					}

					if (data?.accounts) {
						for (const account of data.accounts) {
							upsertWsAccount(db, account);
						}
					}

					if (data?.workspaceMemberships) {
						for (const membership of data.workspaceMemberships) {
							upsertWorkspaceMembership(db, membership);
						}
					}

					if (data?.permissions) {
						for (const permission of data.permissions) {
							upsertWsPermission(db, permission);
						}
					}

					if (data?.templates?.length > 0) {
						for (const template of data.templates) {
							upsertWsTemplate(db, template);
						}
					}

					setBootstrapComplete(true);
				});

			await Promise.all([userInfoResponse, bootstrapResponse]);
		};
		f();
	}, [client, db, pubSub]);

	const { results: currentFeed } = useLiveQuery(() => {
		if (!currentFeedId) return;
		return db.feed.liveUnique({
			where: {
				id: currentFeedId,
			},
		});
	}, [currentFeedId]);

	const { results: feedPermissions } = useLiveQuery(() => {
		if (!currentFeedId) return;
		return db.permission.liveMany({
			where: {
				enabled: 1,
				feedId: currentFeedId,
				workspace_membershipId: {
					not: null,
				},
			},
		});
	}, [currentFeedId]);

	const { results: workspacePendingInvites } = useLiveQuery(
		db.direct_ws_invitation.liveMany({
			where: {
				claimedBy: null,
			},
		}),
	);

	const { results: myMemberships } = useLiveQuery(() => {
		if (!myAccount?.id) return;
		return db.workspace_membership.liveMany({
			where: {
				accountId: myAccount?.id,
				status: "active",
			},
		});
	}, [myAccount?.id]);

	const { results: myFeedPermissions } = useLiveQuery(() => {
		if (!myMemberships) return;
		return db.permission.liveMany({
			where: {
				enabled: 1,
				feedId: currentFeedId,
				workspace_membershipId: {
					in: myMemberships?.map((m) => m.id) || [],
				},
			},
		});
	}, [myMemberships, currentFeedId]);

	const isCurrentFeedAdmin = myFeedPermissions?.some((p) => p.name === "admin");

	useEffect(() => {
		const f = async () => {
			if (!feedPermissions || feedPermissions.length === 0) {
				console.log("No feed permissions");
				setCurrentFeedAccounts(new Map());
				return;
			}

			const memberIds = feedPermissions?.map((p) => p.workspace_membershipId);

			const memberships = await db.workspace_membership.findMany({
				where: {
					id: {
						in: memberIds,
					},
					status: "active",
					accountId: {
						not: null,
					},
				},
			});

			// set our custom query parameters to search for only active invites
			const mask = Array(memberIds.length).fill("?").join();
			const pendingInvites = await db.raw({
				sql: `
					SELECT 
						*
					FROM 
						direct_ws_invitation
					JOIN 
						workspace_membership 
					ON 
						direct_ws_invitation.workspaceMembershipId = workspace_membership.id
					WHERE 
						direct_ws_invitation.workspaceMembershipId IN (${mask})
					AND 
						workspace_membership.status = 'active'
					AND 
						direct_ws_invitation.claimedBy is null`,
				args: memberIds,
			});

			const accountIds = memberships?.map((m) => m.accountId) || [];

			try {
				const feedAccounts: Account[] = await db.account.findMany({
					where: {
						id: {
							in: accountIds,
						},
					},
				});

				setCurrentFeedAccounts(accountInfo(feedAccounts));
				setCurrentFeedPendingInvites(pendingInvites);
			} catch (e) {
				console.log(e);
			}
		};
		f();
	}, [feedPermissions, workspacePendingInvites, currentFeedId]);

	const loadWorkspaceDetails = React.useCallback(
		async (workspaceId: string) => {
			if (!myAccount?.id) return;
			const isAdmin = await db.workspace_membership.findFirst({
				where: {
					workspaceId,
					accountId: myAccount?.id,
					status: "active",
					role: "admin",
				},
			});
			if (!isAdmin) return;
			await client.getWorkspaceInvitations(workspaceId).then((resp) => {
				console.log("Workspace details response", { resp });
				for (const membership of resp?.workspaceMemberships || []) {
					upsertWorkspaceMembership(db, membership);
				}
				for (const account of resp?.accounts || []) {
					upsertWsAccount(db, account);
				}
				for (const directInvitation of resp?.directInvitations || []) {
					upsertDirectWsInvitation(db, directInvitation);
				}
			});
		},
		[client, db, myAccount?.id],
	);

	const loadWorkspaceWorkflowItems = React.useCallback(
		async (workspaceId: string) => {
			if (!myAccount?.id) return;
			const isAdmin = await db.workspace_membership.findFirst({
				where: {
					workspaceId,
					accountId: myAccount?.id,
					status: "active",
					role: "admin",
				},
			});
			if (!isAdmin) return;
			await client.getWorkspaceWorkflowItems(workspaceId).then((resp) => {
				console.log("Workspace workflow items response", { resp });
				for (const workflowItem of resp?.workflowItems || []) {
					upsertWsDraft(db, workflowItem);
				}
				// load associated content of the workflow items
				for (const i of resp?.audioEncodings || []) {
					upsertWsAudioEncoding(db, i);
				}
				for (const i of resp?.callRecords || []) {
					upsertWsCallRecord(db, i);
				}
				for (const i of resp?.files || []) {
					upsertWsFile(db, i);
				}
				for (const i of resp?.links || []) {
					upsertWsLink(db, i);
				}
				for (const i of resp?.transcriptions || []) {
					upsertWsTranscription(db, i);
				}
				for (const i of resp?.displayArtifacts || []) {
					upsertWsDisplayArtifact(db, i);
				}
			});

			await client.getScheduledBroadcasts(workspaceId).then((resp) => {
				console.log("Workspace scheduled broadcasts response", { resp });
				for (const scheduleTrigger of resp?.scheduleTriggers || []) {
					upsertWsScheduleTrigger(db, scheduleTrigger);
				}
				for (const broadcastAction of resp?.broadcastActions || []) {
					upsertWsBroadcastAction(db, broadcastAction);
				}
				for (const broadcastRecipient of resp?.broadcastRecipients || []) {
					upsertWsBroadcastRecipient(db, broadcastRecipient);
				}
			});
		},
		[client, db, myAccount?.id],
	);

	const fetchWorkspaceMembership = React.useCallback(
		async (accountId: string, workspaceId: string) => {
			const workspaceMembership = await db.workspace_membership.findFirst({
				where: {
					accountId,
					workspaceId,
					status: "active",
				},
			});
			if (!workspaceMembership) {
				return Promise.reject("Invalid Workspace");
			}
			return workspaceMembership;
		},
		[db],
	);

	const checkFeedPermissions = React.useCallback(
		async (workspaceMembershipId: any, feedId: string) => {
			const feed = await db.feed.findUnique({ where: { id: feedId } });
			if (!feed) {
				return Promise.reject("Invalid Feed");
			}
			const permissions = await db.permission.findMany({
				where: {
					feedId,
					enabled: 1,
					workspace_membershipId: { in: [workspaceMembershipId] },
				},
			});
			if (permissions?.length === 0) {
				return Promise.reject("No Feed Permissions");
			}
			return true;
		},
		[db],
	);

	const listFeedPermissions = React.useCallback(
		async (
			workspaceMembershipId: string,
			feedId: string,
		): Promise<Array<Permission>> => {
			try {
				return await db.permission.findMany({
					where: {
						feedId,
						workspace_membershipId: { in: [workspaceMembershipId] },
					},
				});
			} catch (e) {
				return [];
			}
		},
		[db],
	);

	const dataState: DataState = {
		myAccount,
		myAccountId: myAccount?.id,
		currentFeedId,
		currentWorkspaceId,
		currentFeed,
		currentFeedAccounts,
		currentFeedPendingInvites,
		isCurrentFeedAdmin,
		workspaceMemberships,
		bootstrapComplete,
		preferredLanguage,
		setPreferredLanguage: setPreferredLanguage,
		fetchWorkspaceMembership,
		checkFeedPermissions,
		listFeedPermissions,
		loadFeed: (feedId: string) => {
			return initialFeedLoad(client, db, feedId);
		},
		loadNextFeedItemPage: async (feedId: string) => {
			return getNextFeedItemPage(client, db, feedId);
		},
		loadWorkspaceDetails,
		loadWorkspaceWorkflowItems,
	};
	return (
		<DataContext.Provider value={dataState}>{children}</DataContext.Provider>
	);
};
export default DataProvider;
