import { FullInput, FullInputProps } from "@/components/Utils";
import { Box, Stack, Typography } from "@mui/material";
import { isEmpty } from "validator";
import * as linkify from "linkifyjs";
import { isValidLink } from "@/utils";
import TextInputHelper from "@/components/TextInputHelper";

export type TextMessageInputProps = FullInputProps & {
	inputId?: string;
	label?: string;
	characterLimit?: number;
	disabled?: boolean;
	// lifting the state props to the parent
	textInputValue?: string;
	textInputHandler: Function;
	textValid?: boolean;
	validateTextHandler: Function;
	className?: string;
	placeholder?: string;
};

export default function TextMessageInput({
	inputId = null,
	label = "",
	rows = 5,
	characterLimit = 5000,
	disabled,
	textInputValue,
	textInputHandler,
	textValid,
	validateTextHandler,
	className,
	placeholder,
}: TextMessageInputProps) {
	const verifyTextInput = (text: string) => {
		if (validateTextHandler) {
			if (
				!isEmpty(text, { ignore_whitespace: true }) &&
				text?.length <= characterLimit
			) {
				validateTextHandler(true);
			} else {
				validateTextHandler(false);
			}
		}
	};

	const handleOnChange = (text) => {
		textInputHandler(text);
		verifyTextInput(text);
	};

	return (
		<Stack
			sx={{
				flexDirection: "row",
				alignItems: "center",
				gap: 1.5,
				width: "100%",
			}}
		>
			<FullInput
				className={className}
				id={inputId}
				label={label}
				placeholder={placeholder}
				disabled={disabled}
				multiline
				rows={rows}
				sx={{
					position: "relative",
					width: "100%",
					p: 0,
					".MuiInputBase-input": {
						fontSize: "1rem",
						lineHeight: 1.5,
						minHeight: 0,
						borderRadius: 2,
						pl: { xs: 1.5, sm: 1 },
						pr: 1,
					},
				}}
				value={textInputValue}
				callback={(e) => handleOnChange(e.target.value)}
				error={textInputValue.length > characterLimit}
				helperText={
					<TextInputHelper textInput={textInputValue} limit={characterLimit} />
				}
			/>
		</Stack>
	);
}
