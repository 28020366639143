import React, { useContext } from 'react';
import { Box, Typography, Link, useTheme } from '@mui/material';
import { UxContext } from '@/models/UxStateProvider';

export default function ReadOnlyFooter() {
  const theme = useTheme();
  const { toggleChannelModal } = useContext(UxContext);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '85px',
        background: theme.palette.neutral.dark,
        zIndex: 1100
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        <Typography sx={{ fontWeight: 700, opacity: 0.5 }}>
          You are a listener on this team
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.neutral.main
          }}
        >
          <Link
            sx={{
              color: theme.palette.neutral.main,
              padding: '0 0.5ch',
              '&:hover': {
                color: theme.palette.primary.main
              }
            }}
            component="button"
            onClick={toggleChannelModal}
          >
            Click here
          </Link>
          to start your own.
        </Typography>
      </Box>
    </Box>
  );
}
