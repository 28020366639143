import { createContext, useState } from "react";
import { SetStateType } from "@/utils";

export type FeedState = {
	fileUploadModal?: boolean;
	textInput?: string;
	setFileUploadModal: SetStateType<boolean>;
	setTextInput: SetStateType<string>;
	textAsInputModal?: boolean;
	setTextAsInputModal: SetStateType<boolean>;
};

export const FeedContext = createContext<FeedState>({
	setFileUploadModal: () => {},
	setTextInput: () => {},
	setTextAsInputModal: () => {},
});

const FeedContextProvider = ({ children }) => {
	// context use to persist state in the feed virtualized list until refactor occurs
	const [textInput, setTextInput] = useState<string>("");
	const [textAsInputModal, setTextAsInputModal] = useState<boolean>(false);
	const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);

	const feedState: FeedState = {
		fileUploadModal,
		textInput,
		textAsInputModal,
		setFileUploadModal,
		setTextInput,
		setTextAsInputModal,
	};
	return (
		<FeedContext.Provider value={feedState}>{children}</FeedContext.Provider>
	);
};

export default FeedContextProvider;
