import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link, Stack, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import ModalForm from "@/elements/ModalForm";
import { DataContext } from "@/models/DataProvider";

export default function WorkspaceId() {
	const { db } = useElectric();
	const navigate = useNavigate();
	const params = useParams();
	const workspaceId = params?.workspaceId as string;
	const feedId = params?.feedId as string;

	const { myAccountId } = useContext(DataContext);

	const { results: workspace, error } = useLiveQuery(() => {
		if (!myAccountId || !workspaceId) return;
		return db.liveRaw({
			sql: `
			select * from workspace

			JOIN workspace_membership 
			ON   workspace_membership.workspaceId = workspace.id 
			AND  workspace_membership.status = 'active'
			AND  workspace_membership.accountId = ?

			WHERE workspace.id = ?
			`,
			args: [myAccountId, workspaceId],
		});
	}, [myAccountId, workspaceId]);

	const { results: myMembership } = useLiveQuery(() => {
		if (!myAccountId || !workspaceId) return;
		return db.workspace_membership.liveFirst({
			where: {
				workspaceId: workspaceId,
				accountId: myAccountId,
				status: "active",
			},
		});
	}, [myAccountId, workspaceId]);

	const [invalidWorkspace, setInvalidWorkspace] = useState<boolean>(true);

	const closeModalAndRedirect = () => {
		navigate("/workspaces");
		setInvalidWorkspace(() => false);
	};

	useEffect(() => {
		const f = async () => {
			if (!myMembership) return;
			if (!workspaceId) {
				navigate("/workspaces");
			}

			if (!feedId) {
				await db
					.raw({
						sql: `
					SELECT feed.id FROM feed

					JOIN permission 
					ON   permission.feedId = feed.id 
					AND  permission.name = 'read'
					AND  permission.enabled = 1
					AND permission.workspace_membershipId = ?

					JOIN workspace_membership 
					ON   workspace_membership.id = permission.workspace_membershipId
					AND  workspace_membership.status = 'active'
					
					WHERE feed.workspaceId = ? 
					ORDER BY feed.updatedAt DESC
					LIMIT 1
					`,
						args: [myMembership?.id, workspaceId],
					})
					.then((res) => {
						const feed = res?.[0]?.id;
						if (feed) {
							navigate(`/workspaces/${workspaceId}/feeds/${feed}`);
						}
					})
					.catch((err) => {
						console.error(err);
					});
			}
		};
		f();
	}, [db, navigate, workspaceId, feedId, myMembership]);

	if (!workspace) {
		return (
			<ModalForm
				open={invalidWorkspace}
				onClose={() => closeModalAndRedirect()}
			>
				<Stack sx={{ alignItems: "center", width: "100%", gap: 1 }}>
					<Typography
						variant="h5"
						component="h2"
						sx={{ mb: 2, fontWeight: 700, textAlign: "center" }}
					>
						You do not have rights to view this content?
					</Typography>

					<Typography sx={{ textAlign: "left" }}>
						Please contact your Storyboard administrator to add you as a
						workspace member.
					</Typography>
					<Link
						component="button"
						onClick={closeModalAndRedirect}
						sx={{ display: "flex", gap: 1 }}
					>
						<ArrowBack role="presentation" />
						<Typography>Return to workspace</Typography>
					</Link>
				</Stack>
			</ModalForm>
		);
	}

	return <></>;
}
