import {
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	Typography,
	useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppContext } from "@/models/AppStateProvider";
import { useLiveQuery } from "electric-sql/react";
import { useContext, useEffect, useState } from "react";
import { upsertWsTemplate } from "@/data/workspace";
import { WsTemplate } from "../../../web-client/api/data-contracts";
import { useParams } from "react-router-dom";
import { useElectric } from "@/electric/ElectricWrapper";
import { FullInput } from "@/components/Utils";
import ConfirmDiscardChanges from "@/components/ModalForms/ConfirmDiscardChanges";
import { Add } from "@mui/icons-material";
import TextInputHelper from "@/components/TextInputHelper";

interface Props {
	visible: boolean;
	setTemplateTextAsTTS: (value: string) => void;
	setActiveEdit: (value: boolean) => void;
}
export default function TemplatesModal({
	visible,
	setTemplateTextAsTTS,
	setActiveEdit,
}: Props) {
	const theme = useTheme();
	const { db } = useElectric();
	const params = useParams();
	const workspaceId = params?.workspaceId;
	const [templates, setTemplates] = useState<Array<WsTemplate>>([]);
	const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
	const [createNewTemplate, setCreateNewTemplate] = useState<boolean>(false);
	const [confirmDelete, setConfirmDelete] = useState<string | null>(null);

	const [disabled, setDisabled] = useState<boolean>(false);
	const [activeTemplate, setActiveTemplate] = useState<WsTemplate | null>(null);
	const [templateText, setTemplateText] = useState<string>("");
	const [templateName, setTemplateName] = useState<string>("");
	const { client } = useContext(AppContext);
	const characterLimit = 5000;

	const { results: userInfo } = useLiveQuery(
		db.account.liveFirst({
			where: {
				mine: 1,
			},
		}),
	);

	const { results: cachedTemplates } = useLiveQuery(() => {
		if (!userInfo?.id) return;
		return db.template.liveMany({
			where: {
				workspaceId,
				deletedAt: null,
			},
		});
	}, [userInfo]);

	useEffect(() => {
		if (cachedTemplates?.length > 0) {
			setLoadingTemplates(() => false);
			return setTemplates(() => cachedTemplates);
		} else {
			setLoadingTemplates(() => true);
			setTimeout(() => {
				setLoadingTemplates(() => false);
			}, 5000);
		}
	}, [cachedTemplates]);

	const useSelectedTemplate = (templateData: WsTemplate) => {
		setTemplateTextAsTTS(templateData.template);
		setActiveTemplate(() => null);
	};

	useEffect(() => {
		if (activeTemplate?.id) {
			setActiveEdit(
				activeTemplate.name !== templateName ||
					activeTemplate.template !== templateText,
			);
		} else {
			setActiveEdit(templateName?.length > 0 || templateText?.length > 0);
		}
	}, [activeTemplate, templateName, templateText]);

	const saveNewTemplate = async (e) => {
		e.preventDefault();
		if (!templateName || !templateText) {
			return;
		}
		setDisabled(() => true);
		const { template } = await client.createWorkspaceTemplate({
			workspaceId,
			name: templateName,
			template: templateText,
		});
		setDisabled(() => false);
		setTemplates((array) => [...array, template]);
		await upsertWsTemplate(db, template);
		setActiveTemplate(() => template);
		setCreateNewTemplate(() => false);
	};

	const saveActiveTemplate = async (e) => {
		e.preventDefault();
		if (!templateName || !templateText) {
			return;
		}
		setDisabled(() => true);
		const { template } = await client.updateWorkspaceTemplate({
			workspaceId,
			templateId: activeTemplate.id,
			name: templateName,
			template: templateText,
		});
		setTemplates((array) =>
			array.map((item) => (item.id === activeTemplate.id ? template : item)),
		);
		setDisabled(() => false);
		await upsertWsTemplate(db, template);
		setActiveTemplate(() => template);
	};

	const onKeyDown = (e) => {
		if (e.metaKey && e.which === 13) {
			activeTemplate?.id ? saveActiveTemplate(e) : saveNewTemplate(e);
		}
	};

	const handleDelete = async () => {
		setDisabled(() => true);
		await client.deleteWorkspaceTemplate({
			workspaceId,
			templateId: confirmDelete,
		});
		setTemplates((array) => array.filter((item) => item.id !== confirmDelete));
		setDisabled(() => false);
		setConfirmDelete(() => null);
		setActiveTemplate(() => null);
		await db.template.delete({
			where: { id: confirmDelete },
		});
	};

	const saveButtonDisabledState =
		templateName === activeTemplate?.name &&
		templateText === activeTemplate?.template;

	return (
		<Box
			sx={{
				position: "absolute",
				background: theme.palette.secondary.dark,
				width: "100%",
				height: "100%",
				zIndex: 3,
				display: visible ? "block" : "none",
			}}
		>
			{confirmDelete && (
				<ConfirmDiscardChanges
					label="Are you sure?"
					text="Confirm you would like to delete this template. Your content will be lost."
					deleteText="Delete"
					setStatus={(value) => {
						if (value) {
							handleDelete();
						} else {
							setConfirmDelete(() => null);
						}
					}}
				/>
			)}

			<Box
				sx={{
					borderBottom: `solid 1px ${theme.palette.secondary.main}`,
					p: 2,
				}}
			>
				<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
					Use a template
				</Typography>
			</Box>
			<Box sx={{ display: "flex" }}>
				<Box
					sx={{
						width: "360px",
						borderRight: `solid 1px ${theme.palette.secondary.main}`,
					}}
				>
					<Box sx={{ p: 1 }}>
						<Button
							onClick={() => {
								setCreateNewTemplate(() => true);
								setActiveTemplate(() => null);
								setTemplateName(() => "");
								setTemplateText(() => "");
							}}
							sx={{ display: "flex", justifyContent: "start" }}
						>
							<Box sx={{ flex: 1, textAlign: "left" }}>New Template</Box>
							<Box sx={{ display: "flex" }}>
								<Add />
							</Box>
						</Button>
					</Box>
					{loadingTemplates ? (
						<Box sx={{ px: 2, mt: 1, height: "426px" }}>Loading Templates</Box>
					) : (
						<List
							sx={{ p: 1, height: "433px", overflowY: "auto" }}
							className="dark-scrollbar"
						>
							{templates?.length > 0 ? (
								templates.map((template) => (
									<ListItem
										sx={{ marginBottom: "0.5rem", padding: 0 }}
										key={template.id}
									>
										<Button
											onClick={(e) => {
												if (e.detail >= 2) {
													useSelectedTemplate(template);
												} else {
													setActiveTemplate(() => template);
													setTemplateName(() => template.name);
													setTemplateText(() => template.template);
													setCreateNewTemplate(() => false);
												}
											}}
											sx={{
												background:
													template.id === activeTemplate?.id &&
													theme.palette.secondary.main,
												justifyContent: "start",
												textAlign: "left",
												textTransform: "none",
											}}
										>
											<Box
												sx={{
													textOverflow: "ellipsis",
													overflow: "hidden",
													whiteSpace: "nowrap",
												}}
											>
												{template.name}
											</Box>
										</Button>
									</ListItem>
								))
							) : (
								<Box sx={{ px: 1 }}>No templates to show</Box>
							)}
						</List>
					)}
				</Box>

				<Box sx={{ width: "664px" }}>
					{activeTemplate && !createNewTemplate && (
						<Box
							component="form"
							onSubmit={(e) => saveActiveTemplate(e)}
							sx={{
								p: 2,
								display: "flex",
								flexFlow: "column",
								height: "495px",
							}}
						>
							<Box sx={{ mb: 2, display: "flex" }}>
								<Box sx={{ flex: 1 }}>
									<FullInput
										id="newTemplateName"
										placeholder="Enter a title"
										autoFocus
										required
										className="input-v2"
										disabled={disabled}
										value={templateName}
										callback={(e) => setTemplateName(e.target.value)}
									/>
								</Box>
								<Box sx={{ display: "flex" }}>
									<Button
										variant="contained"
										color="primary"
										type="submit"
										sx={{ mr: 1, mx: 2 }}
										disabled={
											saveButtonDisabledState ||
											disabled ||
											templateText?.length > characterLimit
										}
									>
										Save
									</Button>
									<IconButton
										aria-label="Delete Template"
										onClick={() => setConfirmDelete(() => activeTemplate.id)}
										disabled={disabled}
									>
										<DeleteIcon />
									</IconButton>
								</Box>
							</Box>
							<Box sx={{ mb: 1, flex: 1 }}>
								<FullInput
									id="createNewTemplate"
									disabled={disabled}
									className="text-input-v2"
									multiline
									required
									placeholder="Start a typing your templated message"
									rows={7}
									error={templateText?.length > characterLimit}
									inputProps={{ className: "dark-scrollbar" }}
									value={templateText}
									callback={(e) => setTemplateText(e.target.value)}
									onKeyDown={onKeyDown}
								/>
								<Box>
									<TextInputHelper
										textInput={templateText}
										limit={characterLimit}
										linksValid={true}
									/>
								</Box>
							</Box>

							<Box sx={{ display: "flex" }}>
								<Button
									variant="contained"
									type="button"
									disabled={!saveButtonDisabledState || disabled}
									onClick={() => useSelectedTemplate(activeTemplate)}
								>
									Use this template
								</Button>
							</Box>
						</Box>
					)}

					{createNewTemplate && !activeTemplate && (
						<Box
							component="form"
							onSubmit={(e) => saveNewTemplate(e)}
							sx={{ p: 2 }}
						>
							<Box sx={{ mb: 2 }}>
								<FullInput
									id="newTemplateName"
									placeholder="Enter a title"
									autoFocus
									required
									className="input-v2"
									disabled={disabled}
									value={templateName}
									callback={(e) => setTemplateName(e.target.value)}
								/>
							</Box>
							<Box sx={{ mb: 2 }}>
								<FullInput
									id="createNewTemplate"
									disabled={disabled}
									className="text-input-v2"
									multiline
									required
									placeholder="Start a typing your templated message"
									rows={7}
									inputProps={{ className: "dark-scrollbar" }}
									value={templateText}
									error={templateText?.length > characterLimit}
									callback={(e) => setTemplateText(e.target.value)}
									onKeyDown={onKeyDown}
								/>
								<Box>
									<TextInputHelper
										textInput={templateText}
										limit={characterLimit}
										linksValid={true}
									/>
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									width: "100%",
									justifyContent: "flex-end",
								}}
							>
								<Button
									sx={{ width: "auto" }}
									variant="contained"
									color="primary"
									type="submit"
									disabled={
										disabled ||
										(templateName?.length === 0 &&
											templateText?.length === 0) ||
										templateText?.length > characterLimit
									}
								>
									Save
								</Button>
							</Box>
						</Box>
					)}

					{!createNewTemplate && !activeTemplate && templates?.length >= 0 && (
						<Box
							sx={{
								height: "100%",
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									background: theme.palette.secondary.main,
									display: "block",
									width: "420px",
									p: 2,
									borderRadius: 2,
									textAlign: "center",
								}}
							>
								<Box>
									Click <strong>“New template”</strong> to create a templated
									message.
								</Box>
								<Box>
									Templated messages can be saved and used in text-to-speech
									messages.
								</Box>
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
}
