import * as Icons from '@mui/icons-material';
export function IconForFile({ mimeType }: { mimeType: string }) {
  const baseType = mimeType.split('/')[0];

  switch (baseType) {
    case 'image':
      return <Icons.Image />;
    case 'audio':
      return <Icons.Audiotrack />;
    case 'video':
      return <Icons.Movie />;
  }

  switch (mimeType) {
    case 'application/pdf':
      return <Icons.PictureAsPdf />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
      return <Icons.Description />;
    case 'text/plain':
    case 'text/html':
    case 'text/css':
    case 'text/javascript':
    case 'application/javascript':
    case 'application/json':
      return <Icons.Code />;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'text/csv':
      return <Icons.InsertChart />;
  }

  return <Icons.InsertDriveFile />;
}
