import { upsertAccountEvent, upsertAppPhoneNumber } from "@/data/oldWorld";
import {
	upsertDirectWsInvitation,
	upsertWorkspace,
	upsertWorkspaceMembership,
	upsertWsFeed,
	upsertWsItem,
	upsertWsPermission,
	upsertWsDraft,
	upsertWsScheduleTrigger,
	upsertWsBroadcastAction,
	deleteWsDraft,
	upsertWsBroadcastRecipient,
} from "@/data/workspace";
import { useElectric } from "@/electric/ElectricWrapper";
import { handleErrorResponse } from "@/utils";
import React, { createContext, useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import {
	AccountEventType,
	CreateWorkspaceDirectInvitationResponse,
	CreateWorkspaceScheduledBroadcastResponse,
	CreateWorkspaceWorkflowItemRequest,
	PublishBroadcastResponse,
	UpdateWorkspaceScheduledBroadcastResponse,
	Workspace,
	WorkspaceRole,
	WsEvent,
	WsItem,
	WsPermission,
	WsWorkflowItem,
} from "web-client/api/data-contracts";
import Client from "web-client/client";
import { DataContext } from "./DataProvider";
import { Feed } from "@/generated/client";

export type ActionState = {
	removeFeed?: (workspaceId: string, feedId: string) => Promise<void>;
	accountEvent?: (event: AccountEventType, data: any) => void;
	createAppPhoneNumber?: (forwardingNumber: string) => Promise<void>;
	createScheduledWorkflowItem?: ({
		workspaceId,
		workflowItemId,
		feedIds,
		scheduledDate,
		scheduledCron,
		timezone,
	}: {
		workspaceId: string;
		workflowItemId: string;
		feedIds: Array<string>;
		scheduledDate?: string;
		scheduledCron?: string;
		timezone?: string;
	}) => Promise<CreateWorkspaceScheduledBroadcastResponse>;
	updateScheduledWorkflowItem?: ({
		workspaceId,
		scheduleId,
		feedIds,
		scheduledDate,
		scheduledCron,
		timezone,
	}: {
		workspaceId: string;
		scheduleId: string;
		feedIds: Array<string>;
		scheduledDate?: string;
		scheduledCron?: string;
		timezone?: string;
	}) => Promise<void>;
	deleteItem?: (itemId: string) => void;
	createWorkflowItem?: (
		workspaceId: string,
		contentId?: string,
		displayName?: string,
		text?: string,
	) => Promise<WsWorkflowItem>;
	deleteWorkflowItem?: (
		workspaceId: string,
		workflowItemId: string,
	) => Promise<void>;
	createWorkspace?: (name: string) => Promise<Workspace>;
	updateWorkspace?: (workspaceId: string, name: string) => Promise<void>;
	publishBroadcast?: ({
		workspaceId,
		contentId,
		workspaceMembershipIds,
		feedIds,
		inputText,
	}: {
		workspaceId: string;
		contentId: string;
		workspaceMembershipIds?: string[];
		feedIds?: string[];
		inputText?: string;
	}) => Promise<PublishBroadcastResponse>;
	publishToWorkspaceFeed?: ({
		workspaceId,
		feedId,
		itemId,
		contentId,
		url,
		text,
	}: {
		workspaceId: string;
		feedId: string;
		itemId?: string;
		contentId: string;
		url?: string;
		text?: string;
	}) => Promise<WsItem>;
	muteUnmute?: (
		workspaceId: string,
		feedId: string,
		workspaceMemberId: string,
		type: string,
	) => Promise<void>;
	promoteMemberToOwner?: (
		workspaceId: string,
		feedId: string,
		workspaceMemberId: string,
	) => Promise<void>;
	demoteOwnerToMember?: (
		workspaceId: string,
		feedId: string,
		workspaceMemberId: string,
		workspaceMembershipId: string,
	) => Promise<void>;
	removeMemberFromFeed?: (
		workspaceId: string,
		feedId: string,
		workspaceMemberId: string,
	) => Promise<void>;
	createWorkspaceFeed?: (
		workspaceId: string,
		title: string,
		isPrivate: boolean,
		readOnly: boolean,
	) => void;
	updateWorkspaceFeed?: (
		workspaceId: string,
		feedId: string,
		title: string,
	) => void;
	createWorkspaceDm?: (
		workspaceId: string,
		workspaceMembershipIds: string[],
	) => Promise<Feed>;
	addWorkspaceMembersToFeed?: (
		workspaceId: string,
		feedId: string,
		workspaceMembershipIds: string[],
	) => void;
	sendWorkspaceInvites?: (
		workspaceId: string,
		emails?: string[],
		phoneNumbers?: string[],
		sendEmail?: boolean,
	) => Promise<CreateWorkspaceDirectInvitationResponse>;
	updateMemberRole?: (
		workspaceId: string,
		workspaceMembershipIds: string[],
		role: WorkspaceRole,
	) => void;
	removeMember?: (
		workspaceId: string,
		workspaceMembershipIds: string[],
	) => void;
};

export const ActionContext = createContext<ActionState>({});

type Props = {
	children: React.ReactNode | React.ReactNode[];
	client: Client;
};

const ActionsProvider = ({ children, client }: Props) => {
	const { showBoundary } = useErrorBoundary();
	const navigate = useNavigate();
	const { db } = useElectric();

	const {
		currentFeedId,
		myAccount,
		workspaceMemberships,
		fetchWorkspaceMembership,
		checkFeedPermissions,
		listFeedPermissions,
	} = useContext(DataContext);

	const removeFeed = React.useCallback(
		async (workspaceId: string, feedId: string) => {
			console.log("Removing feed", workspaceId, feedId);
			await client
				.unSubscribeFromWorkspaceFeed(workspaceId, feedId)
				.catch(async (e) => {
					const error = await handleErrorResponse(e);
					showBoundary(error);
				});
			console.log("Unsubscribed from feed", feedId);
			await db.feed.delete({ where: { id: feedId } });
			if (currentFeedId === feedId) {
				navigate(`/workspaces/${workspaceId}`);
			}
		},
		[client, db, currentFeedId, navigate, showBoundary],
	);

	const accountEvent = React.useCallback(
		async (name: AccountEventType, data: any) => {
			if (!myAccount) return;
			if (data.feedId && data.feedItemId) {
				const existingEvent = await db.account_event.findFirst({
					where: {
						name,
						feedId: data.feedId,
						itemId: data.feedItemId,
						accountId: myAccount.id,
					},
				});

				if (existingEvent) {
					return;
				}
			}
			const event: WsEvent = {
				name,
				...data,
				createdAt: new Date(Date.now()).toISOString(),
				accountId: myAccount.id,
			};
			upsertAccountEvent(db, event);
			// console.log("Account Event", event.name, event);
			client.createAccountEvent(event);
		},
		[client, db, myAccount],
	);

	const createAppPhoneNumber = React.useCallback(
		async (forwardingNumber: string) => {
			const appPhoneNumber = await client.createPhoneNumber(forwardingNumber);
			upsertAppPhoneNumber(db, appPhoneNumber);
		},
		[client, db],
	);

	const deleteItem = React.useCallback(
		async (itemId: string) => {
			const feedItem = await db.item.findUnique({ where: { id: itemId } });
			const feed = await db.feed.findUnique({ where: { id: feedItem.feedId } });
			if (!feed || !feed.workspaceId || !itemId) return;
			const { item } = await client.deleteWorkspaceFeedItem(
				feed.workspaceId,
				feed.id,
				itemId,
			);

			upsertWsItem(db, item);
		},
		[client, db],
	);

	const createWorkspace = React.useCallback(
		async (name: string) => {
			const { workspace, workspaceMemberships } =
				await client.createWorkspace(name);
			await upsertWorkspace(db, workspace);
			for (const membership of workspaceMemberships) {
				upsertWorkspaceMembership(db, membership);
			}
			return workspace;
		},
		[client, db],
	);

	const updateWorkspace = React.useCallback(
		async (workspaceId: string, name: string) => {
			const { workspace } = await client.updateWorkspace(workspaceId, { name });
			upsertWorkspace(db, workspace);
		},
		[client, db],
	);

	const createWorkspaceFeed = React.useCallback(
		async (
			workspaceId: string,
			title: string,
			isPrivate: boolean,
			readOnly: boolean,
		) => {
			const { feed, workspaceMemberships, permissions } =
				await client.createWorkspaceFeed({
					workspaceId,
					title,
					isPrivate,
					readOnly,
				});

			await upsertWsFeed(db, feed);
			for (const membership of workspaceMemberships) {
				upsertWorkspaceMembership(db, membership);
			}
			for (const p of permissions) {
				upsertWsPermission(db, p);
			}

			return feed;
		},
		[client, db],
	);

	const updateWorkspaceFeed = React.useCallback(
		async (workspaceId: string, feedId: string, newTitle: string) => {
			const { feed } = await client.updateWorkspaceFeed({
				workspaceId,
				feedId,
				title: newTitle,
			});

			upsertWsFeed(db, feed);
		},
		[client, db],
	);

	const createWorkspaceDm = React.useCallback(
		async (workspaceId: string, workspaceMembershipIds: string[]) => {
			const { feed, workspaceMemberships, permissions } =
				await client.createWorkspaceDm({
					workspaceId,
					workspaceMembershipIds,
				});

			await upsertWsFeed(db, feed);
			for (const membership of workspaceMemberships) {
				upsertWorkspaceMembership(db, membership);
			}
			for (const p of permissions) {
				upsertWsPermission(db, p);
			}

			return feed;
		},
		[client, db],
	);

	const addWorkspaceMembersToFeed = React.useCallback(
		async (
			workspaceId: string,
			feedId: string,
			workspaceMembershipIds: string[],
		) => {
			const { permissions } = await client.addWorkspaceMembersToFeed(
				workspaceId,
				feedId,
				workspaceMembershipIds,
			);

			for (const p of permissions) {
				upsertWsPermission(db, p);
			}
		},
		[client, db],
	);

	const sendWorkspaceInvites = React.useCallback(
		async (
			workspaceId: string,
			emails: string[],
			phoneNumbers: string[],
			sendEmail: boolean,
		) => {
			const { invitations, workspaceMemberships } =
				await client.createWorkspaceInvitations(
					workspaceId,
					emails,
					phoneNumbers,
					sendEmail,
				);
			for (const i of invitations) {
				upsertDirectWsInvitation(db, i);
			}
			for (const m of workspaceMemberships) {
				upsertWorkspaceMembership(db, m);
			}

			return {
				invitations,
				workspaceMemberships,
			};
		},
		[client, db],
	);

	const updateMemberRole = React.useCallback(
		async (
			workspaceId: string,
			workspaceMembershipIds: string[],
			role: WorkspaceRole,
		) => {
			const { workspaceMemberships } = await client.updateWorkspaceMember(
				workspaceId,
				workspaceMembershipIds,
				role,
			);

			for (const workspaceMembership of workspaceMemberships) {
				console.log("Upserting workspace membership", workspaceMembership);
				upsertWorkspaceMembership(db, workspaceMembership);
			}
		},
		[client, db],
	);

	const removeMember = React.useCallback(
		async (workspaceId: string, workspaceMembershipIds: string[]) => {
			const { workspaceMemberships } = await client.removeWorkspaceMember(
				workspaceId,
				workspaceMembershipIds,
			);

			for (const workspaceMembership of workspaceMemberships) {
				upsertWorkspaceMembership(db, workspaceMembership);
			}
		},
		[client, db],
	);

	const muteUnmute = React.useCallback(
		async (
			workspaceId: string,
			feedId: string,
			workspaceMemberId: string,
			type: string,
		): Promise<any> => {
			const workspaceMembership = await fetchWorkspaceMembership(
				workspaceMemberId,
				workspaceId,
			);

			const permissions = await listFeedPermissions(
				workspaceMembership.id,
				feedId,
			);

			const permission = permissions.find((perm) => perm.name === "write");
			await upsertWsPermission(db, {
				...permission,
				name: "write",
				id: permission.id,
				enabled: type !== "mute",
			});
			client.muteUnmuteWorkspaceFeedMember(
				workspaceId,
				feedId,
				workspaceMemberId,
				type,
			);
		},
		[client, db],
	);

	const promoteMemberToOwner = React.useCallback(
		async (workspaceId: string, feedId: string, workspaceMemberId: string) => {
			const { permissions } = await client.promoteDemoteMember(
				workspaceId,
				feedId,
				workspaceMemberId,
				"promote",
			);
			for (const p of permissions) {
				upsertWsPermission(db, p);
			}
		},
		[client, db],
	);

	const demoteOwnerToMember = React.useCallback(
		async (
			workspaceId: string,
			feedId: string,
			workspaceMemberId: string,
			workspaceMembershipId: string,
		) => {
			const workspaceMembership = await fetchWorkspaceMembership(
				workspaceMemberId,
				workspaceId,
			);

			const permissions = await listFeedPermissions(
				workspaceMembership.id,
				feedId,
			);

			const permission = permissions.find((perm) => perm.name === "admin");
			await upsertWsPermission(db, {
				...permission,
				name: "admin",
				id: permission.id,
				enabled: false,
			});
			client.promoteDemoteMember(
				workspaceId,
				feedId,
				workspaceMemberId,
				"demote",
			);
		},
		[client, db],
	);

	const removeMemberFromFeed = React.useCallback(
		async (workspaceId: string, feedId: string, workspaceMemberId: string) => {
			const workspaceMembership = await fetchWorkspaceMembership(
				workspaceMemberId,
				workspaceId,
			);

			const permissions = await listFeedPermissions(
				workspaceMembership.id,
				feedId,
			);

			client.removeMemberFromFeed(workspaceId, feedId, workspaceMemberId);
			for (const permission of permissions) {
				upsertWsPermission(db, {
					...permission,
					name: permission.name,
					id: permission.id,
					enabled: false,
				});
			}
		},
		[client, db],
	);

	const createWorkflowItem = React.useCallback(
		async (
			workspaceId: string,
			contentId?: string,
			displayName?: string,
			text?: string,
		) => {
			let workflowItem;
			if (text) {
				workflowItem = await client.createWorkspaceWorkflowItem({
					workspaceId: workspaceId,
					displayName: displayName,
					inputText: text,
				});
				if (!workflowItem) throw new Error("WorkflowItem not created");
			} else {
				workflowItem = await client.createWorkspaceWorkflowItem({
					workspaceId: workspaceId,
					contentId: contentId,
					displayName: displayName,
				});
				if (!workflowItem) throw new Error("WorkflowItem not created");
			}

			if (workflowItem) {
				upsertWsDraft(db, workflowItem);
			}

			return workflowItem;
		},
		[client, db],
	);

	const deleteWorkflowItem = React.useCallback(
		async (workspaceId: string, workflowItemId: string) => {
			await client.deleteWorkspaceWorkflowItem(workspaceId, workflowItemId);

			await deleteWsDraft(db, workflowItemId);
		},
		[client, db],
	);

	const createScheduledWorkflowItem = React.useCallback(
		async ({
			workspaceId,
			workflowItemId,
			feedIds,
			scheduledDate,
			scheduledCron,
			timezone,
		}: {
			workspaceId: string;
			workflowItemId: string;
			feedIds: Array<string>;
			scheduledDate?: string;
			scheduledCron?: string;
			timezone?: string;
		}) => {
			const workspaceMembershipIds = workspaceMemberships?.map((wm) => wm.id);

			let props = {
				workspaceId: workspaceId,
				feedIds,
				workspaceMembershipIds,
				workflowItemId: workflowItemId,
			};

			if (scheduledDate) {
				props = { ...props, date: scheduledDate };
			}

			if (scheduledCron) {
				props = { ...props, cron: scheduledCron, timezone: timezone };
			}

			const scheduledBroadcast = await client.createScheduledBroadcast(props);

			if (!scheduledBroadcast)
				throw new Error("scheduledBroadcast not created");

			console.log("created broadcast", scheduledBroadcast);
			if (scheduledBroadcast) {
				if (scheduledBroadcast?.scheduleTrigger) {
					upsertWsScheduleTrigger(db, scheduledBroadcast?.scheduleTrigger);
				}

				if (scheduledBroadcast?.broadcastAction) {
					upsertWsBroadcastAction(db, scheduledBroadcast?.broadcastAction);
				}

				if (scheduledBroadcast?.broadcastRecipients) {
					for (const broadcastRecipient of scheduledBroadcast?.broadcastRecipients ||
						[]) {
						upsertWsBroadcastRecipient(db, broadcastRecipient);
					}
				}
			}

			return scheduledBroadcast;
		},
		[client, db, workspaceMemberships],
	);

	const updateScheduledWorkflowItem = React.useCallback(
		async ({
			workspaceId,
			scheduleId,
			feedIds,
			scheduledDate,
			scheduledCron,
			timezone,
		}: {
			workspaceId: string;
			scheduleId: string;
			feedIds: Array<string>;
			scheduledDate?: string;
			scheduledCron?: string;
			timezone?: string;
		}) => {
			const workspaceMembershipIds = workspaceMemberships?.map((wm) => wm.id);

			let props = {
				workspaceId: workspaceId,
				feedIds,
				workspaceMembershipIds,
				scheduleId: scheduleId,
			};

			if (scheduledDate) {
				props = { ...props, date: scheduledDate };
			}

			if (scheduledCron) {
				props = { ...props, cron: scheduledCron, timezone: timezone };
			}

			const scheduledBroadcast = await client.updateScheduledBroadcast(props);

			if (!scheduledBroadcast)
				throw new Error("scheduledBroadcast not updated");

			console.log("updated broadcast", scheduledBroadcast);
			if (scheduledBroadcast) {
				if (scheduledBroadcast?.scheduleTrigger) {
					upsertWsScheduleTrigger(db, scheduledBroadcast?.scheduleTrigger);
				}

				if (scheduledBroadcast?.broadcastAction) {
					upsertWsBroadcastAction(db, scheduledBroadcast?.broadcastAction);
				}

				if (scheduledBroadcast?.broadcastRecipients) {
					for (const broadcastRecipient of scheduledBroadcast?.broadcastRecipients ||
						[]) {
						upsertWsBroadcastRecipient(db, broadcastRecipient);
					}
				}
			}
		},
		[client, db, workspaceMemberships],
	);

	const publishToWorkspaceFeed = React.useCallback(
		async ({
			workspaceId,
			feedId,
			itemId,
			contentId,
			url,
			text,
		}: {
			workspaceId: string;
			feedId: string;
			itemId?: string;
			contentId: string;
			url?: string;
			text?: string;
		}) => {
			const item = await client.publishToWorkspaceFeed({
				workspaceId,
				feedId,
				itemId,
				contentId,
				url,
				text,
			});
			upsertWsItem(db, item);
			return item;
		},
		[client, db],
	);

	const publishBroadcast = React.useCallback(
		async ({
			workspaceId,
			contentId,
			workspaceMembershipIds,
			feedIds,
			inputText,
		}: {
			workspaceId: string;
			contentId: string;
			workspaceMembershipIds?: string[];
			feedIds?: string[];
			inputText?: string;
		}) => {
			const broadcast = await client.publishBroadcast({
				workspaceId,
				contentId,
				workspaceMembershipIds,
				feedIds,
				inputText,
			});

			return broadcast;
		},
		[client, db],
	);

	const actionState: ActionState = {
		accountEvent,
		addWorkspaceMembersToFeed,
		createAppPhoneNumber,
		createScheduledWorkflowItem,
		createWorkflowItem,
		createWorkspace,
		createWorkspaceFeed,
		createWorkspaceDm,
		deleteItem,
		deleteWorkflowItem,
		demoteOwnerToMember,
		muteUnmute,
		promoteMemberToOwner,
		publishToWorkspaceFeed,
		publishBroadcast,
		removeFeed,
		removeMember,
		removeMemberFromFeed,
		sendWorkspaceInvites,
		updateMemberRole,
		updateWorkspace,
		updateWorkspaceFeed,
		updateScheduledWorkflowItem,
	};

	return (
		<ActionContext.Provider value={actionState}>
			{children}
		</ActionContext.Provider>
	);
};
export default ActionsProvider;
