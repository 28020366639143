import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button/Button";
import IconButton from "@mui/material/IconButton/IconButton";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useTheme } from "@mui/material";
import React from "react";
import { pwaInfo } from "virtual:pwa-info";
import { useRegisterSW } from "virtual:pwa-register/react";

// eslint-disable-next-line no-console
if (pwaInfo) {
	console.log(pwaInfo);
}

export default function ReloadPrompt() {
	const theme = useTheme();
	// replaced dynamically
	const buildDate = "__DATE__";
	// replaced dyanmicaly
	const reloadSW = "__RELOAD_SW__";
	// 60 seconds per update on prod, 10 seconds in dev
	const intervalMS = (import.meta.env.PROD ? 60 : 10) * 1000;

	const {
		needRefresh: [needRefresh, setNeedRefresh],
		updateServiceWorker,
	} = useRegisterSW({
		onRegisteredSW(swUrl, r) {
			// eslint-disable-next-line no-console
			console.log(`Service Worker at: ${swUrl}`);
			// @ts-expect-error just ignore
			if (reloadSW === "true") {
				// eslint-disable-next-line chai-friendly/no-unused-expressions
				r &&
					setInterval(async () => {
						// eslint-disable-next-line no-console
						console.log("Checking for sw update");
						if (!(!r.installing && navigator)) return;

						if ("connection" in navigator && !navigator.onLine) return;

						const resp = await fetch(swUrl, {
							cache: "no-store",
							headers: {
								cache: "no-store",
								"cache-control": "no-cache",
							},
						});

						if (resp?.status === 200) await r.update();
					}, intervalMS); /* 10s check */
			} else {
				// eslint-disable-next-line prefer-template,no-console
				console.log("SW Registered: " + r);
			}
		},
		onRegisterError(error) {
			// eslint-disable-next-line no-console
			console.log("SW registration error", error);
		},
	});

	const refresh = () => {
		updateServiceWorker(true);
	};

	const close = () => {
		setNeedRefresh(false);
	};

	const action = (
		<React.Fragment>
			{needRefresh && (
				<Button color="inherit" size="small" onClick={() => refresh()}>
					Reload
				</Button>
			)}
			<IconButton
				aria-label="close"
				color="primary"
				sx={{ p: 0.5 }}
				onClick={() => close()}
			>
				<CloseIcon role="img" />
			</IconButton>
		</React.Fragment>
	);

	const message = (
		<React.Fragment>
			<span>New version available, click on reload button to update.</span>
		</React.Fragment>
	);

	const messageProps = {
		sx: {
			backgroundColor: theme.palette.brand.primary.dark,
			color: theme.palette.primary.main,
		},
	};

	return (
		<React.Fragment>
			<Snackbar
				open={needRefresh}
				action={action}
				message={message}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ bottom: { xs: "148px" } }}
				ContentProps={messageProps}
				onClose={() => close()}
			/>
		</React.Fragment>
	);
}
