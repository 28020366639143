import { Box, Button, Typography, useTheme } from "@mui/material";
import TextMessageInput from "@/components/TextMessageInput";
import TemplateIcon from "@/components/Icons/TemplateIcon";
import PaperAirplane from "@/components/Icons/PaperAirplane";
import cuid from "cuid";
import { useContext, useEffect, useState } from "react";
import { ActionContext } from "@/models/ActionsProvider";
import { useParams } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

interface Props {
	ttsFormText: string;
	loadTemplateModal: (value: boolean) => void;
	setTTSTextValue: (value: string) => void;
	closeTTSModal: () => void;
}
export default function TTSForm({
	ttsFormText,
	loadTemplateModal,
	setTTSTextValue,
	closeTTSModal,
}: Props) {
	const { showTemplates } = useFlags();
	const theme = useTheme();
	const params = useParams();
	const { publishToWorkspaceFeed } = useContext(ActionContext);
	const [disabled, setDisabled] = useState<boolean>(false);
	const [textInputValue, setTextInputValue] = useState<string>("");
	const [linksValid, setLinksValid] = useState<boolean>(true);
	const [textValid, setTextValid] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const workspaceId = params?.workspaceId;
	const feedId = params?.feedId;

	useEffect(() => {
		setTextInputValue(() => ttsFormText);
		setTextValid(() => true);
	}, [ttsFormText]);

	const reset = () => {
		setLoading(false);
		setTextInputValue("");
		setTextValid(false);
		setLinksValid(true);
		setDisabled(false);
	};
	const sendTextMessage = async (e) => {
		e.preventDefault();
		if (textValid && linksValid) {
			try {
				setDisabled(true);
				setLoading(true);
				const contentId = cuid();

				await publishToWorkspaceFeed({
					workspaceId,
					feedId,
					contentId,
					text: textInputValue,
				});
			} catch (error) {
				return Promise.reject(error);
			} finally {
				closeTTSModal();
				reset();
			}
		}
	};
	const handleTextInput = (text: string) => {
		setTTSTextValue(text);
		setTextInputValue(text);
	};
	const handleTextValidation = (valid: boolean) => {
		setTextValid(valid);
	};
	const handleLinksValidation = (valid: boolean) => {
		setLinksValid(valid);
	};
	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					borderBottom: `solid 1px ${theme.palette.secondary.main}`,
					p: 2,
				}}
			>
				<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
					New Text to Speech Message
				</Typography>
			</Box>
			<Box component="form" sx={{ p: 2 }} onSubmit={(e) => sendTextMessage(e)}>
				<Box sx={{ mb: 2 }}>
					<TextMessageInput
						className="text-input-v2"
						rows={15}
						placeholder="Start a typing your message"
						disabled={disabled}
						textInputValue={textInputValue}
						linksValid={linksValid}
						textInputHandler={handleTextInput}
						validateTextHandler={handleTextValidation}
						validateLinksHandler={handleLinksValidation}
					/>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
					<Box sx={{ flexGrow: 1, display: "flex" }}>
						{showTemplates && (
							<Button
								type="button"
								onClick={() => loadTemplateModal(true)}
								sx={{
									display: "flex",
									background: theme.palette.secondary.main,
									alignItems: "center",
									justifyItems: "center",
									borderRadius: 6,
									width: "auto",
									px: 2,
								}}
							>
								<TemplateIcon />{" "}
								<Box sx={{ ml: 1, textTransform: "none" }}>Use a template</Box>
							</Button>
						)}
					</Box>
					<Box>
						<Button
							type="submit"
							disabled={textInputValue?.length === 0 || disabled}
							sx={{
								background: theme.palette.secondary.main,
								display: "flex",
								alignItems: "center",
								justifyItems: "center",
								borderRadius: "100%",
								width: "44px",
								height: "44px",
							}}
							aria-label="Send TTS Message"
						>
							<PaperAirplane
								style={{
									fill:
										textInputValue?.length === 0
											? theme.palette.neutral.main
											: theme.palette.primary.main,
									display: "flex",
									width: "20px",
									height: "20px",
								}}
							/>
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
