import { TrackingContext } from "@/models/TrackingStateProvider";
import { SetStateType } from "@/utils";
import { useMediaQuery, useTheme } from "@mui/material";
import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";

export type UxState = {
	bothNavOpen?: boolean;
	channelModalOpen?: boolean;
	channelUsersModalOpen?: boolean;
	editChannelSettings?: boolean;
	editWorkspaceSettings?: boolean;
	inviteModalOpen?: boolean;
	navTitle?: string;
	isMdUp?: boolean;
	isSmUp?: boolean;
	leftNavOpen?: boolean;
	rightNavOpen?: boolean;
	channelModalNew?: boolean;
	recurringScheduleModalOpen?: boolean;
	scheduleMessageModalOpen?: boolean;
	setBothNavOpen: SetStateType<boolean>;
	setEditChannelSettings: SetStateType<boolean>;
	setEditWorkspaceSettings: SetStateType<boolean>;
	setNavTitle: SetStateType<string>;
	setInviteModalOpen: SetStateType<boolean>;
	setLeftNavOpen: SetStateType<boolean>;
	setRightNavOpen: SetStateType<boolean>;
	setChannelModalOpen: SetStateType<boolean>;
	setChannelUsersModalOpen: SetStateType<boolean>;
	setChannelModalNew: SetStateType<boolean>;
	setRecurringScheduleModalOpen: SetStateType<boolean>;
	setScheduleMessageModalOpen: SetStateType<boolean>;
	setWorkspaceModalOpen: SetStateType<boolean>;
	toggleInviteModal: () => void;
	toggleLeftNav: () => void;
	toggleRightNav: () => void;
	toggleChannelModal: () => void;
	toggleChannelUsersModal: () => void;
	toggleWorkspaceModal: () => void;
	workspaceModalOpen?: boolean;

	toggleDmModalOpen?: () => void;
	setDmModalOpen?: SetStateType<boolean>;
	dmModalOpen?: boolean;
};

//create a context, with createContext api
export const UxContext = createContext<UxState>({
	setBothNavOpen: () => {},
	setEditChannelSettings: () => {},
	setEditWorkspaceSettings: () => {},
	setNavTitle: () => {},
	setInviteModalOpen: () => {},
	setLeftNavOpen: () => {},
	setRightNavOpen: () => {},
	setChannelModalOpen: () => {},
	setChannelModalNew: () => {},
	setChannelUsersModalOpen: () => {},
	setRecurringScheduleModalOpen: () => {},
	setScheduleMessageModalOpen: () => {},
	setWorkspaceModalOpen: () => {},
	toggleInviteModal: () => {},
	toggleLeftNav: () => {},
	toggleRightNav: () => {},
	toggleChannelModal: () => {},
	toggleChannelUsersModal: () => {},
	toggleWorkspaceModal: () => {},
});

type Props = {
	children: ReactNode | ReactNode[];
};

const UxStateProvider = ({ children }: Props) => {
	const { ampli } = useContext(TrackingContext);
	const [leftNavOpen, setLeftNavOpen] = useState<boolean>(false);
	const [rightNavOpen, setRightNavOpen] = useState<boolean>(false);
	const [bothNavOpen, setBothNavOpen] = useState(leftNavOpen && rightNavOpen);
	const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
	const [channelModalOpen, setChannelModalOpen] = useState<boolean>(false);
	const [channelModalNew, setChannelModalNew] = useState<boolean>(false);
	const [channelUsersModalOpen, setChannelUsersModalOpen] =
		useState<boolean>(false);
	const [scheduleMessageModalOpen, setScheduleMessageModalOpen] =
		useState<boolean>(false);
	const [workspaceModalOpen, setWorkspaceModalOpen] = useState<boolean>(false);
	const [editChannelSettings, setEditChannelSettings] =
		useState<boolean>(false);
	const [editWorkspaceSettings, setEditWorkspaceSettings] =
		useState<boolean>(false);
	const [navTitle, setNavTitle] = useState<string>("");
	const [dmModalOpen, setDmModalOpen] = useState<boolean>(false);
	const [recurringScheduleModalOpen, setRecurringScheduleModalOpen] =
		useState<boolean>(false);

	const theme = useTheme();
	const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
	const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

	const toggleLeftNav = () => {
		if (!leftNavOpen) {
			ampli.feedsListOpen();
		}
		if (!leftNavOpen && rightNavOpen) {
			if (!isMdUp) {
				setRightNavOpen(false);
			}
		}
		setLeftNavOpen(!leftNavOpen);
	};

	const toggleRightNav = () => {
		if (!rightNavOpen) {
			ampli.membersListOpen();
		}
		if (!rightNavOpen && leftNavOpen) {
			if (!isMdUp) {
				setLeftNavOpen(false);
			}
		}
		setRightNavOpen(!rightNavOpen);
	};

	useEffect(() => {
		// on screen size changes from large to medium, lets close both navs and only allow one open at a time
		if (!isMdUp) {
			setLeftNavOpen(false);
			setRightNavOpen(false);
		}
	}, [isMdUp]);

	useEffect(() => {
		const shouldBoth = leftNavOpen && rightNavOpen;
		if (shouldBoth !== bothNavOpen) {
			setBothNavOpen(shouldBoth);
		}
	}, [bothNavOpen, leftNavOpen, rightNavOpen]);

	useEffect(() => {
		const shouldBoth = leftNavOpen && rightNavOpen;
		if (shouldBoth !== bothNavOpen) {
			setBothNavOpen(shouldBoth);
		}
	}, [bothNavOpen, leftNavOpen, rightNavOpen]);

	const toggleInviteModal = () => {
		setInviteModalOpen(!inviteModalOpen);
	};

	const toggleChannelModal = () => {
		setChannelModalNew(() => true);
		setChannelModalOpen(!channelModalOpen);
	};

	const toggleChannelUsersModal = () => {
		setChannelUsersModalOpen(!channelUsersModalOpen);
	};

	const toggleWorkspaceModal = () => {
		setWorkspaceModalOpen(!workspaceModalOpen);
	};

	const uxState: UxState = {
		bothNavOpen,
		channelModalOpen,
		channelUsersModalOpen,
		editChannelSettings,
		editWorkspaceSettings,
		inviteModalOpen,
		navTitle,
		isMdUp,
		isSmUp,
		leftNavOpen,
		rightNavOpen,
		recurringScheduleModalOpen,
		scheduleMessageModalOpen,
		setBothNavOpen,
		setEditChannelSettings,
		setEditWorkspaceSettings,
		setInviteModalOpen,
		setLeftNavOpen,
		setRightNavOpen,
		setChannelModalOpen,
		setChannelModalNew,
		channelModalNew,
		setChannelUsersModalOpen,
		setNavTitle,
		setRecurringScheduleModalOpen,
		setScheduleMessageModalOpen,
		setWorkspaceModalOpen,
		toggleInviteModal,
		toggleLeftNav,
		toggleRightNav,
		toggleChannelModal,
		toggleChannelUsersModal,
		toggleWorkspaceModal,
		workspaceModalOpen,
		setDmModalOpen,
		toggleDmModalOpen: () => {
			setDmModalOpen(!dmModalOpen);
		},
		dmModalOpen,
	};

	return <UxContext.Provider value={uxState}>{children}</UxContext.Provider>;
};

export default UxStateProvider;
