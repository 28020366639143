import { SVGProps } from "react";
export default function ChatBubble({ ...props }: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="33"
			height="32"
			viewBox="0 0 33 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M4.0498 6.18113C4.0498 4.61054 5.25912 3.27637 6.7998 3.27637H25.1998C26.7405 3.27637 27.9498 4.61054 27.9498 6.18113V20.9097C27.9498 22.4803 26.7405 23.8145 25.1998 23.8145H9.29484L4.0498 29.4125V6.18113Z"
				fill="white"
			/>
		</svg>
	);
}
