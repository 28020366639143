import { Item } from "@/generated/client";
import { DataContext } from "@/models/DataProvider";
import { format } from "date-fns";
import { useContext, useMemo } from "react";

export interface FullItem {
	id: string;
	feedId: string;
	contentId: string;
	groupId?: string;
	deletedAt?: string;
	createdAt: string;
	accountId: string;
	status: string;
	mine: boolean;
	label: string;
	key: string;
	unread: boolean;
	feedItemIndex: number;
	lastActiveItem: boolean;
	sticky: boolean;
	stickyLabel: string;
	lastActiveItemIdIsMine: boolean;
	shortDateStr: string;
}

export const FeedMap = ({ items }: { items: Item[] }): FullItem[] => {
	const { myAccount } = useContext(DataContext);
	const today = useMemo(() => new Date(), []);

	const { todayStr, yesterdayStr } = useMemo(() => {
		const todayStr = format(today, "MMddyyyy");
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		const yesterdayStr = format(yesterday, "MMddyyyy");

		return { todayStr, yesterdayStr };
	}, [today]);

	return useMemo(() => {
		const dateFormat = (publishedDate, today) => {
			const date = new Date(publishedDate);
			const dateFormat =
				date.getFullYear() === today.getFullYear()
					? "EEE, MMM d"
					: "EEE, MMM d, yyyy";
			const shortDateStr = format(date, "MMddyyyy");
			const formattedDate = format(date, dateFormat);
			const formattedTime = format(date, "h:mm aa");
			return {
				shortDateStr,
				formattedDate,
				formattedTime,
			};
		};

		const getStickyDateLabel = (date, formattedDate) => {
			let label: string;
			switch (date) {
				case todayStr:
					label = "Today";
					break;
				case yesterdayStr:
					label = "Yesterday";
					break;
				default:
					label = formattedDate;
					break;
			}

			return label;
		};

		return items?.length > 0
			? items.map((feedItem, index): FullItem => {
					const { shortDateStr, formattedTime } = dateFormat(
						feedItem.createdAt,
						today,
					);

					const firstTodayIndex = items.findIndex(
						(cfItem) =>
							dateFormat(cfItem.createdAt, today)?.shortDateStr ===
							shortDateStr,
					);

					const firstItem = dateFormat(items[firstTodayIndex].createdAt, today);

					const sticky = firstTodayIndex === index;
					const dailyLabel = getStickyDateLabel(
						shortDateStr,
						firstItem.formattedDate,
					);
					const stickyLabel = sticky ? dailyLabel : "";

					const lastActiveItem = items[items.length - 1]?.id === feedItem.id;

					const lastActiveItemIdIsMine =
						lastActiveItem && feedItem?.accountId === myAccount?.id;

					return {
						id: feedItem.id,
						feedId: feedItem.feedId,
						contentId: feedItem.contentId,
						groupId: feedItem.groupId,
						deletedAt: feedItem.deletedAt,
						createdAt: feedItem.createdAt,
						accountId: feedItem.accountId,
						status: feedItem.status,
						mine: feedItem.accountId === myAccount?.id,
						label: `${dailyLabel} @ ${formattedTime}`,
						key: feedItem.createdAt,
						sticky,
						stickyLabel,
						feedItemIndex: index++,
						lastActiveItem,
						lastActiveItemIdIsMine,
						shortDateStr,
						unread: false,
						...feedItem,
					};
			  })
			: [];
	}, [items, myAccount?.id, today, todayStr, yesterdayStr]);
};
