import { FullInput } from "@/components/Utils";
import {
	Box,
	Button,
	Checkbox,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { VList } from "virtua";
import { useLiveQuery } from "electric-sql/react";
import { ActionContext } from "@/models/ActionsProvider";
import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { UppyContext } from "@/models/UppyContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { useElectric } from "@/electric/ElectricWrapper";
import { getDmChannels } from "@/data/liveQueries/dmChannels";
import Fuse from "fuse.js";
import cuid from "cuid";

export default function ScheduleMessageChannels() {
	const {
		goToNextStep,
		scheduledText,
		selectedChannels,
		setSelectedChannels,
		resetAll,
	} = useContext(SchedulingContext);
	const { setScheduleMessageModalOpen } = useContext(UxContext);
	const { publishToWorkspaceFeed, publishBroadcast } =
		useContext(ActionContext);
	const { removeMetaData, uppyClient, addedFile } = useContext(UppyContext);
	const [sending, setSending] = useState<boolean>(false);
	const { db } = useElectric();

	const [searchField, setSearchField] = useState<string>("");

	const params = useParams();
	const workspaceId = params?.workspaceId;

	const theme = useTheme();
	const { results: channels } = useLiveQuery(() => {
		if (!workspaceId) return;
		return db.feed.liveMany({
			where: {
				workspaceId,
			},
			orderBy: {
				title: "asc",
			},
		});
	}, [workspaceId]);

	const { dmChannels } = getDmChannels(useLiveQuery, db, { channels });

	const mappedChannels = channels
		?.map((channel) => {
			if (channel.isDm === 1 && !channel?.title) {
				const dm = dmChannels?.find((d) => d.feedId === channel.id);
				return {
					...channel,
					title: dm?.name || dm?.email,
				};
			}
			return channel;
		})
		.sort((a, b) => a?.title?.localeCompare(b?.title));

	// fuse search engine
	const fuse = new Fuse(mappedChannels, {
		// https://www.fusejs.io/examples.html#extended-search rules
		useExtendedSearch: true,
		keys: ["title", "id"],
	});

	const searchedChannels = useMemo(() => {
		if (searchField) {
			// `'` denotes includes this search text
			return fuse.search(`'${searchField}`)?.map((result) => result.item);
		}
		return mappedChannels;
	}, [searchField, mappedChannels, fuse]);

	const handleSendNow = async () => {
		try {
			setSending(true);
			let contentId = cuid();
			if (addedFile) {
				contentId = addedFile?.meta?.contentId;
				removeMetaData("feedId");
				await uppyClient.upload();
			}

			await publishBroadcast({
				workspaceId,
				contentId,
				feedIds: selectedChannels,
				inputText: scheduledText,
			});
		} catch (error) {
			return Promise.reject(error);
		} finally {
			setSending(false);
			setSelectedChannels([]);
			resetAll();
			setScheduleMessageModalOpen(false);
		}
	};

	const handleToggle = (id: string) => () => {
		const currentIndex = selectedChannels?.indexOf(id);
		const newChecked = [...selectedChannels];

		if (currentIndex === -1) {
			newChecked.push(id);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setSelectedChannels(newChecked);
	};

	const secondaryAction = (channel) => {
		return (
			<Checkbox
				edge="end"
				onChange={handleToggle(channel?.id)}
				checked={selectedChannels?.indexOf(channel?.id) !== -1}
				inputProps={{ "aria-labelledby": channel?.id }}
				color="primary"
			/>
		);
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<FullInput
					id="channel-name"
					placeholder="Search channels"
					value={searchField}
					callback={(e) => setSearchField(e.target.value)}
				/>
			</Box>
			<VList
				style={{
					width: "100%",
					height: "100%",
					maxHeight: 350,
					borderRadius: 8,
					border: `1.5px solid ${theme.palette.secondary.light}`,
					boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
					position: "relative",
					background: theme.palette.secondary.dark,
					overflow: searchedChannels?.length > 0 ? "auto" : "hidden",
					padding: "8px 10px",
				}}
			>
				{searchedChannels?.length > 0 ? (
					searchedChannels?.map((channel, index) => (
						<ListItem
							key={channel.id}
							secondaryAction={secondaryAction(channel)}
							disablePadding
						>
							<ListItemButton onClick={handleToggle(channel?.id)} disableRipple>
								<ListItemText id={channel?.id} primary={channel?.title} />
							</ListItemButton>
						</ListItem>
					))
				) : (
					<ListItem
						key={"no-results"}
						disablePadding
						sx={{ textAlign: "center" }}
					>
						<ListItemText
							primary={
								<Typography sx={{ fontWeight: 700 }}>
									No channels found
								</Typography>
							}
						/>
					</ListItem>
				)}
			</VList>
			<Stack
				sx={{
					flexDirection: { xs: "column", sm: "row" },
					width: "100%",
					gap: 2,
				}}
			>
				<LoadingButton
					loading={sending}
					variant="outlined"
					sx={{ order: { xs: 1, sm: 0 } }}
					onClick={handleSendNow}
					disabled={selectedChannels?.length === 0}
				>
					Send Now
				</LoadingButton>
				<Button
					variant="contained"
					color="primary"
					sx={{ order: { xs: 0, sm: 1 } }}
					onClick={goToNextStep}
					disabled={selectedChannels?.length === 0 || sending}
				>
					Schedule for later
				</Button>
			</Stack>
		</>
	);
}
