import { TrackingContext } from "@/models/TrackingStateProvider";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext } from "react";
import { FeedContext } from "@/models/FeedContextProvider";
import FileUploadModal from "./FileUploadModal";
import { IconButtonProps } from "@mui/material";

export type UploadFileButtonProps = IconButtonProps & {};

export default function UploadFileButton({ ...props }: UploadFileButtonProps) {
	const { ampli } = useContext(TrackingContext);
	const { textAsInputModal, setFileUploadModal, setTextAsInputModal } =
		useContext(FeedContext);
	const { fileUploads } = useFlags();

	const toggleFileUpload = () => {
		ampli.fileUploadInvoke();
		setFileUploadModal((value) => !value);
	};

	if (!fileUploads || !fileUploads?.enabled) return null;

	return (
		<>
			<FileUploadModal />
			<IconButton
				color={props.color}
				onClick={toggleFileUpload}
				aria-label="Upload File"
				{...props}
			>
				<AddIcon role="presentation" />
			</IconButton>
		</>
	);
}
