import ModalForm from "@/elements/ModalForm";
import { TrackingContext } from "@/models/TrackingStateProvider";
import * as Icons from "@mui/icons-material";
import {
	Box,
	Button,
	Checkbox,
	Stack,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCallback, useContext, useEffect, useState } from "react";
import { UxContext } from "@/models/UxStateProvider";
import { StyledChipsInput } from "@/components/Utils";
import { ActionContext } from "@/models/ActionsProvider";
import { useParams } from "react-router-dom";
import { PhoneService } from "@/utils";
import isEmail from "validator/lib/isEmail";
import { useLiveQuery } from "electric-sql/react";
import { useElectric } from "@/electric/ElectricWrapper";

export default function InviteToWorkspaceModal() {
	const theme = useTheme();
	const { db } = useElectric();
	const { ampli } = useContext(TrackingContext);
	const { sendWorkspaceInvites, addWorkspaceMembersToFeed } =
		useContext(ActionContext);
	const { inviteModalOpen, setInviteModalOpen } = useContext(UxContext);
	const [sendEmail, setSendEmail] = useState<boolean>(true);
	const params = useParams();
	const workspaceId = params.workspaceId;
	const feedId = params.feedId;

	const phoneService = PhoneService();

	const modalPages = [];
	const [modalPageNum, setModalPageNum] = useState(0);

	const { results: workspace } = useLiveQuery(
		db.workspace.liveUnique({
			where: {
				id: workspaceId,
			},
		}),
	);

	const nextModalPage = () => {
		if (modalPageNum < modalPages.length - 1) {
			setModalPageNum(modalPageNum + 1);
		}
	};

	const prevModalPage = () => {
		if (modalPageNum > 0) {
			setModalPageNum(modalPageNum - 1);
		}
	};

	const [open, setOpen] = useState(inviteModalOpen);
	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState<string>();
	const [processing, setProcessing] = useState(false);
	const [contacts, setContacts] = useState<string[]>([]);
	const defaultHelperText = "Press enter to complete your entry.";
	const [contactHelperText, setContactHelperText] =
		useState<string>(defaultHelperText);
	const invitesCount = contacts?.length;
	const emails = contacts?.filter((contact) => isEmail(contact));
	const phoneNumbers = contacts
		?.filter((contact) => phoneService?.isValidNumber(contact))
		.map((number) => {
			//if a number doesn't contain a country code, then default to US
			if (!number.includes("+")) {
				return `+1${number}`;
			}
			return number;
		});

	const mailErrorText = "Something went wrong, please try again later.";

	const toggleSendEmail = useCallback(() => {
		setSendEmail((prev) => {
			const toggled = !prev;
			if (toggled) {
				ampli.activateEmailSend();
			} else {
				ampli.deactivateEmailSend();
			}
			return toggled;
		});
	}, [ampli]);

	const handleClose = useCallback(() => {
		setInviteModalOpen(false);
		setOpen(false);
		setModalPageNum(0);
		setProcessing(false);
		setContacts([]);
		setSendEmail(true);
	}, [setInviteModalOpen]);

	const handleContactsChange = (values: string[]) => {
		console.log("** handleContactsChange", values);
		setContacts(values);
	};

	const validateContact = (contact: string) => {
		if (isEmail(contact) || phoneService.isValidNumber(contact)) {
			// valid input
			return { isError: false, textError: "" };
		} else {
			// invalid input
			return { isError: true, textError: "Invalid email or phone input" };
		}
	};

	const handleInviteClick = async () => {
		if (contacts.length) {
			ampli.inviteWorkspaceUser();

			setProcessing(true);
			setError(false);
			setErrorText("");
			try {
				const { workspaceMemberships } = await sendWorkspaceInvites(
					workspaceId,
					emails || [],
					phoneNumbers || [],
					sendEmail,
				);
				// if were open on a feedId then invite to the channel as well
				if (feedId && workspaceMemberships) {
					const membershipIds = workspaceMemberships.map(
						(membership) => membership.id,
					);
					await addWorkspaceMembersToFeed(workspaceId, feedId, membershipIds);
				}
				console.warn("** mail/invite/success");
				nextModalPage();
				setProcessing(false);
				setContacts([]);
				setSendEmail(true);
			} catch (e) {
				console.warn("** ERR ERR mail/invite/click", e);
				setError(true);
				setErrorText(mailErrorText);
				setProcessing(false);
			}
		}
	};

	const handleContactHelperText = useCallback(
		(node) => {
			if (invitesCount > 0 && node?.value === "") {
				setContactHelperText("Double click to edit a contact.");
			} else {
				setContactHelperText(defaultHelperText);
			}
		},
		[invitesCount],
	);

	useEffect(() => {
		if (inviteModalOpen !== open) {
			setOpen(inviteModalOpen);
		}
	}, [open, inviteModalOpen]);

	modalPages.push(
		// page 0
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
					fontWeight: 500,
					gap: 1.5,
					pt: { xs: 2, sm: 0 },
				}}
			>
				<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
					Invite new users to workspace
				</Typography>
				<Typography>
					Enter an email address or phone number to invite new users.
				</Typography>
			</Box>
			<Stack sx={{ width: "100%", gap: 0.75 }}>
				<StyledChipsInput
					inputRef={handleContactHelperText}
					placeholder="Type or paste emails or phone numbers..."
					aria-placeholder="Type or paste emails or phone numbers..."
					helperText={contactHelperText || ""}
					value={contacts}
					addOnWhichKey={["Enter", "Tab", ";", ","]}
					validate={validateContact}
					onChange={handleContactsChange}
					renderChip={(Component, key, props) => {
						return (
							<Component {...props} key={key} deleteIcon={<Icons.Close />} />
						);
					}}
					hideClearAll
					addOnBlur={true}
				/>
				<Stack
					sx={{
						display: "flex",
						alignItems: "center",
						flexDirection: "row",
						width: "100%",
						gap: 1,
					}}
				>
					<Checkbox
						edge="end"
						color="primary"
						checked={sendEmail}
						disabled={processing}
						onChange={toggleSendEmail}
						sx={{ pl: 0 }}
					/>
					<Typography>Send email invites to new users.</Typography>
				</Stack>
			</Stack>
			{error && (
				<Box
					sx={{
						textAlign: "center",
					}}
				>
					<Typography sx={{ color: theme.palette.error.contrastText }}>
						{errorText}
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", sm: "row" },
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
					gap: 2.5,
				}}
			>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => handleClose()}
					disabled={processing}
					aria-label="Cancel Invites"
					sx={{
						width: { xs: "100%", sm: "auto" },
						order: { xs: 1, sm: 0 },
						flexGrow: 1,
						flexBasis: "100%",
					}}
				>
					CANCEL
				</Button>
				<LoadingButton
					loading={processing}
					variant="contained"
					color="primary"
					onClick={handleInviteClick}
					sx={{
						width: { xs: "100%", sm: "auto" },
						order: { xs: 0, sm: 1 },
						flexGrow: 1,
						flexBasis: "100%",
					}}
					disabled={invitesCount === 0}
				>
					Invite {invitesCount} {invitesCount === 1 ? "User" : "Users"}
				</LoadingButton>
			</Box>
		</>,
		// page 1
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
					fontWeight: 500,
					gap: 1.5,
				}}
			>
				<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
					Invitation Successful!
				</Typography>
				<Typography sx={{ textAlign: "center" }}>
					The people you invited will see <b>{workspace?.name}</b> when they
					login.
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", sm: "row" },
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
					gap: 2.5,
				}}
			>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => prevModalPage()}
					aria-label="Invite More"
					startIcon={<Icons.ArrowBack role="presentation" />}
					sx={{
						width: { xs: "100%", sm: "auto" },
						order: { xs: 1, sm: 0 },
						flexGrow: 1,
						flexBasis: "100%",
					}}
				>
					Invite More
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleClose}
					sx={{
						width: { xs: "100%", sm: "auto" },
						order: { xs: 0, sm: 1 },
						flexGrow: 1,
						flexBasis: "100%",
					}}
				>
					Done
				</Button>
			</Box>
		</>,
	);

	return (
		<ModalForm open={open} onClose={handleClose}>
			{modalPages[modalPageNum]}
		</ModalForm>
	);
}
