import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { UxContext } from "@/models/UxStateProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography, useTheme } from "@mui/material";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import { useLiveQuery } from "electric-sql/react";
import { useCallback, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectWorkspaceMemberships } from "./Workspace/memberships/selectWorkspaceMemberships";

export default function DmModal() {
	const { db } = useElectric();
	const navigate = useNavigate();
	const params = useParams();
	const workspaceId = params.workspaceId;

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [saving, setSaving] = useState(false);

	// console.log("MODAL RENDER", { selectedUsers });

	const { myAccountId } = useContext(DataContext);
	const { createWorkspaceDm } = useContext(ActionContext);

	const { setDmModalOpen, dmModalOpen, toggleInviteModal } =
		useContext(UxContext);

	const theme = useTheme();

	const handleClose = () => {
		setDmModalOpen(false);
	};

	const createDmAction = async () => {
		// disable the button while creating a feed
		setSaving(true);
		const newWorkspaceDm = await createWorkspaceDm(workspaceId, selectedUsers);
		handleClose();
		setSaving(false);
		if (newWorkspaceDm) {
			navigate(`/workspaces/${workspaceId}/feeds/${newWorkspaceDm.id}`);
		}
	};

	const { results: isWorkspaceAdmin } = useLiveQuery(
		db.workspace_membership.liveFirst({
			where: {
				AND: [
					{ accountId: myAccountId },
					{ workspaceId },
					{ role: { contains: "admin" } },
				],
			},
		}),
	);

	const handleWorkspaceInvite = useCallback(() => {
		handleClose();
		toggleInviteModal();
	}, [toggleInviteModal]);

	const handleSelectedUsers = (users: string[]) => {
		// best practice to use a handler as a prop to update a stateful value
		setSelectedUsers(users);
	};

	return (
		<>
			<ModalForm open={dmModalOpen} onClose={handleClose}>
				<>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: "100%",
							fontWeight: 500,
							gap: 1.5,
						}}
					>
						<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
							New Message
						</Typography>
					</Box>
					<SelectWorkspaceMemberships
						saving={saving}
						selectedUsers={selectedUsers}
						workspaceId={workspaceId}
						selectedUsersHandler={handleSelectedUsers}
						handleWorkspaceInvite={handleWorkspaceInvite}
						isWorkspaceAdmin={isWorkspaceAdmin}
						accountIdsToExclude={[myAccountId]}
						theme={theme}
						selectType="radio"
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", sm: "row" },
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
							gap: 2.5,
						}}
					>
						<LoadingButton
							loading={saving}
							disabled={selectedUsers.length !== 1}
							variant="contained"
							color="primary"
							onClick={createDmAction}
							sx={{
								width: { xs: "100%", sm: "auto" },
								order: { xs: 0, sm: 1 },
								flexGrow: 1,
								flexBasis: "100%",
								display: "flex",
								alignItems: "center",
							}}
						>
							<MapsUgcIcon sx={{ mr: 1 }} />
							Start New Message
						</LoadingButton>
					</Box>
				</>
			</ModalForm>
		</>
	);
}
