import React from "react";
import {
	Box,
	Button,
	List,
	ListItemButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { UxContext } from "@/models/UxStateProvider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function WorkspaceTools() {
	const { toggleLeftNav } = React.useContext(UxContext);
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const workspaceId = params?.workspaceId as string;
	const { showTemplates } = useFlags();
	const theme = useTheme();
	const navigateToUrl = (url: string) => {
		toggleLeftNav();
		navigate(url);
	};

	const isSelected = (key) => {
		return location?.pathname?.includes(key) || false;
	};

	const toolLinks = [
		{
			enabled: true,
			key: "workflows",
			text: "Scheduled Messages",
		},
		{
			enabled: showTemplates,
			key: "template-management",
			text: "Templates",
		},
	]
		.filter((item) => item.enabled)
		.map((item) => ({
			...item,
			url: `/workspaces/${workspaceId}/${item.key}`,
		}));

	return (
		<Stack
			sx={{
				flexGrow: 1,
				pb: 1.5,
				px: 2,
			}}
		>
			<Box
				key={`${workspaceId}-tools-controls`}
				sx={{
					display: "flex",
					position: "sticky",
					top: 0,
					pt: 2.5,
					zIndex: 1100,
					background: theme.palette.secondary.dark,
				}}
			>
				<Box
					sx={{
						width: "100%",
						gap: 1.5,
						fontWeight: 700,
						display: "fex",
						alignItems: "center",
						fontSize: "1.25rem",
						pb: 1,
					}}
				>
					Tools
				</Box>
			</Box>
			<List key={`${workspaceId}-tools`}>
				{toolLinks?.length > 0 &&
					toolLinks.map((item) => (
						<ListItemButton
							key={item.key}
							aria-label={`Navigation to ${item.text}`}
							onClick={() => navigateToUrl(item.url)}
							selected={isSelected(item.key)}
							disableRipple={true}
							sx={{
								width: "100%",
								borderRadius: "8px",
								color: theme.palette.neutral.main,
								"&.Mui-selected": {
									color: theme.palette.primary.main,
									background: theme.palette.secondary.main,
								},
								justifyContent: "space-between",
								wordBreak: "break-word",
								p: 2,
								fontSize: "1.125rem",
								fontWeight: 500,
							}}
						>
							<Box
								sx={{
									flexGrow: 1,
									textAlign: "left",
									justifyContent: "start",
								}}
							>
								{item.text}
							</Box>
							<Box
								sx={{
									flex: "0 1 auto",
									display: "flex",
									alignItems: "center",
								}}
							>
								<ChevronRightIcon />
							</Box>
						</ListItemButton>
					))}
			</List>
		</Stack>
	);
}
