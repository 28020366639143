import { Box, Button, Link, Stack, Typography, useTheme } from "@mui/material";
import { useCallback, useContext, useState, useEffect } from "react";
import {
	SchedulingContext,
	UploadType,
} from "@/models/SchedulingContextProvider";
import { UppyContext } from "@/models/UppyContextProvider";
import { isEmpty } from "validator";
import AddedFile from "../UploadFiles/AddedFile";
import HandleFileUpload from "../UploadFiles/HandleFileUpload";
import { FullInput } from "../Utils";
import { Base64 } from "js-base64";

export default function ScheduleMessageFileUpload() {
	const {
		addedFile,
		removeFiles,
		addMetaData,
		isAudioFileUpload,
		setIsScheduledUpload,
	} = useContext(UppyContext);
	const {
		displayName,
		setUploadType,
		goToNextStep,
		uploadDetails,
		setUploadDetails,
		setDisplayName,
	} = useContext(SchedulingContext);
	const theme = useTheme();

	const [title, setTitle] = useState<string>("");
	const [titleValid, setTitleValid] = useState<boolean>(false);
	const [descriptionValid, setDescriptionValid] = useState<boolean>(true);

	const titleCharLimit = 40;
	const descriptionCharLimit = 500;

	const verifyTitleInput = useCallback((title) => {
		if (
			!isEmpty(title, { ignore_whitespace: true }) &&
			title?.length <= titleCharLimit
		) {
			setTitleValid(true);
		} else {
			setTitleValid(false);
		}
	}, []);

	const verifyDescriptionInput = useCallback((description) => {
		if (description?.length <= descriptionCharLimit) {
			setDescriptionValid(true);
		} else {
			setDescriptionValid(false);
		}
	}, []);

	const handleTitleChange = useCallback(
		(title) => {
			setUploadDetails((prev) => ({ ...prev, title }));
			setDisplayName(title);
			verifyTitleInput(title);
		},
		[setUploadDetails, setDisplayName, verifyTitleInput],
	);

	const handleDescriptionChange = useCallback(
		(description) => {
			setUploadDetails((prev) => ({ ...prev, description }));
			verifyDescriptionInput(description);
		},
		[setUploadDetails, verifyDescriptionInput],
	);

	const handleContinue = () => {
		if (uploadDetails?.title && titleValid && descriptionValid) {
			// tusd requires metadata to be Base64 encoded, encode to support emojis, etc
			// https://tus.io/protocols/resumable-upload#upload-metadata
			addMetaData({
				title: Base64.encode(uploadDetails?.title),
				description: Base64.encode(uploadDetails?.description),
			});
		}
		setIsScheduledUpload(true);
		goToNextStep();
	};

	useEffect(() => {
		if (addedFile?.name) {
			handleTitleChange(addedFile?.name);
		}
	}, [addedFile?.name, handleTitleChange]);

	const HelperText = ({ characterLimit, field }) => {
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: 1,
					width: "100%",
				}}
			>
				<Typography sx={{ fontSize: "1rem" }}>
					{field?.length}/{characterLimit}
				</Typography>
			</Box>
		);
	};

	return (
		<Stack
			sx={{
				width: "100%",
				height: "100%",
				flexGrow: 1,
			}}
		>
			<Stack
				sx={{
					width: "100%",
					gap: 2,
					flexGrow: 1,
				}}
			>
				{addedFile ? (
					<Stack sx={{ gap: 1 }}>
						<AddedFile file={addedFile} />
						<Link
							component="button"
							sx={{ alignSelf: "flex-end" }}
							onClick={removeFiles}
						>
							Replace
						</Link>
						{isAudioFileUpload ? (
							<>
								<FullInput
									label="Title*"
									required
									value={uploadDetails?.title}
									callback={(e) => handleTitleChange(e.target.value)}
									error={uploadDetails?.title?.length > titleCharLimit}
									helperText={
										<HelperText
											characterLimit={titleCharLimit}
											field={uploadDetails?.title}
										/>
									}
								/>
								<FullInput
									label="Description"
									multiline
									minRows={5}
									value={uploadDetails?.description}
									callback={(e) => handleDescriptionChange(e.target.value)}
									error={
										uploadDetails?.description?.length > descriptionCharLimit
									}
									helperText={
										<HelperText
											characterLimit={descriptionCharLimit}
											field={uploadDetails?.description}
										/>
									}
								/>
							</>
						) : (
							<FullInput
								label={
									<Box>
										<Typography sx={{ fontWeight: 700 }}>
											Internal Name*
										</Typography>
										<Typography>
											Only you will be able to see this name.
										</Typography>
									</Box>
								}
								required
								value={displayName}
								callback={(e) => setDisplayName(e.target.value)}
								error={displayName?.length > titleCharLimit}
								helperText={
									<HelperText
										characterLimit={titleCharLimit}
										field={displayName}
									/>
								}
							/>
						)}
					</Stack>
				) : (
					<>
						<HandleFileUpload />
						<Typography
							sx={{
								alignSelf: "flex-end",
							}}
						>
							<Link
								component="button"
								style={{ color: theme.palette.primary.main }}
								onClick={() => setUploadType(UploadType.TTS)}
							>
								Enter text instead
							</Link>
						</Typography>
					</>
				)}

				<Button
					variant="contained"
					color="primary"
					sx={{ order: { xs: 0, sm: 1 } }}
					onClick={handleContinue}
					disabled={!addedFile || !titleValid}
				>
					Continue
				</Button>
			</Stack>
		</Stack>
	);
}
