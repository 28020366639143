import { useMemo, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, IconButton, useTheme, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import InitialsAvatar from "./Avatar";
import PlaybackIndicator from "./PlaybackIndicator/Index";
import { PlayArrow as PlayArrowIcon } from "@mui/icons-material";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import useSwipeToDismiss from "@/hooks/useSwipeToDismiss";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";

export interface ToastPlayerProps {
	onClose?: () => void;
}

export default function ToastPlayer({ onClose }: ToastPlayerProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	const { isSmUp } = useContext(UxContext);
	const { db } = useElectric();
	const { queuePlaying, playQueue, pauseQueue } = useContext(AudioAppContext);

	const { results: currentQueueItem } = useLiveQuery(
		db.audio_queue_item.liveFirst({
			orderBy: {
				createdAt: "asc",
			},
		}),
	);

	const { results: currentItem } = useLiveQuery(() => {
		if (!currentQueueItem?.itemId) return;
		return db.item.liveFirst({
			where: {
				id: currentQueueItem?.itemId,
			},
		});
	}, [currentQueueItem?.id]);

	const { results: currentItemsFeed } = useLiveQuery(() => {
		if (!currentItem?.feedId) return;
		return db.feed.liveFirst({
			where: {
				id: currentItem?.feedId,
			},
		});
	}, [currentItem?.feedId]);

	const { results: currentItemWorkspace } = useLiveQuery(() => {
		if (!currentItemsFeed?.workspaceId) return;
		return db.workspace.liveFirst({
			where: {
				id: currentItemsFeed?.workspaceId,
			},
		});
	}, [currentItemsFeed?.workspaceId]);

	const { results: userInfo } = useLiveQuery(() => {
		if (!currentItem?.accountId) return;
		return db.account.liveFirst({
			where: {
				id: currentItem?.accountId,
			},
		});
	}, [currentItem?.accountId]);

	const itemUrl = useMemo(() => {
		if (currentItem && currentItemsFeed) {
			return `/workspaces/${currentItemsFeed?.workspaceId}/feeds/${currentItemsFeed?.id}#${currentItem?.id}`;
		}
	}, [currentItem, currentItemsFeed]);

	const swipeHandlers = useSwipeToDismiss({
		onDismiss: () => {
			if (onClose) {
				onClose();
			}
		},
	});

	const handleTap = () => {
		if (itemUrl) {
			navigate(itemUrl);
		}
	};

	return (
		<>
			<Stack
				direction="row"
				sx={{
					maxWidth: 400,
					height: 60,
					width: "100%",
					p: 1.25,
					alignItems: "center",
					background: theme.palette.secondary.dark,
					border: `1px solid ${theme.palette.secondary.light}`,
					borderRadius: 2,
					boxShadow:
						"0px 4px 4px rgba(0, 0, 0, 0.25), 0px 8px 12px rgba(0, 0, 0, 0.15)",
					cursor: "pointer",
				}}
				{...swipeHandlers}
			>
				<Stack
					direction="row"
					sx={{
						alignItems: "center",
						justifyContent: "space-between",
						height: "100%",
						width: "100%",
					}}
				>
					<Stack
						direction="row"
						sx={{ alignItems: "center", width: "100%" }}
						onClick={handleTap}
					>
						<InitialsAvatar
							aria-label={userInfo?.name}
							initials={userInfo?.name?.[0]}
							color={userInfo?.avatarColor}
							sxProps={{
								fontSize: "20px",
								fontWeight: 700,
								width: 40,
								height: 40,
							}}
						/>
						<Stack px={2.25} py={0.25}>
							<Typography fontSize={18}>{userInfo?.name}</Typography>
							<Typography fontSize={12}>
								{currentItemWorkspace?.name} • {currentItemsFeed?.title}
							</Typography>
						</Stack>
					</Stack>
					{queuePlaying ? (
						<IconButton onClick={pauseQueue}>
							<PlaybackIndicator playing={queuePlaying} variant="secondary" />
						</IconButton>
					) : (
						<IconButton onClick={playQueue}>
							<PlayArrowIcon role="img" />
						</IconButton>
					)}
				</Stack>
			</Stack>
			{isSmUp && (
				<IconButton
					color="primary"
					onClick={onClose}
					aria-label="close toast player"
				>
					<Close role="img" />
				</IconButton>
			)}
		</>
	);
}
