import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { UxContext } from "@/models/UxStateProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { SelectWorkspaceMemberships } from "./memberships/selectWorkspaceMemberships";

export default function ChannelUsersModal() {
	const [saving, setSaving] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);

	const { db } = useElectric();

	const theme = useTheme();
	const params = useParams();

	const workspaceId = params?.workspaceId;
	const feedId = params?.feedId;

	const { myAccountId, currentFeedAccounts } = useContext(DataContext);

	const { addWorkspaceMembersToFeed } = useContext(ActionContext);

	const { channelUsersModalOpen, setChannelUsersModalOpen, toggleInviteModal } =
		useContext(UxContext);

	const { results: isWorkspaceAdmin } = useLiveQuery(() => {
		if (!workspaceId) return;
		return db.workspace_membership.liveFirst({
			where: {
				AND: [
					{ accountId: myAccountId },
					{ workspaceId },
					{ role: { contains: "admin" } },
				],
			},
		});
	}, [workspaceId]);

	const handleClose = useCallback(() => {
		setChannelUsersModalOpen(false);
	}, [setChannelUsersModalOpen]);

	const handleSelectedUsers = (users: string[]) => {
		// best practice to use a handler as a prop to update a stateful value
		setSelectedUsers(users);
	};

	const handleAddUsers = useCallback(async () => {
		try {
			setSaving(true);
			await addWorkspaceMembersToFeed(workspaceId, feedId, selectedUsers);
		} catch (e) {
			console.log(e);
		} finally {
			setSaving(false);
			setSelectedUsers([]);
			handleClose();
		}
	}, [feedId, workspaceId, selectedUsers]);

	const handleWorkspaceInvite = useCallback(() => {
		handleClose();
		toggleInviteModal();
	}, [toggleInviteModal]);

	const alreadyCheckedAccountIds = Array.from(
		currentFeedAccounts?.values() ?? [],
	).map((account) => account.id);

	return (
		<>
			<ModalForm open={channelUsersModalOpen} onClose={handleClose}>
				<>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: "100%",
							fontWeight: 500,
							gap: 1.5,
						}}
					>
						<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
							Add Users to this Channel
						</Typography>
						<Typography>Select workspace users below.</Typography>
					</Box>
					<SelectWorkspaceMemberships
						saving={saving}
						selectedUsers={selectedUsers}
						workspaceId={workspaceId}
						selectedUsersHandler={handleSelectedUsers}
						handleWorkspaceInvite={handleWorkspaceInvite}
						isWorkspaceAdmin={isWorkspaceAdmin}
						alreadyCheckedAccountIds={alreadyCheckedAccountIds}
						accountIdsToExclude={[myAccountId]}
						theme={theme}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", sm: "row" },
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
							gap: 2.5,
						}}
					>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => handleClose()}
							disabled={saving}
							sx={{
								width: { xs: "100%", sm: "auto" },
								order: { xs: 1, sm: 0 },
								flexGrow: 1,
								flexBasis: "100%",
							}}
						>
							CANCEL
						</Button>
						<LoadingButton
							loading={saving}
							disabled={selectedUsers.length === 0}
							variant="contained"
							color="primary"
							onClick={handleAddUsers}
							sx={{
								width: { xs: "100%", sm: "auto" },
								order: { xs: 0, sm: 1 },
								flexGrow: 1,
								flexBasis: "100%",
							}}
						>
							Add {selectedUsers.length || ""}
							{selectedUsers.length > 1 ? " users" : " user"}
						</LoadingButton>
					</Box>
				</>
			</ModalForm>
		</>
	);
}
