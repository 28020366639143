import { Stack, Typography, useTheme } from "@mui/material";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { useParams } from "react-router-dom";
import WorkflowScheduledItem from "./WorkflowScheduledItem";

export default function WorkflowScheduledItems() {
	const { db } = useElectric();
	const params = useParams();
	const workspaceId = params?.workspaceId;
	const theme = useTheme();

	const { results: workflowItems } = useLiveQuery(() => {
		if (!workspaceId) return;
		return db.workflow_item.liveMany({
			where: {
				workspaceId,
				deletedAt: null,
			},
			orderBy: {
				createdAt: "desc",
			},
		});
	}, [workspaceId]);

	const { results: broadcasts } = useLiveQuery(() => {
		if (!workflowItems) return;
		return db.broadcast_action.liveMany({
			where: {
				workflowItemId: { in: workflowItems?.map((wi) => wi?.id) },
			},
		});
	}, [workflowItems]);

	const { results: oneTimeSchedules } = useLiveQuery(() => {
		if (!broadcasts) return;
		return db.schedule_trigger.liveMany({
			where: {
				broadcastActionId: { in: broadcasts?.map((b) => b.id) },
				oneTimeSchedule: { not: null, gte: new Date()?.toISOString() },
				cronSchedule: null,
			},
		});
	}, [broadcasts]);

	const scheduledItems = workflowItems?.filter((wi) => {
		const broadcast = broadcasts?.find((b) => b?.workflowItemId === wi?.id);
		const schedule = oneTimeSchedules?.find(
			(ots) => ots?.broadcastActionId === broadcast?.id,
		);

		if (schedule) {
			return wi;
		}
	});

	return (
		<Stack sx={{ width: "100%", py: 5 }}>
			{scheduledItems?.length > 0 ? (
				<Stack sx={{ width: "100%", gap: 3 }}>
					{scheduledItems?.map((scheduledItem) => (
						<WorkflowScheduledItem
							key={`${scheduledItem?.id}`}
							workflowItem={scheduledItem}
						/>
					))}
				</Stack>
			) : (
				<Stack
					sx={{
						width: "100%",
						py: 1.5,
						px: 2,
						background: theme.palette.secondary.main,
						borderRadius: 1.5,
						alignItems: "center",
					}}
				>
					<Typography sx={{ fontWeight: 600 }}>
						No scheduled messages
					</Typography>
				</Stack>
			)}
		</Stack>
	);
}
