import { DataContext } from "@/models/DataProvider";
import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { FullTooltip } from "./Utils";
import { useLiveQuery } from "electric-sql/react";
import { useElectric } from "@/electric/ElectricWrapper";
import LockIcon from "@mui/icons-material/Lock";
import { UxContext } from "@/models/UxStateProvider";

export default function FeedHeader() {
	const [isOverflowTitle, setIsOverFlowTitle] = React.useState(undefined);
	const titleRef = React.useRef<HTMLHeadingElement>(null);
	const theme = useTheme();

	const { currentFeed, currentFeedAccounts } = React.useContext(DataContext);
	const { navTitle } = React.useContext(UxContext);

	const accounts = Array.from(currentFeedAccounts?.values());

	const resizeTrigger = React.useCallback(() => {
		if (titleRef.current === undefined) {
			return;
		}
		const hasOverflow =
			titleRef.current.offsetWidth < titleRef.current.scrollWidth ||
			titleRef.current.offsetHeight < titleRef.current.scrollHeight;
		if (hasOverflow === isOverflowTitle) {
			return;
		}
		setIsOverFlowTitle(hasOverflow);
	}, [isOverflowTitle]);

	React.useLayoutEffect(() => {
		let observer;

		if (titleRef.current) {
			if ("ResizeObserver" in window) {
				observer = new ResizeObserver(resizeTrigger);
				observer.observe(titleRef.current);
			}
		}
		resizeTrigger();
		return () => {
			observer?.disconnect?.();
		};
	}, [titleRef, resizeTrigger]);

	const membersLabel = React.useMemo(() => {
		if (!accounts || accounts.length === 0) return;
		const label = `member${accounts?.length > 1 ? "s" : ""}`;
		return `${accounts?.length} ${label}`;
	}, [accounts]);

	const displayTitle =
		currentFeed?.title || accounts?.map((a) => a.name).join(", ") || navTitle;

	const header = (
		<Typography
			ref={titleRef}
			variant="h6"
			component="h2"
			sx={{ fontWeight: 700 }}
			noWrap
		>
			{displayTitle}
		</Typography>
	);

	return (
		<Stack
			sx={{
				width: "100%",
				py: 2,
				minWidth: 0,
				alignItems: { xs: "flex-start", sm: "center" },
				justifyContent: "center",
			}}
		>
			{!isOverflowTitle ? (
				header
			) : (
				<FullTooltip
					disableFocusListener={!isOverflowTitle}
					disableHoverListener={!isOverflowTitle}
					disableTouchListener={!isOverflowTitle}
					title={displayTitle}
					placement="bottom-start"
				>
					{header}
				</FullTooltip>
			)}
			<Typography
				variant="subtitle2"
				component="p"
				sx={{
					fontWeight: 400,
					color: theme.palette.neutral.main,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{" "}
				{currentFeed?.isPrivate === 1 && (
					<LockIcon
						sx={{ fontSize: 12, mr: 0.5 }}
						aria-label="Private Channel"
					/>
				)}
				{membersLabel}
				{currentFeed?.readOnly === 1 && (
					<span>&nbsp;•&nbsp;Listen-only mode</span>
				)}
			</Typography>
		</Stack>
	);
}
