import ModalForm from "@/elements/ModalForm";
import { AppContext } from "@/models/AppStateProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { FullInput } from "./Utils";
import { getRandomTeamname, handleErrorResponse } from "@/utils";
import {
	Box,
	Button,
	Checkbox,
	IconButton,
	Typography,
	Stack,
	useTheme,
	Radio,
} from "@mui/material";
import { Help } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { DataContext } from "@/models/DataProvider";
import { ActionContext } from "@/models/ActionsProvider";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { FullTooltip } from "./Utils";

export default function ChannelModal() {
	const navigate = useNavigate();
	const params = useParams();
	const workspaceId = params.workspaceId;
	const [newChannelName, setNewChannelName] = useState("");
	const [openTooltip, setTooltipOpen] = useState(false);
	const [saving, setSaving] = useState(false);
	const [readOnlyFeed, setReadOnlyFeed] = useState(false);
	const { showBoundary } = useErrorBoundary();

	const [isPrivate, setIsPrivate] = useState<boolean>(true);
	const changeChannelType = (value) => {
		setIsPrivate(() => value);
	};
	const toggleReadOnly = () => setReadOnlyFeed((value) => !value);

	const { client, flags } = useContext(AppContext);
	const { currentFeedId, myAccount } = useContext(DataContext);
	const { createWorkspaceFeed, updateWorkspaceFeed } =
		useContext(ActionContext);
	const {
		channelModalOpen,
		setChannelModalOpen,
		editChannelSettings,
		channelModalNew,
	} = useContext(UxContext);
	const { ampli } = useContext(TrackingContext);
	const theme = useTheme();
	const { db } = useElectric();
	const channelNameCharLimit = 40;

	const handleClose = useCallback(() => {
		setChannelModalOpen(false);
	}, [setChannelModalOpen]);

	const handleTooltipClose = () => {
		setTooltipOpen(false);
	};

	const handleTooltipOpen = () => {
		setTooltipOpen(true);
	};

	const createFeedAction = async () => {
		let newWorkspaceFeed;
		const randomTeamName = getRandomTeamname();
		const channelName = newChannelName || randomTeamName;
		setNewChannelName(channelName);

		let newFeedId: string;

		try {
			// disable the button while creating a feed
			setSaving(true);
			newWorkspaceFeed = await createWorkspaceFeed(
				workspaceId,
				channelName,
				isPrivate,
				readOnlyFeed,
			);

			ampli.feedCreate({
				teamName: channelName,
				feedId: newWorkspaceFeed.id,
				listenOnly: readOnlyFeed,
			});
		} catch (e) {
			const error = await handleErrorResponse(e);
			showBoundary(error);
		} finally {
			handleClose();
			setNewChannelName("");
			setReadOnlyFeed(false);
			setSaving(false);
			if (newWorkspaceFeed) {
				navigate(`/workspaces/${workspaceId}/feeds/${newWorkspaceFeed.id}`);
			}
		}
	};

	const updateFeedAction = useCallback(async () => {
		try {
			setSaving(true);
			await updateWorkspaceFeed(workspaceId, currentFeedId, newChannelName);
		} catch (e) {
			const error = await handleErrorResponse(e);
			showBoundary(error);
		} finally {
			setSaving(false);
			setChannelModalOpen(false);
		}
	}, [
		workspaceId,
		currentFeedId,
		showBoundary,
		newChannelName,
		updateWorkspaceFeed,
		setChannelModalOpen,
	]);

	const handleClick = useCallback(async () => {
		if (!editChannelSettings) {
			await createFeedAction();
		} else {
			await updateFeedAction();
		}
	}, [editChannelSettings, createFeedAction, updateFeedAction]);

	useEffect(() => {
		if (!editChannelSettings) {
			if (myAccount?.name) {
				setNewChannelName(`${myAccount?.name}'s Channel`);
			} else {
				setNewChannelName("Your Channel");
			}
		} else {
			db.feed
				.findUnique({
					where: {
						id: currentFeedId,
					},
				})
				.then((feed) => {
					console.log("UPDATES", { feed });
					if (feed.title) {
						setIsPrivate(() => feed.isPrivate === 1);
						setNewChannelName(feed.title);
					}
				});
			// need setting to determine if feed was set to readonly here.
		}
	}, [db, editChannelSettings, currentFeedId, myAccount]);

	const { results: isWorkspaceAdmin } = useLiveQuery(() => {
		if (!workspaceId) return;
		return db.workspace_membership.liveFirst({
			where: {
				AND: [
					{ accountId: myAccount?.id },
					{ workspaceId },
					{ role: { contains: "admin" } },
				],
			},
		});
	}, [workspaceId, myAccount?.id]);

	const ChannelNameLabel = () => {
		const displayTitle = (
			<Stack sx={{ gap: 1 }}>
				<Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
					Naming a channel
				</Typography>
				<Typography sx={{ fontWeight: 500 }}>
					When naming a channel, it is best to be specific and brief, so that
					the names are easy to recall and say when using voice commands. Avoid
					using special characters or emojis in your channel name.
				</Typography>
			</Stack>
		);

		return (
			<Stack
				sx={{
					flexDirection: "row",
					alignItems: "center",
					position: "relative",
					gap: 1,
				}}
			>
				<Typography>Channel name</Typography>
				<FullTooltip
					describeChild
					title={displayTitle}
					placement="bottom-start"
					onOpen={handleTooltipOpen}
					onClose={handleTooltipClose}
					slotProps={{
						tooltip: {
							sx: {
								"&.MuiTooltip-tooltip": {
									padding: 2.25,
									width: "100%",
									maxWidth: 400,
								},
							},
						},
						popper: {
							sx: {
								'&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip':
									{
										mt: 1,
									},
							},
						},
					}}
				>
					<IconButton
						sx={{
							p: 0,
							borderRadius: "1000px",
							"&:hover:not([disabled])": {
								background: "transparent",
							},
						}}
					>
						<Help
							role="img"
							sx={{
								fontSize: "1.25rem",
								color: openTooltip
									? theme.palette.info.light
									: theme.palette.primary.main,
							}}
						/>
					</IconButton>
				</FullTooltip>
			</Stack>
		);
	};

	const ChannelNameHelperText = ({ characterLimit, field }) => {
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: 1,
					width: "100%",
				}}
			>
				<Typography>
					{field?.length}/{characterLimit}
				</Typography>
			</Box>
		);
	};

	const ListenOnlyMode = () => {
		return (
			<Box sx={{ mt: 1, pl: 2.5 }}>
				<Typography
					sx={{
						fontWeight: 500,
						color: readOnlyFeed
							? theme.palette.primary.main
							: theme.palette.neutral.main,
					}}
				>
					Listen-only mode
				</Typography>
				<Stack
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
					}}
				>
					<Typography
						sx={{ color: theme.palette.neutral.main, fontSize: "0.875rem" }}
					>
						Only you will be able to post. You can not modify this setting
						later.
					</Typography>
					<Box sx={{ width: "120px", justifyContent: "end", display: "flex" }}>
						<Checkbox
							checked={readOnlyFeed}
							disabled={!channelModalNew}
							onChange={toggleReadOnly}
						/>
					</Box>
				</Stack>
			</Box>
		);
	};

	return (
		<ModalForm maxHeight="750px" open={channelModalOpen} onClose={handleClose}>
			<>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "100%",
						fontWeight: 500,
						gap: 1.5,
						textAlign: "center",
					}}
				>
					<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
						{!editChannelSettings ? "Create a new channel" : "Channel settings"}
					</Typography>
					{!editChannelSettings ? (
						<Typography>
							Channels are conversations with one or more users in your
							Workspace.
						</Typography>
					) : null}
				</Box>

				<FullInput
					id="new-channelname"
					label={<ChannelNameLabel />}
					placeholder="E.g. Weekly updates, Carol/Peter,"
					disabled={saving}
					value={newChannelName}
					callback={(e) => setNewChannelName(e.target.value)}
					error={newChannelName?.length > channelNameCharLimit}
					formControlProps={{
						sx: {
							"& .MuiFormHelperText-root": {
								mb: 0,
							},
						},
					}}
					helperText={
						<ChannelNameHelperText
							characterLimit={channelNameCharLimit}
							field={newChannelName}
						/>
					}
				/>

				<Box sx={{ boxModel: "border-box" }}>
					<Typography sx={{ mb: 1, fontWeight: 500 }}>
						Select a channel type:
					</Typography>
					<Box
						sx={{
							background: theme.palette.secondary.main,
							borderRadius: 2,
							p: 2,
							mb: 2,
						}}
					>
						<Typography
							sx={{
								fontWeight: 500,
								color: isPrivate
									? theme.palette.primary.main
									: theme.palette.neutral.main,
							}}
						>
							Private Channel
						</Typography>
						<Stack
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								flexDirection: "row",
							}}
						>
							<Typography
								sx={{ color: theme.palette.neutral.main, fontSize: "0.875rem" }}
							>
								Any number of workspace users can join this channel by owner
								invitation.
							</Typography>
							<Box
								sx={{ width: "120px", justifyContent: "end", display: "flex" }}
							>
								<Radio
									disabled={!channelModalNew}
									checked={isPrivate}
									onClick={() => changeChannelType(true)}
								/>
							</Box>
						</Stack>

						{isPrivate ? <ListenOnlyMode /> : null}
					</Box>
					{isWorkspaceAdmin ? (
						<Box sx={{ px: 2 }}>
							<Typography
								sx={{
									fontWeight: 500,
									color: !isPrivate
										? theme.palette.primary.main
										: theme.palette.neutral.main,
								}}
							>
								Open Channel
							</Typography>
							<Stack
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									flexDirection: "row",
								}}
							>
								<Typography
									sx={{
										color: theme.palette.neutral.main,
										fontSize: "0.875rem",
									}}
								>
									All workspace users will join this channel automatically.
								</Typography>
								<Box
									sx={{
										width: "120px",
										justifyContent: "end",
										display: "flex",
									}}
								>
									<Radio
										disabled={!channelModalNew}
										checked={!isPrivate}
										onClick={() => changeChannelType(false)}
									/>
								</Box>
							</Stack>
							{!isPrivate ? <ListenOnlyMode /> : null}
						</Box>
					) : null}
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", sm: "row" },
						alignItems: "center",
						justifyContent: "space-between",
						width: "100%",
						gap: 2.5,
					}}
				>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => handleClose()}
						disabled={saving}
						aria-label="Cancel Channel Creation"
						sx={{
							width: { xs: "100%", sm: "auto" },
							order: { xs: 1, sm: 0 },
							flexGrow: 1,
							flexBasis: "100%",
						}}
					>
						CANCEL
					</Button>
					<LoadingButton
						loading={saving}
						disabled={newChannelName?.length > channelNameCharLimit}
						variant="contained"
						color="primary"
						onClick={handleClick}
						aria-label={!editChannelSettings ? "CREATE CHANNEL" : "Save"}
						sx={{
							width: { xs: "100%", sm: "auto" },
							order: { xs: 0, sm: 1 },
							flexGrow: 1,
							flexBasis: "100%",
						}}
					>
						{!editChannelSettings ? "CREATE CHANNEL" : "Save"}
					</LoadingButton>
				</Box>
			</>
		</ModalForm>
	);
}
