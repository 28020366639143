import Loading from "@/components/Loading/Index";
import ReadOnlyFooter from "@/components/ReadOnlyFooter";
import SessionRecorderFooter from "@/components/SessionRecorderFooter/Index";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { DataContext } from "@/models/DataProvider";
import { Alert, Box, Typography, Snackbar, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
	startTransition,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "regenerator-runtime/runtime";
import { VList, VListHandle } from "virtua";
import { useElectric } from "../electric/ElectricWrapper";
import AddChannelUsersLink from "./Workspace/AddChannelUsersLink";
import FeedDateHeader from "./FeedDateHeader";
import FeedItem from "./FeedItem";
import ToastPlayer from "./ToastPlayer";
import { FeedMap, FullItem } from "./FeedMap";
import FeedContextProvider from "@/models/FeedContextProvider";
import { UppyContext } from "@/models/UppyContextProvider";

export default function FeedC() {
	const { pauseQueue, handsFreeModeEnabled } = useContext(AudioAppContext);
	const { uploading, isAudioFileUpload, isScheduledUpload } =
		useContext(UppyContext);
	const [firstUnseen, setFirstUnseen] = useState<FullItem>();
	const [showToastPlayer, setShowToastPlayer] = useState<boolean>(false);
	const [fetching, setFetching] = useState<boolean>(false);
	const [textInputState, setTextInputState] = useState<boolean>(false);
	const [scrolling, setScrolling] = useState(false);
	const {
		currentFeed,
		currentFeedAccounts,
		isCurrentFeedAdmin,
		loadNextFeedItemPage,
	} = useContext(DataContext);

	const { hash } = useLocation();

	const accounts = Array.from(currentFeedAccounts?.values() ?? []);
	const userCount = accounts.length;
	const hasScrolled = useRef(false);

	const feedId = currentFeed?.id;
	const workspaceId = currentFeed?.workspaceId;
	const { myAccount } = useContext(DataContext);
	const { db } = useElectric();

	const { results: currentFeedEvents } = useLiveQuery(() => {
		if (!feedId) return;
		return db.account_event.liveMany({
			where: {
				feedId: feedId,
			},
			orderBy: {
				createdAt: "desc",
			},
		});
	}, [feedId]);

	const { results: items } = useLiveQuery(() => {
		if (!feedId) return;
		return db.item.liveMany({
			where: {
				feedId: feedId,
				deletedAt: null,
			},
			orderBy: {
				createdAt: "asc",
			},
		});
	}, [feedId]);

	const { results: audioQueue } = useLiveQuery(() => {
		if (!feedId) return;
		return db.audio_queue_item.liveMany({
			orderBy: {
				createdAt: "asc",
			},
		});
	}, [feedId]);

	const currentFeedItems = FeedMap({ items });

	const { results: myFeedPermissions } = useLiveQuery(() => {
		if (!feedId || !myAccount?.id) return;
		return db.liveRaw({
			sql: `
				SELECT
					*
				FROM
					permission	
				JOIN
					workspace_membership
				ON
					permission.workspace_membershipId = workspace_membership.id
				WHERE
					permission.feedId = ?
				AND
					workspace_membership.accountId = ?
				AND
					permission.enabled = 1
			`,
			args: [feedId, myAccount?.id],
		});
	}, [feedId, myAccount]);

	const haveWritePermission = myFeedPermissions?.some(
		(p) => p.name === "write",
	);

	const canRecordOrUpload = haveWritePermission;
	const theme = useTheme();

	useEffect(() => {
		if (myAccount && currentFeedEvents?.length > 0) {
			const lastViewed = currentFeedEvents?.find(
				(e) => e.name === "Opened Feed" && e.accountId === myAccount?.id,
			);

			if (lastViewed) {
				const firstUnseen = currentFeedItems.find(
					(i) =>
						i.createdAt > lastViewed.createdAt && i.accountId !== myAccount?.id,
				);
				setFirstUnseen(firstUnseen);

				// if a user added a new recording to the feed, then scroll to it
				const newRecording = currentFeedItems.find(
					(i) => i.createdAt > lastViewed.createdAt,
				);

				if (newRecording && firstUnseen && !hasScrolled.current) {
					vListRef?.current?.scrollToIndex(currentFeedItems.length - 1, {
						align: "end",
						smooth: true,
					});
					hasScrolled.current = true;
					setFirstUnseen(null);
				}
			}
		}
	}, [myAccount, currentFeedEvents, currentFeedItems]);

	const initialLoad = useRef(true);
	const vListRef = useRef<VListHandle>(null);
	const isLoadingMore = useRef(false);

	const loadMore = useCallback(async () => {
		const feed = await db.feed.findUnique({ where: { id: feedId } });
		if (feed.loadedLastPage === 1) {
			isLoadingMore.current = false;
			return;
		}
		if (feed.loadedFirstPage === 0) {
			console.log("waiting for first page");
			return;
		}
		console.log("LOADING MORE");
		setFetching(true);
		await loadNextFeedItemPage(feedId);
		vListRef.current.scrollToIndex(11, { align: "start" });
		isLoadingMore.current = false;
		setFetching(false);
	}, [feedId]);

	const rangeChangeLoadMore = async (vListIndex) => {
		if (initialLoad.current || isLoadingMore.current) return;

		if (vListIndex === 0) {
			isLoadingMore.current = true;
			await loadMore();
		}
	};

	const scrollToActiveId = (index) => {
		vListRef.current?.scrollToIndex(index, { align: "start", smooth: true });
	};

	useEffect(() => {
		if (!vListRef?.current) return;
		if (initialLoad.current && currentFeedItems.length > 0 && !scrolling) {
			let scrollToIndex = currentFeedItems.length - 1;
			if (hash) {
				const hashId = hash.split("#")?.[1];
				scrollToIndex = currentFeedItems?.findIndex(
					(item) => item.id === hashId,
				);
			}
			vListRef?.current?.scrollToIndex(scrollToIndex, {
				align: "end",
			});
			setTimeout(() => {
				initialLoad.current = false;
			}, 200);
		}
	}, [currentFeedItems?.length]);

	const dismissToast = useCallback(() => {
		pauseQueue();
		setShowToastPlayer(false);
	}, [pauseQueue]);

	useEffect(() => {
		if (audioQueue?.length > 0 && handsFreeModeEnabled) {
			setShowToastPlayer(true);
		} else {
			setShowToastPlayer(false);
		}
	}, [audioQueue, handsFreeModeEnabled]);

	const scrollToBottom = () => {
		const vListRefId = document.getElementById("vListRef");
		const scrollDownSize = vListRefId?.offsetHeight + 15;
		if (scrollDownSize && !scrolling) {
			setTimeout(() => {
				vListRef?.current?.scrollToIndex(scrollDownSize, {
					align: "end",
				});
			}, 300);
		}
	};

	if (!feedId || !currentFeedItems) return null;

	return (
		<FeedContextProvider>
			{uploading && isAudioFileUpload && !isScheduledUpload ? (
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					sx={{
						width: "100%",
						padding: 1,
						top: { xs: 85 },
						zIndex: 1100,
					}}
					open={true}
				>
					<Alert
						severity="info"
						sx={{
							width: "100%",
							color: theme.palette.primary.main,
						}}
						elevation={6}
						variant="filled"
					>
						Uploading file...
					</Alert>
				</Snackbar>
			) : null}
			{showToastPlayer && (
				<Box
					key={"toast-player"}
					sx={{
						position: "fixed",
						top: 156,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						px: 2,
						width: "100%",
						zIndex: 1100,
					}}
				>
					<ToastPlayer onClose={dismissToast} />
				</Box>
			)}
			{fetching && (
				<Box
					sx={{
						p: 2,
						height: 80,
						display: "flex",
						alignItems: "center",
						position: "fixed",
						justifyContent: "center",
						left: 0,
						top: "110px",
						width: "calc(100vw - 15px)",
						zIndex: 200,
					}}
				>
					<Loading size="small" />
				</Box>
			)}
			{currentFeedItems?.length > 0 ? (
				<VList
					className="dark-scrollbar"
					style={{
						height: "calc(100svh - 205px)",
						paddingBottom: "205px",
					}}
					reverse
					ref={vListRef}
					id="vListRef"
					onRangeChange={(startIndex) => rangeChangeLoadMore(startIndex)}
					onScroll={() => {
						startTransition(() => {
							setScrolling(true);
						});
					}}
					onScrollStop={() => {
						startTransition(() => {
							setScrolling(false);
						});
					}}
				>
					{currentFeedItems.map((feedItem, index) => (
						<Fragment key={`feed-item-${index}`}>
							{feedItem.sticky && (
								<FeedDateHeader
									dateId={feedItem.shortDateStr}
									onClick={() => scrollToActiveId(index)}
								>
									{feedItem.stickyLabel}
								</FeedDateHeader>
							)}
							<Box
								id={`item-${index}`}
								sx={{
									maxWidth: { xs: 1200 },
									margin: "0 auto",
									display: "flex",
									flexDirection: "column",
									justifyContent: feedItem.mine ? "flex-end" : "flex-start",
								}}
							>
								<FeedItem
									feed={currentFeed}
									accountInfo={currentFeedAccounts}
									item={feedItem}
									unread={feedItem.unread}
								/>
							</Box>
						</Fragment>
					))}
				</VList>
			) : (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						height:
							currentFeedItems.length === 0 ? "calc(100svh - 165px)" : "auto",
						color: theme.palette.secondary.light,
					}}
				>
					<>
						{currentFeedItems.length === 0 ? (
							<>
								{currentFeed.isDm === 1 && (
									<Typography
										variant="h6"
										component="h4"
										color={theme.palette.primary.main}
										fontWeight={700}
									>
										There are no messages yet
									</Typography>
								)}
								{currentFeed.isDm !== 1 &&
									isCurrentFeedAdmin &&
									currentFeed?.isPrivate === 1 && (
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: "column",
												textAlign: "center",
												gap: "28px",
												width: "100%",
												maxWidth: { xs: "calc(100% - 32px)", sm: "415px" },
												padding: "40px 60px",
												borderRadius: "8px",
												background: theme.palette.secondary.dark,
											}}
										>
											<Box>
												<Typography
													variant="h6"
													component="h4"
													color={theme.palette.primary.main}
													fontWeight={700}
												>
													Add Users to this Channel
												</Typography>
												<Typography color={theme.palette.neutral.main}>
													Invite existing workspace users to start a
													conversation
												</Typography>
											</Box>
											<AddChannelUsersLink />
										</Box>
									)}
							</>
						) : (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: 0.5,
									py: 4,
									color: theme.palette.neutral.main,
								}}
							>
								<Typography fontWeight={700}>
									There is no one else here....
								</Typography>
								<AddChannelUsersLink
									variant="link"
									sx={{ color: theme.palette.neutral.main }}
								/>
							</Box>
						)}
					</>
				</Box>
			)}
			{canRecordOrUpload ? (
				<SessionRecorderFooter
					workspaceId={workspaceId}
					feedId={feedId}
					vListRef={vListRef}
				/>
			) : (
				<ReadOnlyFooter />
			)}
		</FeedContextProvider>
	);
}
