import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { ActionContext } from "@/models/ActionsProvider";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import { Button, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UxContext } from "@/models/UxStateProvider";
import {
	isPast,
	isFuture,
	startOfDay,
	format,
	getDate,
	getMinutes,
	getHours,
	isValid,
} from "date-fns";
import { UppyContext } from "@/models/UppyContextProvider";
import ScheduleMessageRecurringOptions from "./ScheduleMessageRecurringOptions";

export default function ScheduleMessageDateAndTime() {
	const [publishing, setPublishing] = useState<boolean>(false);
	const {
		doesRepeat,
		setDoesRepeat,
		goToPrevStep,
		displayName,
		scheduledText,
		selectedChannels,
		selectedDate,
		setSelectedDate,
		selectedDaysOfWeek,
		setSelectedDaysOfWeek,
		timezone,
		resetAll,
	} = useContext(SchedulingContext);
	const { addedFile, removeMetaData, uppyClient } = useContext(UppyContext);
	const [minDatetime, setMinDatetime] = useState<Date>(new Date(Date.now()));
	const [error, setError] = useState<boolean>(false);
	const { setScheduleMessageModalOpen } = useContext(UxContext);
	const { createWorkflowItem, createScheduledWorkflowItem } =
		useContext(ActionContext);
	const params = useParams();
	const workspaceId = params?.workspaceId;

	const doesRepeatHandler = (doesRepeat: boolean) => {
		setDoesRepeat(doesRepeat);
	};

	const selectedDaysOfWeekHandler = (daysOfWeek: Array<number>) => {
		setSelectedDaysOfWeek(daysOfWeek);
	};

	const handleDateSelection = (date: Date) => {
		if (isValid(date)) {
			if (isPast(date)) {
				// if selected date is today, it cannot be earlier than the current datetime
				setError(true);
			} else {
				setError(false);
			}

			setSelectedDate(date);

			if (isFuture(date)) {
				setMinDatetime(startOfDay(date));
			} else {
				setMinDatetime(date);
			}
		} else {
			setError(true);
		}
	};

	const publishWorkflowItem = async () => {
		try {
			setPublishing(true);
			let contentId = "";
			if (addedFile) {
				contentId = addedFile?.meta?.contentId;
				// nullify the feedId to prevent uploads being sent right away
				// connect the contentId to the workflowItem
				removeMetaData("feedId");
				await uppyClient.upload();
			}

			const workflowItem = await createWorkflowItem(
				workspaceId,
				contentId,
				displayName,
				scheduledText,
			);
			let scheduledWorkflow: any = {
				workspaceId,
				workflowItemId: workflowItem?.id,
				feedIds: selectedChannels,
			};
			if (doesRepeat) {
				const mins = getMinutes(selectedDate);
				const hours = getHours(selectedDate);
				// const month = getMonth(selectedDate);
				const month = "*";
				let daysOfWeek = "?";
				let dayOfMonth = "?";

				if (selectedDaysOfWeek?.length > 0) {
					// figure out when we have the ability for multi day selection
					// daysOfWeek = selectedDaysOfWeek.join(",");
					daysOfWeek = selectedDaysOfWeek[0].toString();
					dayOfMonth = "?";
				} else {
					dayOfMonth = getDate(selectedDate).toString();
				}

				const scheduledCron = `${mins} ${hours} ${dayOfMonth} ${month} ${daysOfWeek} *`;
				scheduledWorkflow.scheduledCron = scheduledCron;
				scheduledWorkflow.timezone = timezone;
			} else {
				scheduledWorkflow.scheduledDate = selectedDate?.toISOString();
			}
			await createScheduledWorkflowItem(scheduledWorkflow);
		} catch (e) {
			console.log("ERROR", e);
		} finally {
			setPublishing(false);
			resetAll();
			setScheduleMessageModalOpen(false);
		}
	};

	return (
		<Stack sx={{ width: "100%", gap: 2, position: "relative" }}>
			<DatePicker
				label="Publish Date*"
				selectedDate={selectedDate}
				dateSelectionHandler={handleDateSelection}
				disabled={doesRepeat || publishing}
			/>
			<TimePicker
				label="Publish Time*"
				selectedTime={selectedDate}
				timeSelectionHandler={handleDateSelection}
				disabled={!selectedDate || publishing}
				minDate={minDatetime}
			/>
			<Stack sx={{ position: "relative" }}>
				<ScheduleMessageRecurringOptions
					doesRepeat={doesRepeat}
					doesRepeatHandler={doesRepeatHandler}
					disabled={publishing}
					selectedDate={selectedDate}
					selectedDaysOfWeek={selectedDaysOfWeek}
					selectedDaysOfWeekHandler={selectedDaysOfWeekHandler}
				/>
			</Stack>
			<Stack
				sx={{
					flexDirection: { xs: "column", sm: "row" },
					width: "100%",
					gap: 2,
				}}
			>
				<Button
					variant="outlined"
					sx={{ order: { xs: 1, sm: 0 } }}
					onClick={goToPrevStep}
					disabled={publishing}
				>
					Back
				</Button>
				<LoadingButton
					loading={publishing}
					disabled={error || (doesRepeat && selectedDaysOfWeek.length === 0)}
					variant="contained"
					color="primary"
					sx={{ order: { xs: 0, sm: 1 } }}
					onClick={publishWorkflowItem}
				>
					Publish
				</LoadingButton>
			</Stack>
		</Stack>
	);
}
