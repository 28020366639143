import { AppContext } from "@/models/AppStateProvider";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import {
	Pause as PauseIcon,
	PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useCallback, useContext } from "react";
import { AudioPlayerProps } from "./AudioPlayer";
import { containerStyle } from "./AudioPlayer/styles";
import { AudioControls } from "./AudioPlayer/timeline";
import { DataContext } from "@/models/DataProvider";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";

interface PlayPauseButtonProps
	extends Pick<AudioPlayerProps, "playPauseIconButtonProps"> {
	/**
	 * @NOTE `audioElement` + `controls` are now optional as
	 *       they come from `AudioAppContext`
	 */
	audioElement?: HTMLAudioElement;
	controls?: AudioControls;
	disabled?: boolean;
	id: string;
	playing: boolean;
}

export default function PlayPauseButton({
	disabled = false,
	playing,
	id,
	playPauseIconButtonProps = {},
}: PlayPauseButtonProps) {
	const { currentFeedId } = useContext(DataContext);
	const { setQueue, playQueue, pauseQueue, getPlayQueue, setManualPlay } =
		useContext(AudioAppContext);

	const { db } = useElectric();
	const { results: currentFeedItems } = useLiveQuery(
		db.item.liveMany({
			where: {
				feedId: currentFeedId,
			},
			orderBy: {
				createdAt: "asc",
			},
		}),
	);

	const { results: audioQueue } = useLiveQuery(
		db.audio_queue_item.liveMany({
			orderBy: {
				createdAt: "asc",
			},
		}),
	);

	const handlePlay = () => {
		if (playing) {
			pauseQueue();
		} else {
			const qSlice = getPlayQueue({
				items: currentFeedItems,
				currentId: id,
			});

			setManualPlay(true);

			// only update the queue if we have new items too add to the previous queue with the same starting index
			// forsee an issue with items being deleted and the queue not being updated as expected
			if (
				audioQueue?.[0]?.itemId === id &&
				audioQueue?.length === qSlice.length
			) {
				console.log("playing queue");
				playQueue();
			} else {
				console.log("setting new queue");
				setQueue(qSlice);
			}
		}
	};

	const { sx: iconButtonSx, ...restIconButtonProps } = playPauseIconButtonProps;
	const mergedSx = { ...containerStyle.playButton, ...iconButtonSx };

	const ariaLabel = playing
		? "Currently playing, click to pause"
		: "Click to play";

	return (
		<IconButton
			disabled={disabled}
			color="primary"
			onClick={handlePlay}
			sx={mergedSx}
			aria-label={ariaLabel}
			{...restIconButtonProps}
		>
			{playing ? <PauseIcon role="img" /> : <PlayArrowIcon role="img" />}
		</IconButton>
	);
}
