import { SVGProps } from "react";
import { FlagProps } from "./Flag";

export type FlagIconProps = FlagProps & SVGProps<SVGSVGElement> & {};

export function NoneFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" {...props}>
			{title && <title>{title}</title>}
			<g
				fill="none"
				fillRule="evenodd"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<path d="M18.5 10.5v-6a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2z"></path>
				<path d="M6.5 8.503h-2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h.003l6-.01a2 2 0 0 0 1.997-2V14.5m-5-1.997h-3"></path>
				<path d="m9 14l-1 1c-.334.333-1.166.833-2.5 1.5"></path>
				<path d="M5.5 12.503c.334 1.166.834 2 1.5 2.499S8.5 16 9.5 16.5m4-12l-3 6m3-6l3 6m-1-2h-4"></path>
			</g>
		</svg>
	);
}

export function EnFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			{title && <title>{title}</title>}
			<mask id="circleFlagsUs0">
				<circle cx="256" cy="256" r="256" fill="#fff"></circle>
			</mask>
			<g mask="url(#circleFlagsUs0)">
				<path
					fill="#eee"
					d="M256 0h256v64l-32 32l32 32v64l-32 32l32 32v64l-32 32l32 32v64l-256 32L0 448v-64l32-32l-32-32v-64z"
				></path>
				<path
					fill="#d80027"
					d="M224 64h288v64H224Zm0 128h288v64H256ZM0 320h512v64H0Zm0 128h512v64H0Z"
				></path>
				<path fill="#0052b4" d="M0 0h256v256H0Z"></path>
				<path
					fill="#eee"
					d="m187 243l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67zm162-81l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Zm162-82l57-41h-70l57 41l-22-67Zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Z"
				></path>
			</g>
		</svg>
	);
}

export function EsFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			{title && <title>{title}</title>}
			<mask id="circleFlagsEsVariant0">
				<circle cx="256" cy="256" r="256" fill="#fff"></circle>
			</mask>
			<g mask="url(#circleFlagsEsVariant0)">
				<path
					fill="#d80027"
					d="M0 0h512v128l-39.8 130.3L512 384v128H0V384l37.8-124L0 128z"
				></path>
				<path fill="#ffda44" d="M0 128h512v256H0z"></path>
			</g>
		</svg>
	);
}

export function EsMXFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			{title && <title>{title}</title>}
			<mask id="circleFlagsMx0">
				<circle cx="256" cy="256" r="256" fill="#fff"></circle>
			</mask>
			<g mask="url(#circleFlagsMx0)">
				<path fill="#eee" d="M144 0h223l33 256l-33 256H144l-32-256z"></path>
				<path fill="#496e2d" d="M0 0h144v512H0z"></path>
				<path fill="#d80027" d="M368 0h144v512H368z"></path>
				<path fill="#ffda44" d="M256 277v10h12l10-22z"></path>
				<path
					fill="#496e2d"
					d="M160 242a96 96 0 0 0 192 0h-11a85 85 0 0 1-170 0zm39 17l-4 2c-2 2-2 6 1 8c15 14 34 22 54 24v17h12v-17c20-2 39-10 54-24c3-2 3-6 1-8s-6-2-8 0a78 78 0 0 1-53 21c-19 0-38-8-53-21z"
				></path>
				<path
					fill="#338af3"
					d="M256 316c-14 0-28-5-40-13l6-9c20 13 48 13 68 0l7 9c-12 8-26 13-41 13z"
				></path>
				<path
					fill="#751a46"
					d="M256 174c22 11 12 33 11 34l-2-4c-5-11-18-18-31-18v11c6 0 11 5 11 11c-7 7-9 17-4 26l4 8l-13 23l29-7l18 18v-11l11 11l23-11l-35-21l-5-21l28 16c4 11 12 21 23 26c9-83-42-91-61-91z"
				></path>
				<path
					fill="#6da544"
					d="M222 271c-15 0-33-12-38-40l11-2c4 23 18 31 27 31c3 0 5-1 6-3c0-2 0-3-6-5c-3-1-7-2-10-5c-10-12 4-24 11-30c1-1 2-2 1-3c0 0-2-2-5-2c-7 0-12-4-14-11c-2-6 2-13 8-17l5 11c-2 0-2 2-2 4c0 0 1 2 3 2c7 0 14 4 16 9c1 3 2 9-5 15c-7 7-11 12-9 15l5 1c5 2 14 5 13 17c-1 8-8 13-17 13h-1z"
				></path>
				<path fill="#ffda44" d="m234 186l-12 11v11l18-9c3-1 3-5 1-7z"></path>
				<circle cx="172" cy="275" r="8" fill="#ffda44"></circle>
				<circle cx="189" cy="302" r="8" fill="#ffda44"></circle>
				<circle cx="216" cy="323" r="8" fill="#ffda44"></circle>
				<circle cx="297" cy="323" r="8" fill="#ffda44"></circle>
				<circle cx="324" cy="302" r="8" fill="#ffda44"></circle>
				<circle cx="341" cy="275" r="8" fill="#ffda44"></circle>
				<rect
					width="34"
					height="22"
					x="239"
					y="299"
					fill="#ff9811"
					rx="11"
					ry="11"
				></rect>
			</g>
		</svg>
	);
}

export function PtFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			{title && <title>{title}</title>}
			<mask id="circleFlagsPt0">
				<circle cx="256" cy="256" r="256" fill="#fff"></circle>
			</mask>
			<g mask="url(#circleFlagsPt0)">
				<path fill="#6da544" d="M0 0h512v512H0z"></path>
				<path
					fill="#ffda44"
					d="M256 100.2L467.5 256L256 411.8L44.5 256z"
				></path>
				<path
					fill="#eee"
					d="M174.2 221a87 87 0 0 0-7.2 36.3l162 49.8a88.5 88.5 0 0 0 14.4-34c-40.6-65.3-119.7-80.3-169.1-52z"
				></path>
				<path
					fill="#0052b4"
					d="M255.7 167a89 89 0 0 0-41.9 10.6a89 89 0 0 0-39.6 43.4a181.7 181.7 0 0 1 169.1 52.2a89 89 0 0 0-9-59.4a89 89 0 0 0-78.6-46.8zM212 250.5a149 149 0 0 0-45 6.8a89 89 0 0 0 10.5 40.9a89 89 0 0 0 120.6 36.2a89 89 0 0 0 30.7-27.3A151 151 0 0 0 212 250.5z"
				></path>
			</g>
		</svg>
	);
}

export function ZhFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			{title && <title>{title}</title>}
			<mask id="circleFlagsCn0">
				<circle cx="256" cy="256" r="256" fill="#fff"></circle>
			</mask>
			<g mask="url(#circleFlagsCn0)">
				<path fill="#d80027" d="M0 0h512v512H0z"></path>
				<path
					fill="#ffda44"
					d="m140.1 155.8l22.1 68h71.5l-57.8 42.1l22.1 68l-57.9-42l-57.9 42l22.2-68l-57.9-42.1H118zm163.4 240.7l-16.9-20.8l-25 9.7l14.5-22.5l-16.9-20.9l25.9 6.9l14.6-22.5l1.4 26.8l26 6.9l-25.1 9.6zm33.6-61l8-25.6l-21.9-15.5l26.8-.4l7.9-25.6l8.7 25.4l26.8-.3l-21.5 16l8.6 25.4l-21.9-15.5zm45.3-147.6L370.6 212l19.2 18.7l-26.5-3.8l-11.8 24l-4.6-26.4l-26.6-3.8l23.8-12.5l-4.6-26.5l19.2 18.7zm-78.2-73l-2 26.7l24.9 10.1l-26.1 6.4l-1.9 26.8l-14.1-22.8l-26.1 6.4l17.3-20.5l-14.2-22.7l24.9 10.1z"
				></path>
			</g>
		</svg>
	);
}

export function FrFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			{title && <title>{title}</title>}
			<mask id="circleFlagsFr0">
				<circle cx="256" cy="256" r="256" fill="#fff"></circle>
			</mask>
			<g mask="url(#circleFlagsFr0)">
				<path
					fill="#eee"
					d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z"
				></path>
				<path fill="#0052b4" d="M0 0h167v512H0z"></path>
				<path fill="#d80027" d="M345 0h167v512H345z"></path>
			</g>
		</svg>
	);
}

export function PlFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			{title && <title>{title}</title>}
			<mask id="circleFlagsPl">
				<circle cx="256" cy="256" r="256" fill="#fff" />
			</mask>
			<g mask="url(#circleFlagsPl)">
				<path fill="#d80027" d="m0 256 256.4-44.3L512 256v256H0z" />
				<path fill="#eee" d="M0 0h512v256H0z" />
			</g>
		</svg>
	);
}

export function SrFlag({ title, locale, ...props }: FlagIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
			{title && <title>{title}</title>}
			<g clipPath="url(#clip0_3072_10900)">
				<path
					d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
					fill="#F0F0F0"
				/>
				<path
					d="M256 0C114.616 0 0 114.616 0 256C0 287.314 5.633 317.311 15.923 345.043H496.078C506.368 317.311 512 287.314 512 256C512 114.616 397.384 0 256 0Z"
					fill="#0052B4"
				/>
				<path
					d="M255.999 0C145.928 0 52.0929 69.472 15.9219 166.957H496.077C459.905 69.472 366.07 0 255.999 0V0Z"
					fill="#D80027"
				/>
				<path
					d="M66.1953 144.695V272.401C66.1953 345.045 161.096 367.304 161.096 367.304C161.096 367.304 255.996 345.045 255.996 272.401V144.695H66.1953Z"
					fill="#D80027"
				/>
				<path
					d="M105.445 166.957H216.75V122.435L194.489 133.565L161.098 100.174L127.706 133.565L105.445 122.435V166.957Z"
					fill="#FFDA44"
				/>
				<path
					d="M233.739 290.182L161.391 217.834L89.043 290.182L112.654 313.793L161.391 265.056L210.128 313.793L233.739 290.182Z"
					fill="#FFDA44"
				/>
				<path
					d="M233.739 222.61H199.983C201.881 219.334 202.985 215.54 202.985 211.482C202.985 199.186 193.018 189.221 180.724 189.221C172.229 189.221 164.852 193.979 161.099 200.974C157.346 193.98 149.969 189.221 141.474 189.221C129.18 189.221 119.213 199.186 119.213 211.482C119.213 215.54 120.317 219.335 122.215 222.61H89.043C89.043 234.905 99.752 244.871 112.045 244.871H111.304C111.304 257.166 121.27 267.132 133.565 267.132C133.565 278.018 141.383 287.062 151.708 288.998L134.168 328.6C142.574 332.017 151.76 333.915 161.392 333.915C171.024 333.915 180.21 332.017 188.616 328.6L171.076 288.998C181.401 287.064 189.219 278.019 189.219 267.132C201.514 267.132 211.48 257.166 211.48 244.871H210.739C223.031 244.871 233.739 234.904 233.739 222.61V222.61Z"
					fill="#F0F0F0"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3072_10900">
					<rect width="512" height="512" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
