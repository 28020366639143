import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { Slider, SliderProps, useTheme } from "@mui/material";
import { useContext } from "react";
import { throttle } from "throttle-typescript";
import { AudioPlayerProps } from "./AudioPlayer";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { DataContext } from "@/models/DataProvider";

export type ChangeCurrentTimeProps = (args: {
	audioElement: HTMLAudioElement;
	duration?: number;
	feedItemId?: string;
}) => SliderProps["onChange"];

export interface PlaybackTimelineProps extends Omit<SliderProps, "size"> {
	className?: string;
	duration: number;
	feedItemId: string;
	position: number;
	size?: AudioPlayerProps["size"];
}

export default function PlaybackTimeline({
	className = "",
	duration = 0,
	feedItemId,
	position,
	size,
	...rest
}: PlaybackTimelineProps) {
	const { currentFeedId } = useContext(DataContext);

	const { audioElement, getPlayQueue, setQueue } = useContext(AudioAppContext);

	const theme = useTheme();

	const { db } = useElectric();
	const { results: items } = useLiveQuery(
		db.item.liveMany({
			where: {
				feedId: currentFeedId,
			},
			orderBy: {
				createdAt: "asc",
			},
		}),
	);

	const { results: audioQueue } = useLiveQuery(
		db.audio_queue_item.liveMany({
			orderBy: {
				createdAt: "asc",
			},
		}),
	);

	const changeCurrentTimeForTimeline = throttle((e, v) => {
		const qSlice = getPlayQueue({
			items,
			currentId: feedItemId,
		});

		const _duration = duration || audioElement?.duration;

		const _seek = () => {
			if (typeof v === "number") {
				const currentPosition = (_duration / 100) * v;
				if (audioElement.fastSeek instanceof Function) {
					audioElement.fastSeek(currentPosition);
				} else {
					audioElement.currentTime = currentPosition;
				}
			}
		};

		if (
			audioQueue?.[0]?.itemId === feedItemId &&
			audioQueue?.length === qSlice.length
		) {
			console.log("timeline /// playing queue");
			_seek();
		} else {
			console.log("timeline /// setting new queue");
			setQueue(qSlice);
			setTimeout(() => _seek(), 0);
		}
	}, 250);

	return (
		<Slider
			role="slider"
			className={className}
			onChange={changeCurrentTimeForTimeline}
			size={size === "large" ? "medium" : size ?? "small"}
			value={position}
			sx={{
				color: theme.palette.text.primary,
				".MuiSlider-rail": { height: "4px", borderRadius: 0 },
				".MuiSlider-track": {
					height: "4px",
					borderRadius: 0,
				},
			}}
			{...rest}
		/>
	);
}
