import {
	Box,
	Divider,
	Link,
	Stack,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import React from "react";
import { DataContext } from "@/models/DataProvider";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";

export default function HandleHandsFreeToggle() {
	const theme = useTheme();
	const { ampli } = React.useContext(TrackingContext);
	const { handsFreeModeEnabled, setHandsFreeMode, queuePlaying, resetQueue } =
		React.useContext(AudioAppContext);
	const { currentWorkspaceId } = React.useContext(DataContext);
	const handsFreeAllowed = handsFreeEnabledForWorkspace(currentWorkspaceId);

	const handleHandsFreeToggle = () => {
		const newHandsFree = !handsFreeModeEnabled;
		setHandsFreeMode(newHandsFree);
		if (newHandsFree) {
			ampli.toggleHandsFreeOn();
		} else {
			ampli.toggleHandsFreeOff();
		}

		if (newHandsFree && !queuePlaying) {
			// clear out old queue before if were not playing and enabling hands free
			resetQueue();
		}
	};
	return (
		<Stack sx={{ p: 1.5 }}>
			{handsFreeAllowed && (
				<Stack
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						width: "100%",
					}}
				>
					<Box>
						<Typography>Hands-Free Mode</Typography>
						<Typography
							sx={{ fontSize: "14px", color: theme.palette.neutral.main }}
						>
							{" "}
							Automatically play new messages.{" "}
						</Typography>
					</Box>
					<Switch
						checked={handsFreeModeEnabled}
						onChange={handleHandsFreeToggle}
					/>
				</Stack>
			)}
			{!handsFreeAllowed && (
				<Stack
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						width: "100%",
					}}
				>
					<Box sx={{ color: theme.palette.neutral.main }}>
						<Typography>Hands-Free Mode</Typography>
						<Typography sx={{ fontSize: "14px" }}>
							Enterprise feature &nbsp;| &nbsp;
							<Link
								sx={{
									fontWeight: "700",
									color: "#1597F8",
									textDecorationColor: "#1597F8",
								}}
								rel="noopener"
								href="https://storyboard.com/product-hands-free?utm_campaign=FY23%20%7C%20In-app%20-%20Hands%20Free%20Upsell&utm_source=In-app-upsell&utm_medium=Web"
								target="_blank"
							>
								contact sales
							</Link>
						</Typography>
					</Box>
					<Switch
						checked={false}
						onChange={() => {
							// do nothing
						}}
						disabled
					/>
				</Stack>
			)}
		</Stack>
	);
}
