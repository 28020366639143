import { TrackingContext } from "@/models/TrackingStateProvider";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import { IconButton } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext } from "react";
import { FeedContext } from "@/models/FeedContextProvider";
import TTSModal from "./TTSModal";
import { IconButtonProps } from "@mui/material";

export type TTSButtonProps = IconButtonProps & {};

export default function TTSButton({ ...props }: TTSButtonProps) {
	const { ampli } = useContext(TrackingContext);
	const { setTextAsInputModal } = useContext(FeedContext);
	const { textAsInput } = useFlags();

	const toggletextAsInputModal = () => {
		setTextAsInputModal((value) => !value);
	};

	const handleInputEnter = () => {
		const input = document.getElementById("text-message-input");
		if (input) {
			input.focus();
		}
	};

	if (!textAsInput) return null;

	return (
		<>
			<TTSModal />
			<IconButton
				color={props.color}
				onClick={toggletextAsInputModal}
				aria-label="Type text message"
				{...props}
			>
				<KeyboardAltOutlinedIcon role="presentation" />
			</IconButton>
		</>
	);
}
