import {
	Box,
	Button,
	Typography,
	Stack,
	List,
	ListItem,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import WorkspaceChannelList from "./WorkspaceChannelList";
import WorkspaceDmList from "./WorkspaceDmList";
import WorkspaceTools from "./WorkspaceTools";
import { useFlags } from "launchdarkly-react-client-sdk";
import HandleHandsFreeToggle from "@/components/Workspace/HandleHandsFreeToggle";
import WorkspaceUserProfile from "@/components/Workspace/WorkspaceUserProfile";
import { InitialsAvatar } from "@/components/Avatar";
import { DataContext } from "@/models/DataProvider";
import ChatBubble from "@/components/Icons/ChatBubble";
import ChatBubbleOutlined from "@/components/Icons/ChatBubbleOutlined";
import Tools from "@/components/Icons/Tools";
import ChannelDialOutlined from "../Icons/ChannelDialOutlined";

export default function WorkspaceList() {
	const { directMessage, toolsTab } = useFlags();
	const { myAccount } = React.useContext(DataContext);
	const theme = useTheme();
	const [activeTab, setActiveTab] = useState<string>("channels");

	const initialsContainer = (
		<Box className="TOAST" sx={{ height: "43px" }}>
			<InitialsAvatar
				initials={myAccount?.name?.[0] || ""}
				color={myAccount?.avatarColor}
				sxProps={{
					fontSize: "13px",
					color: theme.palette.primary.main,
					width: 34,
					height: 34,
				}}
			/>
		</Box>
	);

	const WorkspaceTabs = [
		{
			enabled: true,
			showCta: true,
			text: "Channels",
			key: "channels",
			active: false,
			component: <WorkspaceChannelList />,
			icons: {
				active: <ChannelDialOutlined />,
				inActive: <ChannelDialOutlined />,
			},
		},
		{
			enabled: directMessage,
			showCta: true,
			text: "DMs",
			key: "dms",
			active: false,
			component: <WorkspaceDmList />,
			icons: { active: <ChatBubble />, inActive: <ChatBubbleOutlined /> },
		},
		// {
		// 	enabled: true,
		// 	showCta: true,
		// 	text: "Drafts",
		// 	key: "drafts",
		// 	active: false,
		// 	component: <WorkspaceDmList />,
		// 	icons: {
		// 		active: <Icons.CellTower sx={{ width: 32, height: 33 }} />,
		// 		inActive: <Icons.CellTowerOutlined sx={{ width: 32, height: 33 }} />,
		// 	},
		// },
		{
			enabled: toolsTab,
			showCta: true,
			text: "Tools",
			key: "tools",
			active: false,
			component: <WorkspaceTools />,
			icons: {
				active: <Tools style={{ fill: theme.palette.primary.main }} />,
				inActive: <Tools style={{ fill: theme.palette.neutral.main }} />,
			},
		},
		{
			enabled: true,
			showCta: true,
			text: "You",
			key: "you",
			active: false,
			component: <WorkspaceUserProfile />,
			icons: { active: initialsContainer, inActive: initialsContainer },
		},
	]
		.map((item) => ({
			...item,
			active: activeTab === item.key,
			icon: activeTab === item.key ? item.icons.active : item.icons.inActive,
		}))
		.filter((tab) => tab.enabled);

	const currentActiveTab = WorkspaceTabs.find((tab) => tab.key === activeTab);

	return (
		<Stack
			sx={{
				flexGrow: 1,
				background: theme.palette.primary.dark,
				gap: 1,
			}}
		>
			<Stack sx={{ gap: 2, height: "100%" }}>
				{currentActiveTab?.showCta ? (
					<Box
						sx={{
							background: theme.palette.secondary.dark,
							borderRadius: 4,
						}}
					>
						<HandleHandsFreeToggle />
					</Box>
				) : null}
				<Box
					className="dark-scrollbar"
					sx={{
						maxHeight: "calc(100svh - 295px)",
						height: "100%",
						overflowY: "auto",
						background: theme.palette.secondary.dark,
						borderRadius: 4,
					}}
				>
					{currentActiveTab?.component}
				</Box>
			</Stack>
			<List
				sx={{
					position: "sticky",
					inset: "0 0 0 0",
					zIndex: 1100,
					display: "flex",
					p: 0,
					height: 90,
				}}
			>
				{WorkspaceTabs?.map((tab) => (
					<ListItem key={tab.key}>
						<Button
							aria-label={`Toggle ${tab.text} Tab`}
							sx={{
								color: tab.active
									? theme.palette.primary.main
									: theme.palette.neutral.main,
							}}
							onClick={() => {
								setActiveTab(() => tab.key);
							}}
						>
							<Box>
								{tab.icon}
								<Box>
									<Typography sx={{ fontSize: "12px", textTransform: "none" }}>
										{tab.text}
									</Typography>
								</Box>
							</Box>
						</Button>
					</ListItem>
				))}
			</List>
		</Stack>
	);
}
