import FeedHeader from "@/components/FeedHeader";
import LanguageDropdown from "@/components/LanguageDropdown";
import MainContent from "@/components/MainContent";
import MembersDrawer from "@/components/MembersDrawer";
import WorkspaceDrawer from "@/components/Workspace/WorkspaceDrawer";
import SBAppBar from "@/components/SBAppBar";
import { useElectric } from "@/electric/ElectricWrapper";
import useLocationChange from "@/hooks/useLocationChange";
import { AppContext } from "@/models/AppStateProvider";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { DataContext } from "@/models/DataProvider";
import { UxContext } from "@/models/UxStateProvider";
import { MAIN_DRAWER_WIDTH } from "@/utils";
import * as Icons from "@mui/icons-material";
import { Box, IconButton, Toolbar } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "@/components/LoadingScreen";
import WorkspaceModal from "@/components/Workspace/WorkspaceModal";
import ChannelModal from "@/components/ChannelModal";
import InviteToWorkspaceModal from "@/components/Workspace/InviteToWorkspaceModal";
import ChannelUsersModal from "@/components/Workspace/ChannelUsersModal";
import DmModal from "@/components/DmModal";
import ScheduleMessageModal from "@/components/Scheduling/ScheduleMessageModal";

export default function Workspace() {
	const { flags } = React.useContext(AppContext);
	const navigate = useNavigate();
	const params = useParams();
	const { db } = useElectric();
	const { currentFeedId, myAccount, bootstrapComplete } =
		React.useContext(DataContext);
	const { toggleChannelModal } = React.useContext(UxContext);
	const feedId = params.feedId || currentFeedId;

	const { resetQueue, handsFreeModeEnabled } =
		React.useContext(AudioAppContext);

	const {
		isSmUp,
		isMdUp,
		leftNavOpen,
		toggleLeftNav,
		setLeftNavOpen,
		rightNavOpen,
		toggleRightNav,
	} = React.useContext(UxContext);
	const [url, setUrl] = React.useState<string | null>(null);

	useLocationChange((location) => {
		const baseUrl = window.location.origin;
		setUrl(baseUrl + location.pathname);
		// if navigated away, then close the left nav
		setLeftNavOpen(false);
		if (!handsFreeModeEnabled) {
			resetQueue();
		}
	});

	const drawerStyles = {
		width: isSmUp ? MAIN_DRAWER_WIDTH : "100%",
		flexShrink: 0,
		"& .MuiDrawer-paper": {
			width: isSmUp ? MAIN_DRAWER_WIDTH : "100%",
			boxSizing: "border-box",
		},
	};

	const workspaceId = params.workspaceId;
	const { results: workspaces } = useLiveQuery(db.workspace.liveMany({}));

	React.useEffect(() => {
		if (bootstrapComplete) {
			if (!workspaceId && workspaces?.length > 0) {
				const topId = workspaces?.[0].id;
				navigate(`/workspaces/${topId}`);
			}
		}
	}, [navigate, workspaceId, workspaces, bootstrapComplete]);

	if (!bootstrapComplete) {
		return <LoadingScreen />;
	}

	return (
		<>
			<WorkspaceModal />
			<ChannelModal />
			<DmModal />
			<ChannelUsersModal />
			<InviteToWorkspaceModal />
			<ScheduleMessageModal />
			<Box sx={{ display: "flex" }} data-feed-layout-wrap>
				<SBAppBar data-app-bar position="fixed">
					<Toolbar
						data-toolbar
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignSelf: "center",
							width: "100%",
						}}
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								position: "relative",
							}}
						>
							<IconButton
								aria-label={`${leftNavOpen ? "Close" : "Open"} Teams List`}
								onClick={toggleLeftNav}
								edge="start"
								sx={{
									mr: { xs: 1, sm: feedId ? "63px" : 0 },
									background: "transparent",
									borderRadius: "1000px",
								}}
							>
								{leftNavOpen ? (
									<Icons.ArrowBack role="img" />
								) : (
									<Icons.Menu role="img" />
								)}
							</IconButton>
							<Box
								sx={{
									maxWidth: { xs: "200px", sm: "100%" },
									width: { xs: "auto", sm: "100%" },
									flex: "1 1 auto",
								}}
							>
								<FeedHeader />
							</Box>
							{feedId ? (
								<>
									<LanguageDropdown />
									<IconButton
										sx={{
											ml: { xs: 0, sm: 2 },
											background: "transparent",
											borderRadius: "1000px",
										}}
										aria-label={`${
											rightNavOpen ? "Close" : "Open"
										} Members List`}
										onClick={toggleRightNav}
										edge="start"
									>
										{rightNavOpen ? (
											<Icons.ArrowForward role="img" />
										) : (
											<Icons.PeopleAlt role="img" />
										)}
									</IconButton>
								</>
							) : null}
						</Box>
					</Toolbar>
				</SBAppBar>
				<WorkspaceDrawer url={url} sx={drawerStyles} />
				<MainContent
					leftOpen={leftNavOpen}
					rightOpen={rightNavOpen}
					sx={{
						height: "100svh",
						pt: "92px",
					}}
				>
					<Outlet />
				</MainContent>
				<MembersDrawer
					handleClose={toggleRightNav}
					url={url}
					sx={drawerStyles}
				/>
			</Box>
		</>
	);
}
