import InviteWorkspaceUsersLink from "@/components/Workspace/InviteWorkspaceUsersLink";
import InviteToWorkspaceModal from "@/components/Workspace/InviteToWorkspaceModal";
import WorkspaceInvitesTable from "@/components/Workspace/WorkspaceInvitesTable";
import WorkspaceMembersTable from "@/components/Workspace/WorkspaceMembersTable";
import { useElectric } from "@/electric/ElectricWrapper";
import { DataContext } from "@/models/DataProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import ModalForm from "@/elements/ModalForm";
import {
	upsertDirectWsInvitation,
	upsertWorkspaceMembership,
	upsertWsAccount,
} from "@/data/workspace";
import { AppContext } from "@/models/AppStateProvider";

export default function WorkspaceUserManagement() {
	const { client } = useContext(AppContext);
	const navigate = useNavigate();
	const { db } = useElectric();
	const { loadWorkspaceDetails } = useContext(DataContext);
	const { setNavTitle } = useContext(UxContext);
	const params = useParams();
	const workspaceId = params.workspaceId;
	const theme = useTheme();

	const [showMembersTable, setShowMembersTable] = useState<boolean>(true);
	const [showPendingInvites, setShowPendingInvites] = useState<boolean>(false);
	const [invalidUser, setInvalidUser] = useState<boolean>(false);

	const { results: myAccount } = useLiveQuery(
		db.account.liveFirst({
			where: {
				mine: 1,
			},
		}),
	);

	const { results: isAdmin } = useLiveQuery(
		db.workspace_membership.liveFirst({
			where: {
				workspaceId,
				accountId: myAccount?.id,
				status: "active",
				role: "admin",
			},
		}),
	);

	useEffect(() => {
		if (workspaceId && myAccount?.id && isAdmin?.id) {
			loadWorkspaceDetails(workspaceId);
		}
	}, [workspaceId, myAccount?.id, isAdmin?.id, loadWorkspaceDetails]);

	const { results: workspaceMemberCountResponse } = useLiveQuery(
		db.liveRaw({
			sql: `
			SELECT COUNT(workspace_membership.accountId) as memberCount
			FROM workspace_membership
            WHERE workspace_membership.workspaceId = ?
			AND workspace_membership.status = 'active'`,
			args: [workspaceId],
		}),
	);

	const { results: workspaceInviteCountResponse } = useLiveQuery(
		db.liveRaw({
			sql: `
			SELECT COUNT(direct_ws_invitation.workspaceMembershipId) as inviteCount
			FROM direct_ws_invitation
			JOIN workspace_membership ON direct_ws_invitation.workspaceMembershipId = workspace_membership.id
			WHERE workspace_membership.status = 'active'
			AND workspace_membership.workspaceId = ?
			AND direct_ws_invitation.claimedBy is null;
			`,
			args: [workspaceId],
		}),
	);

	const workspaceMemberCount = workspaceMemberCountResponse?.[0]?.memberCount;
	const workspaceInviteCount = workspaceInviteCountResponse?.[0]?.inviteCount;

	const showMembersView = () => {
		setShowMembersTable(true);
		setShowPendingInvites(false);
	};

	const showInvitesView = () => {
		setShowMembersTable(false);
		setShowPendingInvites(true);
	};

	useEffectOnce(() => {
		setNavTitle("Manage members");
	});

	const { results: userInfo } = useLiveQuery(() => {
		return db.account.liveFirst({
			where: {
				mine: 1,
			},
		});
	}, []);

	const { results: workspaceMembership } = useLiveQuery(() => {
		if (!userInfo) return;
		return db.workspace_membership.liveFirst({
			where: {
				workspaceId: workspaceId,
				accountId: userInfo?.id,
				status: "active",
			},
		});
	}, [userInfo]);

	useEffect(() => {
		if (!userInfo || !workspaceMembership?.role) {
			return;
		}
		if (workspaceMembership?.role !== "admin") {
			setInvalidUser(() => true);
		} else {
			setInvalidUser(() => false);
		}
	}, [workspaceMembership, userInfo]);

	const closeModalAndRedirect = () => {
		navigate(`/workspaces/${workspaceId}`);
	};

	if (invalidUser) {
		return (
			<ModalForm open={invalidUser} onClose={() => closeModalAndRedirect()}>
				<div>
					<Typography
						variant="h5"
						component="h2"
						sx={{ mb: 2, fontWeight: 700 }}
					>
						You do not have rights to view this content
					</Typography>

					<div>
						Please contact your Storyboard administrator for more information
					</div>
				</div>
			</ModalForm>
		);
	}

	return (
		<>
			<InviteToWorkspaceModal />
			<Stack
				className="dark-scrollbar"
				sx={{
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
					height: "100%",
					py: 8,
					px: 2,
					overflow: "auto",
				}}
			>
				<Stack
					sx={{
						width: "100%",
						maxWidth: 1300,
						height: "auto",
						pb: 6,
						flexDirection: { xs: "column", sm: "row" },
						justifyContent: { xs: "flex-start", sm: "space-between" },
						gap: 2,
					}}
				>
					<Stack
						sx={{
							flexDirection: "row",
							justifyContent: { xs: "space-bewteen", sm: "flex-start" },
							gap: { xs: 1, sm: 3 },
							height: 80,
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<Button
							onClick={showMembersView}
							sx={{
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 1,
								background: showMembersTable
									? theme.palette.secondary.main
									: theme.palette.secondary.dark,
								maxWidth: { xs: "calc(50% - 8px)", sm: 200 },
								height: "100%",
								textTransform: "none",
								fontWeight: 700,
								flex: "0 0 auto",
							}}
						>
							{workspaceMemberCount}{" "}
							{workspaceMemberCount === 1 ? "Member" : "Members"}
						</Button>
						<Button
							onClick={showInvitesView}
							sx={{
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 1,
								background: showPendingInvites
									? theme.palette.secondary.main
									: theme.palette.secondary.dark,
								textTransform: "none",
								fontWeight: 700,
								maxWidth: { xs: "calc(50% - 8px)", sm: 215 },
								height: "100%",
								flex: "0 0 auto",
							}}
						>
							{workspaceInviteCount > 0 ? workspaceInviteCount : ""}
							{" Pending "}
							{workspaceInviteCount === 1 ? "invite" : "invites"}
						</Button>
					</Stack>
					<Stack
						sx={{
							flexDirection: "row",
							gap: 3,
							alignItems: "center",
						}}
					>
						{/* <Typography fontWeight="700">xxx remaining licenses</Typography> */}
						<InviteWorkspaceUsersLink sx={{ minWidth: 245 }} />
					</Stack>
				</Stack>
				{showMembersTable && !showPendingInvites ? (
					<WorkspaceMembersTable />
				) : null}
				{!showMembersTable && showPendingInvites ? (
					<WorkspaceInvitesTable />
				) : null}
			</Stack>
		</>
	);
}
