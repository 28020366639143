import { FeedContext } from "@/models/FeedContextProvider";
import ModalForm from "@/elements/ModalForm";
import { useContext, useState } from "react";
import ConfirmDiscardChanges from "@/components/ModalForms/ConfirmDiscardChanges";
import TemplatesModal from "@/components/ModalForms/TemplatesModal";
import TTSForm from "@/components/ModalForms/TTSForm";
import { Box } from "@mui/material";

export default function TTSModal() {
	const { textAsInputModal, setTextAsInputModal } = useContext(FeedContext);
	const [templateModalStatus, setTemplateModalStatus] =
		useState<boolean>(false);
	const [hasTTSText, setHasTTSText] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [unsavedTemplateData, setUnsavedTemplateData] =
		useState<boolean>(false);
	const [ttsFormText, setTTSFormText] = useState<string>("");

	const reset = () => {
		setHasTTSText(() => false);
		setShowConfirmModal(() => false);
		setTemplateModalStatus(() => false);
		setTTSFormText(() => "");
		setTextAsInputModal(false);
	};

	const handleClose = (forceClose?: boolean) => {
		if (forceClose === true) {
			return reset();
		} else if (unsavedTemplateData) {
			setShowConfirmModal(() => true);
		} else if (templateModalStatus) {
			setTemplateModalStatus(() => false);
		} else if (hasTTSText) {
			setShowConfirmModal(() => true);
			return;
		} else {
			setTextAsInputModal(false);
		}
	};

	return (
		<ModalForm
			id="tts-modal"
			open={textAsInputModal}
			onClose={handleClose}
			maxWidth={1024}
			sx={{
				justifyContent: "start",
				minHeight: "500px",
				overflow: "hidden",
				padding: 0,
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					height: "100%",
				}}
			>
				{showConfirmModal && (
					<ConfirmDiscardChanges
						label="Unsaved changes"
						text="Are you sure you want to discard this message? Your content will be lost."
						setStatus={(value) => {
							if (value) {
								handleClose(true);
							} else {
								setShowConfirmModal(() => false);
							}
						}}
					/>
				)}

				<TemplatesModal
					visible={templateModalStatus === true}
					setTemplateTextAsTTS={(data) => {
						setTTSFormText(() => data);
						setTemplateModalStatus(() => false);
						setHasTTSText(() => true);
					}}
					setActiveEdit={(activeEditTemplateValue) =>
						setUnsavedTemplateData(() => activeEditTemplateValue)
					}
				/>
				<TTSForm
					ttsFormText={ttsFormText}
					loadTemplateModal={(value) => setTemplateModalStatus(() => value)}
					setTTSTextValue={(value: string) =>
						setHasTTSText(() => value?.length > 0)
					}
					closeTTSModal={() => {
						handleClose(true);
						setTTSFormText(() => "");
					}}
				/>
			</Box>
		</ModalForm>
	);
}
