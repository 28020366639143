/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateFeedRequest,
  CreateFeedResponse,
  Feed,
  FeedEventsResponse,
  FeedItemsResponse,
  InviteViaEmailRequest,
  Item,
  PublishToFeedRequest,
  UpdateFeedRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Feeds<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name FeedsCreate
   * @summary createFeed
   * @request POST:/feeds
   */
  feedsCreate = (body: CreateFeedRequest, params: RequestParams = {}) =>
    this.request<CreateFeedResponse, any>({
      path: `/feeds`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsDetail
   * @summary getFeedById
   * @request GET:/feeds/{feedId}
   */
  feedsDetail = (feedId: string, params: RequestParams = {}) =>
    this.request<Feed, any>({
      path: `/feeds/${feedId}`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsPartialUpdate
   * @summary updateFeed
   * @request PATCH:/feeds/{feedId}
   */
  feedsPartialUpdate = (feedId: string, body: UpdateFeedRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/feeds/${feedId}`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name InviteViaEmailCreate
   * @summary inviteViaEmail
   * @request POST:/feeds/{feedId}/invite-via-email
   */
  inviteViaEmailCreate = (feedId: string, body: InviteViaEmailRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/feeds/${feedId}/invite-via-email`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ItemsCreate
   * @summary publishToFeed
   * @request POST:/feeds/{feedId}/items
   */
  itemsCreate = (feedId: string, body: PublishToFeedRequest, params: RequestParams = {}) =>
    this.request<Item, any>({
      path: `/feeds/${feedId}/items`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name ItemsDetail
   * @summary getFeedItemsByFeedId
   * @request GET:/feeds/{feedId}/items
   */
  itemsDetail = (
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<FeedItemsResponse, any>({
      path: `/feeds/${feedId}/items`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name UnSubscribeCreate
   * @summary unSubscribe
   * @request POST:/feeds/{feedId}/unSubscribe
   */
  unSubscribeCreate = (feedId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/feeds/${feedId}/unSubscribe`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name EventsDetail
   * @summary getFeedEventsByFeedId
   * @request GET:/feeds/{feedId}/events
   */
  eventsDetail = (feedId: string, params: RequestParams = {}) =>
    this.request<FeedEventsResponse, any>({
      path: `/feeds/${feedId}/events`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name SubscribeDetail
   * @summary subscribeToFeed
   * @request GET:/feeds/{feedId}/subscribe
   */
  subscribeDetail = (feedId: string, params: RequestParams = {}) =>
    this.request<Feed, any>({
      path: `/feeds/${feedId}/subscribe`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name DetailsDetail
   * @summary getFeedDetailsByFeedId
   * @request GET:/feeds/{feedId}/details
   */
  detailsDetail = (feedId: string, params: RequestParams = {}) =>
    this.request<Feed, any>({
      path: `/feeds/${feedId}/details`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
