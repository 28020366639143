export default [
  {
    "statements": [
      "CREATE TABLE \"account\" (\n  \"id\" TEXT NOT NULL,\n  \"firstName\" TEXT,\n  \"lastName\" TEXT,\n  \"email\" TEXT,\n  \"emailVerified\" INTEGER DEFAULT 0 NOT NULL,\n  \"phoneNumber\" TEXT,\n  \"phoneNumberVerified\" INTEGER DEFAULT 0 NOT NULL,\n  \"name\" TEXT,\n  \"avatarColor\" TEXT,\n  \"accountType\" TEXT,\n  \"mine\" INTEGER DEFAULT 0 NOT NULL,\n  \"preferredLanguage\" TEXT,\n  CONSTRAINT \"account_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.account', 1);\n    ",
      "\n    /* Triggers for table account */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_account_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_account_primarykey\n      BEFORE UPDATE ON main.account\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_account_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_account_into_oplog\n       AFTER INSERT ON main.account\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.account')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'account', 'INSERT', json_object('id', new.id), json_object('accountType', new.accountType, 'avatarColor', new.avatarColor, 'email', new.email, 'emailVerified', new.emailVerified, 'firstName', new.firstName, 'id', new.id, 'lastName', new.lastName, 'mine', new.mine, 'name', new.name, 'phoneNumber', new.phoneNumber, 'phoneNumberVerified', new.phoneNumberVerified, 'preferredLanguage', new.preferredLanguage), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_account_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_account_into_oplog\n       AFTER UPDATE ON main.account\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.account')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'account', 'UPDATE', json_object('id', new.id), json_object('accountType', new.accountType, 'avatarColor', new.avatarColor, 'email', new.email, 'emailVerified', new.emailVerified, 'firstName', new.firstName, 'id', new.id, 'lastName', new.lastName, 'mine', new.mine, 'name', new.name, 'phoneNumber', new.phoneNumber, 'phoneNumberVerified', new.phoneNumberVerified, 'preferredLanguage', new.preferredLanguage), json_object('accountType', old.accountType, 'avatarColor', old.avatarColor, 'email', old.email, 'emailVerified', old.emailVerified, 'firstName', old.firstName, 'id', old.id, 'lastName', old.lastName, 'mine', old.mine, 'name', old.name, 'phoneNumber', old.phoneNumber, 'phoneNumberVerified', old.phoneNumberVerified, 'preferredLanguage', old.preferredLanguage), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_account_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_account_into_oplog\n       AFTER DELETE ON main.account\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.account')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'account', 'DELETE', json_object('id', old.id), NULL, json_object('accountType', old.accountType, 'avatarColor', old.avatarColor, 'email', old.email, 'emailVerified', old.emailVerified, 'firstName', old.firstName, 'id', old.id, 'lastName', old.lastName, 'mine', old.mine, 'name', old.name, 'phoneNumber', old.phoneNumber, 'phoneNumberVerified', old.phoneNumberVerified, 'preferredLanguage', old.preferredLanguage), NULL);\n    END;\n    "
    ],
    "version": "20240116224027_656"
  },
  {
    "statements": [
      "CREATE TABLE \"account_event\" (\n  \"id\" TEXT NOT NULL,\n  \"accountId\" TEXT NOT NULL,\n  \"name\" TEXT NOT NULL,\n  \"createdAt\" TEXT NOT NULL,\n  \"feedId\" TEXT,\n  \"itemId\" TEXT,\n  \"contentId\" TEXT,\n  CONSTRAINT \"account_event_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE UNIQUE INDEX \"account_event_accountId_feedId_itemId_contentId_name_key\" ON \"account_event\" (\"accountId\" ASC, \"feedId\" ASC, \"itemId\" ASC, \"contentId\" ASC, \"name\" ASC);\n",
      "CREATE INDEX \"account_event_createdAt_idx\" ON \"account_event\" (\"createdAt\" DESC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.account_event', 1);\n    ",
      "\n    /* Triggers for table account_event */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_account_event_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_account_event_primarykey\n      BEFORE UPDATE ON main.account_event\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_account_event_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_account_event_into_oplog\n       AFTER INSERT ON main.account_event\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.account_event')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'account_event', 'INSERT', json_object('id', new.id), json_object('accountId', new.accountId, 'contentId', new.contentId, 'createdAt', new.createdAt, 'feedId', new.feedId, 'id', new.id, 'itemId', new.itemId, 'name', new.name), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_account_event_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_account_event_into_oplog\n       AFTER UPDATE ON main.account_event\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.account_event')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'account_event', 'UPDATE', json_object('id', new.id), json_object('accountId', new.accountId, 'contentId', new.contentId, 'createdAt', new.createdAt, 'feedId', new.feedId, 'id', new.id, 'itemId', new.itemId, 'name', new.name), json_object('accountId', old.accountId, 'contentId', old.contentId, 'createdAt', old.createdAt, 'feedId', old.feedId, 'id', old.id, 'itemId', old.itemId, 'name', old.name), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_account_event_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_account_event_into_oplog\n       AFTER DELETE ON main.account_event\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.account_event')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'account_event', 'DELETE', json_object('id', old.id), NULL, json_object('accountId', old.accountId, 'contentId', old.contentId, 'createdAt', old.createdAt, 'feedId', old.feedId, 'id', old.id, 'itemId', old.itemId, 'name', old.name), NULL);\n    END;\n    "
    ],
    "version": "20240116224027_778"
  },
  {
    "statements": [
      "CREATE TABLE \"audio_encoding\" (\n  \"id\" TEXT NOT NULL,\n  \"contentId\" TEXT NOT NULL,\n  \"codec\" TEXT NOT NULL,\n  \"mimeType\" TEXT,\n  \"duration\" REAL NOT NULL,\n  \"url\" TEXT NOT NULL,\n  \"transcriptionId\" TEXT,\n  \"transcriptionType\" TEXT,\n  \"language\" TEXT,\n  \"translatedFrom\" TEXT,\n  \"priority\" INTEGER DEFAULT 0 NOT NULL,\n  \"generatedVoice\" TEXT,\n  \"generatedService\" TEXT,\n  \"createdAt\" TEXT,\n  CONSTRAINT \"audio_encoding_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE INDEX \"audio_encoding_contentId_idx\" ON \"audio_encoding\" (\"contentId\" ASC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.audio_encoding', 1);\n    ",
      "\n    /* Triggers for table audio_encoding */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_audio_encoding_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_audio_encoding_primarykey\n      BEFORE UPDATE ON main.audio_encoding\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_audio_encoding_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_audio_encoding_into_oplog\n       AFTER INSERT ON main.audio_encoding\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.audio_encoding')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'audio_encoding', 'INSERT', json_object('id', new.id), json_object('codec', new.codec, 'contentId', new.contentId, 'createdAt', new.createdAt, 'duration', new.duration, 'generatedService', new.generatedService, 'generatedVoice', new.generatedVoice, 'id', new.id, 'language', new.language, 'mimeType', new.mimeType, 'priority', new.priority, 'transcriptionId', new.transcriptionId, 'transcriptionType', new.transcriptionType, 'translatedFrom', new.translatedFrom, 'url', new.url), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_audio_encoding_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_audio_encoding_into_oplog\n       AFTER UPDATE ON main.audio_encoding\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.audio_encoding')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'audio_encoding', 'UPDATE', json_object('id', new.id), json_object('codec', new.codec, 'contentId', new.contentId, 'createdAt', new.createdAt, 'duration', new.duration, 'generatedService', new.generatedService, 'generatedVoice', new.generatedVoice, 'id', new.id, 'language', new.language, 'mimeType', new.mimeType, 'priority', new.priority, 'transcriptionId', new.transcriptionId, 'transcriptionType', new.transcriptionType, 'translatedFrom', new.translatedFrom, 'url', new.url), json_object('codec', old.codec, 'contentId', old.contentId, 'createdAt', old.createdAt, 'duration', old.duration, 'generatedService', old.generatedService, 'generatedVoice', old.generatedVoice, 'id', old.id, 'language', old.language, 'mimeType', old.mimeType, 'priority', old.priority, 'transcriptionId', old.transcriptionId, 'transcriptionType', old.transcriptionType, 'translatedFrom', old.translatedFrom, 'url', old.url), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_audio_encoding_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_audio_encoding_into_oplog\n       AFTER DELETE ON main.audio_encoding\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.audio_encoding')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'audio_encoding', 'DELETE', json_object('id', old.id), NULL, json_object('codec', old.codec, 'contentId', old.contentId, 'createdAt', old.createdAt, 'duration', old.duration, 'generatedService', old.generatedService, 'generatedVoice', old.generatedVoice, 'id', old.id, 'language', old.language, 'mimeType', old.mimeType, 'priority', old.priority, 'transcriptionId', old.transcriptionId, 'transcriptionType', old.transcriptionType, 'translatedFrom', old.translatedFrom, 'url', old.url), NULL);\n    END;\n    "
    ],
    "version": "20240116224027_843"
  },
  {
    "statements": [
      "CREATE TABLE \"feed\" (\n  \"id\" TEXT NOT NULL,\n  \"title\" TEXT,\n  \"workspaceId\" TEXT,\n  \"feedType\" TEXT DEFAULT (CAST('userCreated' AS TEXT)) NOT NULL,\n  \"readOnly\" INTEGER DEFAULT 0 NOT NULL,\n  \"isPrivate\" INTEGER DEFAULT 0 NOT NULL,\n  \"isDm\" INTEGER DEFAULT 0 NOT NULL,\n  \"loadedFirstPage\" INTEGER DEFAULT 0 NOT NULL,\n  \"loadedLastPage\" INTEGER DEFAULT 0 NOT NULL,\n  \"loadedEvents\" INTEGER DEFAULT 0 NOT NULL,\n  \"loadedPermissions\" INTEGER DEFAULT 0 NOT NULL,\n  \"createdAt\" TEXT,\n  \"updatedAt\" TEXT,\n  \"latestActivity\" TEXT,\n  \"lastOpened\" TEXT,\n  CONSTRAINT \"feed_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE INDEX \"feed_updatedAt_idx\" ON \"feed\" (\"updatedAt\" DESC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.feed', 1);\n    ",
      "\n    /* Triggers for table feed */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_feed_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_feed_primarykey\n      BEFORE UPDATE ON main.feed\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_feed_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_feed_into_oplog\n       AFTER INSERT ON main.feed\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.feed')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'feed', 'INSERT', json_object('id', new.id), json_object('createdAt', new.createdAt, 'feedType', new.feedType, 'id', new.id, 'isDm', new.isDm, 'isPrivate', new.isPrivate, 'lastOpened', new.lastOpened, 'latestActivity', new.latestActivity, 'loadedEvents', new.loadedEvents, 'loadedFirstPage', new.loadedFirstPage, 'loadedLastPage', new.loadedLastPage, 'loadedPermissions', new.loadedPermissions, 'readOnly', new.readOnly, 'title', new.title, 'updatedAt', new.updatedAt, 'workspaceId', new.workspaceId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_feed_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_feed_into_oplog\n       AFTER UPDATE ON main.feed\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.feed')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'feed', 'UPDATE', json_object('id', new.id), json_object('createdAt', new.createdAt, 'feedType', new.feedType, 'id', new.id, 'isDm', new.isDm, 'isPrivate', new.isPrivate, 'lastOpened', new.lastOpened, 'latestActivity', new.latestActivity, 'loadedEvents', new.loadedEvents, 'loadedFirstPage', new.loadedFirstPage, 'loadedLastPage', new.loadedLastPage, 'loadedPermissions', new.loadedPermissions, 'readOnly', new.readOnly, 'title', new.title, 'updatedAt', new.updatedAt, 'workspaceId', new.workspaceId), json_object('createdAt', old.createdAt, 'feedType', old.feedType, 'id', old.id, 'isDm', old.isDm, 'isPrivate', old.isPrivate, 'lastOpened', old.lastOpened, 'latestActivity', old.latestActivity, 'loadedEvents', old.loadedEvents, 'loadedFirstPage', old.loadedFirstPage, 'loadedLastPage', old.loadedLastPage, 'loadedPermissions', old.loadedPermissions, 'readOnly', old.readOnly, 'title', old.title, 'updatedAt', old.updatedAt, 'workspaceId', old.workspaceId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_feed_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_feed_into_oplog\n       AFTER DELETE ON main.feed\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.feed')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'feed', 'DELETE', json_object('id', old.id), NULL, json_object('createdAt', old.createdAt, 'feedType', old.feedType, 'id', old.id, 'isDm', old.isDm, 'isPrivate', old.isPrivate, 'lastOpened', old.lastOpened, 'latestActivity', old.latestActivity, 'loadedEvents', old.loadedEvents, 'loadedFirstPage', old.loadedFirstPage, 'loadedLastPage', old.loadedLastPage, 'loadedPermissions', old.loadedPermissions, 'readOnly', old.readOnly, 'title', old.title, 'updatedAt', old.updatedAt, 'workspaceId', old.workspaceId), NULL);\n    END;\n    "
    ],
    "version": "20240116224027_912"
  },
  {
    "statements": [
      "CREATE TABLE \"item\" (\n  \"id\" TEXT NOT NULL,\n  \"feedId\" TEXT NOT NULL,\n  \"contentId\" TEXT NOT NULL,\n  \"groupId\" TEXT,\n  \"deletedAt\" TEXT,\n  \"accountId\" TEXT,\n  \"createdAt\" TEXT NOT NULL,\n  \"status\" TEXT DEFAULT (CAST('Completed' AS TEXT)),\n  CONSTRAINT \"item_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE INDEX \"item_contentId_idx\" ON \"item\" (\"contentId\" ASC);\n",
      "CREATE INDEX \"item_feedId_createdAt_idx\" ON \"item\" (\"feedId\" ASC, \"createdAt\" DESC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.item', 1);\n    ",
      "\n    /* Triggers for table item */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_item_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_item_primarykey\n      BEFORE UPDATE ON main.item\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_item_into_oplog\n       AFTER INSERT ON main.item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'item', 'INSERT', json_object('id', new.id), json_object('accountId', new.accountId, 'contentId', new.contentId, 'createdAt', new.createdAt, 'deletedAt', new.deletedAt, 'feedId', new.feedId, 'groupId', new.groupId, 'id', new.id, 'status', new.status), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_item_into_oplog\n       AFTER UPDATE ON main.item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'item', 'UPDATE', json_object('id', new.id), json_object('accountId', new.accountId, 'contentId', new.contentId, 'createdAt', new.createdAt, 'deletedAt', new.deletedAt, 'feedId', new.feedId, 'groupId', new.groupId, 'id', new.id, 'status', new.status), json_object('accountId', old.accountId, 'contentId', old.contentId, 'createdAt', old.createdAt, 'deletedAt', old.deletedAt, 'feedId', old.feedId, 'groupId', old.groupId, 'id', old.id, 'status', old.status), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_item_into_oplog\n       AFTER DELETE ON main.item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'item', 'DELETE', json_object('id', old.id), NULL, json_object('accountId', old.accountId, 'contentId', old.contentId, 'createdAt', old.createdAt, 'deletedAt', old.deletedAt, 'feedId', old.feedId, 'groupId', old.groupId, 'id', old.id, 'status', old.status), NULL);\n    END;\n    "
    ],
    "version": "20240116224027_982"
  },
  {
    "statements": [
      "CREATE TABLE \"file\" (\n  \"id\" TEXT NOT NULL,\n  \"contentId\" TEXT NOT NULL,\n  \"url\" TEXT NOT NULL,\n  \"mimeType\" TEXT,\n  \"name\" TEXT,\n  CONSTRAINT \"file_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE INDEX \"file_contentId_idx\" ON \"file\" (\"contentId\" ASC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.file', 1);\n    ",
      "\n    /* Triggers for table file */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_file_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_file_primarykey\n      BEFORE UPDATE ON main.file\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_file_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_file_into_oplog\n       AFTER INSERT ON main.file\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.file')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'file', 'INSERT', json_object('id', new.id), json_object('contentId', new.contentId, 'id', new.id, 'mimeType', new.mimeType, 'name', new.name, 'url', new.url), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_file_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_file_into_oplog\n       AFTER UPDATE ON main.file\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.file')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'file', 'UPDATE', json_object('id', new.id), json_object('contentId', new.contentId, 'id', new.id, 'mimeType', new.mimeType, 'name', new.name, 'url', new.url), json_object('contentId', old.contentId, 'id', old.id, 'mimeType', old.mimeType, 'name', old.name, 'url', old.url), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_file_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_file_into_oplog\n       AFTER DELETE ON main.file\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.file')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'file', 'DELETE', json_object('id', old.id), NULL, json_object('contentId', old.contentId, 'id', old.id, 'mimeType', old.mimeType, 'name', old.name, 'url', old.url), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_051"
  },
  {
    "statements": [
      "CREATE TABLE \"link\" (\n  \"id\" TEXT NOT NULL,\n  \"contentId\" TEXT NOT NULL,\n  \"url\" TEXT NOT NULL,\n  \"description\" TEXT,\n  \"title\" TEXT,\n  \"image\" TEXT,\n  \"linkOrder\" INTEGER DEFAULT 0 NOT NULL,\n  CONSTRAINT \"link_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE UNIQUE INDEX \"link_contentId_url_key\" ON \"link\" (\"contentId\" ASC, \"url\" ASC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.link', 1);\n    ",
      "\n    /* Triggers for table link */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_link_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_link_primarykey\n      BEFORE UPDATE ON main.link\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_link_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_link_into_oplog\n       AFTER INSERT ON main.link\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.link')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'link', 'INSERT', json_object('id', new.id), json_object('contentId', new.contentId, 'description', new.description, 'id', new.id, 'image', new.image, 'linkOrder', new.linkOrder, 'title', new.title, 'url', new.url), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_link_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_link_into_oplog\n       AFTER UPDATE ON main.link\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.link')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'link', 'UPDATE', json_object('id', new.id), json_object('contentId', new.contentId, 'description', new.description, 'id', new.id, 'image', new.image, 'linkOrder', new.linkOrder, 'title', new.title, 'url', new.url), json_object('contentId', old.contentId, 'description', old.description, 'id', old.id, 'image', old.image, 'linkOrder', old.linkOrder, 'title', old.title, 'url', old.url), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_link_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_link_into_oplog\n       AFTER DELETE ON main.link\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.link')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'link', 'DELETE', json_object('id', old.id), NULL, json_object('contentId', old.contentId, 'description', old.description, 'id', old.id, 'image', old.image, 'linkOrder', old.linkOrder, 'title', old.title, 'url', old.url), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_116"
  },
  {
    "statements": [
      "CREATE TABLE \"transcription\" (\n  \"id\" TEXT NOT NULL,\n  \"contentId\" TEXT NOT NULL,\n  \"transcriptionType\" TEXT NOT NULL,\n  \"transcriptionContent\" TEXT NOT NULL,\n  \"language\" TEXT DEFAULT (CAST('en' AS TEXT)) NOT NULL,\n  \"translatedFrom\" TEXT,\n  \"backendModel\" TEXT,\n  \"priority\" INTEGER DEFAULT 0 NOT NULL,\n  \"confidence\" REAL,\n  \"executionTime\" REAL DEFAULT 0 NOT NULL,\n  \"format\" TEXT DEFAULT (CAST('Text' AS TEXT)) NOT NULL,\n  \"pipeline\" TEXT,\n  \"bucket\" TEXT,\n  \"key\" TEXT,\n  \"url\" TEXT,\n  \"createdAt\" TEXT,\n  CONSTRAINT \"transcription_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE INDEX \"transcription_contentId_idx\" ON \"transcription\" (\"contentId\" ASC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.transcription', 1);\n    ",
      "\n    /* Triggers for table transcription */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_transcription_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_transcription_primarykey\n      BEFORE UPDATE ON main.transcription\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_transcription_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_transcription_into_oplog\n       AFTER INSERT ON main.transcription\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.transcription')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'transcription', 'INSERT', json_object('id', new.id), json_object('backendModel', new.backendModel, 'bucket', new.bucket, 'confidence', new.confidence, 'contentId', new.contentId, 'createdAt', new.createdAt, 'executionTime', new.executionTime, 'format', new.format, 'id', new.id, 'key', new.key, 'language', new.language, 'pipeline', new.pipeline, 'priority', new.priority, 'transcriptionContent', new.transcriptionContent, 'transcriptionType', new.transcriptionType, 'translatedFrom', new.translatedFrom, 'url', new.url), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_transcription_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_transcription_into_oplog\n       AFTER UPDATE ON main.transcription\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.transcription')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'transcription', 'UPDATE', json_object('id', new.id), json_object('backendModel', new.backendModel, 'bucket', new.bucket, 'confidence', new.confidence, 'contentId', new.contentId, 'createdAt', new.createdAt, 'executionTime', new.executionTime, 'format', new.format, 'id', new.id, 'key', new.key, 'language', new.language, 'pipeline', new.pipeline, 'priority', new.priority, 'transcriptionContent', new.transcriptionContent, 'transcriptionType', new.transcriptionType, 'translatedFrom', new.translatedFrom, 'url', new.url), json_object('backendModel', old.backendModel, 'bucket', old.bucket, 'confidence', old.confidence, 'contentId', old.contentId, 'createdAt', old.createdAt, 'executionTime', old.executionTime, 'format', old.format, 'id', old.id, 'key', old.key, 'language', old.language, 'pipeline', old.pipeline, 'priority', old.priority, 'transcriptionContent', old.transcriptionContent, 'transcriptionType', old.transcriptionType, 'translatedFrom', old.translatedFrom, 'url', old.url), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_transcription_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_transcription_into_oplog\n       AFTER DELETE ON main.transcription\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.transcription')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'transcription', 'DELETE', json_object('id', old.id), NULL, json_object('backendModel', old.backendModel, 'bucket', old.bucket, 'confidence', old.confidence, 'contentId', old.contentId, 'createdAt', old.createdAt, 'executionTime', old.executionTime, 'format', old.format, 'id', old.id, 'key', old.key, 'language', old.language, 'pipeline', old.pipeline, 'priority', old.priority, 'transcriptionContent', old.transcriptionContent, 'transcriptionType', old.transcriptionType, 'translatedFrom', old.translatedFrom, 'url', old.url), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_178"
  },
  {
    "statements": [
      "CREATE TABLE \"permission\" (\n  \"id\" TEXT NOT NULL,\n  \"name\" TEXT NOT NULL,\n  \"accountId\" TEXT,\n  \"workspace_membershipId\" TEXT,\n  \"feedId\" TEXT,\n  \"workflowItemId\" TEXT,\n  \"enabled\" INTEGER NOT NULL,\n  \"expiresAt\" TEXT,\n  CONSTRAINT \"permission_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE INDEX \"permission_feedId_idx\" ON \"permission\" (\"feedId\" ASC);\n",
      "CREATE INDEX \"permission_workflowItemId_idx\" ON \"permission\" (\"workflowItemId\" ASC);\n",
      "CREATE INDEX \"permission_workspace_membershipId_idx\" ON \"permission\" (\"workspace_membershipId\" ASC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.permission', 1);\n    ",
      "\n    /* Triggers for table permission */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_permission_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_permission_primarykey\n      BEFORE UPDATE ON main.permission\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_permission_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_permission_into_oplog\n       AFTER INSERT ON main.permission\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.permission')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'permission', 'INSERT', json_object('id', new.id), json_object('accountId', new.accountId, 'enabled', new.enabled, 'expiresAt', new.expiresAt, 'feedId', new.feedId, 'id', new.id, 'name', new.name, 'workflowItemId', new.workflowItemId, 'workspace_membershipId', new.workspace_membershipId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_permission_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_permission_into_oplog\n       AFTER UPDATE ON main.permission\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.permission')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'permission', 'UPDATE', json_object('id', new.id), json_object('accountId', new.accountId, 'enabled', new.enabled, 'expiresAt', new.expiresAt, 'feedId', new.feedId, 'id', new.id, 'name', new.name, 'workflowItemId', new.workflowItemId, 'workspace_membershipId', new.workspace_membershipId), json_object('accountId', old.accountId, 'enabled', old.enabled, 'expiresAt', old.expiresAt, 'feedId', old.feedId, 'id', old.id, 'name', old.name, 'workflowItemId', old.workflowItemId, 'workspace_membershipId', old.workspace_membershipId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_permission_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_permission_into_oplog\n       AFTER DELETE ON main.permission\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.permission')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'permission', 'DELETE', json_object('id', old.id), NULL, json_object('accountId', old.accountId, 'enabled', old.enabled, 'expiresAt', old.expiresAt, 'feedId', old.feedId, 'id', old.id, 'name', old.name, 'workflowItemId', old.workflowItemId, 'workspace_membershipId', old.workspace_membershipId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_246"
  },
  {
    "statements": [
      "CREATE TABLE \"workspace\" (\n  \"id\" TEXT NOT NULL,\n  \"name\" TEXT NOT NULL,\n  CONSTRAINT \"workspace_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.workspace', 1);\n    ",
      "\n    /* Triggers for table workspace */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_workspace_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_workspace_primarykey\n      BEFORE UPDATE ON main.workspace\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_workspace_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_workspace_into_oplog\n       AFTER INSERT ON main.workspace\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workspace')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workspace', 'INSERT', json_object('id', new.id), json_object('id', new.id, 'name', new.name), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_workspace_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_workspace_into_oplog\n       AFTER UPDATE ON main.workspace\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workspace')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workspace', 'UPDATE', json_object('id', new.id), json_object('id', new.id, 'name', new.name), json_object('id', old.id, 'name', old.name), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_workspace_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_workspace_into_oplog\n       AFTER DELETE ON main.workspace\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workspace')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workspace', 'DELETE', json_object('id', old.id), NULL, json_object('id', old.id, 'name', old.name), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_309"
  },
  {
    "statements": [
      "CREATE TABLE \"workspace_membership\" (\n  \"id\" TEXT NOT NULL,\n  \"workspaceId\" TEXT NOT NULL,\n  \"accountId\" TEXT,\n  \"status\" TEXT NOT NULL,\n  \"role\" TEXT NOT NULL,\n  CONSTRAINT \"workspace_membership_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE INDEX \"workspace_membership_accountId_idx\" ON \"workspace_membership\" (\"accountId\" ASC);\n",
      "CREATE UNIQUE INDEX \"workspace_membership_workspaceId_accountId_key\" ON \"workspace_membership\" (\"workspaceId\" ASC, \"accountId\" ASC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.workspace_membership', 1);\n    ",
      "\n    /* Triggers for table workspace_membership */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_workspace_membership_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_workspace_membership_primarykey\n      BEFORE UPDATE ON main.workspace_membership\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_workspace_membership_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_workspace_membership_into_oplog\n       AFTER INSERT ON main.workspace_membership\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workspace_membership')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workspace_membership', 'INSERT', json_object('id', new.id), json_object('accountId', new.accountId, 'id', new.id, 'role', new.role, 'status', new.status, 'workspaceId', new.workspaceId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_workspace_membership_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_workspace_membership_into_oplog\n       AFTER UPDATE ON main.workspace_membership\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workspace_membership')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workspace_membership', 'UPDATE', json_object('id', new.id), json_object('accountId', new.accountId, 'id', new.id, 'role', new.role, 'status', new.status, 'workspaceId', new.workspaceId), json_object('accountId', old.accountId, 'id', old.id, 'role', old.role, 'status', old.status, 'workspaceId', old.workspaceId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_workspace_membership_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_workspace_membership_into_oplog\n       AFTER DELETE ON main.workspace_membership\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workspace_membership')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workspace_membership', 'DELETE', json_object('id', old.id), NULL, json_object('accountId', old.accountId, 'id', old.id, 'role', old.role, 'status', old.status, 'workspaceId', old.workspaceId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_375"
  },
  {
    "statements": [
      "CREATE TABLE \"app_phone_number\" (\n  \"id\" TEXT NOT NULL,\n  \"phoneNumber\" TEXT NOT NULL,\n  \"forwardingNumber\" TEXT,\n  \"feedId\" TEXT,\n  CONSTRAINT \"app_phone_number_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.app_phone_number', 1);\n    ",
      "\n    /* Triggers for table app_phone_number */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_app_phone_number_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_app_phone_number_primarykey\n      BEFORE UPDATE ON main.app_phone_number\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_app_phone_number_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_app_phone_number_into_oplog\n       AFTER INSERT ON main.app_phone_number\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.app_phone_number')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'app_phone_number', 'INSERT', json_object('id', new.id), json_object('feedId', new.feedId, 'forwardingNumber', new.forwardingNumber, 'id', new.id, 'phoneNumber', new.phoneNumber), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_app_phone_number_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_app_phone_number_into_oplog\n       AFTER UPDATE ON main.app_phone_number\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.app_phone_number')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'app_phone_number', 'UPDATE', json_object('id', new.id), json_object('feedId', new.feedId, 'forwardingNumber', new.forwardingNumber, 'id', new.id, 'phoneNumber', new.phoneNumber), json_object('feedId', old.feedId, 'forwardingNumber', old.forwardingNumber, 'id', old.id, 'phoneNumber', old.phoneNumber), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_app_phone_number_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_app_phone_number_into_oplog\n       AFTER DELETE ON main.app_phone_number\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.app_phone_number')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'app_phone_number', 'DELETE', json_object('id', old.id), NULL, json_object('feedId', old.feedId, 'forwardingNumber', old.forwardingNumber, 'id', old.id, 'phoneNumber', old.phoneNumber), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_440"
  },
  {
    "statements": [
      "CREATE TABLE \"call_record\" (\n  \"id\" TEXT NOT NULL,\n  \"contentId\" TEXT NOT NULL,\n  \"appPhoneNumberId\" TEXT NOT NULL,\n  \"toNumber\" TEXT,\n  \"fromNumber\" TEXT,\n  \"direction\" TEXT,\n  CONSTRAINT \"call_record_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.call_record', 1);\n    ",
      "\n    /* Triggers for table call_record */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_call_record_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_call_record_primarykey\n      BEFORE UPDATE ON main.call_record\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_call_record_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_call_record_into_oplog\n       AFTER INSERT ON main.call_record\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.call_record')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'call_record', 'INSERT', json_object('id', new.id), json_object('appPhoneNumberId', new.appPhoneNumberId, 'contentId', new.contentId, 'direction', new.direction, 'fromNumber', new.fromNumber, 'id', new.id, 'toNumber', new.toNumber), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_call_record_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_call_record_into_oplog\n       AFTER UPDATE ON main.call_record\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.call_record')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'call_record', 'UPDATE', json_object('id', new.id), json_object('appPhoneNumberId', new.appPhoneNumberId, 'contentId', new.contentId, 'direction', new.direction, 'fromNumber', new.fromNumber, 'id', new.id, 'toNumber', new.toNumber), json_object('appPhoneNumberId', old.appPhoneNumberId, 'contentId', old.contentId, 'direction', old.direction, 'fromNumber', old.fromNumber, 'id', old.id, 'toNumber', old.toNumber), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_call_record_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_call_record_into_oplog\n       AFTER DELETE ON main.call_record\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.call_record')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'call_record', 'DELETE', json_object('id', old.id), NULL, json_object('appPhoneNumberId', old.appPhoneNumberId, 'contentId', old.contentId, 'direction', old.direction, 'fromNumber', old.fromNumber, 'id', old.id, 'toNumber', old.toNumber), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_507"
  },
  {
    "statements": [
      "CREATE TABLE \"direct_ws_invitation\" (\n  \"id\" TEXT NOT NULL,\n  \"workspaceMembershipId\" TEXT NOT NULL,\n  \"email\" TEXT,\n  \"phoneNumber\" TEXT,\n  \"claimedAt\" TEXT,\n  \"claimedBy\" TEXT,\n  \"createdAt\" TEXT,\n  \"updatedAt\" TEXT,\n  CONSTRAINT \"direct_ws_invitation_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.direct_ws_invitation', 1);\n    ",
      "\n    /* Triggers for table direct_ws_invitation */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_direct_ws_invitation_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_direct_ws_invitation_primarykey\n      BEFORE UPDATE ON main.direct_ws_invitation\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_direct_ws_invitation_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_direct_ws_invitation_into_oplog\n       AFTER INSERT ON main.direct_ws_invitation\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.direct_ws_invitation')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'direct_ws_invitation', 'INSERT', json_object('id', new.id), json_object('claimedAt', new.claimedAt, 'claimedBy', new.claimedBy, 'createdAt', new.createdAt, 'email', new.email, 'id', new.id, 'phoneNumber', new.phoneNumber, 'updatedAt', new.updatedAt, 'workspaceMembershipId', new.workspaceMembershipId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_direct_ws_invitation_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_direct_ws_invitation_into_oplog\n       AFTER UPDATE ON main.direct_ws_invitation\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.direct_ws_invitation')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'direct_ws_invitation', 'UPDATE', json_object('id', new.id), json_object('claimedAt', new.claimedAt, 'claimedBy', new.claimedBy, 'createdAt', new.createdAt, 'email', new.email, 'id', new.id, 'phoneNumber', new.phoneNumber, 'updatedAt', new.updatedAt, 'workspaceMembershipId', new.workspaceMembershipId), json_object('claimedAt', old.claimedAt, 'claimedBy', old.claimedBy, 'createdAt', old.createdAt, 'email', old.email, 'id', old.id, 'phoneNumber', old.phoneNumber, 'updatedAt', old.updatedAt, 'workspaceMembershipId', old.workspaceMembershipId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_direct_ws_invitation_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_direct_ws_invitation_into_oplog\n       AFTER DELETE ON main.direct_ws_invitation\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.direct_ws_invitation')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'direct_ws_invitation', 'DELETE', json_object('id', old.id), NULL, json_object('claimedAt', old.claimedAt, 'claimedBy', old.claimedBy, 'createdAt', old.createdAt, 'email', old.email, 'id', old.id, 'phoneNumber', old.phoneNumber, 'updatedAt', old.updatedAt, 'workspaceMembershipId', old.workspaceMembershipId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_570"
  },
  {
    "statements": [
      "CREATE TABLE \"audio_queue_item\" (\n  \"id\" TEXT NOT NULL,\n  \"itemId\" TEXT,\n  \"createdAt\" TEXT,\n  CONSTRAINT \"audio_queue_item_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.audio_queue_item', 1);\n    ",
      "\n    /* Triggers for table audio_queue_item */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_audio_queue_item_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_audio_queue_item_primarykey\n      BEFORE UPDATE ON main.audio_queue_item\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_audio_queue_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_audio_queue_item_into_oplog\n       AFTER INSERT ON main.audio_queue_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.audio_queue_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'audio_queue_item', 'INSERT', json_object('id', new.id), json_object('createdAt', new.createdAt, 'id', new.id, 'itemId', new.itemId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_audio_queue_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_audio_queue_item_into_oplog\n       AFTER UPDATE ON main.audio_queue_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.audio_queue_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'audio_queue_item', 'UPDATE', json_object('id', new.id), json_object('createdAt', new.createdAt, 'id', new.id, 'itemId', new.itemId), json_object('createdAt', old.createdAt, 'id', old.id, 'itemId', old.itemId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_audio_queue_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_audio_queue_item_into_oplog\n       AFTER DELETE ON main.audio_queue_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.audio_queue_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'audio_queue_item', 'DELETE', json_object('id', old.id), NULL, json_object('createdAt', old.createdAt, 'id', old.id, 'itemId', old.itemId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_631"
  },
  {
    "statements": [
      "CREATE TABLE \"display_artifact\" (\n  \"id\" TEXT NOT NULL,\n  \"title\" TEXT NOT NULL,\n  \"description\" TEXT,\n  \"contentId\" TEXT NOT NULL,\n  \"deletedAt\" TEXT,\n  \"createdAt\" TEXT NOT NULL,\n  CONSTRAINT \"display_artifact_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "CREATE UNIQUE INDEX \"display_artifact_contentId_key\" ON \"display_artifact\" (\"contentId\" ASC);\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.display_artifact', 1);\n    ",
      "\n    /* Triggers for table display_artifact */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_display_artifact_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_display_artifact_primarykey\n      BEFORE UPDATE ON main.display_artifact\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_display_artifact_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_display_artifact_into_oplog\n       AFTER INSERT ON main.display_artifact\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.display_artifact')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'display_artifact', 'INSERT', json_object('id', new.id), json_object('contentId', new.contentId, 'createdAt', new.createdAt, 'deletedAt', new.deletedAt, 'description', new.description, 'id', new.id, 'title', new.title), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_display_artifact_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_display_artifact_into_oplog\n       AFTER UPDATE ON main.display_artifact\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.display_artifact')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'display_artifact', 'UPDATE', json_object('id', new.id), json_object('contentId', new.contentId, 'createdAt', new.createdAt, 'deletedAt', new.deletedAt, 'description', new.description, 'id', new.id, 'title', new.title), json_object('contentId', old.contentId, 'createdAt', old.createdAt, 'deletedAt', old.deletedAt, 'description', old.description, 'id', old.id, 'title', old.title), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_display_artifact_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_display_artifact_into_oplog\n       AFTER DELETE ON main.display_artifact\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.display_artifact')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'display_artifact', 'DELETE', json_object('id', old.id), NULL, json_object('contentId', old.contentId, 'createdAt', old.createdAt, 'deletedAt', old.deletedAt, 'description', old.description, 'id', old.id, 'title', old.title), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_691"
  },
  {
    "statements": [
      "CREATE TABLE \"workflow_item\" (\n  \"id\" TEXT NOT NULL,\n  \"workspaceId\" TEXT NOT NULL,\n  \"contentId\" TEXT NOT NULL,\n  \"createdAt\" TEXT,\n  \"deletedAt\" TEXT,\n  \"displayName\" TEXT,\n  CONSTRAINT \"workflow_item_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.workflow_item', 1);\n    ",
      "\n    /* Triggers for table workflow_item */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_workflow_item_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_workflow_item_primarykey\n      BEFORE UPDATE ON main.workflow_item\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_workflow_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_workflow_item_into_oplog\n       AFTER INSERT ON main.workflow_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workflow_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workflow_item', 'INSERT', json_object('id', new.id), json_object('contentId', new.contentId, 'createdAt', new.createdAt, 'deletedAt', new.deletedAt, 'displayName', new.displayName, 'id', new.id, 'workspaceId', new.workspaceId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_workflow_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_workflow_item_into_oplog\n       AFTER UPDATE ON main.workflow_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workflow_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workflow_item', 'UPDATE', json_object('id', new.id), json_object('contentId', new.contentId, 'createdAt', new.createdAt, 'deletedAt', new.deletedAt, 'displayName', new.displayName, 'id', new.id, 'workspaceId', new.workspaceId), json_object('contentId', old.contentId, 'createdAt', old.createdAt, 'deletedAt', old.deletedAt, 'displayName', old.displayName, 'id', old.id, 'workspaceId', old.workspaceId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_workflow_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_workflow_item_into_oplog\n       AFTER DELETE ON main.workflow_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.workflow_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'workflow_item', 'DELETE', json_object('id', old.id), NULL, json_object('contentId', old.contentId, 'createdAt', old.createdAt, 'deletedAt', old.deletedAt, 'displayName', old.displayName, 'id', old.id, 'workspaceId', old.workspaceId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_754"
  },
  {
    "statements": [
      "CREATE TABLE \"published_workflow_item\" (\n  \"id\" TEXT NOT NULL,\n  \"workflowItemId\" TEXT NOT NULL,\n  \"broadcastId\" TEXT NOT NULL,\n  \"accountId\" TEXT NOT NULL,\n  \"workspaceId\" TEXT NOT NULL,\n  \"contentId\" TEXT NOT NULL,\n  \"createdAt\" TEXT,\n  CONSTRAINT \"published_workflow_item_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.published_workflow_item', 1);\n    ",
      "\n    /* Triggers for table published_workflow_item */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_published_workflow_item_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_published_workflow_item_primarykey\n      BEFORE UPDATE ON main.published_workflow_item\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_published_workflow_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_published_workflow_item_into_oplog\n       AFTER INSERT ON main.published_workflow_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.published_workflow_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'published_workflow_item', 'INSERT', json_object('id', new.id), json_object('accountId', new.accountId, 'broadcastId', new.broadcastId, 'contentId', new.contentId, 'createdAt', new.createdAt, 'id', new.id, 'workflowItemId', new.workflowItemId, 'workspaceId', new.workspaceId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_published_workflow_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_published_workflow_item_into_oplog\n       AFTER UPDATE ON main.published_workflow_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.published_workflow_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'published_workflow_item', 'UPDATE', json_object('id', new.id), json_object('accountId', new.accountId, 'broadcastId', new.broadcastId, 'contentId', new.contentId, 'createdAt', new.createdAt, 'id', new.id, 'workflowItemId', new.workflowItemId, 'workspaceId', new.workspaceId), json_object('accountId', old.accountId, 'broadcastId', old.broadcastId, 'contentId', old.contentId, 'createdAt', old.createdAt, 'id', old.id, 'workflowItemId', old.workflowItemId, 'workspaceId', old.workspaceId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_published_workflow_item_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_published_workflow_item_into_oplog\n       AFTER DELETE ON main.published_workflow_item\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.published_workflow_item')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'published_workflow_item', 'DELETE', json_object('id', old.id), NULL, json_object('accountId', old.accountId, 'broadcastId', old.broadcastId, 'contentId', old.contentId, 'createdAt', old.createdAt, 'id', old.id, 'workflowItemId', old.workflowItemId, 'workspaceId', old.workspaceId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_818"
  },
  {
    "statements": [
      "CREATE TABLE \"broadcast\" (\n  \"id\" TEXT NOT NULL,\n  \"workspaceId\" TEXT NOT NULL,\n  \"createdAt\" TEXT,\n  CONSTRAINT \"broadcast_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.broadcast', 1);\n    ",
      "\n    /* Triggers for table broadcast */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_broadcast_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_broadcast_primarykey\n      BEFORE UPDATE ON main.broadcast\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_broadcast_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_broadcast_into_oplog\n       AFTER INSERT ON main.broadcast\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast', 'INSERT', json_object('id', new.id), json_object('createdAt', new.createdAt, 'id', new.id, 'workspaceId', new.workspaceId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_broadcast_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_broadcast_into_oplog\n       AFTER UPDATE ON main.broadcast\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast', 'UPDATE', json_object('id', new.id), json_object('createdAt', new.createdAt, 'id', new.id, 'workspaceId', new.workspaceId), json_object('createdAt', old.createdAt, 'id', old.id, 'workspaceId', old.workspaceId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_broadcast_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_broadcast_into_oplog\n       AFTER DELETE ON main.broadcast\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast', 'DELETE', json_object('id', old.id), NULL, json_object('createdAt', old.createdAt, 'id', old.id, 'workspaceId', old.workspaceId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_878"
  },
  {
    "statements": [
      "CREATE TABLE \"broadcast_recipient\" (\n  \"id\" TEXT NOT NULL,\n  \"broadcastId\" TEXT NOT NULL,\n  \"workspaceId\" TEXT NOT NULL,\n  \"feedId\" TEXT,\n  \"workspaceMembershipId\" TEXT,\n  \"createdAt\" TEXT,\n  CONSTRAINT \"broadcast_recipient_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.broadcast_recipient', 1);\n    ",
      "\n    /* Triggers for table broadcast_recipient */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_broadcast_recipient_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_broadcast_recipient_primarykey\n      BEFORE UPDATE ON main.broadcast_recipient\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_broadcast_recipient_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_broadcast_recipient_into_oplog\n       AFTER INSERT ON main.broadcast_recipient\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast_recipient')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast_recipient', 'INSERT', json_object('id', new.id), json_object('broadcastId', new.broadcastId, 'createdAt', new.createdAt, 'feedId', new.feedId, 'id', new.id, 'workspaceId', new.workspaceId, 'workspaceMembershipId', new.workspaceMembershipId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_broadcast_recipient_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_broadcast_recipient_into_oplog\n       AFTER UPDATE ON main.broadcast_recipient\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast_recipient')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast_recipient', 'UPDATE', json_object('id', new.id), json_object('broadcastId', new.broadcastId, 'createdAt', new.createdAt, 'feedId', new.feedId, 'id', new.id, 'workspaceId', new.workspaceId, 'workspaceMembershipId', new.workspaceMembershipId), json_object('broadcastId', old.broadcastId, 'createdAt', old.createdAt, 'feedId', old.feedId, 'id', old.id, 'workspaceId', old.workspaceId, 'workspaceMembershipId', old.workspaceMembershipId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_broadcast_recipient_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_broadcast_recipient_into_oplog\n       AFTER DELETE ON main.broadcast_recipient\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast_recipient')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast_recipient', 'DELETE', json_object('id', old.id), NULL, json_object('broadcastId', old.broadcastId, 'createdAt', old.createdAt, 'feedId', old.feedId, 'id', old.id, 'workspaceId', old.workspaceId, 'workspaceMembershipId', old.workspaceMembershipId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_936"
  },
  {
    "statements": [
      "CREATE TABLE \"schedule_trigger\" (\n  \"id\" TEXT NOT NULL,\n  \"oneTimeSchedule\" TEXT,\n  \"cronSchedule\" TEXT,\n  \"workspaceId\" TEXT NOT NULL,\n  \"broadcastActionId\" TEXT,\n  \"createdAt\" TEXT,\n  \"updatedAt\" TEXT,\n  \"timezone\" TEXT,\n  \"enabled\" INTEGER DEFAULT 1 NOT NULL,\n  CONSTRAINT \"schedule_trigger_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.schedule_trigger', 1);\n    ",
      "\n    /* Triggers for table schedule_trigger */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_schedule_trigger_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_schedule_trigger_primarykey\n      BEFORE UPDATE ON main.schedule_trigger\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_schedule_trigger_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_schedule_trigger_into_oplog\n       AFTER INSERT ON main.schedule_trigger\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.schedule_trigger')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'schedule_trigger', 'INSERT', json_object('id', new.id), json_object('broadcastActionId', new.broadcastActionId, 'createdAt', new.createdAt, 'cronSchedule', new.cronSchedule, 'enabled', new.enabled, 'id', new.id, 'oneTimeSchedule', new.oneTimeSchedule, 'timezone', new.timezone, 'updatedAt', new.updatedAt, 'workspaceId', new.workspaceId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_schedule_trigger_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_schedule_trigger_into_oplog\n       AFTER UPDATE ON main.schedule_trigger\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.schedule_trigger')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'schedule_trigger', 'UPDATE', json_object('id', new.id), json_object('broadcastActionId', new.broadcastActionId, 'createdAt', new.createdAt, 'cronSchedule', new.cronSchedule, 'enabled', new.enabled, 'id', new.id, 'oneTimeSchedule', new.oneTimeSchedule, 'timezone', new.timezone, 'updatedAt', new.updatedAt, 'workspaceId', new.workspaceId), json_object('broadcastActionId', old.broadcastActionId, 'createdAt', old.createdAt, 'cronSchedule', old.cronSchedule, 'enabled', old.enabled, 'id', old.id, 'oneTimeSchedule', old.oneTimeSchedule, 'timezone', old.timezone, 'updatedAt', old.updatedAt, 'workspaceId', old.workspaceId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_schedule_trigger_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_schedule_trigger_into_oplog\n       AFTER DELETE ON main.schedule_trigger\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.schedule_trigger')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'schedule_trigger', 'DELETE', json_object('id', old.id), NULL, json_object('broadcastActionId', old.broadcastActionId, 'createdAt', old.createdAt, 'cronSchedule', old.cronSchedule, 'enabled', old.enabled, 'id', old.id, 'oneTimeSchedule', old.oneTimeSchedule, 'timezone', old.timezone, 'updatedAt', old.updatedAt, 'workspaceId', old.workspaceId), NULL);\n    END;\n    "
    ],
    "version": "20240116224028_995"
  },
  {
    "statements": [
      "CREATE TABLE \"broadcast_action\" (\n  \"id\" TEXT NOT NULL,\n  \"workspaceId\" TEXT NOT NULL,\n  \"workflowItemId\" TEXT NOT NULL,\n  \"broadcastId\" TEXT NOT NULL,\n  \"createdAt\" TEXT,\n  \"updatedAt\" TEXT,\n  CONSTRAINT \"broadcast_action_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.broadcast_action', 1);\n    ",
      "\n    /* Triggers for table broadcast_action */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_broadcast_action_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_broadcast_action_primarykey\n      BEFORE UPDATE ON main.broadcast_action\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_broadcast_action_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_broadcast_action_into_oplog\n       AFTER INSERT ON main.broadcast_action\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast_action')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast_action', 'INSERT', json_object('id', new.id), json_object('broadcastId', new.broadcastId, 'createdAt', new.createdAt, 'id', new.id, 'updatedAt', new.updatedAt, 'workflowItemId', new.workflowItemId, 'workspaceId', new.workspaceId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_broadcast_action_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_broadcast_action_into_oplog\n       AFTER UPDATE ON main.broadcast_action\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast_action')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast_action', 'UPDATE', json_object('id', new.id), json_object('broadcastId', new.broadcastId, 'createdAt', new.createdAt, 'id', new.id, 'updatedAt', new.updatedAt, 'workflowItemId', new.workflowItemId, 'workspaceId', new.workspaceId), json_object('broadcastId', old.broadcastId, 'createdAt', old.createdAt, 'id', old.id, 'updatedAt', old.updatedAt, 'workflowItemId', old.workflowItemId, 'workspaceId', old.workspaceId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_broadcast_action_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_broadcast_action_into_oplog\n       AFTER DELETE ON main.broadcast_action\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.broadcast_action')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'broadcast_action', 'DELETE', json_object('id', old.id), NULL, json_object('broadcastId', old.broadcastId, 'createdAt', old.createdAt, 'id', old.id, 'updatedAt', old.updatedAt, 'workflowItemId', old.workflowItemId, 'workspaceId', old.workspaceId), NULL);\n    END;\n    "
    ],
    "version": "20240116224029_060"
  },
  {
    "statements": [
      "CREATE TABLE \"template\" (\n  \"id\" TEXT NOT NULL,\n  \"name\" TEXT NOT NULL,\n  \"template\" TEXT NOT NULL,\n  \"workspaceId\" TEXT NOT NULL,\n  \"authorId\" TEXT NOT NULL,\n  \"createdAt\" TEXT,\n  \"updatedAt\" TEXT,\n  \"deletedAt\" TEXT,\n  CONSTRAINT \"template_pkey\" PRIMARY KEY (\"id\")\n) WITHOUT ROWID;\n",
      "\n    -- Toggles for turning the triggers on and off\n    INSERT OR IGNORE INTO _electric_trigger_settings(tablename,flag) VALUES ('main.template', 1);\n    ",
      "\n    /* Triggers for table template */\n  \n    -- ensures primary key is immutable\n    DROP TRIGGER IF EXISTS update_ensure_main_template_primarykey;\n    ",
      "\n    CREATE TRIGGER update_ensure_main_template_primarykey\n      BEFORE UPDATE ON main.template\n    BEGIN\n      SELECT\n        CASE\n          WHEN old.id != new.id THEN\n\t\tRAISE (ABORT, 'cannot change the value of column id as it belongs to the primary key')\n        END;\n    END;\n    ",
      "\n    -- Triggers that add INSERT, UPDATE, DELETE operation to the _opslog table\n    DROP TRIGGER IF EXISTS insert_main_template_into_oplog;\n    ",
      "\n    CREATE TRIGGER insert_main_template_into_oplog\n       AFTER INSERT ON main.template\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.template')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'template', 'INSERT', json_object('id', new.id), json_object('authorId', new.authorId, 'createdAt', new.createdAt, 'deletedAt', new.deletedAt, 'id', new.id, 'name', new.name, 'template', new.template, 'updatedAt', new.updatedAt, 'workspaceId', new.workspaceId), NULL, NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS update_main_template_into_oplog;\n    ",
      "\n    CREATE TRIGGER update_main_template_into_oplog\n       AFTER UPDATE ON main.template\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.template')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'template', 'UPDATE', json_object('id', new.id), json_object('authorId', new.authorId, 'createdAt', new.createdAt, 'deletedAt', new.deletedAt, 'id', new.id, 'name', new.name, 'template', new.template, 'updatedAt', new.updatedAt, 'workspaceId', new.workspaceId), json_object('authorId', old.authorId, 'createdAt', old.createdAt, 'deletedAt', old.deletedAt, 'id', old.id, 'name', old.name, 'template', old.template, 'updatedAt', old.updatedAt, 'workspaceId', old.workspaceId), NULL);\n    END;\n    ",
      "\n    DROP TRIGGER IF EXISTS delete_main_template_into_oplog;\n    ",
      "\n    CREATE TRIGGER delete_main_template_into_oplog\n       AFTER DELETE ON main.template\n       WHEN 1 == (SELECT flag from _electric_trigger_settings WHERE tablename == 'main.template')\n    BEGIN\n      INSERT INTO _electric_oplog (namespace, tablename, optype, primaryKey, newRow, oldRow, timestamp)\n      VALUES ('main', 'template', 'DELETE', json_object('id', old.id), NULL, json_object('authorId', old.authorId, 'createdAt', old.createdAt, 'deletedAt', old.deletedAt, 'id', old.id, 'name', old.name, 'template', old.template, 'updatedAt', old.updatedAt, 'workspaceId', old.workspaceId), NULL);\n    END;\n    "
    ],
    "version": "20240116224029_125"
  }
]