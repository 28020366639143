import { InitialsAvatar } from "@/components/Avatar";
import { AppContext } from "@/models/AppStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { defaultFocusStyles, menuItemFocusSX } from "@/utils";
import * as Icons from "@mui/icons-material";
import { useLiveQuery } from "electric-sql/react";
import { Account, Permission, WorkspaceMembership } from "@/generated/client";
import {
	Box,
	Collapse,
	Drawer,
	DrawerProps,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import {
	KeyboardEvent,
	MouseEvent,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { throttle } from "throttle-typescript";
import { DataContext } from "@/models/DataProvider";
import { DrawerHeader } from "./DrawerHeader";
import { ActionContext } from "@/models/ActionsProvider";
import { useParams } from "react-router-dom";
import { useElectric } from "@/electric/ElectricWrapper";
import AddChannelUsersLink from "./Workspace/AddChannelUsersLink";
import ChannelModalConfirmationDialogs from "@/components/ChannelModalConfirmationDialogs";
import MemberDrawerListItem from "@/components/MemberDrawerListItem";
import MemberDrawerSubOptions from "@/components/MemberDrawerSubOptions";
import "../assets/menus.css";

export interface MembersDrawerProps extends DrawerProps {
	handleClose: () => void;
	url: string | null;
}

export default function MembersDrawer({
	className,
	sx,
	handleClose,
}: MembersDrawerProps) {
	const { flags } = useContext(AppContext);
	const {
		currentFeedId,
		myAccount,
		isCurrentFeedAdmin,
		currentFeedAccounts,
		currentFeedPendingInvites,
	} = useContext(DataContext);
	const {
		isSmUp,
		leftNavOpen,
		rightNavOpen,
		toggleRightNav,
		setChannelUsersModalOpen,
		setChannelModalOpen,
		setChannelModalNew,
		setEditChannelSettings,
	} = useContext(UxContext);

	const { removeFeed } = useContext(ActionContext);
	const params = useParams();
	const workspaceId = params.workspaceId;
	const { db } = useElectric();

	const { results: feedPermissions } = useLiveQuery(
		db.permission.liveMany({
			where: {
				feedId: currentFeedId,
			},
		}),
	);

	const { results: workspaceMemberships } = useLiveQuery(
		db.workspace_membership.liveMany({
			where: {
				workspaceId,
			},
		}),
	);

	const accounts = Array.from(currentFeedAccounts?.values() ?? []);

	const accountDetails = useCallback(
		(
			accountId: string,
		): {
			isAdmin: boolean;
			isMuted: boolean;
			workspaceMembership: WorkspaceMembership | null;
			permissions: Array<Permission>;
		} => {
			if (!workspaceMemberships || !feedPermissions) {
				return;
			}
			const findWorkspaceMembership = workspaceMemberships.find(
				(membership) => membership.accountId === accountId,
			);
			if (!findWorkspaceMembership) {
				return;
			}
			const accountPermissions = feedPermissions.filter(
				(permission) =>
					permission.workspace_membershipId === findWorkspaceMembership.id,
			);

			return {
				isAdmin: !!accountPermissions.find(
					(permission) =>
						permission?.name === "admin" && permission.enabled === 1,
				),
				isMuted:
					accountPermissions.filter(
						(permission) =>
							permission.name === "write" && permission.enabled === 1,
					)?.length === 0 && accountPermissions?.length > 1,
				workspaceMembership: findWorkspaceMembership,
				permissions: accountPermissions,
			};
		},
		[workspaceMemberships, feedPermissions],
	);

	if (accounts?.length > 0) {
		for (const account of accounts) {
			const accountDetail = accountDetails(account?.id);
			if (accountDetail?.workspaceMembership) {
				account.permissions = accountDetail.permissions;
				account.workspaceMembership = accountDetail.workspaceMembership;
				account.isAdmin = accountDetail.isAdmin;
				account.isMuted = accountDetail.isMuted;
			}
		}
	}

	const [canLeaveChannel, setCanLeaveChannel] = useState<boolean>(false);
	const [isPrivateChannel, setIsPrivateChannel] = useState<boolean>(false);
	const [isDm, setIsDm] = useState<boolean>(false);
	const [workspaceMembershipId, setWorkspaceMembershipId] =
		useState<string>("");
	const [showActiveMembers, setShowActiveMembers] = useState<boolean>(true);
	const [showPendingInvites, setShowPendingInvites] = useState<boolean>(true);

	const theme = useTheme();

	const [membersMenuAnchorEl, setMembersMenuAnchorEl] =
		useState<null | HTMLElement>(null);
	const [membersMenuAnchorElOpen, setMembersMenuAnchorElOpen] = useState(false);
	const menuAnchorElOpen = Boolean(membersMenuAnchorEl);

	const leaveChannel = useCallback(async () => {
		await removeFeed(workspaceId, currentFeedId).then(() => toggleRightNav());
	}, [currentFeedId, removeFeed, toggleRightNav]);

	const handleLeaveChannel = () => {
		setMembersMenuAnchorEl(null);
		leaveChannel();
	};

	const [disabledMemberMenu, setDisabledMemberMenu] = useState<boolean>(false);
	const [individualMemberAnchorEl, setIndividualMemberAnchorEl] =
		useState<null | HTMLElement>(null);
	const [activeMemberMenuAccount, setActiveMemberMenuAccount] =
		useState<null | Account>(null);
	const handleIndividualMemberOptions = (
		event: MouseEvent<HTMLButtonElement>,
		account: Account,
	) => {
		setIndividualMemberAnchorEl(event.currentTarget);
		setActiveMemberMenuAccount(() => account);
	};
	const [confirmDialogOpen, setConfirmDialogOpen] = useState<string | null>(
		null,
	);
	const setConfirmDeleteDialog = (value: null | string) => {
		if (value === null) {
			setActiveMemberMenuAccount(() => null);
		}
		setConfirmDialogOpen(() => value);
	};

	useEffect(() => {
		db.workspace_membership
			.findFirst({
				where: {
					accountId: myAccount?.id,
					workspaceId,
				},
			})
			.then((resp) => {
				if (resp?.id) {
					setWorkspaceMembershipId(() => resp.id);
				}
			});
	}, [workspaceId, myAccount]);

	useEffect(() => {
		if (!currentFeedId) {
			return;
		}
		db.feed
			.findUnique({
				where: {
					id: currentFeedId,
				},
			})
			.then((res) => {
				setIsPrivateChannel(() => res?.isPrivate === 1);
				setCanLeaveChannel(() => res?.isPrivate === 1);
				setIsDm(() => res?.isDm === 1);
			});
	}, [db, currentFeedId]);

	const _handleKeyUp = (event: KeyboardEvent<HTMLButtonElement>) => {
		const keyCode = event.key.toLowerCase();

		/**
		 * @NOTE filter key presses
		 *  - when initially focused, this event will fire off of `TAB`
		 *  -> in order to keep the illusion that the parent element is opened,
		 *     we need to throttle + filter for <space> or <enter>
		 */
		if (keyCode === " " || keyCode === "enter") {
			setMembersMenuAnchorElOpen(!membersMenuAnchorElOpen);
		}
	};

	const handleKeyUp = throttle(_handleKeyUp, 100);

	const handleMemberMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setMembersMenuAnchorEl(event.currentTarget);
	};

	const handleMemberMenuClose = () => {
		setMembersMenuAnchorEl(null);
		setMembersMenuAnchorElOpen(false);
		setEditChannelSettings(false);
	};

	const handleChannelSettings = () => {
		setEditChannelSettings(true);
		setChannelModalOpen(true);
		setChannelModalNew(() => false);
	};

	const handleAddUsersClick = () => {
		setChannelUsersModalOpen(true);
	};

	const toggleActiveMembers = () => {
		setShowActiveMembers(!showActiveMembers);
	};

	const togglePendingInvites = () => {
		setShowPendingInvites(!showPendingInvites);
	};

	/**
	 * @NOTE mui drawer variants
	 *  - `persistent` - drawer overlays content, blocks main content
	 *      - accessing main content closes drawer
	 *  - `temporary` - drawer overlaps but DOES NOT BLOCK main content
	 *  - `permanent` - drawer overtakes everything
	 */

	const drawerVariant = isSmUp ? "persistent" : "temporary";

	return (
		<>
			<ChannelModalConfirmationDialogs
				feedId={currentFeedId}
				workspaceId={workspaceId}
				account={activeMemberMenuAccount}
				section={confirmDialogOpen}
				isOpen={confirmDialogOpen !== null}
				closeModal={() => {
					setConfirmDeleteDialog(null);
				}}
			/>

			<Drawer
				variant={drawerVariant}
				sx={sx}
				anchor="right"
				open={rightNavOpen}
				onClose={handleClose}
			>
				<Paper
					sx={{
						background: theme.palette.primary.dark,
						p: 2,
						overflow: "hidden",
						height: "100svh",
						position: "relative",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							height: "100%",
						}}
					>
						<DrawerHeader position="top">
							<Stack
								direction="row"
								spacing={2}
								alignItems="center"
								justifyContent="space-between"
								width="100%"
							>
								<Typography variant="h6" component="h2">
									Members
								</Typography>
								{!isDm &&
									currentFeedId &&
									(isPrivateChannel || isCurrentFeedAdmin) && (
										<>
											<IconButton
												color="secondary"
												aria-label={"Options menu for channel"}
												onClick={handleMemberMenuOpen}
												onKeyUp={handleKeyUp}
												sx={{
													...(menuAnchorElOpen ? defaultFocusStyles : {}),
													color: theme.palette.primary.main,
												}}
											>
												<Icons.PendingOutlined role="img" />
											</IconButton>
											<Menu
												anchorEl={membersMenuAnchorEl}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "right",
												}}
												sx={{
													mt: 1,
													...menuItemFocusSX,
												}}
												open={menuAnchorElOpen}
												onClose={handleMemberMenuClose}
											>
												{isCurrentFeedAdmin && (
													<MenuItem
														onClick={handleChannelSettings}
														key="channel-settings"
													>
														<ListItemIcon>
															<Icons.Settings role="img" />
														</ListItemIcon>
														<ListItemText
															primaryTypographyProps={{ fontWeight: 500 }}
														>
															Channel settings
														</ListItemText>
													</MenuItem>
												)}
												{isPrivateChannel && (
													<MenuItem
														disabled={!canLeaveChannel}
														onClick={handleLeaveChannel}
														key="leave-channel"
													>
														<ListItemIcon>
															<Icons.ArrowOutward role="img" />
														</ListItemIcon>
														<ListItemText
															primaryTypographyProps={{ fontWeight: 500 }}
														>
															Leave channel
														</ListItemText>
													</MenuItem>
												)}
											</Menu>
										</>
									)}
							</Stack>
							{!isSmUp && (
								<IconButton
									color="primary"
									onClick={handleClose}
									aria-label="close drawer"
								>
									<Icons.Close role="img" />
								</IconButton>
							)}
						</DrawerHeader>
						<Box
							sx={{
								overflowY: "auto",
								overflowX: "hidden",
								maxHeight: "calc(100svh - 128px)",
								height: "100%",
								background: theme.palette.secondary.dark,
								borderRadius: 4,
							}}
							className="dark-scrollbar"
						>
							<Stack
								sx={{
									p: 2,
									gap: 3.75,
									flexGrow: 1,
								}}
							>
								{!isDm && isPrivateChannel && isCurrentFeedAdmin && (
									<AddChannelUsersLink />
								)}
								<List key={`${workspaceId}-${currentFeedId}-members`}>
									<IconButton
										onClick={toggleActiveMembers}
										sx={{ width: "100%", justifyContent: "space-between" }}
									>
										<Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
											Active members
										</Typography>
										{showActiveMembers ? (
											<Icons.KeyboardArrowUp role="img" />
										) : (
											<Icons.KeyboardArrowDown role="img" />
										)}
									</IconButton>
									<Collapse in={showActiveMembers}>
										{accounts?.map((account) => {
											return (
												<Box
													key={account.id}
													sx={{
														display: "flex",
														width: "100%",
														position: "relative",
														mb: 1,
														p: 1,
														alignItems: "center",
													}}
													className={`${
														account.id === activeMemberMenuAccount?.id &&
														"active-parent"
													}`}
												>
													<MemberDrawerListItem
														isDm={isDm}
														account={account}
														myAccount={myAccount}
														isCurrentFeedAdmin={isCurrentFeedAdmin}
													>
														{!isDm && (
															<Box className="member-list-button">
																<IconButton
																	color="secondary"
																	aria-label={"Options menu for channel"}
																	onClick={(event) =>
																		handleIndividualMemberOptions(
																			event,
																			account,
																		)
																	}
																	onKeyUp={handleKeyUp}
																	sx={{
																		...(menuAnchorElOpen
																			? defaultFocusStyles
																			: {}),
																		color: theme.palette.primary.main,
																	}}
																>
																	<Icons.MoreVert role="img" />
																</IconButton>

																<MemberDrawerSubOptions
																	account={account}
																	activeMemberMenuAccount={
																		activeMemberMenuAccount
																	}
																	individualMemberAnchorEl={
																		individualMemberAnchorEl
																	}
																	disabledMemberMenu={disabledMemberMenu}
																	setActiveMemberMenuAccount={(value) => {
																		setActiveMemberMenuAccount(() => value);
																	}}
																	setConfirmDialogOpen={(value) => {
																		setConfirmDialogOpen(() => value);
																	}}
																	resetIndividualMemberAnchorEl={(value) => {
																		setIndividualMemberAnchorEl(() => value);
																		setActiveMemberMenuAccount(() => value);
																	}}
																/>
															</Box>
														)}
													</MemberDrawerListItem>
												</Box>
											);
										})}
									</Collapse>
								</List>
								{currentFeedPendingInvites?.length > 0 && (
									<List>
										<IconButton
											onClick={togglePendingInvites}
											sx={{ width: "100%", justifyContent: "space-between" }}
										>
											<Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
												Pending members
											</Typography>
											{showPendingInvites ? (
												<Icons.KeyboardArrowUp role="img" />
											) : (
												<Icons.KeyboardArrowDown role="img" />
											)}
										</IconButton>
										<Collapse in={showPendingInvites}>
											{currentFeedPendingInvites?.map((invite) => {
												return (
													<span key={invite.id}>
														<ListItem
															className="menu-options-list"
															key={invite.id}
															sx={{
																px: 0,
															}}
														>
															<ListItemIcon>
																<InitialsAvatar
																	sxProps={{
																		fontSize: "16px",
																		color: theme.palette.primary.main,
																		width: 40,
																		height: 40,
																	}}
																/>
															</ListItemIcon>
															<ListItemText
																sx={{ flexGrow: 0 }}
																primaryTypographyProps={{
																	fontWeight: 500,
																}}
															>
																{invite?.email || invite?.phoneNumber}
															</ListItemText>
														</ListItem>
													</span>
												);
											})}
										</Collapse>
									</List>
								)}
							</Stack>
						</Box>
					</Box>
				</Paper>
			</Drawer>
		</>
	);
}
