import ModalForm from "@/elements/ModalForm";
import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { AccountInfo } from "@/models/accountInfo";
import { ActionContext } from "@/models/ActionsProvider";
export default function ChannelModalConfirmationDialogs({
	feedId,
	workspaceId,
	account,
	section,
	isOpen,
	closeModal,
}: {
	feedId: string;
	workspaceId: string;
	account: AccountInfo;
	section: string;
	isOpen: boolean;
	closeModal: () => void;
}) {
	const { demoteOwnerToMember, muteUnmute, promoteMemberToOwner, removeMemberFromFeed } =
		useContext(ActionContext);

	if (!account) {
		return;
	}

	return (
		<ModalForm open={isOpen} onClose={() => closeModal()}>
			<Box>
				{section === "change-member-status" && !account?.isAdmin && (
					<>
						<Typography sx={{ fontSize: 24 }}>Promote to owner</Typography>
						<Typography sx={{ fontSize: 16, mb: 2 }}>
							This member will be able to manage this channel’s settings, and
							continue to post when in read-only mode.
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column", sm: "row" },
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								gap: 2.5,
							}}
						>
							<LoadingButton
								variant="contained"
								sx={{ borderRadius: 6 }}
								onClick={() => {
									promoteMemberToOwner(workspaceId, feedId, account.id);
									closeModal();
								}}
								aria-label="Confirm Remove Member"
							>
								Promote
							</LoadingButton>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => closeModal()}
							>
								Cancel
							</Button>
						</Box>
					</>
				)}

				{section === "change-member-status" && account.isAdmin && (
					<>
						<Typography sx={{ fontSize: 24 }}>Demote to member</Typography>
						<Typography sx={{ fontSize: 16, mb: 2 }}>
							This owner will no longer be able to manage this channel’s
							settings or post in read-only mode. They will remain a member of
							the channel.
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column", sm: "row" },
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								gap: 2.5,
							}}
						>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => closeModal()}
							>
								Cancel
							</Button>
							<LoadingButton
								variant="contained"
								color="error"
								sx={{ borderRadius: 6 }}
								onClick={() => {
									demoteOwnerToMember(
										workspaceId,
										feedId,
										account.id,
										account.workspaceMembership.id,
									);
									closeModal();
								}}
								aria-label="Confirm Remove Member"
							>
								Demote
							</LoadingButton>
						</Box>
					</>
				)}

				{section === "change-mute-status" && !account.isMuted && (
					<>
						<Typography sx={{ fontSize: 24 }}>Mute member</Typography>
						<Typography sx={{ fontSize: 16, mb: 2 }}>
							This member will no longer be able to add new messages or uploads
							to the Channel. This action is temporary and can be undone.
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column", sm: "row" },
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								gap: 2.5,
							}}
						>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => closeModal()}
							>
								Cancel
							</Button>
							<LoadingButton
								variant="contained"
								color="error"
								sx={{ borderRadius: 6 }}
								onClick={() => {
									muteUnmute(workspaceId, feedId, account.id, "mute");
									closeModal();
								}}
								aria-label="Confirm Remove Member"
							>
								Mute Member
							</LoadingButton>
						</Box>
					</>
				)}

				{section === "change-mute-status" && account.isMuted && (
					<>
						<Typography sx={{ fontSize: 24 }}>Unmute member</Typography>
						<Typography sx={{ fontSize: 16, mb: 2 }}>
							This member will be able to add new messages and uploads to the
							Channel.
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column", sm: "row" },
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								gap: 2.5,
							}}
						>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => closeModal()}
							>
								Cancel
							</Button>
							<LoadingButton
								variant="contained"
								sx={{ borderRadius: 6 }}
								onClick={() => {
									muteUnmute(workspaceId, feedId, account.id, "un-mute");
									closeModal();
								}}
								aria-label="Confirm Remove Member"
							>
								Unmute Member
							</LoadingButton>
						</Box>
					</>
				)}

				{section === 'remove-from-channel' && (
					<>
						<Typography sx={{ fontSize: 24 }}>
							Remove user from channel
						</Typography>
						<Typography sx={{ fontSize: 16, mb: 2 }}>
							This users will loose access to this channel. You can invite them again later.
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column", sm: "row" },
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								gap: 2.5,
							}}
						>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => closeModal()}
							>
								Cancel
							</Button>
							<LoadingButton
								variant="contained"
								color="error"
								sx={{ borderRadius: 6 }}
								onClick={  () => {
									removeMemberFromFeed(workspaceId, feedId, account.id)
									closeModal()
								}}
								aria-label="Confirm Remove Member"
							>
								Remove
							</LoadingButton>
						</Box>
					</>
				)}

			</Box>
		</ModalForm>
	);
}
