import { Link, Stack, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "regenerator-runtime/runtime";

import { TrackingContext } from "@/models/TrackingStateProvider";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";

import Loading from "@/components/Loading/Index";
import Feed from "@/components/Feed";
import ModalForm from "@/elements/ModalForm";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";

export default function FeedId() {
	const {
		loadFeed,
		currentFeed,
		myAccountId,
		loadWorkspaceDetails,
		fetchWorkspaceMembership,
		checkFeedPermissions,
	} = React.useContext(DataContext);
	const { accountEvent } = React.useContext(ActionContext);
	const { ampli } = React.useContext(TrackingContext);

	const { db } = useElectric();
	const navigate = useNavigate();
	const params = useParams();
	const feedId = params?.feedId as string;
	const workspaceId = params?.workspaceId as string;
	const [invalidFeed, setInvalidFeed] = React.useState<boolean>(false);
	const [workspaceRedirect, setWorkspaceRedirect] = React.useState<string>(
		`/workspaces/${workspaceId}`,
	);

	const closeModalAndRedirect = () => {
		navigate(workspaceRedirect);
		setInvalidFeed(() => false);
	};

	const triggerOpenedFeed = async (feedId: string) => {
		accountEvent("Opened Feed", { feedId });
		ampli.feedOpen({ feedId });
	};

	React.useEffect(() => {
		const f = async () => {
			await fetchWorkspaceMembership(myAccountId, workspaceId)
				.then((membership) => {
					setInvalidFeed(() => false);
					checkFeedPermissions(membership.id, feedId)
						.then(() => {
							setInvalidFeed(() => false);
						})
						.catch(() => {
							setWorkspaceRedirect(`/workspaces/${workspaceId}`);
							setInvalidFeed(() => true);
						});
				})
				.catch(() => {
					setWorkspaceRedirect("/workspaces");
					setTimeout(() => {
						setInvalidFeed(() => true);
					}, 500);
				});
		};
		f();
	}, [
		workspaceId,
		feedId,
		myAccountId,
		fetchWorkspaceMembership,
		checkFeedPermissions,
		setInvalidFeed,
		setWorkspaceRedirect,
	]);

	React.useEffect(() => {
		const f = async () => {
			if (!myAccountId) return;
			await triggerOpenedFeed(feedId);

			await loadFeed(feedId).catch(() => {
				setTimeout(() => {
					setInvalidFeed(() => true);
				}, 500);
			});
		};
		f();
	}, [feedId, myAccountId, loadFeed, triggerOpenedFeed, setInvalidFeed]);

	React.useEffect(() => {
		if (workspaceId) {
			loadWorkspaceDetails(workspaceId);
		}
	}, [workspaceId, loadWorkspaceDetails]);

	if (invalidFeed) {
		return (
			<ModalForm open={invalidFeed} onClose={() => closeModalAndRedirect()}>
				<Stack sx={{ alignItems: "center", width: "100%", gap: 1 }}>
					<Typography
						variant="h5"
						component="h2"
						sx={{ mb: 2, fontWeight: 700, textAlign: "center" }}
					>
						You do not have rights to view this content?
					</Typography>

					<Typography sx={{ textAlign: "left" }}>
						Please contact your Storyboard administrator to add you as a
						workspace member.
					</Typography>
					<Link
						component="button"
						onClick={closeModalAndRedirect}
						sx={{ display: "flex", gap: 1 }}
					>
						<ArrowBack role="presentation" />
						<Typography>Return to workspace</Typography>
					</Link>
				</Stack>
			</ModalForm>
		);
	}

	if (!feedId || !currentFeed) {
		return (
			<Stack
				sx={{
					width: "100%",
					height: "100%",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Loading />
			</Stack>
		);
	}

	if (currentFeed.loadedFirstPage === 0) {
		return (
			<Stack
				sx={{
					width: "100%",
					height: "100%",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Loading />
			</Stack>
		);
	}

	return <Feed />;
}
