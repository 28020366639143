import { useState, useContext, useEffect } from "react";
import { Box, Button, Divider, Stack, useTheme } from "@mui/material";
import WorkflowScheduledItems from "@/components/Workflows/WorkflowScheduledItems";
import WorkflowRecurringItems from "@/components/Workflows/WorkflowRecurringItems";
import ScheduleCalendar from "@/components/Icons/ScheduleCalendar";
import { UxContext } from "@/models/UxStateProvider";
import { DataContext } from "@/models/DataProvider";
import ScheduleMessageModal from "@/components/Scheduling/ScheduleMessageModal";
import { useParams } from "react-router-dom";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { useEffectOnce } from "usehooks-ts";

export default function Workflows() {
	const { setScheduleMessageModalOpen, setNavTitle } = useContext(UxContext);
	const { loadWorkspaceDetails, loadWorkspaceWorkflowItems } =
		useContext(DataContext);
	const [showScheduled, setShowScheduled] = useState<boolean>(true);
	const [showRecurring, setShowRecurring] = useState<boolean>(false);
	const { db } = useElectric();
	const params = useParams();
	const workspaceId = params.workspaceId;
	const theme = useTheme();

	const { results: myAccount } = useLiveQuery(
		db.account.liveFirst({
			where: {
				mine: 1,
			},
		}),
	);

	const { results: isAdmin } = useLiveQuery(
		db.workspace_membership.liveFirst({
			where: {
				workspaceId,
				accountId: myAccount?.id,
				status: "active",
				role: "admin",
			},
		}),
	);

	useEffect(() => {
		if (workspaceId && myAccount?.id && isAdmin?.id) {
			loadWorkspaceWorkflowItems(workspaceId);
		}
	}, [workspaceId, myAccount?.id, isAdmin?.id, loadWorkspaceWorkflowItems]);

	useEffectOnce(() => {
		setNavTitle("Scheduled Messages");
	});

	return (
		<>
			<ScheduleMessageModal />
			<Stack
				className="dark-scrollbar"
				sx={{
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
					height: "100%",
					py: 8,
					px: 2,
					overflow: "auto",
				}}
			>
				<Stack
					sx={{
						width: "100%",
						maxWidth: 1300,
						height: "auto",
						flexDirection: { xs: "column", sm: "row" },
						alignItems: "center",
						justifyContent: { xs: "flex-start", sm: "space-between" },
						gap: 2,
						// position: "sticky",
						// top: 0,
					}}
				>
					<Stack
						sx={{
							flexDirection: "row",
							justifyContent: { xs: "space-between", sm: "flex-start" },
							gap: { xs: 1, sm: 3 },
							height: 80,
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<Button
							onClick={() => {
								setShowRecurring(false);
								setShowScheduled(true);
							}}
							sx={{
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 1,
								background: showScheduled
									? theme.palette.secondary.main
									: theme.palette.secondary.dark,
								maxWidth: { xs: "calc(50% - 8px)", sm: 200 },
								height: "100%",
								textTransform: "none",
								fontWeight: 700,
								flex: "0 0 auto",
								padding: 4,
							}}
						>
							Scheduled
						</Button>
						<Button
							onClick={() => {
								setShowScheduled(false);
								setShowRecurring(true);
							}}
							sx={{
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 1,
								background: showRecurring
									? theme.palette.secondary.main
									: theme.palette.secondary.dark,
								maxWidth: { xs: "calc(50% - 8px)", sm: 200 },
								height: "100%",
								textTransform: "none",
								fontWeight: 700,
								flex: "0 0 auto",
								padding: 4,
							}}
						>
							Recurring
						</Button>
					</Stack>
					<Box>
						<Button
							color="primary"
							variant="contained"
							sx={{ px: 6, gap: 1 }}
							onClick={() => setScheduleMessageModalOpen(true)}
						>
							<ScheduleCalendar /> New Scheduled Message
						</Button>
					</Box>
				</Stack>
				<Divider
					sx={{
						width: "100%",
						maxWidth: 1300,
						borderWidth: "0.75px",
						borderColor: theme.palette.secondary.light,
						mt: 3,
					}}
				/>
				{showScheduled && !showRecurring ? (
					<Stack sx={{ width: "100%", maxWidth: 1300 }}>
						<WorkflowScheduledItems />
					</Stack>
				) : null}
				{showRecurring && !showScheduled ? (
					<Stack sx={{ width: "100%", maxWidth: 1300 }}>
						<WorkflowRecurringItems />
					</Stack>
				) : null}
			</Stack>
		</>
	);
}
