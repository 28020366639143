import { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import TemplateIcon from "@/components/Icons/TemplateIcon";
import { Button, Box, Stack, Typography, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AppContext } from "@/models/AppStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import ModalForm from "@/elements/ModalForm";
import { FullInput } from "@/components/Utils";
import { useParams } from "react-router-dom";
import { WsTemplate } from "web-client/api/data-contracts";
import { useLiveQuery } from "electric-sql/react";
import { upsertWsTemplate } from "@/data/workspace";
import { useElectric } from "@/electric/ElectricWrapper";
import TextInputHelper from "@/components/TextInputHelper";
import ConfirmDiscardChanges from "@/components/ModalForms/ConfirmDiscardChanges";
import { useEffectOnce } from "usehooks-ts";

export default function TemplateManagement() {
	const { db } = useElectric();
	const [disabled, setDisabled] = useState<boolean>(false);
	const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
	const [confirmDeleteDialog, setConfirmDeleteDialog] =
		useState<boolean>(false);
	const [templateId, setTemplateId] = useState<string>("");
	const [deleteId, setDeleteId] = useState<string>("");
	const [activeTemplate, setActiveTemplate] = useState<WsTemplate | null>(null);
	const [templateText, setTemplateText] = useState<string>("");
	const [templateName, setTemplateName] = useState<string>("");
	const [templates, setTemplates] = useState<Array<WsTemplate>>([]);
	const [confirmAndDiscard, setConfirmAndDiscard] = useState<boolean>(false);
	const disabledSaveState =
		activeTemplate?.name !== templateName ||
		activeTemplate?.template !== templateText;
	const characterLimit = 5000;

	const params = useParams();
	const workspaceId = params?.workspaceId as string;
	const theme = useTheme();
	const { client } = useContext(AppContext);
	const { setNavTitle } = useContext(UxContext);
	const newTemplate = templateId === "new-template";

	const openNewTemplateModal = () => {
		setTemplateId(() => "new-template");
		setTemplateName(() => "");
		setTemplateText(() => "");
	};

	const confirmDelete = (id: string) => {
		setConfirmDeleteDialog(() => true);
		setDeleteId(() => id);
	};

	const editTemplate = (id: string) => {
		const findActiveTemplate = templates.find((template) => template.id === id);
		setActiveTemplate(() => findActiveTemplate);
		setTemplateText(() => findActiveTemplate.template);
		setTemplateName(() => findActiveTemplate.name);
		setTemplateId(() => id);
	};

	const handleDelete = async () => {
		setDisabled(() => true);
		await client.deleteWorkspaceTemplate({ workspaceId, templateId: deleteId });
		setTemplates((array) => array.filter((item) => item.id !== deleteId));
		setDisabled(() => false);
		setDeleteId(() => "");
		setConfirmDeleteDialog(() => false);
		await db.template.delete({
			where: { id: deleteId },
		});
	};

	const formCreateNewTemplate = async (e) => {
		e.preventDefault();
		setDisabled(() => true);
		const { template } = await client.createWorkspaceTemplate({
			workspaceId,
			name: templateName,
			template: templateText,
		});
		setDisabled(() => false);
		setTemplates((array) => [...array, template]);
		setTemplateId(() => "");
		setTemplateName(() => "");
		setTemplateText(() => "");
		await upsertWsTemplate(db, template);
	};
	const formEditTemplate = async (e) => {
		e.preventDefault();
		setDisabled(() => true);
		const { template } = await client.updateWorkspaceTemplate({
			workspaceId,
			templateId,
			name: templateName,
			template: templateText,
		});
		setTemplates((array) =>
			array.map((item) => (item.id === templateId ? template : item)),
		);
		setDisabled(() => false);
		setTemplateId(() => "");
		await upsertWsTemplate(db, template);
	};

	/**
	 * Handle Command Enter when in a text box
	 * @param e
	 */
	const onKeyDown = (e) => {
		if (e.metaKey && e.which === 13) {
			newTemplate ? formCreateNewTemplate(e) : formEditTemplate(e);
		}
	};

	const { results: userInfo } = useLiveQuery(
		db.account.liveFirst({
			where: {
				mine: 1,
			},
		}),
	);

	const { results: cachedTemplates } = useLiveQuery(() => {
		if (!userInfo?.id) return;
		return db.template.liveMany({
			where: {
				workspaceId,
				deletedAt: null,
			},
		});
	}, [userInfo]);

	useEffect(() => {
		if (cachedTemplates?.length > 0) {
			setLoadingTemplates(() => false);
			return setTemplates(() => cachedTemplates);
		} else {
			setLoadingTemplates(() => true);
			setTimeout(() => {
				setLoadingTemplates(() => false);
			}, 5000);
		}
	}, [cachedTemplates]);

	const checkDataBeforeClosing = () => {
		if (disabledSaveState) {
			setConfirmAndDiscard(() => true);
		} else {
			setTemplateId(() => "");
		}
	};

	useEffectOnce(() => {
		setNavTitle("Templates");
	});

	return (
		<>
			<ModalForm
				id="template-modal"
				open={templateId !== ""}
				onClose={() => checkDataBeforeClosing()}
				sx={{ padding: "0rem" }}
			>
				<Box sx={{ width: "100%" }}>
					{confirmAndDiscard && (
						<ConfirmDiscardChanges
							label="Unsaved changes"
							text="Are you sure you want to discard this message? Your content will be lost."
							deleteText="Discard"
							setStatus={(value: boolean) => {
								if (value) {
									setTemplateId(() => "");
									setConfirmAndDiscard(() => false);
								} else {
									setConfirmAndDiscard(() => false);
								}
							}}
						/>
					)}

					<Box
						sx={{
							borderBottom: `solid 1px ${theme.palette.secondary.main}`,
							py: 2,
							px: 4,
							mb: 2,
						}}
					>
						<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
							{newTemplate ? "New Template" : "Edit Template"}
						</Typography>
					</Box>

					<Box
						component="form"
						sx={{ px: 2, pb: 2 }}
						onSubmit={(e) =>
							newTemplate ? formCreateNewTemplate(e) : formEditTemplate(e)
						}
					>
						<Box sx={{ mb: 2 }}>
							<FullInput
								id="newTemplateName"
								placeholder="Enter a title"
								autoFocus
								required
								className="input-v2"
								disabled={disabled}
								value={templateName}
								callback={(e) => setTemplateName(e.target.value)}
							/>
						</Box>
						<Box>
							<FullInput
								id="createNewTemplate"
								disabled={disabled}
								className="text-input-v2"
								multiline
								required
								placeholder="Start a typing your templated message"
								rows={7}
								inputProps={{ className: "dark-scrollbar" }}
								value={templateText}
								callback={(e) => setTemplateText(e.target.value)}
								onKeyDown={onKeyDown}
							/>
						</Box>
						<Box sx={{ my: 1 }}>
							<TextInputHelper
								textInput={templateText}
								limit={characterLimit}
								linksValid={true}
							/>
						</Box>

						<Box
							sx={{
								display: "flex",
								width: "100%",
								justifyContent: "flex-end",
							}}
						>
							<Button
								sx={{ width: "auto" }}
								variant="contained"
								color="primary"
								type="submit"
								disabled={
									disabled ||
									(templateName?.length === 0 && templateText?.length === 0) ||
									templateText?.length > characterLimit ||
									!disabledSaveState
								}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Box>
			</ModalForm>

			<ModalForm
				id="delete-template-modal"
				open={confirmDeleteDialog}
				onClose={() => setConfirmDeleteDialog(false)}
			>
				<Box>
					<Box sx={{ textAlign: "center" }}>
						<Typography sx={{ fontSize: 24 }}>Are you sure?</Typography>
						<Typography sx={{ fontSize: 16, mb: 2 }}>
							Confirm you would like to delete this template. <br />
							Your content will be lost.
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", sm: "row" },
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
							gap: 2.5,
						}}
					>
						<Button
							variant="outlined"
							color="primary"
							disabled={disabled}
							onClick={() => setConfirmDeleteDialog(false)}
						>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="error"
							loading={disabled}
							sx={{ borderRadius: 6 }}
							onClick={handleDelete}
							aria-label="Confirm Delete Template"
						>
							Delete
						</LoadingButton>
					</Box>
				</Box>
			</ModalForm>

			<Stack
				className="dark-scrollbar"
				sx={{
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
					height: "100%",
					py: 8,
					px: 2,
					overflow: "auto",
				}}
			>
				<Stack
					sx={{
						width: "100%",
						maxWidth: 1300,
						height: "auto",
						pb: 4,
						mb: 4,
						flexDirection: { xs: "column", sm: "row" },
						justifyContent: { xs: "flex-start", sm: "space-between" },
						gap: 2,
					}}
				>
					<Stack
						sx={{
							flexDirection: "row",
							justifyContent: { xs: "space-between", sm: "flex-start" },
							gap: { xs: 1, sm: 3 },
							height: 80,
							width: { xs: "100%", sm: "auto" },
						}}
					>
						{templates?.length > 0 && (
							<Button
								sx={{
									alignItems: "center",
									justifyContent: "center",
									borderRadius: 1,
									background: theme.palette.secondary.main,
									textTransform: "none",
									fontWeight: 700,
									height: "100%",
									flex: "0 0 auto",
									px: 4,
								}}
							>
								Active Templates
							</Button>
						)}
					</Stack>
					<Stack
						sx={{
							flexDirection: "row",
							gap: 3,
							alignItems: "center",
						}}
					>
						<Button
							color="primary"
							variant="contained"
							sx={{ px: 6, gap: 1 }}
							onClick={() => openNewTemplateModal()}
						>
							<TemplateIcon /> New Template
						</Button>
					</Stack>
				</Stack>

				<Box
					sx={{
						width: "100%",
						maxWidth: 1300,
						background: theme.palette.secondary.dark,
						borderRadius: 2,
						py: 3,
						px: 3,
					}}
				>
					{templates?.length > 0 ? (
						templates.map((template) => (
							<Box
								key={template.id}
								sx={{
									borderRadius: "0.5rem",
									display: "flex",
									alignItems: "center",
									mb: 1,
									background: template.id === templateId && "#404449",
								}}
							>
								<Button
									aria-label="Edit Template"
									sx={{
										flexGrow: "1",
										textTransform: "none",
										pl: 2,
										mr: 2,
										justifyContent: "flex-start",
									}}
									onClick={() => editTemplate(template.id)}
								>
									{template.name}
								</Button>
								<Box sx={{ flex: "0 1 auto", display: "flex" }}>
									<Button onClick={() => editTemplate(template.id)}>
										Edit
									</Button>
									<Button onClick={() => confirmDelete(template.id)}>
										<DeleteIcon />
									</Button>
								</Box>
							</Box>
						))
					) : (
						<Box sx={{ textAlign: "center" }}>
							<Typography sx={{ fontWeight: 600 }}>
								{loadingTemplates ? "Loading Templates" : "No templates"}
							</Typography>
						</Box>
					)}
				</Box>
			</Stack>
		</>
	);
}
