import { UxContext } from '@/models/UxStateProvider';
import { Tags } from '@/utils';
import { Button, styled as styledMUI, useTheme } from '@mui/material';
import { ReactNode, useContext, useEffect, useRef } from 'react';
import { drawerOpenStyles } from './SBAppBar';

export interface FeedDateHeaderProps {
  dateId: string;
  children: ReactNode;
  onClick: () => void;
}

const MUIHeader = styledMUI('div')({
  top: '-1px',
  width: '100vw',
  position: 'sticky',
  height: '48px',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '500',
  boxSizing: 'border-box',
  zIndex: 1099
});

export default function FeedDateHeader({
  dateId,
  children,
  onClick
}: FeedDateHeaderProps) {
  const headerRef = useRef<HTMLDivElement>();
  const dateHeaderRef = useRef<HTMLButtonElement>();

  const theme = useTheme();
  const { isSmUp, leftNavOpen, rightNavOpen } = useContext(UxContext);

  useEffect(() => {
    let observer;
    if (headerRef.current) {
      observer = new IntersectionObserver(
        ([e]) =>
          dateHeaderRef.current?.classList.toggle(
            'is-sticky',
            e.intersectionRatio < 1
          ),
        { threshold: [1] }
      );

      observer.observe(headerRef.current);
    }
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _drawerOpenStyles = {
    ...drawerOpenStyles({
      isSmUp,
      leftNavOpen,
      rightNavOpen,
      theme
    }),
    width: '100%',
    marginRight: 0,
    marginLeft: 0
  };

  return (
    <MUIHeader ref={headerRef} as={Tags.h3} sx={_drawerOpenStyles}>
      <Button
        id={dateId}
        ref={dateHeaderRef}
        onClick={onClick}
        disableRipple={true}
        aria-label={`scroll to ${dateId}`}
        sx={{
          padding: 1,
          transition: '.1s',
          textTransform: 'none',
          '&.is-sticky': {
            background: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(4px)'
          }
        }}
      >
        {children}
      </Button>
    </MUIHeader>
  );
}
