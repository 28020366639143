import { Electric } from '@/generated/client';
import { Feed } from '@prisma/client';
import { LiveResultContext } from 'electric-sql/dist/client/model/model';
import { ResultData } from 'electric-sql/dist/frameworks/react/hooks/useLiveQuery';
import { DependencyList } from 'react';

export type DmChannel = {
    workspace_membershipId: string;
    feedId: string;
    accountId: string;
    name?: string;
    email?: string;
};

type queryType<Res> = (
  runQueryFn: () => LiveResultContext<Res>,
  dependencies: DependencyList
) => ResultData<Res>;

export function getDmChannels<Res>(
  useLiveQuery: queryType<Res>,
  db: Electric['db'],
  { channels }: { channels: Array<Feed> }
) {
 const { results: dmChannels, error } = useLiveQuery(() => {
    if(!channels && channels?.length === 0) {
        return;
    }

    return db.liveRaw({
        sql: `SELECT
                permission.workspace_membershipId,
                permission.feedId,
                workspace_membership.accountId,
                account.name,
                account.email
            FROM
                permission
            INNER JOIN workspace_membership ON workspace_membership.id = permission.workspace_membershipId
            INNER JOIN account ON account.id = workspace_membership.accountId
            WHERE
                permission.enabled = 1
                    AND
                permission.feedId IN (?)
                    AND
                permission.workspace_membershipId IS NOT NULL
                    AND
                account.mine = 0
            GROUP BY permission.workspace_membershipId`,
        args: [
            channels
            ?.filter((c) => c?.isDm === 1)
            ?.map((d) => d?.id)
            ?.join(',')
        ]
    });

 }, [channels])

  return { dmChannels, error } as {
    dmChannels: DmChannel[];
    error: any;
  };
}
