import { useState, useContext, useEffect, useCallback } from "react";
import { Box, Link, Stack, Button, Typography, useTheme } from "@mui/material";
import ModalForm from "@/elements/ModalForm";
import HandleFileUpload from "./HandleFileUpload";
import { FullInput } from "../Utils";
import { isEmpty } from "validator";
import { FeedContext } from "@/models/FeedContextProvider";
import { UppyContext } from "@/models/UppyContextProvider";
import { Base64 } from "js-base64";
import AddedFile from "./AddedFile";

export default function FileUploadModal() {
	const { fileUploadModal, setFileUploadModal } = useContext(FeedContext);
	const {
		addedFile,
		addMetaData,
		hasFiles,
		isAudioFileUpload,
		removeFiles,
		uppyClient,
	} = useContext(UppyContext);
	const theme = useTheme();

	const [title, setTitle] = useState<string>("");
	const [titleValid, setTitleValid] = useState<boolean>(false);
	const [description, setDescription] = useState<string>("");
	const [descriptionValid, setDescriptionValid] = useState<boolean>(true);

	const titleCharLimit = 40;
	const descriptionCharLimit = 500;

	const verifyTitleInput = useCallback((title) => {
		if (
			!isEmpty(title, { ignore_whitespace: true }) &&
			title?.length <= titleCharLimit
		) {
			setTitleValid(true);
		} else {
			setTitleValid(false);
		}
	}, []);

	const verifyDescriptionInput = useCallback((description) => {
		if (description?.length <= descriptionCharLimit) {
			setDescriptionValid(true);
		} else {
			setDescriptionValid(false);
		}
	}, []);

	const handleTitleChange = useCallback(
		(title) => {
			setTitle(title);
			verifyTitleInput(title);
		},
		[verifyTitleInput],
	);

	const handleDescriptionChange = useCallback(
		(description) => {
			setDescription(description);
			verifyDescriptionInput(description);
		},
		[verifyDescriptionInput],
	);

	const publishUpload = async () => {
		if (titleValid && descriptionValid) {
			try {
				// tusd requires metadata to be Base64 encoded, encode to support emojis, etc
				// https://tus.io/protocols/resumable-upload#upload-metadata
				addMetaData({
					title: Base64.encode(title),
					description: Base64.encode(description),
				});
				setFileUploadModal(false);
				await uppyClient.upload();
				reset();
			} catch (e) {
				console.log("Error uploading long form audio", e);
			}
		}
	};

	const handleClose = () => {
		removeFiles();
		setFileUploadModal(false);
	};

	const reset = () => {
		setTitle("");
		setDescription("");
		setTitleValid(false);
		setDescriptionValid(true);
	};

	useEffect(() => {
		if (addedFile?.name) {
			handleTitleChange(addedFile?.name);
		}
	}, [addedFile?.name, handleTitleChange]);

	const HelperText = ({ characterLimit, field }) => {
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: 1,
					width: "100%",
				}}
			>
				<Box>
					{field?.length}/{characterLimit}
				</Box>
			</Box>
		);
	};

	return (
		<ModalForm
			id="file-upload"
			maxHeight={hasFiles && isAudioFileUpload ? "750px" : "600px"}
			open={fileUploadModal}
			onClose={handleClose}
			sx={{ justifyContent: "center", flexGrow: 1, overflow: "hidden" }}
		>
			{hasFiles && isAudioFileUpload ? (
				<Stack
					sx={{
						width: "100%",
						alignItems: "center",
						gap: 1,
						flexGrow: 1,
						overflowY: "auto",
					}}
					className="dark-scrollbar"
				>
					<Typography
						variant="h5"
						component="h3"
						sx={{ fontWeight: 700, pb: 4.5 }}
					>
						File Upload Details
					</Typography>
					<Stack sx={{ gap: 1, width: "100%" }}>
						<AddedFile file={addedFile} />
						<Link
							component="button"
							sx={{ alignSelf: "flex-end" }}
							onClick={removeFiles}
						>
							Replace
						</Link>
					</Stack>
					<FullInput
						label="Title*"
						required
						value={title}
						callback={(e) => handleTitleChange(e.target.value)}
						error={title.length > titleCharLimit}
						helperText={
							<HelperText characterLimit={titleCharLimit} field={title} />
						}
					/>
					<FullInput
						label="Description"
						multiline
						rows={5}
						value={description}
						callback={(e) => handleDescriptionChange(e.target.value)}
						error={description.length > descriptionCharLimit}
						helperText={
							<HelperText
								characterLimit={descriptionCharLimit}
								field={description}
							/>
						}
					/>
					<Stack
						sx={{
							flexDirection: { xs: "column", sm: "row" },
							width: "100%",
							alignItems: "center",
							justifyContent: "space-between",
							gap: 2,
						}}
					>
						<Button
							variant="outlined"
							sx={{ order: { xs: 1, sm: 0 } }}
							onClick={removeFiles}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							sx={{ order: { xs: 0, sm: 1 } }}
							onClick={publishUpload}
							disabled={!titleValid || !descriptionValid}
						>
							Publish
						</Button>
					</Stack>
				</Stack>
			) : (
				<HandleFileUpload />
			)}
		</ModalForm>
	);
}
