import { Stack, Typography, useTheme } from "@mui/material";
import {
	AudioFileOutlined,
	ImageOutlined,
	DescriptionOutlined,
} from "@mui/icons-material";

export default function AddedFile({ file }) {
	const fileType = file?.meta?.type;
	const fileIcon = () => {
		let icon;
		if (fileType) {
			switch (fileType) {
				case "audio":
					icon = <AudioFileOutlined role="presentation" />;
					break;
				case "image":
					icon = <ImageOutlined role="presentation" />;
					break;
				default:
					icon = <DescriptionOutlined role="presentation" />;
					break;
			}
		}
		return icon;
	};
	const theme = useTheme();

	return (
		<Stack
			sx={{
				width: "100%",
				flexDirection: "row",
				alignItems: "center",
				px: 2,
				py: 1.5,
				background: theme.palette.secondary.main,
				borderRadius: 1.5,
				gap: 1,
			}}
		>
			{fileIcon()}
			<Typography
				sx={{
					display: "-webkit-box",
					"-webkit-line-clamp": "2",
					"-webkit-box-orient": "vertical",
					wordBreak: "break-word",
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
			>
				{file?.name}
			</Typography>
		</Stack>
	);
}
