import { useElectric } from "@/electric/ElectricWrapper";
import { DataContext } from "@/models/DataProvider";
import { UxContext } from "@/models/UxStateProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { defaultFocusStyles, handleErrorResponse } from "@/utils";
import * as Icons from "@mui/icons-material";
import {
	Box,
	Button,
	Divider,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DrawerHeader } from "@/components/DrawerHeader";

export default function WorkspaceDrawerHeader() {
	const {
		isSmUp,
		toggleLeftNav,
		setLeftNavOpen,
		setRightNavOpen,
		toggleWorkspaceModal,
		setEditWorkspaceSettings,
		setWorkspaceModalOpen,
	} = React.useContext(UxContext);
	const { myAccount } = React.useContext(DataContext);
	const { ampli } = React.useContext(TrackingContext);
	const theme = useTheme();

	const { db } = useElectric();
	const params = useParams();
	const navigate = useNavigate();
	const workspaceId = params.workspaceId;

	const { results: workspace } = useLiveQuery(
		db.workspace.liveUnique({
			where: {
				id: workspaceId,
			},
		}),
	);

	const { results: workspaceList } = useLiveQuery(
		db.workspace.liveMany({
			orderBy: { name: "asc" },
		}),
	);

	const { results: isWorkspaceAdmin } = useLiveQuery(
		db.workspace_membership.liveFirst({
			where: {
				AND: [
					{ accountId: myAccount?.id },
					{ workspaceId },
					{ role: { contains: "admin" } },
				],
			},
		}),
	);

	const [workspaceAnchorEl, setWorkspaceAnchorEl] =
		React.useState<null | HTMLElement>(null);
	const [workspaceSettingsAnchorEl, setWorkspaceSettingsAnchorEl] =
		React.useState<null | HTMLElement>(null);
	const workspaceMenuOpen = Boolean(workspaceAnchorEl);
	const workspaceSettingsMenuOpen = Boolean(workspaceSettingsAnchorEl);

	const openWorkspaceMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setWorkspaceAnchorEl(event.currentTarget);
	};

	const closeWorkspaceMenu = () => {
		setWorkspaceAnchorEl(null);
	};

	const goToWorkspace = (ws) => {
		closeWorkspaceMenu();
		setTimeout(() => navigate(`/workspaces/${ws.id}`), 200);
	};

	const openWorkspaceSettingsMenu = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setWorkspaceSettingsAnchorEl(event.currentTarget);
	};

	const closeWorkspaceSettingsMenu = () => {
		setWorkspaceSettingsAnchorEl(null);
	};

	const goToUserManagement = useCallback(() => {
		ampli.manageUsers();
		setLeftNavOpen(false);
		setRightNavOpen(false);
		closeWorkspaceSettingsMenu();
		setTimeout(
			() => navigate(`/workspaces/${workspaceId}/user-management`),
			200,
		);
	}, [
		workspaceId,
		ampli,
		navigate,
		setLeftNavOpen,
		setRightNavOpen,
		closeWorkspaceSettingsMenu,
	]);

	const openWorkspaceModal = useCallback(
		(edit) => {
			if (edit) {
				ampli.editWorkspaceName();
			} else {
				ampli.createWorkspace();
			}
			closeWorkspaceMenu();
			setEditWorkspaceSettings(edit);
			setWorkspaceModalOpen(true);
		},
		[
			ampli,
			closeWorkspaceMenu,
			setEditWorkspaceSettings,
			setWorkspaceModalOpen,
		],
	);

	const truncatedName = (
		string: string,
		length: number,
		dots?: string,
	): string =>
		`${string?.substring(0, length)}${string?.length >= length ? dots : ""}`;

	return (
		<>
			<DrawerHeader position="top" sx={{ p: 0 }}>
				<Stack
					sx={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						py: 2,
						gap: 1,
						width: "100%",
						height: 80,
					}}
				>
					<Stack
						sx={{
							flexDirection: "row",
							alignItems: "center",
							gap: 1.5,
						}}
					>
						<Button
							variant="text"
							color="primary"
							onClick={openWorkspaceMenu}
							sx={{
								textTransform: "none",
								gap: 1.5,
								p: 0,
								"&:hover": {
									background: "transparent",
								},
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									borderRadius: "8px",
									height: 44,
									width: 44,
									border: `1px solid ${theme.palette.neutral.main}`,
								}}
							>
								{workspace?.name[0]}
							</Box>
							<Typography
								title={workspace?.name}
								variant="h6"
								component="h2"
								sx={{
									display: "flex",
									alignItems: "center",
									fontSize: "1.2rem",
									lineHeight: "1.5rem",
									maxHeight: "72px",
									overflow: "hidden",
								}}
							>
								{truncatedName(workspace?.name, 60, "...")}
								{workspaceMenuOpen ? (
									<Icons.KeyboardArrowUp role="img" />
								) : (
									<Icons.KeyboardArrowDown role="img" />
								)}
							</Typography>
						</Button>
						<Menu
							anchorEl={workspaceAnchorEl}
							open={workspaceMenuOpen}
							onClose={closeWorkspaceMenu}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							sx={{
								mt: 1,
								".MuiMenu-list": {},
							}}
							MenuListProps={{
								sx: {
									padding: "24px 20px",
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
									gap: 2.5,
								},
							}}
						>
							{workspaceList?.map((ws) => (
								<MenuItem
									key={`workspace-${ws.id}`}
									onClick={() => goToWorkspace(ws)}
									sx={{
										gap: 1.5,
										borderRadius: "8px",
										p: 0,
										pr: 1,
										width: "100%",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											borderRadius: "8px",
											height: 44,
											width: 44,
											border: `1px solid ${theme.palette.neutral.main}`,
										}}
									>
										{ws?.name[0]}
									</Box>
									<Typography
										title={ws?.name}
										variant="h6"
										component="h2"
										sx={{
											display: "flex",
											alignItems: "center",
											fontSize: "1.2rem",
											lineHeight: "1.5rem",
											maxHeight: "72px",
											overflow: "hidden",
										}}
									>
										{truncatedName(ws?.name, 60, "...")}
									</Typography>
								</MenuItem>
							))}
							<Divider
								sx={{
									width: "100%",
									m: "0 !important",
									borderBottomWidth: "1.5px",
									borderColor: theme.palette.secondary.main,
								}}
							/>
							<MenuItem
								key={"create-workspace"}
								onClick={() => openWorkspaceModal(false)}
								sx={{
									gap: 1.5,
									alignItems: "center",
									justifyContent: "center",
									borderRadius: "8px",
									alignSelf: "center",
								}}
							>
								<Icons.AddCircleOutline />
								<Typography variant="h6" sx={{ fontWeight: 700 }}>
									New Workspace
								</Typography>
							</MenuItem>
						</Menu>
					</Stack>
					{workspaceId && isWorkspaceAdmin ? (
						<>
							<IconButton
								color="secondary"
								aria-label={"Options menu for workspace"}
								onClick={openWorkspaceSettingsMenu}
							>
								<Icons.PendingOutlined role="img" />
							</IconButton>
							<Menu
								anchorEl={workspaceSettingsAnchorEl}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								sx={{
									mt: 1,
								}}
								open={workspaceSettingsMenuOpen}
								onClose={closeWorkspaceSettingsMenu}
							>
								<Box>
									<MenuItem onClick={goToUserManagement} key="workspace-users">
										<ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
											Manage users
										</ListItemText>
									</MenuItem>
									<MenuItem
										onClick={() => openWorkspaceModal(true)}
										key="workspace-name"
									>
										<ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
											Edit workspace name
										</ListItemText>
									</MenuItem>
								</Box>
							</Menu>
						</>
					) : null}
				</Stack>
				{!isSmUp && (
					<IconButton
						color="primary"
						aria-label="close drawer"
						onClick={toggleLeftNav}
					>
						<Icons.Close role="img" />
					</IconButton>
				)}
			</DrawerHeader>
		</>
	);
}
