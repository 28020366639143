import {
	Stack,
	Box,
	IconButton,
	Button,
	Typography,
	List,
	ListItem,
	useTheme,
	ListSubheader,
	ListItemButton,
	Collapse,
} from "@mui/material";
import {
	Done,
	DoneAll,
	Close,
	Check,
	KeyboardArrowDown,
	KeyboardArrowUp,
} from "@mui/icons-material";
import { AccountInfo } from "@/models/accountInfo";
import { AccountEvent, Item } from "@/generated/client";
import { InitialsAvatar } from "./Avatar";
import { TrackingContext } from "@/models/TrackingStateProvider";
import React, { forwardRef, useImperativeHandle } from "react";
import ModalForm from "@/elements/ModalForm";
import { format } from "date-fns";
import { useToggle } from "usehooks-ts";

export type Status = {
	account: AccountInfo;
	started?: AccountEvent;
	completed: AccountEvent;
};

export const FeedItemStatus = forwardRef(
	(
		{
			sender,
			item,
			statuses,
			statusMessage,
		}: {
			sender: AccountInfo;
			item: Item;
			statuses: Status[];
			statusMessage?: string;
		},
		ref?: React.MutableRefObject<any>,
	) => {
		const [messageDetailsOpen, setMessageDetailsOpen] = React.useState(false);
		const { ampli } = React.useContext(TrackingContext);

		const [showSent, showSentToggle] = useToggle(true);
		const [showCompleted, showCompletedToggle] = useToggle(true);
		const [showStarted, showStartedToggle] = useToggle(true);
		const theme = useTheme();

		const started = statuses?.filter((status) => status?.started);
		const completed = statuses?.filter((status) => status?.completed);

		const handleOpen = React.useCallback(() => {
			setMessageDetailsOpen(true);
			ampli.viewMessageDetails();
		}, [setMessageDetailsOpen, ampli]);

		const handleClose = React.useCallback(() => {
			setMessageDetailsOpen(false);
		}, [setMessageDetailsOpen]);

		const getFormattedTime = React.useCallback((dateString: string) => {
			const date = new Date(dateString);
			return format(date, "M/dd/yy h:mm aaa");
		}, []);

		useImperativeHandle(
			ref,
			() => ({
				open() {
					handleOpen();
				},
				close() {
					handleClose();
				},
			}),
			[handleOpen, handleClose],
		);

		const StatusListHeader = ({
			children,
			callback,
			titleColor = theme.palette.primary.main,
		}: {
			children: React.ReactNode;
			callback: Function;
			titleColor?: string;
		}) => (
			<ListSubheader
				sx={{
					background: theme.palette.primary.dark,
					p: 0,
					borderBottom: `1px solid ${theme.palette.secondary.main}`,
				}}
			>
				<ListItemButton
					onClick={() => callback()}
					sx={{ padding: "12px 44px" }}
				>
					<Typography
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							color: titleColor,
							width: "100%",
						}}
						component="div"
					>
						{children}
					</Typography>
				</ListItemButton>
			</ListSubheader>
		);

		const StatusListItem = ({
			account,
			statusDate,
		}: {
			account: AccountInfo;
			statusDate: string;
		}) => (
			<ListItem
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					padding: "12px 44px",
					borderBottom: `1px solid ${theme.palette.secondary.main}`,
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					<InitialsAvatar
						initials={account?.initials?.[0] || account?.name?.[0] || ""}
						color={account?.avatarColor}
					/>
					<Typography fontWeight={500}>{account?.name}</Typography>
				</Box>
				<Box>{getFormattedTime(statusDate)}</Box>
			</ListItem>
		);

		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "4px",
				}}
			>
				{completed?.length > 0 ? (
					<Button
						aria-label="View message details"
						onClick={() => setMessageDetailsOpen(true)}
					>
						<Typography
							variant="body2"
							sx={{
								display: "flex",
								alignItems: "center",
								fontSize: "0.875rem",
								gap: "4px",
								fontWeight: 500,
							}}
						>
							<DoneAll fontSize="small" /> {completed?.length}
						</Typography>
					</Button>
				) : (
					<>
						{statusMessage && (
							<Typography
								sx={{
									fontSize: "0.75rem",
									display: "flex",
									alignItems: "center",
									fontWeight: 500,
									color:
										statusMessage === "Sending..."
											? theme.palette.primary.main
											: theme.palette.neutral.main,
								}}
							>
								{statusMessage === "Sent" && <Check sx={{ pr: 0.5 }} />}
								{statusMessage}
							</Typography>
						)}
					</>
				)}
				<ModalForm
					open={messageDetailsOpen}
					onClose={handleClose}
					sx={{
						padding: { xs: "0", sm: "0 0 12px 0" },
					}}
				>
					<Stack
						sx={{ alignItems: "flex-start", height: "100%", width: "100%" }}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								padding: "12px 44px",
								position: "sticky",
								top: 0,
								zIndex: 1100,
								background: theme.palette.secondary.dark,
							}}
						>
							<Typography variant="h6" component="h2">
								Message Details
							</Typography>
						</Box>
						<List
							sx={{
								width: "100%",
								position: "relative",
								overflow: "auto",
								maxHeight: { sm: 450 },
								"& ul": { padding: 0 },
							}}
							subheader={<li />}
						>
							<li key={`section-${item?.id}-sent`}>
								<ul key={`section-${item?.id}-sent-events`}>
									<StatusListHeader
										callback={() => showSentToggle()}
										titleColor={theme.palette.neutral.main}
									>
										<Stack
											flexDirection={"row"}
											sx={{
												flexDirection: "row",
												gap: 1,
												alignItems: "center",
											}}
										>
											<Done role="img" fontSize="small" />
											Sent
										</Stack>
										{showSent ? (
											<KeyboardArrowUp role="img" />
										) : (
											<KeyboardArrowDown role="img" />
										)}
									</StatusListHeader>
									<Collapse in={showSent}>
										<StatusListItem
											account={sender}
											statusDate={item?.createdAt}
										/>
									</Collapse>
								</ul>
							</li>
							{completed?.length > 0 && (
								<li key={`section-${item?.id}-listened`}>
									<ul key={`section-${item?.id}-completed-events`}>
										<StatusListHeader callback={() => showCompletedToggle()}>
											<Stack
												flexDirection={"row"}
												sx={{
													flexDirection: "row",
													gap: 1,
													alignItems: "center",
												}}
											>
												<DoneAll role="img" fontSize="small" />
												Completed
											</Stack>
											{showCompleted ? (
												<KeyboardArrowUp role="img" />
											) : (
												<KeyboardArrowDown role="img" />
											)}
										</StatusListHeader>
										<Collapse in={showCompleted}>
											{completed.map((status, index) => (
												<StatusListItem
													key={`status_${index}`}
													account={status?.account}
													statusDate={status?.completed?.createdAt}
												/>
											))}
										</Collapse>
									</ul>
								</li>
							)}
							{started?.length > 0 && (
								<li key={`section-${item.id}-started`}>
									<ul key={`section-${item.id}-started-events`}>
										<StatusListHeader callback={() => showStartedToggle()}>
											<Stack
												flexDirection={"row"}
												sx={{
													flexDirection: "row",
													gap: 1,
													alignItems: "center",
												}}
											>
												<Done role="img" fontSize="small" />
												Started
											</Stack>
											{showStarted ? (
												<KeyboardArrowUp role="img" />
											) : (
												<KeyboardArrowDown role="img" />
											)}
										</StatusListHeader>
										<Collapse in={showStarted}>
											{started.map((status, index) => (
												<StatusListItem
													key={`status_${index}`}
													account={status?.account}
													statusDate={status?.started?.createdAt}
												/>
											))}
										</Collapse>
									</ul>
								</li>
							)}
						</List>
					</Stack>
				</ModalForm>
			</Box>
		);
	},
);

export default FeedItemStatus;
