import PlaybackIndicator from "@/components/PlaybackIndicator/Index";
import RadialProgress from "@/components/RadialProgress";
import AudioLevelIndicator from "@/components/Visualization/AudioLevelIndicator";
import useLocationChange from "@/hooks/useLocationChange";
import { AppContext } from "@/models/AppStateProvider";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { AUDIO_FILTERS, getBandpassData } from "@/utils/audio";
import { buildVersion } from "@/utils/environment";
import type { AudioNodeInterface } from "@/utils/recording";
import {
	AudioContextInterface,
	cancelWakeLock,
	clearIndexDB,
	fetchAudioMetaData,
	fetchUploadConfig,
	forceFileDownload,
	getPaddedTime,
	monitorAudioLevels,
	requestWakeLock,
	setupAudioNode,
} from "@/utils/recording";
import ClearIcon from "@mui/icons-material/Clear";
import HourglassTopTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import {
	Alert,
	AlertColor,
	Box,
	Chip,
	Snackbar,
	styled,
	useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import Sessioncore from "@storyboard-fm/sessioncore";
import Bowser from "bowser";
import React, {
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
	RefObject,
} from "react";
import { useTus } from "use-tus";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { VoiceAssistantContext } from "@/models/VoiceAssistantContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import cuid from "cuid";
import { drawerOpenStyles } from "../SBAppBar";
import "./index.css";
import { DataContext } from "@/models/DataProvider";
import { ActionContext } from "@/models/ActionsProvider";
import UploadFileButton from "@/components/UploadFiles/UploadFileButton";
import { VListHandle } from "virtua";
import TTSButton from "../UploadFiles/TTSButton";

const showSnackBar: boolean = true;
const sessionCorePrefix: string = "sessioncore-";
const maxRecordingLength: number = Number(
	import.meta.env.VITE_SESSION_RECORDING_LENGTH ?? 900,
);
const intervalLength: number = Number(
	import.meta.env.VITE_SESSION_RECORDING_INTERVAL ?? 90,
);

interface SessionCoreInterface {
	id: string;
	ctx: AudioContext;
	source: MediaStreamAudioSourceNode;
	setup: () => void;
	start: () => void;
	stop: () => void;
	wav: () => Blob;
	blob: () => Blob;
}

interface IntervalInterface {
	message: string;
	color: string;
	severity: AlertColor;
}

interface TimeInterface {
	milliseconds: number;
	seconds: number;
	time: string;
}

const initialTimeObject = {
	milliseconds: 0,
	seconds: 0,
	time: "00:00",
};

/**
 * Interval Steps. Automatically set when interval changes
 */
const intervals: IntervalInterface[] = [
	{ message: "", color: "#00ff04", severity: "info" },
	{
		message: "Shorter messages are easier to consume",
		color: "#00ff04",
		severity: "info",
	},
	{
		message:
			"Consider recording multiple short messages rather than one long one",
		color: "#84ff00",
		severity: "info",
	},
	{ message: "", color: "#c8ff00", severity: "success" },
	{ message: "", color: "#fffb00", severity: "success" },
	{ message: "", color: "#FFC400FF", severity: "warning" },
	{ message: "", color: "#ff9900", severity: "warning" },
	{ message: "", color: "#ff7300", severity: "warning" },
	{ message: "", color: "#ff4400", severity: "error" },
	{
		message: `The recording limit is ${maxRecordingLength / 60} min`,
		color: "#ff1e00",
		severity: "error",
	},
	{ message: "", color: "#ff0073", severity: "error" },
];

export const SessionRecorderWrapper = styled("div")({});

export default function SessionRecorderFooter({
	workspaceId,
	feedId,
	vListRef = null,
}: { workspaceId: string; feedId: string; vListRef?: RefObject<VListHandle> }) {
	const { ampli } = React.useContext(TrackingContext);
	const { config, flags, client } = useContext(AppContext);
	const { voiceAssistant, command } = useContext(VoiceAssistantContext);

	const { publishToWorkspaceFeed } = useContext(ActionContext);
	const { myAccount, preferredLanguage } = useContext(DataContext);
	const { queuePlaying, setPlaybackEnabled, playQueue, pauseQueue } =
		React.useContext(AudioAppContext);

	const { db } = useElectric();

	const { results: audioQueue } = useLiveQuery(
		db.audio_queue_item.liveMany({
			orderBy: {
				createdAt: "desc",
			},
		}),
	);

	const { results: feedItems } = useLiveQuery(() => {
		if (!feedId) return;
		return db.item.liveMany({
			where: {
				feedId: feedId,
				deletedAt: null,
			},
			orderBy: {
				createdAt: "asc",
			},
		});
	}, [feedId]);

	const forceDownload: boolean = flags.recordingDebug ?? false;
	const debugConsole: boolean = flags.recordingDebug ?? false;

	const theme = useTheme();
	const { isSmUp, leftNavOpen, rightNavOpen } = useContext(UxContext);

	const { upload, setUpload } = useTus();
	const { platform } = Bowser.parse(window.navigator.userAgent);
	const isMobile: boolean = platform.type === "mobile";

	/**
	 * useRef
	 */
	const requestRef = useRef<number | undefined>(undefined);
	const sessionCore = useRef<SessionCoreInterface>({} as SessionCoreInterface);
	const startTime = useRef<Date>(new Date());
	const queuePlayingRef = useRef<boolean>(queuePlaying);

	/**
	 * useState
	 */
	const [audioLevel, setAudioLevel] = useState<number>(0);
	const [audioNode, setAudioNode] = useState(null as AudioNodeInterface);
	const [color, setColor] = useState<string>("");
	const [counter, setCounter] = useState<number>(0);
	const [disabled, setDisabled] = useState<boolean>(false);
	const [error, setError] = useState<object | null>(null);
	const [message, setMessage] = useState<string | null>(null);
	const [processing, setProcessing] = useState<boolean>(false);
	const [progress, setProgress] = useState<number>(0);
	const [recording, setRecording] = useState<boolean>(false);
	const [sessionName, setSessionName] = useState<string>(
		`${sessionCorePrefix}${Date.now()}`,
	);
	const [severity, setSeverity] = React.useState<AlertColor>("info");
	const [snackBarOpen, setSnackBarOpen] = React.useState<boolean>(false);
	const [timeObject, setTimeObject] =
		useState<TimeInterface>(initialTimeObject);
	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const [wakeLock, setWakeLock] = useState(null);
	const [contentId, setContentId] = useState<string>(null);
	/**
	 * Button disabled state
	 */
	const disabledState = useMemo(() => {
		if (disabled) {
			return true;
		} else if (error) {
			return false;
		} else if (processing) {
			return true;
		} else if (uploadProgress > 0) {
			return true;
		}
		return false;
	}, [processing, uploadProgress, error, disabled]);

	const markUploadAsComplete = () => {
		setProcessing(() => false);
		setContentId(() => null);
		setUploadProgress(() => 0);
		setCounter(() => 0);
		setError(() => null);
		setDisabled(() => false);
		clearIndexDB(sessionName, null).then((data) => data);
	};

	/**
	 * Handle File Upload
	 * @param file
	 * @param duration
	 */
	const setupUploadData = async (file: Blob, duration: number) => {
		const { stage, tusUrl } = fetchUploadConfig(config);
		const uploadContentId = contentId || cuid();

		const item = await publishToWorkspaceFeed({
			workspaceId,
			feedId,
			contentId: uploadContentId,
		});

		const itemId = item?.id;
		client.createContentEvent({
			contentId: uploadContentId,
			step: "client_uploading",
			status: "started",
			feedId,
		});
		const startTime = Date.now();

		const audioMetaData: AudioContextInterface = fetchAudioMetaData(
			sessionCore.current.ctx,
		);

		const metadata = {
			filename: uploadContentId,
			filetype: file.type,
			id: uploadContentId,
			codec: "wav",
			type: "audio",
			uploadId: uploadContentId,
			surface: "web",
			surfaceContext: window?.navigator?.userAgent,
			surfaceBuild: buildVersion(),
			stage: stage,
			feedItemId: itemId,
			feedId: feedId,
			accountId: myAccount?.id,
			duration: duration ? duration.toString() : "",
			preferredLanguage,
			inputType: "Recording",
			...audioMetaData,
		};

		console.log({ metadata });

		setUpload(file, {
			storeFingerprintForResuming: true,
			endpoint: tusUrl,
			retryDelays: [0, 3000, 5000, 10000, 20000],
			metadata,
			onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
				console.log(
					"onChunkComplete",
					{ chunkSize },
					{ bytesAccepted },
					{ bytesTotal },
				);
			},
			onProgress: (bytesUploaded, bytesTotal) => {
				if (bytesUploaded > 0) {
					const percentage = Math.floor((bytesUploaded / bytesTotal) * 100);
					console.log({ percentage });
					setUploadProgress(() => percentage);
				}
			},
			onError: async (error) => {
				console.error("There wan an error:", error);
				ampli.uploadFailed({ feedId });
				setUploadProgress(() => 0);
				setDisabled(() => false);
				setError(() => error);
				Sentry.captureException(error);
				client.createContentEvent({
					contentId: uploadContentId,
					step: "client_uploading",
					status: "failed",
					feedId,
					duration: Date.now() - startTime,
					error,
				});
			},
			onSuccess: async () => {
				console.log("File Upload Complete");
				client.createContentEvent({
					contentId: uploadContentId,
					step: "client_uploading",
					status: "finished",
					feedId,
					duration: Date.now() - startTime,
				});
				markUploadAsComplete();
				ampli.uploadRecord();
			},
			onShouldRetry: (err, retryAttempt, options) => {
				console.log(err, retryAttempt, options);
				Sentry.captureException(err);
				return true;
			},
		});
	};

	/**
	 * Process time with animationFrame
	 * Builds a time object
	 * milliseconds, seconds and time
	 */
	const buildTimeObject = () => {
		requestRef.current = requestAnimationFrame(buildTimeObject);
		const now = new Date().getTime();
		const then = startTime.current.getTime();
		const timeFloorAbs = Math.floor(Math.abs(then - now) / 1000);
		setTimeObject(() => ({
			milliseconds: Math.floor(Math.abs(then - now)),
			seconds: timeFloorAbs,
			time: getPaddedTime(timeFloorAbs),
		}));
	};

	/**
	 * Start Timer
	 */
	const startTimer = (ctx: AudioContext) => {
		startTime.current = new Date();
		cancelAnimationFrame(requestRef.current);
		requestRef.current = requestAnimationFrame(buildTimeObject);
	};

	/**
	 * Stop timer
	 */
	const stopTimer = () => {
		setTimeObject(() => initialTimeObject);
		cancelAnimationFrame(requestRef.current);
	};

	/**
	 * Start recording
	 * Setup Session Core
	 * Setup Audio Node to monitor audio levels
	 * Start Timer
	 */
	const startRecording = async () => {
		const localSessionName = cuid();
		setContentId(() => localSessionName);
		setSessionName(() => localSessionName);
		client.createContentEvent({
			contentId: localSessionName,
			step: "client_recording",
			status: "started",
			feedId,
		});
		const session = new Sessioncore(localSessionName);
		const f1 = AUDIO_FILTERS.STANDARD.HIGHPASS;
		const f2 = AUDIO_FILTERS.STANDARD.LOWPASS;
		const { centerFreq, q } = getBandpassData(f1, f2);
		await session.setup(null, { bandpass: [centerFreq, q] });
		const ctx = session.ctx;
		await session.start();
		const localAudioNode = await setupAudioNode();
		if (localAudioNode) {
			setAudioNode(() => localAudioNode);
		} else {
			setAudioNode(() => null);
		}
		const wakeLock = await requestWakeLock();
		// set the previous playback state before we pause the recording
		queuePlayingRef.current = queuePlaying;
		if (queuePlaying) {
			pauseQueue();
		}
		setWakeLock(() => wakeLock);
		setRecording(() => true);
		setPlaybackEnabled(false);
		startTimer(ctx);
		ampli.startRecord({ feedId });
		sessionCore.current = session;
	};

	const compareTimerDurationWithSessionCoreDuration = (
		duration,
		sessionCoreDuration,
	) => {
		// how long the timer says the audio is
		const loggedDuration: number = Math.ceil(duration);
		// how long session core says the audio is
		const sessionDuration: number = Math.floor(sessionCoreDuration);
		// threshold to check audio difference
		const allowableRange: number = duration / 10 + duration > 10 ? 10 : 0;
		const low: number = loggedDuration - allowableRange;
		const high: number = loggedDuration + allowableRange;
		const durationMisMatch: boolean =
			sessionDuration < low && sessionDuration > high;

		// if the there is an audio mismatch log it
		if (durationMisMatch) {
			ampli.localProcessingError({
				duration: loggedDuration,
				expectedDuration: sessionDuration,
				message: "sessioncore audio duration does not match timer duration",
			});
		}
	};

	/**
	 * Stop recording and upload audio file
	 */
	const stopRecording = async () => {
		const duration = timeObject.milliseconds / 1000;
		// prevent double clicks
		if (disabled) {
			return false;
		}
		client.createContentEvent({
			contentId: contentId,
			step: "client_recording",
			status: "finished",
			feedId,
			duration: timeObject.milliseconds,
		});
		setDisabled(() => true);
		setProcessing(() => true);
		stopTimer();
		const startProcessingTime = Date.now();
		client.createContentEvent({
			contentId: contentId,
			step: "client_processing",
			status: "started",
			feedId,
		});
		// wait 1/2 extra second to ensure all recording is complete.
		await new Promise((resolve) => setTimeout(resolve, 500));
		await sessionCore.current.stop();
		setAudioLevel(() => 0);
		setCounter(() => 0);
		setRecording(() => false);
		setSnackBarOpen(() => false);

		resumePlayback();

		const file = await sessionCore.current.wav();

		if (!file) {
			ampli.audioProcessingTimeout();
		}

		compareTimerDurationWithSessionCoreDuration(
			duration,
			sessionCore.current.ctx.currentTime,
		);

		// close/stop any tracks for force stop recording
		audioNode.mediaStream.getTracks().forEach((track) => track.stop());
		await cancelWakeLock(wakeLock);

		if (forceDownload) {
			forceFileDownload(file, `${sessionName}.wav`, "audio/x-wav");
		}

		const processingDuration = Date.now() - startProcessingTime;
		if (file) {
			client.createContentEvent({
				contentId: contentId,
				step: "client_processing",
				status: "finished",
				feedId,
				duration: processingDuration,
			});
			await setupUploadData(file, duration);
		} else {
			client.createContentEvent({
				contentId: contentId,
				step: "client_processing",
				status: "failed",
				feedId,
				duration: processingDuration,
			});
		}
	};

	const resumePlayback = () => {
		setPlaybackEnabled(true);
		// if we were previously playing before the recording started and we have a queue still
		// then continue playback
		if (queuePlayingRef.current && audioQueue?.length > 0) {
			playQueue();
		}
	};

	/**
	 * Cancel recording and clear default values
	 */
	const cancel = async () => {
		const duration = timeObject.milliseconds / 1000;
		client.createContentEvent({
			contentId: contentId,
			step: "client_recording",
			status: "canceled",
			feedId,
			duration: duration,
		});
		stopTimer();
		setContentId(() => null);
		setAudioLevel(() => 0);
		setCounter(() => 0);
		setProcessing(() => false);
		setRecording(() => false);
		setSnackBarOpen(() => false);
		resumePlayback();
		await sessionCore.current.stop();
		audioNode.mediaStream.getTracks().forEach((track) => track.stop());
		await cancelWakeLock(wakeLock);
	};

	/**
	 * Set the current interval / counter level
	 * @param seconds
	 */
	const setCurrentCounter = (seconds) => {
		if ((seconds + 1) % intervalLength === 1 && seconds + 1 >= intervalLength) {
			setCounter((value) => value + 1);
		}
	};

	/**
	 * Create a smooth animation for the progress bar
	 * @param milliseconds
	 */
	const calculateRadialProgressInMilliSeconds = (milliseconds) => {
		const seconds = milliseconds / 1000;
		const multiplier = counter * 100;
		const radialProgressPercentage = Number(
			(Math.ceil(seconds * 100) / intervalLength - multiplier).toFixed(2),
		);
		if (radialProgressPercentage > 100) {
			setProgress(() => 0);
		}
		setProgress(() => radialProgressPercentage);
	};

	/**
	 * Auto-Stop recording if seconds is greater or eaual to max recording length
	 * @param milliseconds
	 */
	const autoStopRecording = async (milliseconds) => {
		const maxMilliseconds = maxRecordingLength * 1000;
		if (milliseconds >= maxMilliseconds && !disabled) {
			await stopRecording();
		}
	};

	/**
	 * Close The Snackbar
	 */
	const handleClose = () => setSnackBarOpen(false);

	/**
	 * Open Snackbar when a message is available
	 * @param counter
	 */
	const openSnackBar = (counter: number) => {
		const currentInterval = intervals[counter];
		if (!currentInterval) {
			return false;
		}
		const { message, color, severity } = currentInterval;
		if (color) {
			setColor(() => color);
		}

		const hasMessage = message?.length > 1 ? message : false;
		if (hasMessage) {
			setMessage(() => message);
			setSnackBarOpen(() => true);
		}
		if (severity) {
			setSeverity(() => severity);
		}
	};

	/**
	 * Close Snackbar
	 * @param milliseconds
	 */
	const closeSnackBar = (milliseconds) => {
		const seconds = Math.floor(milliseconds / 1000);
		const currentInterval =
			(seconds + 2) % intervalLength === 1 && seconds + 2 >= intervalLength;

		if (currentInterval && snackBarOpen) {
			setSnackBarOpen(() => false);
		}
	};

	/**
	 * Upload file when it's ready
	 */
	useEffect(() => {
		if (upload) {
			setProcessing(() => false);
			upload.start();
		}
	}, [upload]);

	/**
	 * Open Snack Bar when messages are available
	 */
	useEffect(() => {
		openSnackBar(counter);
	}, [counter]);

	/**
	 * Close Snackbar
	 * Auto Stop Recording
	 * Calculate Radial Progress
	 * Set Audio Levels
	 */
	useEffect(() => {
		closeSnackBar(timeObject.milliseconds);
		autoStopRecording(timeObject.milliseconds);
		calculateRadialProgressInMilliSeconds(timeObject.milliseconds);
		setAudioLevel(() => monitorAudioLevels(audioNode, isMobile));
	}, [
		timeObject.milliseconds,
		closeSnackBar,
		autoStopRecording,
		calculateRadialProgressInMilliSeconds,
		audioNode,
		isMobile,
	]);

	/**
	 * Update counter based on current time in seconds
	 */
	useEffect(() => {
		setCurrentCounter(timeObject.seconds);
	}, [timeObject.seconds]);

	/**
	 * On Mount clear index db
	 */
	useEffect(() => {
		clearIndexDB(null, sessionCorePrefix);
	}, []);

	const goToPlaybackItem = React.useCallback(() => {
		const currentQueueItemItemId = audioQueue?.[0].itemId;
		if (currentQueueItemItemId) {
			const queueItemIndex = feedItems.findIndex(
				(item) => item.id === currentQueueItemItemId,
			);
			vListRef?.current?.scrollToIndex(queueItemIndex, {
				align: "start",
				smooth: true,
			});
		}
	}, [audioQueue, feedItems]);

	// if a user leaves the current feed and is recording, then cancel their recording
	useLocationChange(() => {
		if (recording) {
			cancel();
		}
	});

	// Current active icon in button
	const buttonIcon = useMemo(() => {
		if (processing) {
			return <HourglassTopTwoToneIcon sx={{ fontSize: 45 }} />;
		} else if (recording) {
			return <SendIcon sx={{ fontSize: 45 }} />;
		} else {
			return <MicIcon sx={{ fontSize: 55 }} />;
		}
	}, [recording, processing]);

	// Bottom text on footer
	const bottomMessage = useMemo(() => {
		if (processing) {
			return "Processing Request";
		} else if (uploadProgress > 0) {
			return "Uploading";
		}
		return `Click to ${recording ? "send" : "record"}`;
	}, [processing, uploadProgress, recording]);

	const showAudioLevels = useMemo(
		() =>
			!!(recording && audioLevel && !disabled && timeObject.milliseconds > 0),
		[recording, audioLevel, disabled, timeObject],
	);

	const _drawerOpenStyles = {
		...drawerOpenStyles({
			isSmUp,
			leftNavOpen,
			rightNavOpen,
			theme,
		}),
	};

	/**
	 * Handle Voice Commands (if enabled)
	 */
	useEffect(() => {
		if (voiceAssistant && command) {
			if (command === "start-recording") {
				startRecording();
			} else if (command === "stop-recording") {
				stopRecording();
			} else if (command === "cancel-recording") {
				cancel();
			}
		}
	}, [command, voiceAssistant]);

	if (!workspaceId || !feedId) return null;

	return (
		<Box sx={{ position: "relative" }}>
			{uploadProgress > 0 && (
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					sx={{
						width: "100%",
						padding: 1,
						top: { xs: 85 },
						zIndex: 1100,
					}}
					open={true}
				>
					<Alert
						severity="info"
						sx={{
							width: "100%",
							color: theme.palette.primary.main,
						}}
						elevation={6}
						variant="filled"
					>
						Uploading recording... {uploadProgress}%
					</Alert>
				</Snackbar>
			)}
			<SessionRecorderWrapper
				className="session-recording-footer"
				aria-label={bottomMessage}
				sx={{
					..._drawerOpenStyles,
					marginLeft: 0,
				}}
			>
				{!recording && audioQueue?.[0] && (
					<Chip
						sx={{
							position: "absolute",
							top: "-45px",
							background: theme.palette.brand.primary.light,
							"&:hover": { background: theme.palette.brand.primary.light },
						}}
						role="presentation"
						clickable
						onClick={goToPlaybackItem}
						label={
							<PlaybackIndicator playing={queuePlaying} variant="secondary" />
						}
					/>
				)}

				{recording && showSnackBar && (
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						sx={{ width: "100%", padding: 1, marginTop: "60px" }}
						open={snackBarOpen}
					>
						<Alert
							icon={false}
							onClose={handleClose}
							severity={severity}
							sx={{
								width: "100%",
								color: theme.palette.primary.main,
								top: "100px",
							}}
							elevation={6}
							variant="filled"
						>
							{message}
						</Alert>
					</Snackbar>
				)}

				<div className="session-recording-footer-info" role="presentation">
					{showAudioLevels && (
						<div>
							<div className="audio-level-indicator">
								<AudioLevelIndicator
									audioLevel={audioLevel}
									color={theme.palette.primary.main}
									size={30}
								/>
							</div>
							<div>{timeObject.time}</div>
						</div>
					)}
				</div>

				<div
					className={`session-recording-footer-toggle-buttons${
						recording ? " recording" : ""
					}`}
				>
					{recording && !disabled && (
						<button
							type="button"
							className="session-recording-footer-cancel"
							onClick={cancel}
							aria-label="Cancel recording"
						>
							<ClearIcon sx={{ fontSize: 25 }} />
						</button>
					)}

					{!recording && (
						<UploadFileButton
							sx={{
								position: "absolute",
								left: "10px",
								boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
								borderRadius: "50%",
								background: theme.palette.secondary.main,
							}}
						/>
					)}
					<button
						type="button"
						id="recordButton"
						className={`session-recording-footer-toggle-button${
							processing ? " processing-request" : ""
						}`}
						disabled={disabledState}
						onClick={recording ? stopRecording : startRecording}
						aria-label="Record new message"
					>
						{buttonIcon}
					</button>

					{!recording && (
						<TTSButton
							sx={{
								position: "absolute",
								right: "10px",
								boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
								borderRadius: "50%",
								background: theme.palette.secondary.main,
							}}
						/>
					)}

					{recording && progress > 0 && (
						<Box
							sx={{
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
							}}
						>
							<RadialProgress
								progress={progress}
								width={120}
								ringColor={theme.palette.secondary.light}
								progressColor={color}
								backgroundColor={theme.palette.primary.dark}
							/>
						</Box>
					)}
				</div>

				<div
					className="session-recording-footer-helper-text"
					aria-live="polite"
				>
					{bottomMessage}
				</div>
				{(recording || processing || uploadProgress > 0) && debugConsole && (
					<Box
						sx={{
							left: "20px",
							top: "-400px",
							position: "absolute",
							padding: 1,
							fontSize: "11px",
							background: "#4a3559",
						}}
					>
						<div>Test Console</div>
						<div>
							counter: {counter}/{maxRecordingLength / intervalLength}{" "}
						</div>
						<div>
							max: {maxRecordingLength} seconds or {maxRecordingLength / 60}{" "}
							minutes
						</div>
						<div>seconds: {timeObject?.seconds}</div>
						<div>audioLevel:{Math.floor(audioLevel)}</div>
						<div>uploadProgress:{uploadProgress}</div>
						<div>progress:{progress}</div>
						<div>disabled:{disabled ? "true" : "false"}</div>
						<div>recording:{recording ? "true" : "false"}</div>
					</Box>
				)}
			</SessionRecorderWrapper>
			{recording && <div className="overlay" />}
		</Box>
	);
}
