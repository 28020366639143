import { SchedulingContext } from "@/models/SchedulingContextProvider";
import {
	Button,
	Stack,
	Link,
	Select,
	MenuItem,
	Typography,
} from "@mui/material";
import { useContext, useEffect, useState, useMemo } from "react";
import { format } from "date-fns";
import { UxContext } from "@/models/UxStateProvider";
import ModalForm, { MODAL_DEFAULT_Z_INDEX } from "@/elements/ModalForm";
import { StyledInput } from "../Utils";
import DaysOfWeekPicker, { MapDaysOfWeek } from "../DaysOfWeekPicker";

export type ScheduleMessageRecurringOptionsProps = {
	disabled?: boolean;
	doesRepeat?: boolean;
	doesRepeatHandler?: Function;
	selectedDate: Date;
	selectedDaysOfWeek: Array<number>;
	selectedDaysOfWeekHandler?: Function;
};

export default function ScheduleMessageRecurringOptions({
	doesRepeat,
	doesRepeatHandler,
	selectedDate,
	selectedDaysOfWeek,
	selectedDaysOfWeekHandler,
	disabled,
}: ScheduleMessageRecurringOptionsProps) {
	const { recurringScheduleModalOpen, setRecurringScheduleModalOpen } =
		useContext(UxContext);

	// const repeatPeriodOptions = [
	// 	{
	// 		value: 1,
	// 		label: `Day${repeatFrequency > 1 ? "s" : ""}`,
	// 	},
	// 	{ value: 7, label: `Week${repeatFrequency > 1 ? "s" : ""}` },
	// 	{ value: 30, label: `Month${repeatFrequency > 1 ? "s" : ""}` },
	// 	// { value: 356, label: `Year${repeatFrequency > 1 ? "s" : ""}` },
	// ];
	const recurringMessage = `Repeats every ${MapDaysOfWeek(
		selectedDaysOfWeek?.[0],
	)} at ${format(selectedDate, "hh:mm aa")}`;

	const repeats = doesRepeat ? 1 : 0;

	const handleDoesRepeat = (e) => {
		const value = e.target.value as number;
		if (value === 1) {
			doesRepeatHandler(true);
			setRecurringScheduleModalOpen(true);
		} else {
			doesRepeatHandler(false);
		}
	};

	const handleDaysSelection = (days) => {
		selectedDaysOfWeekHandler(days);
	};

	const saveSchedule = () => {
		setRecurringScheduleModalOpen(false);
	};

	return (
		<Stack sx={{ width: "100%" }}>
			<Stack sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}>
				<Select
					sx={{ maxWidth: "267px", width: "100%" }}
					value={repeats}
					onChange={handleDoesRepeat}
					MenuProps={{
						sx: {
							zIndex: MODAL_DEFAULT_Z_INDEX,
							mt: 1,
						},
					}}
					disabled={disabled}
				>
					<MenuItem value={0}>Does not repeat</MenuItem>
					<MenuItem value={1}>Custom recurrence</MenuItem>
				</Select>
				{doesRepeat ? (
					<Link
						component="button"
						onClick={() => setRecurringScheduleModalOpen(true)}
					>
						Edit
					</Link>
				) : null}
			</Stack>
			<ModalForm
				maxWidth={485}
				maxHeight={530}
				open={recurringScheduleModalOpen}
				onClose={() => setRecurringScheduleModalOpen(false)}
				sx={{ padding: { sx: "28px", sm: "28px" } }}
			>
				<Stack sx={{ width: "100%", gap: 3.5 }}>
					<Typography variant="h5" component="h3">
						Custom recurrence
					</Typography>
					<Stack sx={{ padding: 1, gap: 3.5 }}>
						{/* TODO: Reimplement the option for frequency period at a later date */}
						{/* <Stack
							sx={{
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
								gap: 1,
							}}
						>
							<Typography>Repeat every</Typography>
							<StyledInput
								type="number"
								inputProps={{ min: 1 }}
								sx={{ maxWidth: "90px" }}
								value={repeatFrequency}
								onChange={(e) => setRepeatFrequency(Number(e.target.value))}
							/>
							<Select
								value={repeatPeriod}
								sx={{ width: "100%", maxWidth: 200 }}
								MenuProps={{
									sx: {
										zIndex: MODAL_DEFAULT_Z_INDEX,
										mt: 1,
									},
								}}
								onChange={(e) => setRepeatPeriod(e.target.value as number)}
							>
								{repeatPeriodOptions.map((period) => (
									<MenuItem
										key={`period-options-${period.value}`}
										value={period.value}
									>
										{period.label}
									</MenuItem>
								))}
							</Select>
						</Stack> */}
						<DaysOfWeekPicker
							label="Repeat every"
							selectedDays={selectedDaysOfWeek}
							daySelectionHandler={handleDaysSelection}
							multiDay={false}
						/>
						{selectedDaysOfWeek?.length > 0 && (
							<Typography sx={{ fontWeight: 600 }}>
								{recurringMessage}
							</Typography>
						)}
						<Stack
							sx={{
								flexDirection: { xs: "column", sm: "row" },
								width: "100%",
								gap: 2,
							}}
						>
							<Button
								variant="outlined"
								sx={{ order: { xs: 1, sm: 0 } }}
								onClick={() => setRecurringScheduleModalOpen(false)}
							>
								Back
							</Button>
							<Button
								variant="contained"
								color="primary"
								sx={{ order: { xs: 0, sm: 1 } }}
								onClick={saveSchedule}
							>
								Done
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</ModalForm>
		</Stack>
	);
}
