import ReloadPrompt from "@/components/ReloadPrompt";
import AppStateProvider from "@/models/AppStateProvider";
import AudioAppContextProvider from "@/models/AudioAppContextProvider";
import RouteGuard from "@/models/RouteGuard";
import TrackingStateProvider from "@/models/TrackingStateProvider";
import UxStateProvider from "@/models/UxStateProvider";
import { ConfigData, FlagData } from "@/routes/index";
import Layout from "@/routes/layout";
import { ScrollRestoration, useOutlet } from "react-router-dom";
import Client from "web-client/client";
import { Ampli } from "./ampli";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import { ElectricWrapper } from "@/electric/ElectricWrapper";
import AppPhoneContextProvider from "@/models/AppPhoneContextProvider";
import DataProvider from "@/models/DataProvider";
import ActionsProvider from "@/models/ActionsProvider";
import UppyContextProvider from "@/models/UppyContextProvider";
import SchedulingContextProvider from "@/models/SchedulingContextProvider";
import VoiceAssistantContextProvider from "@/models/VoiceAssistantContextProvider";

function App({
	config,
	client,
	ampli,
	flags,
}: {
	config: ConfigData;
	client: Client;
	ampli: Ampli;
	flags: FlagData;
}) {
	const outlet = useOutlet();
	const {
		baseDomain,
		tusUrl,
		graphqlUrl,
		graphqlApiKey,
		sessionRecordingLength,
		gaKey,
		env,
		trackingKey,
	} = config;
	console.log("AppRoot", {
		baseDomain,
		tusUrl,
		graphqlUrl,
		graphqlApiKey,
		gaKey,
		env,
		trackingKey,
		flags,
	});

	return (
		<>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<Layout>
					<TrackingStateProvider ampli={ampli} />
					<ElectricWrapper>
						<AudioAppContextProvider handsFreeModeFlag={true}>
							<AppStateProvider
								client={client}
								baseDomain={baseDomain}
								tusUrl={tusUrl}
								graphqlUrl={graphqlUrl}
								graphqlApiKey={graphqlApiKey}
								sessionRecordingLength={sessionRecordingLength}
								initialFlags={flags}
							>
								<DataProvider client={client}>
									<ActionsProvider client={client}>
										<AppPhoneContextProvider>
											<RouteGuard>
												<UppyContextProvider>
													<UxStateProvider>
														<VoiceAssistantContextProvider>
															<SchedulingContextProvider>
																{outlet}
																<ReloadPrompt />
																<ScrollRestoration />
															</SchedulingContextProvider>
														</VoiceAssistantContextProvider>
													</UxStateProvider>
												</UppyContextProvider>
											</RouteGuard>
										</AppPhoneContextProvider>
									</ActionsProvider>
								</DataProvider>
							</AppStateProvider>
						</AudioAppContextProvider>
					</ElectricWrapper>
				</Layout>
			</ErrorBoundary>
		</>
	);
}

export default App;
